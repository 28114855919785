import * as types from "./types";
/**
 * this action will login the user
 */
export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user,
  };
};

export const loginCustomerAction = (payload) => {
  return {
    type: types.LOGIN_CUSTOMER,
    payload,
  };
};
/**
 * this action will login the customer
 */
export const registrationCustomerAction = (payload) => {
  return {
    type: types.REGISTRATION_CUSTOMER,
    payload,
  };
};

/**
 * this action will update the user
 */
export const setUser = (user) => {
  return {
    type: types.SET_USER,
    payload: user,
  };
};
/**
 * this action will update the customer
 */
export const setCustomer = (payload) => {
  return {
    type: types.SET_CUSTOMER,
    payload,
  };
};

/**
 * this action will logout the user
 */
export const logOutUser = (data) => {
  return {
    type: types.LOGOUT_USER,
    payload: data,
  };
};
/**
 * this action will logout the customer
 *
 */
export const logOutCustomer = () => {
  return {
    type: types.LOGOUT_CUSTOMER,
  };
};
/**
 * this action will change password of the customer
 *
 */
export const changePassword = (payload) => {
  return {
    type: types.CHANGE_CUSTOMER_PASSWORD,
    payload,
  };
};

export const getLocation = () => ({
  type: types.GET_LOCATION,
});

export const getLocationAdmin = () => ({
  type: types.GET_ADMIN_LOCATION,
});

export const selectLocation = (payload) => ({
  type: types.SELECT_LOCATION,
  payload,
});

export const createLocation = (payload) => ({
  type: types.CREATE_LOCATION,
  payload,
});

export const updateLocation = (payload) => ({
  type: types.UPDATE_LOCATION,
  payload,
});

export const getAddressAndPhone = () => ({
  type: types.GET_ADDRESS_AND_PHONE,
});
