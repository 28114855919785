import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  restaurentTiming:[],
  msg: "",
};
function manageRestaurentReducer(state = initialState, action) {
  const { response, payload, msg } = action;
  switch (action.type) {
    case types.GET_RESTAURENT_TIMING:
      return { ...state, loading: true };
    case types.GET_RESTAURENT_TIMING_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        restaurentTiming: response.result || [],
        msg: msg,
      };
    case types.GET_RESTAURENT_TIMING_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}

export default manageRestaurentReducer;
