import * as types from "./types";

/***
 * this action will get payments list 
 */
export const getPayment = () => ({
    type: types.GET_PAYMENT,
    payload:{}
});

/***
 * this action will refund  payment 
 */
export const refundPayment = (payload) => ({
    type: types.REFUND_PAYMENT,
    payload
});