import React, { useState, useEffect } from "react";
import RightSideModal from "../../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, FormLabel, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import validation from "../../../helpers/validation";
import {
  addDeliveryCharges,
  updateDeliveryCharges,
  deleteDeliveryCharges,
} from "../../../Action/DeliveryChargesAction";
// import duplicateValidation from "../../helpers/duplicateValidation";

function ManageDeliveryChargesPopup(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data: editDataObject },
  } = props;
  const [deliverCharges, setDeliverCharges] = useState([
    {
      min: 0,
      max: 0,
      driverReimbursement: 0,
      price: 0,
    },
  ]);
  const [errors, setErrors] = useState([]);
  //close  the model and clear the state
  const closeModaleWithClearAll = () => {
    closeModal();
    setDeliverCharges([
      {
        min: 0,
        max: 0,
        driverReimbursement: 0,
        price: 0,
      },
    ]);
    setErrors([]);
  };
  //handle change in delivery charges
  const handleDeliveryChages = (e, index) => {
    setDeliverCharges((prevSate) =>
      prevSate.map((item, i) =>
        i === index ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  //add new row in form
  const addnewDeliveryCharges = () => {
    const addNewValues = [...deliverCharges];
    addNewValues.push({
      min: 0,
      max: 0,
      driverReimbursement: 0,
      price: 0,
    });
    setDeliverCharges(addNewValues);
  };
  //delete row from form
  const deleteDeliveryChargeRow = (index) => {
    const deleteValues = [...deliverCharges];
    deleteValues.splice(index, 1);
    setDeliverCharges(deleteValues);
  };
  //submit the modifier
  const onSubmitMasterFormModifier = (e, action) => {
    e.preventDefault();
    const isValid = validation(deliverCharges);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      deliverCharges.map((item, index) => {
        switch (action) {
          case "create":
            dispatch(addDeliveryCharges(item));
            break;
          case "update":
            dispatch(
              updateDeliveryCharges({
                data: item,
                _id: item._id,
              })
            );
            break;
          default:
            break;
        }
      });
      closeModaleWithClearAll();
    }
  };
  //mount the delivery charges
  useEffect(
    (res) => {
      if (edit) {
        setDeliverCharges([editDataObject]);
      }
    },
    [editDataObject]
  );
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addnewDeliveryCharges}
          title={`${edit ? "Update" : "Add"} Delivery  Charges`}
          addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_add_master_modifier"}
            onSubmit={(e) =>
              onSubmitMasterFormModifier(e, edit ? "update" : "create")
            }
          >
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th>Minimum Miles</th>
                    <th>Maximum Miles</th>
                    <th>Charges</th>
                    <th>Driver Fee</th>
                    {edit === true ? "" : <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(deliverCharges).length > 0
                    ? deliverCharges.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="number"
                              name="min"
                              placeholder="Minimum Mile"
                              value={item.min}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDeliveryChages(e, index)}
                            />
                            {errors[index]?.min && (
                              <p className="error-validation">
                                {errors[index].min}
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="max"
                              placeholder="Maximum Mile"
                              value={item.max}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDeliveryChages(e, index)}
                            />
                            {errors[index]?.max && (
                              <p className="error-validation">
                                {errors[index].max}
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="price"
                              placeholder="Driver Fee"
                              value={item.price}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDeliveryChages(e, index)}
                            />
                            {errors[index]?.price && (
                              <p className="error-validation">
                                {errors[index].price}
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="driverReimbursement"
                              placeholder="Driver Fee"
                              value={item.driverReimbursement}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDeliveryChages(e, index)}
                            />
                            {errors[index]?.driverReimbursement && (
                              <p className="error-validation">
                                {errors[index].driverReimbursement}
                              </p>
                            )}
                          </td>
                          {edit === true ? (
                            ""
                          ) : (
                            <td>
                              <div className="deleteButton_modifier_category">
                                <button
                                  disabled={
                                    Object.keys(deliverCharges).length === 1
                                      ? true
                                      : false
                                  }
                                  className="deleteButton_1"
                                  onClick={() => deleteDeliveryChargeRow(index)}
                                  type="button"
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">{edit ? "Update" : "Save"}</button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default ManageDeliveryChargesPopup;
