import React, { useEffect } from 'react';
import { ComponentLoader } from '../../../helpers/PageLoader';
const OutOfStock = ({ show, handleOutOfStockMemo }) => {
    return (
        <>
            <div className={`modal custommodal custommodel1 ${show ? 'showModel' : ''}`} id="checkoutPopUp" show={{ top: "15px !importent", left: "15px !importend" }}>
                <div className="modal-dialog modal-lg" style={{ height: "510px" }}>
                    <div className="modal-content" style={{ height: "510px" }} >
                        <div className="modal-header position-relative">
                            <h4 className="modal-title"> Sorry </h4>
                            <button onClick={handleOutOfStockMemo} type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body pl-0 pr-0 pb-0 text-center">
                            <h1>Items is Out of Stock </h1>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OutOfStock;