import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLading: false,
  error: {},
  msg: "",
  modifierCategoryList: [],
};

const manageModifierCategoryReducer = (state = initialState, action) => {
  const { msg, payload, error } = action;
  switch (action.type) {
    case types.GET_MODIFIER_CATEGORY_LIST:
      return { ...state, loading: true };
    case types.POST_MODIFIER_CATEGORY_LIST:
      return { ...state, tableLading: true };
    case types.DELETE_MODIFIER_CATEGORY_LIST_ITEM:
      return { ...state, tableLading: true };
    case types.UPDATE_MODIFIER_CATEGORY_LIST_ITEM:
      return { ...state, tableLading: true };

    case types.GET_MODIFIER_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLading: false,
        modifierCategoryList: payload,
        msg: msg,
      };

    case types.GET_MODIFIER_CATEGORY_LIST_ERROR:
      return { ...state, loading: false, tableLading: false, error: error };

    default:
      return state;
  }
};

export default manageModifierCategoryReducer;
