import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import { FaRegTrashAlt, FaRegEdit, FaHandHoldingUsd } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../helpers/ConfirmModal";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../../helpers/PageLoader";
import { getOrdersListArchive } from "../../../Action/manageOrdersAction";
import ManageOrderPopup from "./orderDetailPopup";

const ManagePaymentArchive = () => {
  const responseData = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [showModal, setShowModal] = useState("no");
  const [showModalRefund, setShowModalRefund] = useState("no");
  const [deletConfirmModal, setDeletConfirmModal] = useState({
    show: false,
    data: [],
  });
  const [deletConfirmModalRefund, setDeletConfirmModalRefund] = useState({
    show: false,
    data: [],
  });
  /*** this function handle values acc to the condition */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
  });
  const [editDataRefund, setEditDataRefund] = useState({
    edit: false,
    data: {},
  });
  const columns = [
    {
      dataField: "randomOrderId",
      text: "OrderId",
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Stripe Payment ID",
      formatter: (cell, row) => {
        return row.payments[0]?.stripePaymentId;
      },
      filterValue: (cell, row) => {
        return row.payments[0]?.stripePaymentId;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(row.payments[0]?.amount);
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(row.payments[0]?.amount);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Method",
      formatter: (cell, row) => {
        return row.payments[0]?.paymentMethod;
      },
      filterValue: (cell, row) => {
        return row.payments[0]?.paymentMethod;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Status",
      formatter: (cell, row) => {
        return row.payments[0]?.paymentStatus;
      },
      filterValue: (cell, row) => {
        return row.payments[0]?.paymentStatus;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Email",
      formatter: (cell, row) => {
        return row.users[0]?.email;
      },
      filterValue: (cell, row) => {
        return row.users[0]?.email;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Contact Number",
      formatter: (cell, row) => {
        return row.contactNumber;
      },
      filterValue: (cell, row) => {
        return row.contactNumber;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button>
                <FaRegEdit
                  onClick={() => {
                    actionData(row, "edit");
                  }}
                />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  /** this function add or update the value and than open the order details popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
      });
    }
  };

  /** this function add or update the value and than open the payment  popup */
  const addNewModalRefund = () => {
    setShowModalRefund((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditDataRefund({
        edit: false,
        data: {},
      });
    }
  };

  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const addNewModalMemorizedRefund = useCallback(addNewModalRefund, [
    showModalRefund,
  ]);
  // mount
  useEffect(() => {
    dispatch(getOrdersListArchive());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { orderList, loading, tableLading } = responseData;
    if (orderList) {
      setLitsData(orderList.reverse());
      setIsLoading({
        pageLoading: loading,
        tableLoading: tableLading,
      });
    }
  }, [responseData]);
  /*** action for open , edit , delete */
  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
        });
        addNewModal();
        break;
      default:
        break;
    }
  };
  /** this function will handle the the add or update flag and open the popup */
  const actionDataRefundEdit = (data, action) => {
    setEditDataRefund({
      edit: true,
      data: data,
    });
    setShowModalRefund((prevState) => (prevState === "yes" ? "no" : "yes"));
  };
  /** this function handle delete close  alert  */
  const handleCloseAlert = () => {
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  /** this function handle success    alert  */
  const handleSuccessAlert = () => {
    dispatch(
      deleteModifierCategoryAction({ itemId: deletConfirmModal.data._id })
    );
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  if (isLoding.pageLoading === true) {
    return <PageLoader size={50} />;
  }
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Archive Payments</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2">
                  <h2>Manage Payments</h2>
                  {/* <button className="btn custombtn" onClick={addNewModal}>
                    Add Modifier Category
                  </button> */}
                </div>
                <div className="card-body">
                  {isLoding.pageLoading === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal === "yes" && (
        <ManageOrderPopup
          showModal={showModal}
          closeModal={addNewModalMemorized}
          editData={editData}
          refundPopupAction={actionDataRefundEdit}
        />
      )}
      {deletConfirmModal && (
        <ConfirmModal
          show={deletConfirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManagePaymentArchive;
