import { useState, useEffect } from "react";
import { getCrewOrDriverQrCode } from "../../Services/manageCrewOrDriver";
import NormalModal from "../../helpers/NormalModal";
import { ComponentLoader } from "../../helpers/PageLoader";
import { Store } from "react-notifications-component";
import { FaCopy } from "react-icons/fa";

export default function CrewOrDriverQrCodePopup(props) {
  const { showModal, closeModal , sizeModal } = props;
  const [qrCode, setqrCode] = useState(null);
  useEffect(() => {
    getCrewOrDriverQrCode({ _id: props.editData?.data?._id }).then((result) => {
      setqrCode(result.data?.result);
    });
  }, []);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handCopy = (text) => {
    navigator.clipboard.writeText(text);
    Store.addNotification({
      message: "Copied",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  };
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      sizeModal={sizeModal}
      title={capitalizeFirstLetter(props.editData?.data?.fname) + "`s QR code"}
    >
      {qrCode == null ? (
        <ComponentLoader />
      ) : (
        <div className="row">
          <div className="col-sm-12 text-center">
            <img src={qrCode} />
          </div>
        </div>
      )}
    </NormalModal>
  );
}
