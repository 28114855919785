import NormalModal from "../../helpers/NormalModal";
import "./orderNotificationPopup.css";
import o_original_logo from "../../Assets/images/o_original_logo.jpg";

export default function ManageNewOrderNotification(props) {
  const { showModal, closeModal, data, actionData, orderCounter } = props;
  const viewOrder = () => {
    props.closeModal();
    props.actionData({ ...props.data, serial: props.serial }, "edit");
  };
  const Footer = () => {
    return (
      <div className="modal-footer justify-content-center flex-column">
        <p>Need to confirm</p>
        <button className="btn btn-outline-success" onClick={viewOrder}>
          View Order
        </button>
      </div>
    );
  };
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      footer={<Footer />}
      classes="notification-modal"
    >
      <div className="order-counting text-center">
        <div className="extra-cirlce">
          <p className="confirm-county">{orderCounter}</p>
          <p className="confirm-list">Order</p>
        </div>
      </div>
    </NormalModal>
  );
}
