import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageUser";
import { Store } from "react-notifications-component";

export function* getUser() {
  try {
    const response = yield call(auth.getUser);
    yield put({
      type: types.GET_USER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_USER_ERROR,
      error,
    });
  }
}

export function* addUser({ payload }) {
  try {
    const response = yield call(auth.addUser, payload);
    yield call(getUser);
  } catch (error) {
    yield put({
      type: types.ADD_USER_ERROR,
      error,
    });
    Store.addNotification({
      message: error?.response?.data?.error?.message || "something went wrong",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

export function* updateUser({ payload }) {
  try {
    const response = yield call(auth.updateUsers, payload);
    yield call(getUser);
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* deleteUser({ payload }) {
  try {
    const res = yield call(auth.deleteUser, payload);
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getUser);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* getCustomer({ payload }) {
  try {
    const response = yield call(auth.getCustomer, payload);
    yield put({
      type: types.GET_CUSTOMER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_CUSTOMER_ERROR,
      error,
    });
  }
}
