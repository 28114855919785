import React, { useState, useEffect } from "react";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { getDriverReport } from "../../Services/reportService";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCrewOrDriver } from "../../Action/crewOrDriverAction";
import { useSelector, useDispatch } from "react-redux";
import formettedDate from "../../helpers/moomentDate";
import moment from "moment";
export default function DriverReport() {
  const dispatch = useDispatch();
  /*** get crew and driver list from redux */
  var { crewOrDriverList } = useSelector((state) => state.crewOrDriver);
  const [serviceType, setserviceType] = useState(["delivery", "pickup", "all"]);
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);
  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };
  const [summary, setsummary] = useState({
    totalDriverReimbursement: 0,
    totalGratuity: 0,
    totalPayable: 0,
  });
  const [litsData, setlitsData] = useState([]);
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  //three month old date
  var threeMonthOldDate = new Date();
  threeMonthOldDate.setMonth(threeMonthOldDate.getMonth() - 2);
  threeMonthOldDate = `${threeMonthOldDate.getFullYear()}-${
    threeMonthOldDate.getMonth() < 10
      ? "0" + threeMonthOldDate.getMonth()
      : threeMonthOldDate.getMonth()
  }-${
    threeMonthOldDate.getDate() < 10
      ? "0" + threeMonthOldDate.getDate()
      : threeMonthOldDate.getDate()
  }`;
  //today date
  var todayDate = new Date().toISOString().substr(0, 10);
  //one month old date
  var oneMonthOldDate = new Date();
  oneMonthOldDate.setMonth(oneMonthOldDate.getMonth() - 1);
  oneMonthOldDate = `${oneMonthOldDate.getFullYear()}-${
    oneMonthOldDate.getMonth() < 10
      ? "0" + oneMonthOldDate.getMonth()
      : oneMonthOldDate.getMonth()
  }-${
    oneMonthOldDate.getDate() < 10
      ? "0" + oneMonthOldDate.getDate()
      : oneMonthOldDate.getDate()
  }`;
  const [filter, setfilter] = useState({
    to: new Date(),
    from: new Date(),
    driverId: "",
  });
  const [filterError, setfilterError] = useState({
    to: false,
    from: false,
    driverId: false,
  });
  const [showResetButton, setshowResetButton] = useState(false);
  const [loading, setloading] = useState(false);
  const handleInt = (e) => Number(e) || 0;
  //handle the change in filter form
  const handleFilterChange = (type, value) => {
    setfilter({ ...filter, [type]: value });
    let tmpError = { ...filterError };
    if (type === "driverId") {
      if (value === "") {
        tmpError[type] = true;
      } else {
        tmpError[type] = false;
      }
    }
    setfilterError(tmpError);
  };
  //handleChange CheckBox
  const handleToggleCheckBox = () => {
    if (isTodayCheckBox) {
      setfilter({
        ...filter,
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY"),
      });
      getDriverReport({
        ...filter,
        collectionName: "AllOrders",
      }).then((result) => {
        setloading(false);
        setlitsData(result.result.reverse());

        calculateSummary(result.result);
        setshowResetButton(true);
      });
    } else {
      setfilter({
        ...filter,
        from: new Date(),
        to: new Date(),
      });
      getDriverReport({
        ...filter,
        collectionName: "order",
      }).then((result) => {
        setloading(false);
        setlitsData(result.result.reverse());
        calculateSummary(result.result);
        setshowResetButton(true);
      });
    }
    setisTodayCheckBox(!isTodayCheckBox);
  };

  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let tmpError = { ...filterError };
    let isValidate = true;
    Object.keys(tmpError).map((key) => {
      if (key === "driverId") {
        if (filter[key] === "") {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      }
    });
    setfilterError(tmpError);
    if (isValidate) {
      setloading(true);
      if (isTodayCheckBox) {
        getDriverReport({ ...filter, collectionName: "order" }).then(
          (result) => {
            setloading(false);
            setlitsData(result.result.reverse());
            calculateSummary(result.result);
            setshowResetButton(true);
          }
        );
      } else {
        getDriverReport({ ...filter, collectionName: "AllOrders" }).then(
          (result) => {
            setloading(false);
            setlitsData(result.result.reverse());
            calculateSummary(result.result);
            setshowResetButton(true);
          }
        );
      }
    }
  };
  //reset the filter
  const resetFilter = (e) => {
    e.preventDefault();
    getDriverReport().then((result) => {
      setlitsData(result.result);
      calculateSummary(result.result);
      setshowResetButton(false);
    });
  };
  //handle download
  const handleDownload = (type) => {
    if (!litsData.length < 1) {
      if (isTodayCheckBox) {
        getDriverReport({ ...filter }, type, "order").then((result) => {});
      } else {
        getDriverReport({ ...filter }, type, "AllOrders").then((result) => {});
      }
    }
  };
  const columns = [
    {
      dataField: "randomOrderId",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Order Date",
      formatter: (cell, row) => {
        return formettedDate(row.createdAt);
      },
      filterValue: (cell, row) => {
        return formettedDate(row.createdAt);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Driver Name",
      formatter: (cell, row) => {
        return row.drivers?.[0].fname + " " + row.drivers?.[0].lname;
      },
      filterValue: (cell, row) => {
        return row.drivers?.[0].fname + " " + row.drivers?.[0].lname;
      },
      sort: true,
    },
    {
      dataField: "gratuityAmount",
      text: "Gratuity Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(cell);
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sort: true,
    },
    {
      dataField: "driverReimbursement",
      text: "Driver Fee",
      formatter: (cell, row) => {
        return "$" + priceMature(cell);
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Total Payable",
      formatter: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row.gratuityAmount) + handleInt(row.driverReimbursement)
          )
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row.gratuityAmount) + handleInt(row.driverReimbursement)
          )
        );
      },
      sort: true,
    },
  ];
  const summaryColoumn = [
    "Total Driver Fee",
    "Total Gratuity Amount",
    "Total Payable",
  ];

  const calculateSummary = (result) => {
    let totalDriverReimbursement = 0;
    let totalGratuity = 0;
    let totalPayable = 0;
    result.forEach((item, index) => {
      totalDriverReimbursement =
        totalDriverReimbursement + handleInt(item.driverReimbursement);
      totalGratuity = totalGratuity + handleInt(item.gratuityAmount);
      totalPayable =
        totalPayable +
        handleInt(item.gratuityAmount) +
        handleInt(item.driverReimbursement);
    });
    setsummary({
      ...summary,
      ["totalDriverReimbursement"]: totalDriverReimbursement,
      ["totalGratuity"]: totalGratuity,
      ["totalPayable"]: totalPayable,
    });
  };
  /** this effect will dispatch the crew and driver */
  useEffect(() => {
    dispatch(getCrewOrDriver());
  }, []);
  const startOfMonth = moment()
    .clone()
    .startOf("month")
    .format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().clone().endOf("month").format("YYYY-MM-DD hh:mm");
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Driver Report</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                  <h2>Driver Report</h2>
                  <div>
                    <button
                      className="btn custombtn mr-2"
                      onClick={() => {
                        handleDownload("pdf");
                      }}
                    >
                      <FaRegFilePdf />
                    </button>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      <FaRegFileExcel />
                    </button>
                  </div>
                </div>
                <div className="card-body actioncard">
                  <div className="row">
                    <div className="col-sm-12">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="col">
                            <div className="form-group">
                              <select
                                value={filter.driverId}
                                name="driverId"
                                className={`form-control ${
                                  filterError.driverId ? "invalid-field" : ""
                                }`}
                                onChange={(value) => {
                                  handleFilterChange(
                                    "driverId",
                                    value.target.value
                                  );
                                }}
                              >
                                <option value="">Select Driver</option>
                                {crewOrDriverList.map((user, index) => {
                                  return user.role === "driver" ? (
                                    <option key={index} value={user._id}>
                                      {user.fname + " " + user.lname}
                                    </option>
                                  ) : (
                                    ""
                                  );
                                })}
                              </select>
                              {filterError.driverId ? (
                                <div className="invalid-feedback">
                                  Please select the driver
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">From</label>
                              {isTodayCheckBox ? (
                                <DatePicker
                                  className={`form-control ${
                                    filterError.from ? "invalid-field" : ""
                                  }`}
                                  name="from"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date()}
                                  readOnly
                                />
                              ) : (
                                <DatePicker
                                  className={`form-control ${
                                    filterError.from ? "invalid-field" : ""
                                  }`}
                                  name="from"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date(filter.from)}
                                  minDate={moment().subtract("3", "M").toDate()}
                                  maxDate={moment().subtract("1", "d").toDate()}
                                  onChange={(value) => {
                                    handleFilterChange("from", value);
                                  }}
                                />
                              )}
                              {filterError.from ? (
                                <div className="invalid-feedback">
                                  This field is required
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">To</label>
                              {isTodayCheckBox ? (
                                <DatePicker
                                  className={`form-control ${
                                    filterError.to ? "invalid-field" : ""
                                  }`}
                                  name="to"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date()}
                                  readOnly
                                />
                              ) : (
                                <DatePicker
                                  className={`form-control ${
                                    filterError.to ? "invalid-field" : ""
                                  }`}
                                  name="to"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date(filter.to)}
                                  minDate={moment().subtract("3", "M").toDate()}
                                  maxDate={moment().subtract("1", "d").toDate()}
                                  onChange={(value) => {
                                    handleFilterChange("to", value);
                                  }}
                                />
                              )}
                              {filterError.to ? (
                                <div className="invalid-feedback">
                                  This field is required
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4 searhorder-btn">
                            <div className="regBtn">
                              <button className="btn mr-2" disabled={false}>
                                Search
                              </button>

                              {showResetButton ? (
                                <button className="btn" onClick={resetFilter}>
                                  clear
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="text-right mb-3">
                    <div className="today_order-wrapper">
                      <input
                        type="checkbox"
                        defaultChecked={isTodayCheckBox}
                        onClick={handleToggleCheckBox}
                      />
                      <b className="ml-1">Today's Orders</b>{" "}
                    </div>
                  </div>
                  <BootstrapeTableData
                    data={litsData}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                    isLoading={loading}
                  />
                  {litsData.length > 0 ? (
                    <div className="react-bootstrap-table">
                      <table className="table table-bordered">
                        <thead className={"font-weight-bold"}>
                          {summaryColoumn.map((value, index) => {
                            return <th key={index}> {value}</th>;
                          })}
                        </thead>
                        <tbody>
                          <tr>
                            <td key={3}>
                              ${priceMature(summary.totalDriverReimbursement)}
                            </td>
                            <td key={2}>
                              ${priceMature(summary.totalGratuity)}
                            </td>
                            <td key={4}>
                              ${priceMature(summary.totalPayable)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
