import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Stripe from "../stripe/stripe";
import Banner from "./Banner";
import DeliveryAndPickup from "../cart/DeliveryAndPopup";
import "./checkout.css";
import { post } from "../../../Services/api";
import { flushCart } from "../../../Action/cartAction";
import { PageLoader } from "../../../helpers/PageLoader";
import { Store } from "react-notifications-component";
import { calculateDeliveryCharges } from "../../../Services/deliveryAndCharges";
import {
  getCustomerAddressAndPhoneNumber,
  getUserAddressAndPhoneNumber,
} from "../../../Services/authenticationServices";
import { Link } from "react-router-dom";
import { getDiscount } from "../../../Action/manageDiscount";
import { getManageProfileForm } from "../../../Action/manageProfileAction";
import { getSingleCoupon } from "../../../Services/manageCoupon";
import formettedDate from "../../../helpers/moomentDate";
import moment from "moment";
import { FaTimesCircle } from "react-icons/fa";
import { getCustomerWallet } from "../../../Services/walletServices";
import ItemSuggestionBox from "./itemSuggestionBox";
import CostumCart from "../cart/CostumCart";
import { getCustomizeItemModifierById } from "../../../Action/manageProductCategoryAction";
import { loadStripe } from "@stripe/stripe-js";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js"

const Checkout = () => {
  const location = useSelector((state) => state.location);
  const [locationStripe, setLocationStripe] = useState("");
  useEffect(() => {
    let a = localStorage.getItem("locationId");
    for (let i = 0; i < location.location.length; i++) {
      if (location.location[i]._id == a) {
        setLocationStripe(location.location[i].publicStripeKey);
      }
    }
  }, [localStorage.locationId]);
  const stripePromise = loadStripe(locationStripe);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [couponStatus, setcouponStatus] = useState(false);
  const [discountStatus, setdiscountStatus] = useState(false);
  const delivaryOrPickup = useSelector((state) => state.delivaryOrPickup);
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const customer = useSelector((state) => state.customer);
  const discount = useSelector((state) => state.discount);
  const [discountobj, setdiscountobj] = useState({});
  const history = useHistory();
  const [paymentType, setpaymentType] =
    useState("coc"); /* should be coc or cod */
  const [deliveryandpickup, setdeliveryandpickup] = useState(false);
  const [loading, setloading] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [discountAmount, setdiscountAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [driverReimbursement, setdriverReimbursement] = useState(0);
  const [isMountainable, setisMountainable] = useState(false);
  const [gratuityAmount, setgratuityAmount] = useState(0);
  const [coupon, setCoupon] = useState({
    isCouponApplied: false,
    couponName: "",
    amount: 0,
    coupon: {},
  });
  const [wallet, setwallet] = useState(null);
  const [isShowCustomcart, setisShowCustomcart] = useState(false);
  const [customCartData, setcustomCartData] = useState({});
  const customizeModalMemorized = useCallback(function (
    item,
    itemName,
    isEdited = false,
    uniqueId = null,
    halfTopping = false,
    itemImage = ""
  ) {
    setcustomCartData({
      itemId: item,
      itemName,
      isEdited,
      uniqueId,
      halfTopping,
      itemImage,
    });
    dispatch(getCustomizeItemModifierById(item));
    setisShowCustomcart(!isShowCustomcart);
  });
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  /** if cart is empty refirect the user to the home page  */
  if (cart.length < 1) {
    history.push("/");
  }
  /**  if cart has some bad data , just throw user  to  the homepage */
  if (typeof delivaryOrPickup.data === "undefined") {
    history.push("/");
    return "";
  }
  /** handle coupon rejection */
  const handleCouponError = (type, text) => {
    Store.addNotification({
      message: text,
      type: type,
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 6000,
        onScreen: true,
      },
    });
  };
  /** remove coupon  */
  const removeCoupon = () => {
    setCoupon({
      ...coupon,
      coupon: {},
      isCouponApplied: false,
      amount: 0,
      couponName: "",
    });
  };
  /** apply coupon */
  const applyCoupon = () => {
    if (coupon.isCouponApplied) {
      return;
    }
    getSingleCoupon({ couponName: coupon.couponName })
      .then((res) => {
        if (res.result) {
          if (!coupon.isCouponApplied) {
            let isValid = false;
            let couponObj = res.result;
            let tmpCoupon = { ...coupon };
            if (couponObj) {
              let total = 0;
              cart.map((cartItem, index) => {
                total = total + cartItem.itemPrice * cartItem.quantity;
              });
              const cartItems = cart.map((item) => {
                return JSON.stringify({ "itemId": item.itemId, "variationId": item.variationId })
              }
              )
              const itemSet1 = [], itemSet2 = []


              // const isAnd = couponObj.couponCombo.some(coupon => coupon.type_text === 'AND')

              // ----------------------------------------- Itemized Coupon -------------------------------------------------
              if (couponObj.itemizedCoupon) {
                if (couponObj.couponMinimumAmount <= total) {
                  // const comboItems = couponObj.couponCombo.map((item) => {
                  //   return JSON.stringify({ "itemId": item.itemId, "variationId": item.variationId })
                  // })

                  const isCartValid = couponObj.couponCombo.filter(couponItem => cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId))

                  couponObj.couponCombo.map((item, index) => {
                    if (item.type_text === 'AND' && item.type === 0 && index <= 2) {
                      itemSet1.push(couponObj.couponCombo[index])
                    } else {
                      itemSet2.push(couponObj.couponCombo[index])

                    }
                  })

                  // Condition 4 combo coupon
                  // if(isAnd && couponObj.couponCombo.every(coupon => coupon.type === 0)) {
                  //   const isCartValid1 = itemSet1.filter(couponItem => cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId)).length;
                  //   const isCartValid2 = itemSet2.filter(couponItem => cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId)).length;
                  //   console.log('RETERT')

                  //   if(isCartValid1 === 0 || isCartValid2 === 0) {
                  //     isValid = true
                  //   } else {
                  //     isValid = false
                  //   }
                  //  } 
                  //  if(!isAnd && couponObj.couponCombo.every(coupon => coupon.type === 0)) {
                  if (couponObj.couponCombo.every(coupon => coupon.type === 0)) {

                    // Everything is OR         
                    isValid = !isCartValid.length || isCartValid.length > 1
                  } else if (couponObj.couponCombo[0]?.type === 1 && couponObj.couponCombo[1]?.type === 0 && couponObj.couponCombo[2]?.type === 0) {
                    const singleAndCoupon = couponObj.couponCombo.shift()
                    const remaingCoupon = couponObj.couponCombo

                    const remCouponValid = remaingCoupon.filter(couponItem => cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId)).length
                    const andCouponValid = cart.filter(ct => ct.itemId === singleAndCoupon.itemId && ct.variationId === singleAndCoupon.variationId).length
                    isValid = !(remCouponValid === 1 && andCouponValid === 1)


                    // console.log(couponObj.couponCombo, 'remaining coupons')
                    // if(couponObj.couponCombo.every(coupon => coupon.type === 0)) {
                    //   // If remaining all are OR
                    //   const validOrCoupons = couponObj.couponCombo.filter(cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId)).length
                    //   const validAndCoupon = cart.filter(ct => ct.itemId === singleAndCoupon.itemId && ct.variationId === singleAndCoupon.variationId).length
                    //   if(validAndCoupon === 1 && validOrCoupons === 1) {
                    //     isValid = false
                    //   }
                    //   console.log(validOrCoupons,'validOrCoupons items')
                    // }
                    // console.log(singleAndCoupon,'singleAndCoupon items')
                  } else if (couponObj.couponCombo[0]?.type === 0 && couponObj.couponCombo[1]?.type === 0 && couponObj.couponCombo[2]?.type === 1) {
                    const singleAndCoupon = couponObj.couponCombo.pop()
                    const remaingCoupon = couponObj.couponCombo

                    const remCouponValid = remaingCoupon.filter(couponItem => cart.some(ct => ct.itemId === couponItem.itemId && ct.variationId === couponItem.variationId)).length
                    const andCouponValid = cart.filter(ct => ct.itemId === singleAndCoupon.itemId && ct.variationId === singleAndCoupon.variationId).length
                    isValid = !(remCouponValid === 1 && andCouponValid === 1)
                  }
                  else if (isCartValid.length !== couponObj.couponCombo.length) {
                    // console.log('LOG: AND case')
                    // Everything is AND              
                    isValid = true
                  }
                  // {
                  //   // valid coupon

                  // } else{
                  //   // Invalid coupon
                  //    isValid = true
                  // }

                  // console.log(cart,'cart items');
                  // console.log(couponObj.couponCombo,'cart items coupons');
                  // console.log(itemSet1,'cart items set 1');
                  // console.log(itemSet2,'cart items set 2');
                  // previous code to check all combo items in cart
                  //  const hasAllComboItems = comboItems.every(item => cartItems.includes(item));
                  // let hasAllComboItems = false 
                  // let optionalComboItems = []
                  // let necessaryComboItems = []


                  // for (let i = 0; i < couponObj.couponCombo.length; i++) {
                  //   let currentItem = couponObj.couponCombo[i]
                  //   if(currentItem.type === 0){
                  //     //insert all optional items in optionalComboItems array
                  //     optionalComboItems.push(comboItems[i])
                  //   }else if(currentItem.type === 1){
                  //       //insert all optional items in necessaryComboItems array
                  //     necessaryComboItems.push(comboItems[i])
                  //   }
                  // }


                  //checks both of the condition in cart
                  // const hasAllComboItems = couponObj.couponCombo.filter(item => cart.some(v => v.itemId === item.itemId && cart.some(vl => vl.variationId === item.variationId)));


                  // console.log(necessaryComboItems, "hasAllComboItems", cartItems)
                  // hasAllComboItems = necessaryComboItems.every(item => cartItems.includes(item)) || optionalComboItems.some(item => cartItems.includes(item))

                  if (isValid) {
                    isValid = false
                    handleCouponError(
                      "danger",
                      "Your cart does not have required items"
                    );
                  } else {
                    isValid = true
                    tmpCoupon.amount = Number(
                      priceMature(couponObj.deductionAmount)
                    );

                  }
                  if (isValid) {
                    setCoupon({
                      ...tmpCoupon,
                      coupon: couponObj,
                      couponName: coupon.couponName,
                      isCouponApplied: true,
                    })
                  }

                } else {
                  //minimum amount error
                  handleCouponError(
                    "danger",
                    "The minimum purchase amount should be $" +
                    couponObj.couponMinimumAmount
                  );
                }
                // ----------------------------------------- Normal Coupon ----------------------------------------------
              } else {
                if (couponObj.couponType === "limited") {
                  if (
                    moment(moment()).isBetween(
                      couponObj.limitedDateStart,
                      couponObj.limitedDateEnd
                    )
                  ) {
                    //check if minimum amount
                    if (couponObj.couponMinimumAmount <= total) {
                      //check service type
                      if (
                        couponObj.couponServiceType === delivaryOrPickup.type ||
                        couponObj.couponServiceType === "both"
                      ) {
                        //check weekdays
                        //check if all days
                        let day = {};
                        day = couponObj.weekdays[0];
                        if (day.isValid) {
                          if (day.isFullDay) {
                            if (couponObj.deductionType === "amount") {
                              tmpCoupon.amount = Number(
                                priceMature(couponObj.deductionAmount)
                              );
                            } else {
                              tmpCoupon.amount = Number(
                                priceMature(
                                  priceMature(
                                    (total * couponObj.deductionAmount) / 100
                                  )
                                )
                              );
                            }
                            isValid = true;
                          } else {
                            //check for time
                            let currentTime = moment(moment(), "HH:mm").unix();
                            let startTime = moment(
                              day.validTimeStart,
                              "HH:mm"
                            ).unix();
                            let endTime = moment(
                              day.validTimeEnd,
                              "HH:mm"
                            ).unix();
                            if (
                              currentTime > startTime &&
                              currentTime < endTime
                            ) {
                              //apply coupon amount
                              if (couponObj.deductionType === "amount") {
                                tmpCoupon.amount = Number(
                                  priceMature(couponObj.deductionAmount)
                                );
                              } else {
                                tmpCoupon.amount = Number(
                                  priceMature(
                                    priceMature(
                                      (total * couponObj.deductionAmount) / 100
                                    )
                                  )
                                );
                              }
                              isValid = true;
                            } else {
                              handleCouponError(
                                "danger",
                                `This Coupon is not valid from ${moment(
                                  day.validTimeStart,
                                  "HH:mm"
                                ).format("HH:mm A")} ${tConvert(
                                  day.validTimeEnd
                                )}`
                              );
                            }
                          }
                        } else {
                          //check for other days
                          let day = couponObj.weekdays.filter(
                            (e) => e.dayName === days[new Date().getDay()]
                          )[0];
                          if (day.isValid) {
                            if (day.isFullDay) {
                              //finally apply coupon
                              if (couponObj.deductionType === "amount") {
                                tmpCoupon.amount = Number(
                                  priceMature(couponObj.deductionAmount)
                                );
                              } else {
                                tmpCoupon.amount = Number(
                                  priceMature(
                                    priceMature(
                                      (total * couponObj.deductionAmount) / 100
                                    )
                                  )
                                );
                              }
                              isValid = true;
                            } else {
                              //check for time
                              let currentTime = moment(
                                moment(),
                                "HH:mm"
                              ).unix();
                              let startTime = moment(
                                day.validTimeStart,
                                "HH:mm"
                              ).unix();
                              let endTime = moment(
                                day.validTimeEnd,
                                "HH:mm"
                              ).unix();
                              if (
                                currentTime > startTime &&
                                currentTime < endTime
                              ) {
                                if (couponObj.deductionType === "amount") {
                                  tmpCoupon.amount = Number(
                                    priceMature(couponObj.deductionAmount)
                                  );
                                } else {
                                  tmpCoupon.amount = Number(
                                    priceMature(
                                      priceMature(
                                        (total * couponObj.deductionAmount) /
                                        100
                                      )
                                    )
                                  );
                                }
                                isValid = true;
                              } else {
                                handleCouponError(
                                  "danger",
                                  `This Coupon is valid from ${tConvert(
                                    day.validTimeStart
                                  )} to ${tConvert(day.validTimeEnd)}`
                                );
                              }
                            }
                          } else {
                            handleCouponError(
                              "danger",
                              `This Coupon is not valid for ${days[new Date().getDay()]
                              }`
                            );
                          }
                        }
                      } else {
                        //service type error
                        handleCouponError(
                          "danger",
                          "This coupon is only valid for " +
                          couponObj.couponServiceType
                        );
                      }
                    } else {
                      //minimum amount error
                      handleCouponError(
                        "danger",
                        "The minimum purchase amount should be $" +
                        couponObj.couponMinimumAmount
                      );
                    }
                  } else {
                    handleCouponError(
                      "danger",
                      `This coupon is only valid from ${formettedDate(
                        couponObj.limitedDateStart
                      )}
                   to  ${formettedDate(couponObj.limitedDateEnd)}`
                    );
                  }
                } else {
                  //check if minimum amount
                  if (couponObj.couponMinimumAmount <= total) {
                    //check service type
                    if (
                      couponObj.couponServiceType === delivaryOrPickup.type ||
                      couponObj.couponServiceType === "both"
                    ) {
                      //check weekdays
                      //check if all days
                      let day = {};
                      day = couponObj.weekdays[0];
                      if (day.isValid) {
                        if (day.isFullDay) {
                          if (couponObj.deductionType === "amount") {
                            tmpCoupon.amount = Number(
                              priceMature(couponObj.deductionAmount)
                            );
                          } else {
                            tmpCoupon.amount = Number(
                              priceMature(
                                priceMature(
                                  (total * couponObj.deductionAmount) / 100
                                )
                              )
                            );
                          }
                          isValid = true;
                        } else {
                          //check for time
                          let currentTime = moment(moment(), "HH:mm").unix();
                          let startTime = moment(
                            day.validTimeStart,
                            "HH:mm"
                          ).unix();
                          let endTime = moment(
                            day.validTimeEnd,
                            "HH:mm"
                          ).unix();
                          if (
                            currentTime > startTime &&
                            currentTime < endTime
                          ) {
                            //apply coupon amount
                            if (couponObj.deductionType === "amount") {
                              tmpCoupon.amount = Number(
                                priceMature(couponObj.deductionAmount)
                              );
                            } else {
                              tmpCoupon.amount = Number(
                                priceMature(
                                  priceMature(
                                    (total * couponObj.deductionAmount) / 100
                                  )
                                )
                              );
                            }
                            isValid = true;
                          } else {
                            handleCouponError(
                              "danger",
                              `This Coupon is only valid from ${tConvert(
                                day.validTimeStart
                              )} ${tConvert(day.validTimeEnd)}`
                            );
                          }
                        }
                      } else {
                        //check for other days
                        let day = couponObj.weekdays.filter(
                          (e) => e.dayName === days[new Date().getDay()]
                        )[0];
                        if (day.isValid) {
                          if (day.isFullDay) {
                            //finally apply coupon
                            if (couponObj.deductionType === "amount") {
                              tmpCoupon.amount = Number(
                                priceMature(couponObj.deductionAmount)
                              );
                            } else {
                              tmpCoupon.amount = Number(
                                priceMature(
                                  priceMature(
                                    (total * couponObj.deductionAmount) / 100
                                  )
                                )
                              );
                            }
                            isValid = true;
                          } else {
                            //check for time
                            let currentTime = moment(moment(), "HH:mm").unix();
                            let startTime = moment(
                              day.validTimeStart,
                              "HH:mm"
                            ).unix();
                            let endTime = moment(
                              day.validTimeEnd,
                              "HH:mm"
                            ).unix();
                            if (
                              currentTime > startTime &&
                              currentTime < endTime
                            ) {
                              if (couponObj.deductionType === "amount") {
                                tmpCoupon.amount = Number(
                                  priceMature(couponObj.deductionAmount)
                                );
                              } else {
                                tmpCoupon.amount = Number(
                                  priceMature(
                                    priceMature(
                                      (total * couponObj.deductionAmount) / 100
                                    )
                                  )
                                );
                              }
                              isValid = true;
                            } else {
                              handleCouponError(
                                "danger",
                                `This Coupon is not valid from ${tConvert(
                                  day.validTimeStart
                                )} ${tConvert(day.validTimeEnd)}`
                              );
                            }
                          }
                        } else {
                          handleCouponError(
                            "danger",
                            `This Coupon is not valid for ${days[new Date().getDay()]
                            }`
                          );
                        }
                      }
                    } else {
                      //service type error
                      handleCouponError(
                        "danger",
                        "This coupon is only valid for " +
                        couponObj.couponServiceType
                      );
                    }
                  } else {
                    //minimum amount error
                    handleCouponError(
                      "danger",
                      "The minimum purchase amount should be $" +
                      couponObj.couponMinimumAmount
                    );
                  }
                }
              }
            }
            if (isValid)
              setCoupon({
                ...tmpCoupon,
                coupon: couponObj,
                couponName: coupon.couponName,
                isCouponApplied: true,
              });
          }
        } else {
          handleCouponError("danger", "Invalid Coupon");
        }
      })
      .catch((error) => {
        handleCouponError("danger", "Invalid Coupon");
      });
  };
  const handleCoupon = (e) => {
    setCoupon({ ...coupon, couponName: handleCouponCodeName(e.target.value) });
  };
  /** handle null and undefind value and 2 digit float point */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleCouponCodeName = (str) =>
    str.replace(/[^a-zA-Z0-9]/g, "_").toUpperCase();
  /**  this reactive function to show the address in format */
  const Address = (name, address, city, state, country) => {
    return `${name},${address}
        `;
  };
  /*** this callback function for  if payment is successfull or not  */
  const paymentSuccessfulPopup = useCallback((orderId = "") => {
    dispatch(flushCart());
    history.push(`/successfull/${orderId}`);
  });

  const [formfield, setformfield] = useState({
    firstName: delivaryOrPickup.data.firstName
      ? delivaryOrPickup.data.firstName
      : "",
    lastName: delivaryOrPickup.data.lastName
      ? delivaryOrPickup.data.lastName
      : "",
    email: delivaryOrPickup.data.email ? delivaryOrPickup.data.email : "",
    contactNumber: delivaryOrPickup.data.contactNumber
      ? delivaryOrPickup.data.contactNumber
      : "",
  });
  const [deliveryerror, setdeliveryerror] = useState({
    firstName: false,
    lastName: false,
    contactNumber: false,
    email: false,
  });
  const [gratuityShow, setgratuityShow] = useState(false);
  /*** validation of contact number  */
  const contains = function (charValue) {
    //eslint-disable-next-line
    let pval = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return !pval.test(charValue);
  };
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /** validate a phone number  */
  const validatePhoneNumber = (value, previousValue) => {
    value = value.replace(/[()  -]/g, "");
    if (value.length < 10) {
      return false;
    }
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return false;
      if (cvLength < 7) return false;
      let firstPhase = currentValue.slice(0, 3);
      let secondPhase = currentValue.slice(3, 6);
      let thiredPhase = currentValue.slice(6, 10);
      if (
        firstPhase == "000" ||
        (firstPhase == secondPhase &&
          secondPhase == thiredPhase.slice(0, -1)) ||
        secondPhase + thiredPhase == "0000000"
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  /** this function will handle rest of functionality for  order  */
  const handleOrder = (e) => {
    e.preventDefault();
    var isValidate = true;
    var tmpError = { ...deliveryerror };
    for (var key in formfield) {
      if (key === "contactNumber") {
        if (validatePhoneNumber(formfield[key].toString())) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "email") {
        //eslint-disable-next-line
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formfield[key]
          )
        ) {
          tmpError[e.target.name] = true;
          isValidate = false;
        } else {
          tmpError[e.target.name] = false;
        }
      } else if (formfield[key] === "" || formfield[key] == undefined) {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    }
    setdeliveryerror(tmpError);
    if (!isValidate) {
      return;
    }
    setloading(true);
    /* update the delivary and pickup data because user can chnage the value before payment */
    delivaryOrPickup.data.firstName = formfield.firstName;
    delivaryOrPickup.data.lastName = formfield.lastName;
    delivaryOrPickup.data.email = formfield.email;
    delivaryOrPickup.data.contactNumber = formfield.contactNumber.replace(
      /[()  -]/g,
      ""
    );
    post("/order/createOrder", {
      type: delivaryOrPickup.type,
      ...delivaryOrPickup.data,
      cart,
      paymentMethod: "cod",
      paymentStatus: "pending",
      taxAmount,
      gratuityAmount,
      couponName,
      deliveryCharge,
      driverReimbursement,
      totalGrossAmount: priceMature(
        taxAmount + deliveryCharge + (total - discountAmount - coupon.amount)
      ),
      totalAmount: total,
    })
      .then((res) => {
        setloading(false);
        dispatch(flushCart());
        history.push(`/successfull/${res.result.order.randomOrderId}`);
      })
      .catch((error) => {
        setloading(false);
        Store.addNotification({
          message: "Payment Failed",
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };
  /** this effect will assign the  brfore reload event on window to prevent user to reload page  */
  useEffect(() => {
    window.onbeforeunload = function () {
      return "Dude, are you sure you want to leave? Think of the kittens!";
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  /**  this effect will calculate delivery charges according to  the   address */
  useEffect(() => {
    if (delivaryOrPickup.type === "delivery") {
      //calculate the delivery charges
      calculateDeliveryCharges(delivaryOrPickup.data)
        .then((res) => {
          let response = res.result;
          setDeliveryCharge(response.price);
          setdriverReimbursement(response.driverReimbursement);
        })
        .catch((error) => {
          //will handle error ;
        });
    } if (delivaryOrPickup.type === "pickup") {
      setDeliveryCharge(0.00);
    }
  }, [delivaryOrPickup]);
  /** this effect will fetch user data if loggedin and pickup */
  useEffect(() => {
    if (delivaryOrPickup.type === "pickup") {
      if (customer.isCustomerLoggedIn) {
        getCustomerAddressAndPhoneNumber()
          .then((res) => {
            let tmpFormfield = { ...formfield };
            tmpFormfield.firstName = res.result.fname;
            tmpFormfield.lastName = res.result.lname;
            tmpFormfield.email = res.result.email;
            tmpFormfield.contactNumber = normalizeInput(
              res.result.contactNumber.toString()
            );
            setformfield(tmpFormfield);
            setisMountainable(true);
          })
          .catch((err) => { });
      } else {
        setformfield({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
        });
        setisMountainable(true);
      }
    } else {
      setisMountainable(true);
    }
  }, [customer]);
  /**
   * this effect will calculate fetch discount from admin
   */
  useEffect(() => {
    if (!discountStatus) {
      return true;
    }
    const { discountList, loading, tableLoading } = discount;
    discountList.sort((a, b) => {
      if (a.amount < b.amount) {
        return 1;
      }
      if (a.amount > b.amount) {
        return -1;
      }
      return 0;
    });
    let total = 0;
    cart.map((cartItem, index) => {
      total = total + cartItem.itemPrice * cartItem.quantity;
    });
    /** this loop condition will only runs one time  */
    let tmpDiscountObj = {};
    for (let i = 0; i < discountList.length; i++) {
      if (total >= discountList[i].amount) {
        tmpDiscountObj = discountList[i - 1] ? discountList[i - 1] : {};
        setdiscountAmount(
          Number(
            priceMature(priceMature((total * discountList[i].percentage) / 100))
          )
        );
        break;
      }
      if (i == discountList.length - 1) {
        if (!tmpDiscountObj.amount) {
          tmpDiscountObj = discountList[i] || {};
        }
      }
    }
    if (!tmpDiscountObj.amount && total < tmpDiscountObj.amount) {
      tmpDiscountObj = discountList[0] || {};
    }
    setdiscountobj(tmpDiscountObj);
  }, [discount, discountStatus]);
  /**
   * this effect will call discount from APIs
   */
  useEffect(() => {
    const userId = localStorage.getItem("userid");
    dispatch(getDiscount());
    dispatch(getManageProfileForm({ userId: userId }));
  }, []);
  /***
   * this discount will calculate tax
   */
  useEffect(() => {
    getUserAddressAndPhoneNumber().then((res) => {
      try {
        let total = 0;
        cart.map((cartItem, index) => {
          total = total + cartItem.itemPrice * cartItem.quantity;
        });
        total = total - discountAmount - coupon.amount + deliveryCharge
        let taxAmountPer = res.result.taxAmount || 0;
        setTaxAmount((total * taxAmountPer) / 100);
      } catch (error) {
        //
      }
    });
  }, [discountAmount, coupon.amount, deliveryCharge]);
  /**
   *  get status
   */
  useEffect(() => {
    getUserAddressAndPhoneNumber().then((res) => {
      try {
        let result = res.result;
        let { couponStatus, discountStatus } = result;
        setdiscountStatus(discountStatus || false);
        setcouponStatus(couponStatus || false);
      } catch (error) {
        //
      }
    });
  }, []);
  /**
   *  get wallet if loggedLogin
   */
  useEffect(() => {
    if (customer.isCustomerLoggedIn) {
      getCustomerWallet()
        .then((res) => {
          if (res.result) setwallet(res.result);
        })
        .catch((error) => {
          alert("failed to fetch wallet information");
        });
    } else {
      setwallet(null);
    }
  }, [customer]);
  /** convert the 24 hours time to the 12 hours and AM PM too */
  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time ? time : "00:00";
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      let tmp = +time[0] % 12;
      time[0] = tmp.toString().length === 1 ? `0${tmp}` : tmp || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  /** add greatuiy with percentage */
  const handleAddGratuity = (amount) => {
    let total = 0;
    cart.map((cartItem, index) => {
      total = total + cartItem.itemPrice * cartItem.quantity;
    });
    setgratuityAmount(Number(priceMature((total * amount) / 100)));
  };
  var total = 0;
  /** this function will open or close   delivery or pickup pop in current page */
  const handleDeliveryAndPickupModelMemo = useCallback(() => {
    setdeliveryandpickup(!deliveryandpickup);
  });
  /** handle fileds changes  */
  const handleFormFieldChange = (e) => {
    var isValidate = true;
    var tmpError = { ...deliveryerror };
    if (e.target.name === "contactNumber") {
      setformfield({
        ...formfield,
        [e.target.name]: normalizeInput(e.target.value),
      });
    } else {
      setformfield({ ...formfield, [e.target.name]: e.target.value });
    }
    if (e.target.name === "contactNumber") {
      if (!validatePhoneNumber(e.target.value.toString())) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      //eslint-disable-next-line
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
      isValidate = false;
    } else {
      tmpError[e.target.name] = false;
    }
    setdeliveryerror(tmpError);
  };
  /** handle show and hide of the gratuity amount div */
  const handleGratuityShow = () => {
    //handle show
    if (gratuityShow) setgratuityShow(false);
  };
  /** handle show and hide of the gratuity amount div */
  const handleGratuityHide = () => {
    //handle show
    if (!gratuityShow) setgratuityShow(true);
  };
  /** handle pay from wallet */
  const handlePayWallet = () => {
    //handle payment
    let total = 0;
    cart.map((cartItem, index) => {
      total = total + cartItem.itemPrice * cartItem.quantity;
    });
    let grandTotal =
      taxAmount +
      gratuityAmount +
      deliveryCharge +
      (total - discountAmount - coupon.amount);
    let walletAmount = wallet.amount;
    if (walletAmount >= grandTotal) {
      setuseWallet((pre) => ({ amount: grandTotal, using: true }));
    } else {
      setuseWallet((pre) => ({
        amount: grandTotal - walletAmount,
        using: true,
      }));
    }
  };
  /** handle cancle pay from wallet */
  const handleCanclePayWallet = () => {
    //handle payment
    setuseWallet((pre) => ({ amount: 0, using: false }));
  };
  var total_product = 0;
  /** this will calculate  the total items in cart */
  cart.map((cartItem, pindex) => {
    total_product = total_product + cartItem.quantity;
  });
  return (
    <>
      {loading ? <PageLoader size={250} /> : ""}
      <div className="main">
        <Banner />
        <section className="porduct-section">
          <div className="container-fluid">
            <div className="home-product">
              <div className="form-row justify-content-between">
                <div className="col-md-4">
                  <h4>You may also like</h4>
                  <ItemSuggestionBox
                    customizeModalMemorized={customizeModalMemorized}
                  />
                </div>

                <div className="col-md-5">
                  <div className="checkoutListDetails">
                    <h4>{total_product} Items you have selected</h4>
                    <table className="table checkListWrapper">
                      <thead>
                        <tr>
                          <th colSpan="2"><b>Items</b></th>
                          <th><b>Size</b></th>
                          <th><b>Quantity</b></th>
                          <th><b>Total Price</b></th>
                        </tr>
                      </thead>

                      <tbody>
                        {cart.map((cartItem, index) => {
                          total =
                            total + cartItem.itemPrice * cartItem.quantity;
                          return (
                            <tr key={index}>
                              <td colSpan="2">
                                <div className="itemsWrapper d-inline-flex align-items-center">
                                  <div className="imgWrapper">
                                    {cartItem.itemImage ? (
                                      <img
                                        src={changeImageUrl(cartItem.itemImage)}
                                        alt="Items here"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                        alt="Items here"
                                      />
                                    )}
                                  </div>
                                  <div className="details">
                                    <h6>{cartItem.itemName}</h6>
                                    <p>
                                      {cartItem.modifier.map(
                                        (modifier, index) => {
                                          return modifier.isDeleted ? (
                                            <div className="order-modifier">
                                              No {modifier.modifierName}{" "}
                                              {modifier.topping
                                                ? `( ${modifier.topping.toUpperCase()} )`
                                                : ""}
                                              {index ==
                                                cartItem.modifier.length - 1
                                                ? ""
                                                : ","}
                                            </div>
                                          ) : (
                                            <div className="order-modifier">
                                              {modifier.modifierName}{" "}
                                              {modifier.topping
                                                ? `(${modifier.topping.toUpperCase()} ${modifier.layer == "extra"
                                                  ? `x 2`
                                                  : ""
                                                })`
                                                : modifier.layer == "extra"
                                                  ? `(x 2)`
                                                  : ""}
                                              {index ==
                                                cartItem.modifier.length - 1
                                                ? ""
                                                : ","}
                                            </div>
                                          );
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="sizes">
                                  <span className="customController">
                                    {cartItem.variationName}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="addmore-row justify-content-center p-0 border-top-0">
                                  {cartItem.quantity}
                                </div>
                              </td>
                              <td>
                                $
                                {priceMature(
                                  cartItem.itemPrice * cartItem.quantity
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {typeof delivaryOrPickup.data === "undefined" ? (
                  ""
                ) : (
                  <div className="col-md-3">
                    <div className="added-addcard OrderDetails">
                      <div className="address">
                        <div className="d-flex align-items-center">
                          <i
                            className="fa fa-map-marker mr-2"
                            aria-hidden="true"
                          ></i>
                          <h2>
                            {delivaryOrPickup.type === "delivery"
                              ? "Address"
                              : "Store location "}
                          </h2>
                        </div>
                        <hr className="mt-1" />
                        <p>
                          {delivaryOrPickup.type === "delivery"
                            ? Address(
                              delivaryOrPickup.data.firstName +
                              " " +
                              delivaryOrPickup.data.lastName,
                              delivaryOrPickup.data.street,
                              delivaryOrPickup.data.city,
                              delivaryOrPickup.data.state
                            )
                            : delivaryOrPickup.data.storeLocation}
                        </p>
                        {/* <button
                          className="btn btn-block btn-secondary"
                          onClick={handleDeliveryAndPickupModelMemo}
                        >
                          Change Address
                        </button> */}
                        <div className="text-center color-green">
                          <hr/><h2><hr/><i><b>
                            You have chosen{" "}
                            {capitalizeFirstLetter(
                              delivaryOrPickup.type
                            )} </b>
                          </i><hr/></h2><hr/>
                        </div>
                      </div>
                      <div className="price mt-5">
                        <h2>Order Details</h2>
                        <hr className="mt-1" />
                        <div className="custm-added">
                          <ul>
                            <li>
                              <span>Sub-Total</span>{" "}
                              <span className="cstm-price">
                                ${priceMature(total)}
                              </span>
                            </li>
                            {discountStatus ? (
                              <li>
                                <span>Discount</span>{" "}
                                <span className="cstm-price">
                                  -$ {priceMature(discountAmount)}
                                </span>
                              </li>
                            ) : (
                              <></>
                            )}
                            <li>
                              <span>Delivery Charges </span>{" "}
                              <span className="cstm-price">
                                $ {priceMature(deliveryCharge)}
                              </span>
                            </li>
                            {/* <li>
                              <span>
                                Taxes and Charges
                                <i
                                  className=" ml-2 fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              <span className="cstm-price">
                                $ {priceMature(taxAmount)}
                              </span>
                            </li> */}
                            {!coupon.isCouponApplied ? (
                              couponStatus ? (
                                <li>
                                  <span>Apply Coupon </span>{" "}
                                  <span className="cstm-price">
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="couponName"
                                        value={coupon.couponName}
                                        onChange={handleCoupon}
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={applyCoupon}
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              ) : (
                                <></>
                              )
                            ) : (
                              <li>
                                <span>Coupon Discount</span>
                                <span
                                  className="cstm-price"
                                  onClick={removeCoupon}
                                >
                                  -${priceMature(coupon.amount)}
                                  <FaTimesCircle />
                                </span>
                              </li>
                            )}<hr />
                            <li>
                              <span>
                                Total before Taxes and Gratuity
                                <i
                                  className=" ml-2 fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              <span className="cstm-price">
                                {priceMature(
                                  (total - discountAmount - coupon.amount + deliveryCharge)
                                )}
                              </span>
                            </li>

                            <li>
                              <span>
                                Taxes and Charges
                                <i
                                  className=" ml-2 fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              <span className="cstm-price">
                                $ {priceMature(taxAmount)}
                              </span>
                            </li>
                            <li
                              className="flexwrapmob"
                            //onMouseOver={handleGratuityShow}
                            //onMouseLeave={handleGratuityHide}
                            >
                              <span>
                                Gratuity Amount
                                <i
                                  className=" ml-2 fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              <span
                                className="cstm-price"
                              //onMouseOver={handleGratuityShow}
                              //onMouseOut={handleGratuityHide}
                              >
                                {gratuityShow ? (
                                  <>$ {priceMature(gratuityAmount)}</>
                                ) : (
                                  <>
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        step=".1"
                                        value={gratuityAmount}
                                        onChange={(e) => {
                                          if (Number(e.target.value) >= 0) {
                                            setgratuityAmount(
                                              Number(e.target.value)
                                            );
                                          }
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          onClick={(e) => handleAddGratuity(10)}
                                        >
                                          10%
                                        </span>
                                        <span
                                          className="input-group-text"
                                          onClick={(e) => handleAddGratuity(20)}
                                        >
                                          20%
                                        </span>
                                        <span
                                          className="input-group-text"
                                          onClick={(e) => handleAddGratuity(25)}
                                        >
                                          25%
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </span>
                            </li>
                            {/* {!coupon.isCouponApplied ? (
                              couponStatus ? (
                                <li>
                                  <span>Apply Coupon </span>{" "}
                                  <span className="cstm-price">
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="couponName"
                                        value={coupon.couponName}
                                        onChange={handleCoupon}
                                      />
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={applyCoupon}
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              ) : (
                                <></>
                              )
                            ) : (
                              <li>
                                <span>Coupon Discount</span>
                                <span
                                  className="cstm-price"
                                  onClick={removeCoupon}
                                >
                                  -${priceMature(coupon.amount)}
                                  <FaTimesCircle />
                                </span>
                              </li>
                            )} */}
                            <hr />
                            <li>
                              <span>Grand Total</span>{" "}
                              <span className="cstm-price">
                                $
                                <span id="gtotal">
                                  {priceMature(
                                    taxAmount +
                                    gratuityAmount +
                                    deliveryCharge +
                                    (total - discountAmount - coupon.amount)
                                  )}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {Object.keys(discountobj).length > 1 ? (
                        <div
                          className="alert alert-success  discount box"
                          role="alert"
                        >
                          Spend ${priceMature(discountobj.amount - total)} more
                          to receive {discountobj.percentage}% percent off
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="checoutbtn">
                        <Link to="/" className="btn">
                          Continue Shopping
                        </Link>
                      </div>
                      {/* {wallet?.amount > 0 ? (
                        <OverlayTrigger
                          placement="bottom"
                          className="mb-3"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Your wallet has ${wallet.amount}
                            </Tooltip>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <li
                              className="mb-3 alert alert-success d-flex justify-content-around"
                              role="alert"
                              {...triggerHandler}
                              ref={ref}
                            >
                              <span className="p-2 flex-fill">
                                <FaWallet fontSize={35} />
                              </span>
                              <span className="p-2 flex-fill h5">
                                Pay from wallet
                              </span>
                              <span className="p-2 flex-fill ">
                                <input type="radio" name="paymentType" />
                              </span>
                            </li>
                          )}
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )} */}
                      <div className="checoutbtn">
                        <Accordion defaultActiveKey={0}>
                          <Accordion.Toggle className="btn" eventKey={1}>
                            Pay
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={1}>
                            {locationStripe ? (
                              <div className="stripe-div-wrapper">
                                <div className="text-center color-red">
                                  <i>
                                    You have chosen{" "}
                                    {capitalizeFirstLetter(
                                      delivaryOrPickup.type
                                    )}
                                  </i>
                                </div>
                                {/* <div className="payment-type-wrapper">
                         
                                                         <div className="form-check pl-3 mb-2 form-check-modified">
                                                           <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="paymentType"
                                                             id="paymentType"
                                                             value="coc"
                                                             onChange={(e) => {
                                                               setpaymentType(e.target.value);
                                                             }}
                                                             defaultChecked
                                                           />
                                                           <label
                                                             className="form-check-label"
                                                             htmlFor="flexRadioDefault1"
                                                           >
                                                             Card
                                                           </label>
                                                         </div>
                                                         <div className="form-check">
                                                           <input
                                                             className="form-check-input"
                                                             type="radio"
                                                             name="paymentType"
                                                             id="paymentType"
                                                             value="cod"
                                                             onChange={(e) => {
                                                               setpaymentType(e.target.value);
                                                             }}
                                                           />
                                                           <label
                                                             className="form-check-label"
                                                             htmlFor="flexRadioDefault2"
                                                           >
                                                             Cash
                                                           </label>
                                                         </div>
                         
                                                       </div> */}
                                {paymentType === "coc" ? (
                                  isMountainable ? (
                                    <Stripe
                                      stripePromise={stripePromise}
                                      data={{
                                        ...delivaryOrPickup.data,
                                        ...formfield,
                                        taxAmount,
                                        gratuityAmount,
                                        couponName: coupon.couponName,
                                        couponAmount: coupon.amount,
                                        totalGrossAmount: priceMature(
                                          taxAmount +
                                          gratuityAmount +
                                          deliveryCharge +
                                          (total -
                                            discountAmount -
                                            coupon.amount)
                                        ),
                                      }}
                                      orderData={{
                                        type: delivaryOrPickup.type,
                                        ...delivaryOrPickup.data,
                                        cart,
                                        taxAmount,
                                        gratuityAmount,
                                        discountAmount,
                                        couponName: coupon.couponName,
                                        couponAmount: coupon.amount,
                                        deliveryCharge,
                                        driverReimbursement,
                                        totalGrossAmount: priceMature(
                                          taxAmount +
                                          gratuityAmount +
                                          deliveryCharge +
                                          (total -
                                            discountAmount -
                                            coupon.amount)
                                        ),
                                        totalAmount: total,
                                      }}
                                      paymentSuccessfulPopup={
                                        paymentSuccessfulPopup
                                      }
                                      wallet={wallet}
                                      isLoading={() => setloading(true)}
                                      isComplete={() => setloading(false)}
                                      isFailed={() => setloading(false)}
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <form>
                                    <div className="checoutbtn">
                                      <div className="sr-combo-inputs-row">
                                        <input
                                          type="text"
                                          id="name"
                                          name="firstName"
                                          placeholder="Name"
                                          autoComplete="cardholder"
                                          className={`sr-input form-control ${deliveryerror.firstName
                                            ? "invalid-field"
                                            : ""
                                            }`}
                                          value={formfield.firstName}
                                          onChange={handleFormFieldChange}
                                          disabled={customer.isCustomerLoggedIn}
                                        />
                                      </div>
                                      <div className="sr-combo-inputs-row">
                                        <input
                                          type="text"
                                          id="name"
                                          name="lastName"
                                          placeholder="Last Name"
                                          autoComplete="cardholder"
                                          className={`sr-input form-control ${deliveryerror.lastName
                                            ? "invalid-field"
                                            : ""
                                            }`}
                                          value={formfield.lastName}
                                          onChange={handleFormFieldChange}
                                          disabled={customer.isCustomerLoggedIn}
                                        />
                                      </div>
                                      <div className="sr-combo-inputs-row">
                                        <input
                                          type="text"
                                          id="email"
                                          name="email"
                                          placeholder="Email"
                                          autoComplete="cardholder"
                                          className={`sr-input form-control ${deliveryerror.email
                                            ? "invalid-field"
                                            : ""
                                            }`}
                                          value={formfield.email}
                                          onChange={handleFormFieldChange}
                                          disabled={customer.isCustomerLoggedIn}
                                        />
                                      </div>
                                      <div className="sr-combo-inputs-row">
                                        <input
                                          type="tel"
                                          id="contactNumber"
                                          name="contactNumber"
                                          placeholder="Contact Number"
                                          autoComplete="cardholder"
                                          className={`sr-input form-control ${deliveryerror.contactNumber
                                            ? "invalid-field"
                                            : ""
                                            }`}
                                          value={formfield.contactNumber}
                                          onChange={handleFormFieldChange}
                                          disabled={customer.isCustomerLoggedIn}
                                        />
                                      </div>
                                      <button
                                        type="submit"
                                        className="btn"
                                        onClick={handleOrder}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </form>
                                )}
                              </div>
                            ) : (
                              <div className="text-center color-red">
                                <i>Payment method not setup</i>
                              </div>
                            )}
                          </Accordion.Collapse>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      {deliveryandpickup && (
        <DeliveryAndPickup
          show={deliveryandpickup}
          handleDeliveryAndPickupModel={handleDeliveryAndPickupModelMemo}
          data={delivaryOrPickup}
        />
      )}
      {isShowCustomcart ? (
        <CostumCart
          show={isShowCustomcart}
          itemIdOfcategory={customCartData?.itemId}
          customizeModal={customizeModalMemorized}
          itemName={customCartData?.itemName}
          isEdited={customCartData?.isEdited}
          uniqueId={customCartData?.uniqueId}
          halfTopping={customCartData?.halfTopping}
          itemImage={customCartData?.itemImage}
          handleRestorentClosedModel={() => { }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Checkout;
