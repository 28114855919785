import React from "react";
// import pagleLoader from "../Assets/images/olivarPizzaLoader.gif";

export const PageLoader = (props) => {
  const { size } = props;

  return (
    <>
      <div className="pageLoader_main">
        {/* <div className="pageLoader" style={{backgroundImage: `url(${pagleLoader})`, width: size, height: size }}> </div> */}
        <div
          className="pageLoader loaderSvg"
          style={{ width: size, height: size ,left:"39%" }}
        >
          <svg viewBox="25 25 50 50">
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            ></circle>
          </svg>
        </div>
      </div>
    </>
  );
};

export const ComponentLoader = (props) => {
  const { size } = props;
  return (
    <>
      <div className="componentLoader_main">
        {/* <div
          className="componentLoader"
          style={{
            backgroundImage: `url(${pagleLoader})`,
            width: size,
            height: size,
          }}
        >
          {" "}
        </div> */}
        <div
          className="componentLoader loaderSvg"
          style={{
            width: size,
            height: size,
          }}
        >
          <svg viewBox="25 25 50 50">
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            ></circle>
          </svg>
        </div>
      </div>
    </>
  );
};
