import React, { useEffect, useState, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { FormLabel, Row, Form, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaRegTrashAlt } from "react-icons/fa";
import validation from "../../helpers/validation";
import duplicateValidation from "../../helpers/duplicateValidation";
import { useDispatch } from "react-redux";
import { postPriceVariationAction, updatePriceVariationAction } from "../../Action/menuItemsAction";

const AddVirationModal = (props) => {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    variationsTypeList,
    editDataVariation: { edit, data },
  } = props;
  const [listMenuItem, setListMenuItem] = useState([
    {
      productname: "",
      variationId: [],
      variationPrice: "",
      outOfStock: false
    },
  ]);
  const [errors, setErrors] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [typeHeadCheck, setTypeHeadCheck] = useState([]);
  const [duplicateShow, setDuplicateShow] = useState(false);
  /** this effect will save modifier into the state */
  useEffect(() => {
    if (typeHeadCheck && edit === true) {
      const { DuplicateShow, errorDuplicate } = duplicateValidation({
        masterModifier: listMenuItem,
        typeHeadCheck: typeHeadCheck,
      });
      setDuplicateErrors(errorDuplicate);
      setDuplicateShow(DuplicateShow);
    }
  }, [edit, typeHeadCheck, listMenuItem]);
  /*** this will add new row in the form */
  const addNewitemRow = () => {
    const values = [...listMenuItem];
    values.push({
      productname: data?.itemName,
      variationId: [],
      variationPrice: "",
      outOfStock: false
    });
    setListMenuItem(values);
    setTypeHeadCheck([]);
  };
  /*** this will delete new row in the form */
  const deleteItemRow = (index) => {
    const removeValues = [...listMenuItem];
    const removeErrors = [...errors];
    removeErrors.splice(index, 1);
    removeValues.splice(index, 1);
    setListMenuItem(removeValues);
    setErrors(removeErrors);
  };
  /** this will close the model and clear the state too */
  const closeModaleWithClearAll = () => {
    closeModal();
    setListMenuItem([
      {
        productname: "",
        variationId: [],
        variationPrice: "",
        outOfStock: false
      },
    ]);
    setErrors([]);
    setDuplicateErrors([]);
    setDuplicateShow(false);
    setTypeHeadCheck([]);
  };
  /*** handle the change on variation listing */
  const variotionChange = (e, index) => {
    setTypeHeadCheck(e);
    setListMenuItem((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, variationId: e } : item
      )
    );
  };
  /*** handle the change on variation pricing etc.. */
  const onChangeInputData = (e, index) => {
    setListMenuItem((prevState) =>
      prevState.map((item, i) =>
        i === index && e.target.name === "variationPrice"
          ? { ...item, variationPrice: e.target.value > 0 ? e.target.value : 0 }
          : i === index
            ? { ...item, [e.target.name]: e.target.value }
            : item
      )
    );
  };
  /*** handle the change on variation checkbhox */
  const onChangeCheckBoxData = (e, index) => {
    setListMenuItem((prevState) =>
      prevState.map((item, i) =>
        i === index && e.target.name === "outOfStock"
          ? { ...item, outOfStock: !item.outOfStock }
          : i === index
            ? { ...item, [e.target.name]: e.target.value }
            : item
      )
    );
  };
  /** this will submit  the form */
  const onSubmitForm = (e, action) => {
    e.preventDefault();
    const isValid = validation(listMenuItem);
    setErrors(isValid);
    const checkIsValid = isValid.map((item) =>
      Object.keys(item).length > 0 ? true : false
    );
    if (checkIsValid.includes(true) === false) {
      const arrayOfData = listMenuItem.map((item, index) => ({
        variationId: item.variationId[0].VariationID,
        variationPrice: parseFloat(item.variationPrice).toFixed(2),
        variationName: item.variationId[0].variationName,
        outOfStock: item.outOfStock
      }));
      const formData = new FormData();
      formData.append("itemId", data.itemId);
      formData.append("VariationDetails", JSON.stringify(arrayOfData));
      switch (action) {
        case "create":
          dispatch(postPriceVariationAction({ data: formData }));
          closeModaleWithClearAll();
          break;
        case "update":
          dispatch(updatePriceVariationAction({ data: formData }))
          closeModaleWithClearAll();
          break;
        default:
          break;
      }
    }
  };
  // update
  useEffect(() => {
    if (edit === true && Object.keys(data.VariationDetails).length > 0) {
      for (let i = 0; i < data.VariationDetails.length; i++) {
        setListMenuItem((prevState) => {
          const editDatafeild = [...prevState];
          if (i === 0) {
            editDatafeild.splice(i, 1);
            editDatafeild.push({
              productname: data.itemName,
              variationId: variationsTypeList.filter(
                (item) =>
                  item.VariationID === data.VariationDetails[i].variationId
              ),
              variationPrice: data.VariationDetails[i].variationPrice,
              outOfStock: data.VariationDetails[i].outOfStock ?? false
            });
          } else {
            editDatafeild.push({
              productname: data.itemName,
              variationId: variationsTypeList.filter(
                (item) =>
                  item.VariationID === data.VariationDetails[i].variationId
              ),
              variationPrice: data.VariationDetails[i].variationPrice,
              outOfStock: data.VariationDetails[i].outOfStock ?? false
            });
          }
          return editDatafeild;
        });
      }
    }
    if (edit === false && Object.keys(data).length > 0) {
      setListMenuItem((prevState) =>
        prevState.map((item) => ({
          ...item,
          productname: data.itemName,
        }))
      );
    }
  }, [edit, data, variationsTypeList]);
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"60"}
          addNewitemRow={addNewitemRow}
          title={"Add Variations"}
          addNewButtonName={"Add New"}
          disabled={duplicateShow === true ? true : false}
          className={"add-variations"}
        >
          <Form
            className={"grid_viration_modal"}
            onSubmit={(e) => onSubmitForm(e, edit === true ? "update" : "create")}
          >
            <Row className="col-form-Row_2">
              <div className="col-form_2">
                <FormLabel>Product Name</FormLabel>
              </div>
              <div className="col-form_2">
                <FormLabel>Variation</FormLabel>
              </div>
              <div className="col-form_2">
                <FormLabel>Price($)</FormLabel>
              </div>
              <div className="col-form_2">
                <FormLabel>Out of Stock </FormLabel>
              </div>
              <div className="col-form_2 deleteColumn">
                <FormLabel>Action</FormLabel>
              </div>
            </Row>

            <div className="appendBox_2">
              {Object.keys(listMenuItem).length > 0
                ? listMenuItem.map((item, index) => (
                  <Row className="col-form-Row_2" key={index}>
                    <div className="col-form_2">
                      <Form.Control
                        type="text"
                        value={item.productname}
                        name="productname"
                        placeholder="Product"
                        disabled={true}
                        onChange={(e) => onChangeInputData(e, index)}
                      />
                      {errors[index]?.productname && (
                        <p className="error-validation">
                          {errors[index].productname}
                        </p>
                      )}
                    </div>
                    <div className="col-form_2">
                      <Typeahead
                        id="variationId"
                        filterBy={["variationName"]}
                        labelKey="variationName"
                        options={variationsTypeList}
                        placeholder="Variation"
                        onChange={(e) => variotionChange(e, index)}
                        selected={item.variationId}
                        disabled={
                          duplicateErrors[index]?.variationId === ""
                            ? true
                            : false
                        }
                      />
                      {(duplicateErrors[index]?.variationId ||
                        errors[index]?.variationId) && (
                          <p className="error-validation">
                            {duplicateErrors[index]?.variationId ||
                              errors[index]?.variationId}
                          </p>
                        )}
                    </div>
                    <div className="col-form_2">
                      <Form.Control
                        type="number"
                        value={item.variationPrice}
                        name="variationPrice"
                        placeholder="Price($)"
                        onChange={(e) => onChangeInputData(e, index)}
                        disabled={duplicateShow === true ? true : false}
                      />
                      {errors[index]?.variationPrice && (
                        <p className="error-validation">
                          {errors[index].variationPrice}
                        </p>
                      )}
                    </div>
                    <div className="col-form_2">
                      <input
                        type="checkbox"
                        key={Math.random()}
                        defaultChecked={item.outOfStock}
                        name="outOfStock"
                        placeholder="Out Of Stock"
                        onChange={(e) => onChangeCheckBoxData(e, index)}
                      />
                      {errors[index]?.outOfStock && (
                        <p className="error-validation">
                          {errors[index].outOfStock}
                        </p>
                      )}
                    </div>
                    <div className="col-form_2 deleteColumn">
                      <button
                        className="deleteButton_1"
                        disabled={
                          Object.keys(listMenuItem).length === 1
                            ? true
                            : false
                        }
                        onClick={() => deleteItemRow(index)}
                        type="button"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </div>
                  </Row>
                ))
                : ""}
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button
                    disabled={duplicateShow === true ? true : false}
                    className="btn"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(AddVirationModal);
