import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch, useSelector } from "react-redux";
import "./manageLocation.css";
import { createLocation, updateLocation } from "../../Action/authAction";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaRedo } from "react-icons/fa";
import { getPlacesSuggestions } from "../../Services/deliveryAndCharges";

const ManageLocationPopup = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    contactNumber: "",
    roleName: "",
    street: "",
  });
  const [errors, setErrors] = useState({});
  const [placeSuggestion, setplaceSuggestion] = useState([]);
  const [placeSuggestionTool, setplaceSuggestionTool] = useState({
    show: false,
    loading: false,
    timeout: 0,
  });
  /**normalize the props  */
  const {
    showModal,
    closeModal,
    editData: { edit, data },
    roles,
  } = props;
  /*** this function will handle the chenges in street field */
  const handlePlaceSuggestionChange = (text) => {
    clearTimeout(placeSuggestionTool.timeout);
    let tmp = setTimeout(() => {
      if (!placeSuggestionTool.show) {
        setplaceSuggestionTool({
          ...placeSuggestionTool,
          show: true,
          loading: true,
        });
      }
      getPlacesSuggestions({ input: text })
        .then((res) => {
          setplaceSuggestion(res.result ?? []);
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: true,
          });
        })
        .catch((error) => {
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: false,
          });
        });
    }, 500);
    setplaceSuggestionTool({ ...placeSuggestionTool, ["timeout"]: tmp });
  };
  /** this function will handle the click of place suggestion list  */
  const handlePlaceSuggestionClick = (addressString, fullAddressString) => {
    setUser({
      ...user,
      ["street"]: fullAddressString,
    });
    setplaceSuggestionTool({
      ...placeSuggestionTool,
      loading: false,
      show: false,
    });
  };
  /** this effect will get address object from props */
  useEffect(() => {
    if (edit) {
      let tmpData = {
        _id: data._id,
        fname: data.fname,
        lname: data.lname,
        email: data.email,
        contactNumber: normalizeInput(data?.contactNumber?.toString()),
        roleName: data.roles?.[0]?._id,
        street: data.addresses?.[0]?.street,
      };
      setUser(tmpData);
    } else {
      setUser({
        fname: "",
        lname: "",
        email: "",
        contactNumber: "",
        roleName: "",
        street: "",
      });
    }
  }, [data]);

  const [userError, setUserError] = useState({
    fname: false,
    lname: false,
    email: false,
    contactNumber: false,
    roleName: false,
    street: false,
  });
  /*** handle close model */
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  /** this will add the mask on phone number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /** handle the change in the form fileds */
  const handleUserChange = (e) => {
    let tmpUser = { ...user };
    if (e.target.name === "contactNumber") {
      tmpUser[e.target.name] = normalizeInput(e.target.value);
    } else if (e.target.name === "street") {
      tmpUser[e.target.name] = e.target.value;
      handlePlaceSuggestionChange(e.target.value);
    } else {
      tmpUser[e.target.name] = e.target.value;
    }
    setUser(tmpUser);
    var tmpError = { ...userError };
    if (e.target.name === "contactNumber") {
      if (e.target.value.toString().length != 14) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "fname") {
      if (e.target.value.length > 10) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "lname") {
      if (e.target.value.length > 10) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
    } else {
      tmpError[e.target.name] = false;
    }
    setUserError(tmpError);
  };
  /** this function will add the new user  **/
  const handleAddUser = () => {
    //this will handle user state
    var isValidate = true;
    var tmpError = { ...userError };
    for (var key in user) {
      if (key === "contactNumber") {
        if (user[key].toString().length != 14) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "email") {
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            user[key]
          )
        ) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (key === "fname") {
        if (!user[key]) {
          tmpError[key] = true;
          isValidate = false;
        } else if (user[key].length > 10) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (key === "lname") {
        if (!user[key]) {
          tmpError[key] = true;
          isValidate = false;
        } else if (user[key].length > 10) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (user[key] === "" || user[key] == undefined) {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    }
    setUserError(tmpError);
    //let update into redux
    if (isValidate) {
      if (edit) {
        let tmpData = { ...user };
        let id = tmpData._id;
        delete tmpData._id;
        dispatch(
          updateLocation({
            id,
            data: {
              ...tmpData,
              ["contactNumber"]: user.contactNumber.replace(/[()  -]/g, ""),
            },
          })
        );
      } else {
        dispatch(
          createLocation({
            ...user,
            ["contactNumber"]: user.contactNumber.replace(/[()  -]/g, ""),
          })
        );
      }
      closeModal();
      setUser({
        fname: "",
        lname: "",
        email: "",
        contactNumber: "",
      });
      setUserError({
        fname: false,
        lname: false,
        email: false,
        contactNumber: false,
      });
    }
  };
  /*** normaize the value  */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const handlePrice = (p) => {
    if (typeof p == "undefined") {
      return 0;
    } else if (p == undefined) {
      return 0;
    } else if (p == "") {
      return 0;
    } else {
      return p;
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={edit === true ? "Edit Location" : "Add Location"}
          edit={edit}
        >
          <div className="px-3 py-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="deliveryOption container">
                  <div className="form-row mt-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Role</label>
                        <select
                          className={`form-control ${
                            userError.roleName ? "invalid-field" : ""
                          }`}
                          value={user.roleName}
                          name="roleName"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        >
                          <option value="">Please select role</option>
                          {roles.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.roleName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          value={user.fname}
                          className={`form-control ${
                            userError.fname ? "invalid-field" : ""
                          }`}
                          placeholder="First Name"
                          name="fname"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={user.lname}
                          className={`form-control ${
                            userError.lname ? "invalid-field" : ""
                          }`}
                          placeholder="Last Name"
                          name="lname"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          value={user.contactNumber}
                          className={`form-control ${
                            userError.contactNumber ? "invalid-field" : ""
                          }`}
                          placeholder="Mobile Number"
                          name="contactNumber"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          value={user.email}
                          className={`form-control ${
                            userError.email ? "invalid-field" : ""
                          }`}
                          placeholder="Email Address"
                          name="email"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          value={user.street}
                          className={`form-control ${
                            userError.lname ? "invalid-field" : ""
                          }`}
                          placeholder="Address"
                          name="street"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                        {placeSuggestionTool.show ? (
                          <div className="autocomplete-items">
                            {placeSuggestionTool.loading ? (
                              <div>loading...</div>
                            ) : (
                              <div className="list-group">
                                {placeSuggestion.map((item, index) => {
                                  return (
                                    <li
                                      className="list-group-item list-group-item-action flex-column align-items-start"
                                      key={index}
                                      onClick={() => {
                                        handlePlaceSuggestionClick(
                                          item.structured_formatting.main_text,
                                          item.description
                                        );
                                      }}
                                    >
                                      <strong>
                                        {item.structured_formatting.main_text}
                                      </strong>
                                      <p>{item.description}</p>
                                    </li>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {errors.street && (
                          <p className="error-validation">{errors.street}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-3 mb-3 row">
                      <div className="text-right col-md-12">
                        <div className="regBtn btn_right_pad_1">
                          <button className="btn" onClick={handleAddUser}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageLocationPopup);
