import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageMenuService";
import { Store } from "react-notifications-component";

// get
export function* getMenuItemsList() {
  try {
    const res = yield call(auth.getMenuItemsSrvice);
    yield put({
      type: types.MENU_ITEMS_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}

// get menu items with variations
export function* getMenuItemsVariation() {
  try {
    const res = yield call(auth.getAllVariationItemList);
    yield put({
      type: types.MENU_ITEMS_VARIATIONS_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.MENU_ITEMS_VARIATIONS_ERROR,
      error: error,
    });
  }
}


// post 
export function* postMenuItems({payload}) {
  try {
    const res = yield call(auth.postMenuItemsSrvice, payload);
    if(res.status === 200 && res.resultCode === 0) {
      yield call(getMenuItemsList)
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }

  } catch (error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}

// delete item,
export function* deleteMenuItems({payload}) {
  try {
    const res = yield call(auth.deleteMenuItemsSrvice, payload);
    if(res.status === 200 && res.resultCode === 0) {
      yield call(getMenuItemsList)
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }

  } catch (error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}

// update
// delete item,
export function* updateMenuItems({payload}) {
  try {
    const res = yield call(auth.updateMenuItemsSrvice, payload);
    if(res.status === 200 && res.resultCode === 0) {
      yield call(getMenuItemsList)
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }

  } catch (error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}

// post price variations 
export function* postPriceVariationsItmSaga({payload}) {
  try {
    const res = yield call(auth.postPriceVariationsItmService, payload);
    if(res.status === 200 && res.resultCode === 0) {
      yield call(getMenuItemsList)
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch(error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}

// update variation of price 

export function* updatePriceVariationsItmSaga({payload}) {
  try {
    const res = yield call(auth.updatePriceVariationsItmService, payload);
    if(res.status === 200 && res.resultCode === 0) {
      yield call(getMenuItemsList)
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch(error) {
    yield put({
      type: types.MENU_ITEMS_ERROR,
      error: error,
    });
  }
}
