import * as types from "../Action/types";

const initialState = {
  manageMenuData: [],
  allMenuData: [],
  loading: false,
  error: {},
  msg: ''
};
function manageMenuReducer(state = initialState, action) {
  const { payload, msg, error  } = action;
  switch (action.type) {
    case types.GET_MENU_ITEMS:
      return { ...state, loading: true };
    case types.MENU_ITEMS_SUCCESS:
      return { ...state, loading: false, manageMenuData: payload, msg: msg };
    case types.MENU_ITEMS_ERROR:
      return { ...state, loading: false, error: error };

    // get all menu items with variations  
    case types.GET_MENU_ITEMS_VARIATIONS:
        return { ...state, loading: true };
    case types.MENU_ITEMS_VARIATIONS_SUCCESS:
        return { ...state, loading: false, allMenuData: payload };
    case types.MENU_ITEMS_VARIATIONS_ERROR:
        return { ...state, loading: false, error: error };

    default:
      return state;
  }
}

export default manageMenuReducer;
