import * as types from "../Action/types";

const initialState = {
  roles: [],
  permission: [],
  loading: false,
  error: {},
  msg: "",
};
function manageRoleAndPermission(state = initialState, action) {
  const { payload, msg, error } = action;
  switch (action.type) {
    case types.GET_ROLE:
      return { ...state, loading: true };
    case types.GET_ROLE_SUCCESS:
      return { ...state, loading: false, roles: payload.result, msg: msg };
    case types.GET_PERMISSION:
      return { ...state, loading: true };
    case types.GET_PERMISSION_SUCCESS:
      return { ...state, loading: false, permission: payload.result, msg: msg };
    default:
      return state;
  }
}

export default manageRoleAndPermission;
