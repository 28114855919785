const replaceImage = (Img) => {
  return Img.replace(
    Img.split("/images")[0],
    process.env.REACT_APP_API_PRODUCTION_IMAGE_URL
  );
  // return Img.replace(Img.split("/images")[0], process.env.REACT_APP_STAGING_BASE_URL);
  // return Img.replace(Img.split("/images")[0], process.env.REACT_APP_LOCAL_BASE_URL);
};

export const changeHostName = (data) => {
  return data.map((vl) => {
    if (vl.itemImage !== "" && vl?.itemImage !== undefined) {
      return { ...vl, itemImage: replaceImage(vl.itemImage) };
    } else if (vl.Image !== "" && vl?.Image !== undefined) {
      return { ...vl, Image: replaceImage(vl.Image) };
    } else {
      return vl;
    }
  });
};

export const changeImageUrl = (Image) => {
  return replaceImage(Image);
};
