import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/discountServices";
import { Store } from "react-notifications-component";

// get
export function* getDiscount() {
  const response = yield call(auth.getDiscount);
  try {
    yield put({
      type: types.GET_DISCOUNT_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    Store.addNotification({
      message: response.message,
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_DISCOUNT_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* addDiscount({ payload }) {
  const response = yield call(auth.addDiscount, payload);
  try {
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getDiscount);
  } catch (err) {
    Store.addNotification({
      message: response.message,
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_DISCOUNT_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* updateDiscount({ payload }) {
  const response = yield call(auth.updateDiscount, payload);
  try {
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getDiscount);
  } catch (err) {
    Store.addNotification({
      message: "update discount Failed",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_DISCOUNT_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* deleteDiscount({ payload }) {
  const response = yield call(auth.deleteDiscount, payload);
  try {
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getDiscount);
  } catch (err) {
    Store.addNotification({
      message: "delete discount Failed",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_DISCOUNT_ERROR,
      msg: response.message,
    });
  }
}
