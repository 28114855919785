import React, { useEffect, useState } from "react";
import { Form, FormLabel, Row, Col, Image } from "react-bootstrap";
import { FaRegImage, FaRegTrashAlt } from "react-icons/fa";
import RightSideModal from "../../helpers/RightSideModal";
import validation from "../../helpers/validation";
import { useDispatch } from "react-redux";
import {
  postProductCategoryAction,
  updateProductCategoryAction,
} from "../../Action/manageProductCategoryAction";

const ManageCategoryModal = (props) => {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editModal: { edit, editData },
  } = props;
  const [productCategoryData, setProductCategoryData] = useState([
    {
      CategoryName: "",
      Description: "",
      Image: { imgPreview: "", imgSend: "" },
    },
  ]);
  const [errors, setErrors] = useState([]);
  //handle change of product category
  const onChangeproductCategory = (e, index) => {
    setProductCategoryData((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? e.target.name === "Image"
            ? {
                ...item,
                Image: {
                  imgPreview: URL.createObjectURL(e.target.files[0]),
                  imgSend: e.target.files[0],
                },
              }
            : { ...item, [e.target.name]: e.target.value }
          : item
      )
    );
  };
  //handle close the model and clear the state
  const closeModalWithItem = () => {
    closeModal();
    setErrors([]);
    setProductCategoryData([
      {
        CategoryName: "",
        Description: "",
        Image: { imgPreview: "", imgSend: "" },
      },
    ]);
  };
  //add new row
  const addNewitemRow = () => {
    const newValues = [...productCategoryData];
    newValues.push({
      CategoryName: "",
      Description: "",
      Image: { imgPreview: "", imgSend: "" },
    });
    setProductCategoryData(newValues);
  };
  //delete row
  const deleteRowItem = (index) => {
    const deleteValue = [...productCategoryData];
    deleteValue.splice(index, 1);
    setProductCategoryData(deleteValue);
  };
  //handle submit
  const onSubmitProductCtegory = (e, action) => {
    e.preventDefault();
    const isValid = validation(productCategoryData);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      const dataArray = productCategoryData.map((item) => ({
        ...item,
        Image: item.Image.imgSend,
      }));
      for (let i = 0; i < dataArray.length; i++) {
        let formData = new FormData();
        formData.append("CategoryName", dataArray[i].CategoryName);
        formData.append("Description", dataArray[i].Description);
        formData.append("Image", dataArray[i].Image);
        switch (action) {
          case "create":
            dispatch(postProductCategoryAction({ data: formData }));
            break;
          case "update":
            dispatch(
              updateProductCategoryAction({
                data: formData,
                itemId: editData._id,
              })
            );
            break;
          default:
            break;
        }
        if (i == dataArray.length - 1) {
          closeModalWithItem();
        }
      }
    }
  };
  //mount the state
  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setProductCategoryData((prevState) =>
        prevState.map((item) => ({
          ...item,
          CategoryName: editData.CategoryName,
          Description: editData.Description,
          Image: { imgPreview: editData.Image, imgSend: "" },
        }))
      );
    }
  }, [editData]);
  return (
    <>
      <RightSideModal
        showModal={showModal}
        closeModal={closeModalWithItem}
        sizeModal={"60"}
        addNewitemRow={addNewitemRow}
        title={
          edit === true ? "Update Product Category" : "Add Product Category"
        }
        addNewButtonName={"Add New"}
        edit={edit}
      >
        <Form
          className={"grid_add_manage_category"}
          onSubmit={(e) =>
            onSubmitProductCtegory(e, edit === true ? "update" : "create")
          }
        >
          <table className="table">
            <thead>
              <tr>
                <th>Product Category Name</th>
                <th>Description</th>
                <th>Image</th>
                {edit ? "" : <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {productCategoryData &&
                productCategoryData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        name="CategoryName"
                        placeholder="Product Category Name"
                        value={item.CategoryName}
                        onChange={(e) => onChangeproductCategory(e, index)}
                      />
                      {errors[index]?.CategoryName && (
                        <p className="error-validation">
                          {errors[index].CategoryName}
                        </p>
                      )}
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="Description"
                        placeholder="Description"
                        value={item.Description}
                        onChange={(e) => onChangeproductCategory(e, index)}
                      />
                      {errors[index]?.Description && (
                        <p className="error-validation">
                          {errors[index].Description}
                        </p>
                      )}
                    </td>

                    <td>
                      <Form.Group
                        controlId="formFile"
                        className="uploadImageRow_2"
                      >
                        <div>
                          <Form.Control
                            name="Image"
                            type="file"
                            className="form-control"
                            onChange={(e) => onChangeproductCategory(e, index)}
                          />
                          <span>
                            {item.Image.imgPreview === "" ||
                            item.Image.imgPreview === undefined ? (
                              <FaRegImage />
                            ) : (
                              <Image src={item.Image.imgPreview} />
                            )}
                          </span>
                        </div>
                      </Form.Group>
                    </td>
                    {edit ? (
                      ""
                    ) : (
                      <td>
                        <div className="deleteButton_modifier_category">
                          <button
                            className="deleteButton_1"
                            onClick={() => deleteRowItem(index)}
                            type="button"
                            disabled={
                              Object.keys(productCategoryData).length === 1
                                ? true
                                : false
                            }
                          >
                            <FaRegTrashAlt />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <Row className="mt-3 mb-3">
            <Col md={12} className="text-right">
              <div className="regBtn btn_right_pad_1">
                <button className="btn">
                  {edit === true ? "Update" : "Save"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </RightSideModal>
    </>
  );
};

export default ManageCategoryModal;
