import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageModifierCategoryServices";
import { Store } from "react-notifications-component";

// get
export function* getModifierCategoryList() {
  const res = yield call(auth.getModifierCategoryListService);
  try {
    yield put({
      type: types.GET_MODIFIER_CATEGORY_LIST_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_MODIFIER_CATEGORY_LIST_ERROR,
      error: error,
    });
  }
}

// ctreate
export function* postModifierCategoryList({ payload }) {
  const res = yield call(auth.postModifierCategoryListService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getModifierCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MODIFIER_CATEGORY_LIST_ERROR,
      error: error,
    });
  }
}

// delete
export function* deleteModifierCategoryListItem({ payload }) {
  const res = yield call(auth.deleteModifierCategoryListService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getModifierCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MODIFIER_CATEGORY_LIST_ERROR,
      error: error,
    });
  }
}

// update
export function* updateModifierCategoryListItem({ payload }) {
  const res = yield call(auth.updateModifierCategoryListService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getModifierCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MODIFIER_CATEGORY_LIST_ERROR,
      error: error,
    });
  }
}
