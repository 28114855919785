import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/homeBannerServices";
import { store } from "react-notifications-component";

export function* getHomeBannerList() {
  const response = yield call(auth.getHomeBanner);
  try {
    yield put({
      type: types.GET_HOME_BANNER_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_HOME_BANNER_ERROR,
      msg: response.message,
    });
  }
}

export function* updateHomeBannerList({ payload }) {
  const response = yield call(auth.updateHomeBanner, payload);
  try {
    yield call(getHomeBannerList);
  } catch (err) {
    yield put({
      type: types.GET_HOME_BANNER_ERROR,
      msg: response.message,
    });
  }
}

export function* deleteHomeBannerList({ payload }) {
  const response = yield call(auth.deleteHomeBanner, payload);
  try {
    yield call(getHomeBannerList);
  } catch (err) {
    yield put({
      type: types.GET_HOME_BANNER_ERROR,
      msg: response.message,
    });
  }
}

export function* changeHomeBannerIndexing({ payload }) {
  const response = yield call(auth.changeHomeBannerIndexing, payload);
  try {
    yield call(getHomeBannerList);
  } catch (err) {
    yield put({
      type: types.GET_HOME_BANNER_ERROR,
      msg: response.message,
    });
  }
}
