import React from 'react'
import aboutimg from '../assets/images/aboutimg.png'

const HomeAbout = (props) => {
  return (
    <section className='aboutsection'>
      <div
        className='container'
      >
        <div className='row'>
          <div className='col-md-6'>
            <div className='aboutcontent'>
              <div className='section-title'>
                <h3>How it started</h3>
              </div>
              <p>
                Oliver’s Pizza Inc. was founded by Dominick Oliver in 1980. The
                franchise organization operates carry out and quick serve stores
                in Michigan. The corporate history is closely linked with the
                personal history of Mr. Oliver, who began his professional
                career as a local pizza franchise owner in 1974. In 1980 he took
                over a troubled pizza franchise on the east side of Detroit.
                Later that year he opened Oliver's Pizza restaurant in Sterling
                Heights MI, followed by two more locations the next year. All
                operating under the same principle, serve delicious food at an
                affordable price, in a warm, clean atmosphere. Principles that
                Mr. Oliver still believes in today.
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <img src={aboutimg} className='img-fluid' alt='' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
