import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  variation:[],
  msg: "",
};

function manageVariationReducer(state = initialState, action) {
  const { response, payload, msg } = action;
  switch (action.type) {
    case types.GET_VARIATION:
      return { ...state, loading: true };
    case types.GET_VARIATION_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        variation: response.result || [],
        msg: msg,
      };
    case types.GET_VARIATION_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}

export default manageVariationReducer;
