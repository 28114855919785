import * as types from "../Action/types";
import { getCookie, setCookie } from "../Utils/cookies";
import { setAuthorization } from "../Services/api";
const initialState = {
    loading: false,
    isCustomerLoggedIn: getCookie('isCustomerLoggedIn') === "true" ? true : false,
    error: {},
    msg: "",
    customerToken: getCookie('customerToken'),
    customer: JSON.parse(getCookie('customer') || '{}')
};
export default function customerReducer(state = initialState, action) {
    const response = action.payload;
    switch (action.type) {
        case types.LOGIN_CUSTOMER:
            return { ...state, loading: true };
        case types.LOGIN_CUSTOMER_SUCCESS:
            return { ...state, loading: false, isCustomerLoggedIn: true, ...response };
        case types.LOGIN_CUSTOMER_ERROR:
            return { loading: false, ...response };
        case types.LOGOUT_CUSTOMER:
            setCookie('isCustomerLoggedIn', 'false');
            setCookie('customerToken', '');
            return { ...state, isCustomerLoggedIn: false, customerToken: '', customer: {} }
        case types.REGISTRATION_CUSTOMER:
            return { ...state, loading: true }
        case types.REGISTRATION_CUSTOMER_SUCCESS:
            return { ...state, loading: false }
        case types.REGISTRATION_CUSTOMER_ERROR:
            return { ...state, loading: false }
        case types.SET_CUSTOMER:
            return { ...state, loading: true }
        case types.SET_CUSTOMER_SUCCESS:
            return { ...state, loading: false, customer: response }
        case types.SET_CUSTOMER_ERROR:
            return { ...state, loading: false }
        case types.CHANGE_CUSTOMER_PASSWORD:
            return { ...state, loading: true }
        case types.CHANGE_CUSTOMER_PASSWORD_SUCCESS:
            return { ...state, loading: false }
        case types.CHANGE_CUSTOMER_PASSWORD_ERROR:
            return { ...state, loading: false }
        default:
            if (state.isCustomerLoggedIn) {
                localStorage.setItem("customerId", state.customer._id);
                setAuthorization(state.customerToken)
            }
            return state;
    }
}
