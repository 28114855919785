import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams , useHistory } from 'react-router-dom'
import userImage from "../../../../Assets/images/user.png";
import padlockIcon from "../../../../Assets/images/padlock.svg";
import { changePassword } from "../../../../Action/authAction";
import { forgotPasswordLink } from "../../../../Services/authenticationServices";
import o_original_logo from "../../../../Assets/images/o_original_logo.jpg";
import loginBg from "../../../../Assets/images/Login-bg.svg";
import { Store } from 'react-notifications-component';

export default function ForgotPasswordLink() {
    const customer = useSelector(state => state.customer);
    const history =  useHistory();
    const params = useParams();
    const [password, setpassword] = useState({ newPassword: "", confirmPassword: "" })
    const [errors, seterrors] = useState({ newPassword: false, confirmPassword: false, tokenNotFound: false });
    //handle change in form
    const handleChange = (e) => {
        setpassword({
            ...password,
            [e.target.name]: e.target.value
        });
        let tmpError = { ...errors }
        if (e.target.name === "newPassword") {
            //password should have  6 length 
            if (e.target.value.length < 6) {
                tmpError[e.target.name] = true
            }
            else {
                tmpError[e.target.name] = false;
            }
            if (e.target.value !== password['confirmPassword']) {
                tmpError['confirmPassword'] = true;
            }
            else {
                tmpError['confirmPassword'] = false;

            }
        }
        else if (e.target.name === "confirmPassword") {
            if (e.target.value !== password['newPassword']) {
                tmpError['confirmPassword'] = true;
            }
            else {
                tmpError['confirmPassword'] = false;
            }
        }
        else if (e.target.value == "") {
            tmpError[e.target.name] = true
        }
        else {
            tmpError[e.target.name] = false;
        }
        seterrors(tmpError);
    }
    //handle submit 
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValidate = true;
        let tmpError = { ...errors };
        Object.keys(tmpError).map(key => {
            if (key === "newPassword") {
                //should not same as current password
                if (password[key] === password['currentPassword']) {
                    tmpError['isNew'] = true;
                    isValidate = false
                }
                else {
                    tmpError['isNew'] = false;
                }
                //password should have  6 length 
                if (password[key].length < 6) {
                    tmpError[key] = true
                    isValidate = false
                }
                else {
                    tmpError[key] = false;
                }
                //password and confirm password should be same 
                if (password[key] !== password['confirmPassword']) {
                    tmpError['confirmPassword'] = true;
                    isValidate = false
                }
                else {
                    tmpError['confirmPassword'] = false;
                }
            }
            else if (key === "confirmPassword") {
                if (password[key] !== password['newPassword']) {
                    tmpError['confirmPassword'] = true;
                    isValidate = false;
                }
                else {
                    tmpError['confirmPassword'] = false;
                }
            }
            else if (password[key] == "") {
                tmpError[key] = true;
                isValidate = false
            }
            else {
                tmpError[key] = false;
            }
        });
        seterrors(tmpError);
        if (isValidate) {
            forgotPasswordLink({ ...password, ...params }).then(res => {
                Store.addNotification({
                    message: res.message,
                    type: "success",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
                setTimeout(()=>{
                    history.push('/');
                },3000)
            }).catch(err => {
                Store.addNotification({
                    message: err.data.err.message || "Wrong token or token has been expired",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            });
        }
    }
    return (
        <section
            className="loginSec"
            style={{ backgroundImage: `url(${loginBg})` }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="loginBox">
                            <div className="loginform">
                                <div className="loginlogo">
                                    <img
                                        src={o_original_logo}
                                        className="img-fluid"
                                        alt="o_original_logo"
                                    />
                                </div>
                                <div className="logintitle">
                                    <h2>Forgot Password</h2>
                                    <span>Please enter your registered email </span>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group has-search">
                                                <label>New Password</label>
                                                <span className="form-control-feedback">
                                                    <img src={padlockIcon}
                                                        alt="" />
                                                </span>
                                                <input type="password"
                                                    className={
                                                        `form-control ${errors.newPassword ? "invalid-field" : ""} ${errors.isNew ? "invalid-field" : ""}`
                                                    }
                                                    placeholder="Enter your new password"
                                                    name="newPassword"
                                                    value={
                                                        password.newPassword
                                                    }
                                                    onChange={handleChange} />
                                                    
                                                {errors.isNew ? <div className='invalid-feedback'> New password should not same as current password</div> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group has-search">
                                                <label>Confirm Password</label>
                                                <span className="form-control-feedback">
                                                    <img src={padlockIcon}
                                                        alt="" />
                                                </span>
                                                <input type="password"
                                                    className={
                                                        `form-control ${errors.confirmPassword ? "invalid-field" : ""
                                                        }`
                                                    }
                                                    placeholder="Re-enter your password"
                                                    name="confirmPassword"
                                                    value={
                                                        password.confirmPassword
                                                    }
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-right">
                                            <div className="regBtn">
                                                <button className="btn"
                                                    disabled={
                                                        customer.loading
                                                    }>
                                                    {
                                                        customer.loading ? "Submit.." : "Submit"
                                                    }
                                                    <img src="images/loginicon.svg" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
