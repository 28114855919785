import { Button } from "react-bootstrap";
import {
  FaRegEdit,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegTrashAlt,
} from "react-icons/fa";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getPermission } from "../../Action/manageRolesAndPermission";
import { getCustomer } from "../../Action/manageUsers";
import { getCustomerExport } from "../../Services/manageUser";
import ManageWalletPopup from "./manageWalletPopup";
export default function manageCustomers() {
  const dispatch = useDispatch();
  const customersList = useSelector((state) => state.customers);
  const login = useSelector((state) => state.login);
  const [customers, setcustomers] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    data: {},
  });
  const [permission, setpermission] = useState({
    add: false,
    update: false,
    delete: false,
  });
  const [refundPopup, setrefundPopup] = useState("no");
  const [data, setdata] = useState({});

  const columns = [
    {
      dataField: "fname",
      text: "Name",
      formatter: (cell, row) => {
        return row.fname + " " + row.lname;
      },
      filterValue: (cell, row) => {
        return row.fname + " " + row.lname;
      },
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => {
        return cell;
      },
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "contactNumber",
      text: "Contact Number",
      formatter: (cell, row) => {
        return normalizeInput(cell.toString());
      },
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Address",
      formatter: (cell, row) => {
        return row.address?.[0]?.street;
      },
      filterValue: (cell, row) => {
        return row.address?.[0]?.street;
      },
      sort: true,
    },
    {
      dataField: Date.now(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <span onClick={(e) => action(row)}>
            <FaRegEdit />
          </span>
        );
      },
      sort: true,
    },
  ];
  useEffect(() => {
    dispatch(getCustomer());
  }, []);
  useEffect(() => {
    setcustomers(customersList.customers || []);
  }, [customersList]);

  useEffect(() => {
    if (login.userData?.role === "superAdmin") {
      setpermission({
        add: true,
        update: true,
        delete: true,
      });
    }
    login.permission.map((item) => {
      if (item.pageAliasName == "manageCustomers") {
        setpermission({
          add: item.add,
          update: item.update,
          delete: item.delete,
        });
      }
    });
  }, [login]);
  const action = (row) => {
    handleshowHideRefundPopup();
    setdata(row);
  };
  const handleshowHideRefundPopup = () => {
    setrefundPopup((prev) => (prev === "yes" ? "no" : "yes"));
  };
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Customers</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                  <h2>Manage Customers</h2>
                  <div>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        getCustomerExport("pdf");
                      }}
                    >
                      <FaRegFilePdf />
                    </button>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        getCustomerExport("excel");
                      }}
                    >
                      <FaRegFileExcel />
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <BootstrapeTableData
                    data={customers}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                    classes="roles-table"
                    isLoading={customersList.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {refundPopup === "yes" && (
        <ManageWalletPopup
          showModal={refundPopup}
          closeModal={handleshowHideRefundPopup}
          permission={permission}
          data={data}
        />
      )}
    </>
  );
}
