import * as types from "./types";

/***
 * this action will get master modifier
 */
export const getUsers = () => ({
  type: types.GET_USER,
});

/***
 * this action will add user
 */
export const addUsers = (payload) => ({
  type: types.ADD_USER,
  payload,
});

/***
 * this action will update user
 */
export const updateUsers = (payload) => ({
  type: types.UPDATE_USER,
  payload,
});

/***
 * this action will delete user
 */
export const deleteUsers = (payload) => ({
  type: types.DELETE_USER,
  payload,
});

/**
 *  this will get customers
 */

export const getCustomer = () => ({
  type: types.GET_CUSTOMER,
});
