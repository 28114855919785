import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageRolesAndPermission";
import { Store, store } from "react-notifications-component";

export function* getRole() {
  try {
    const response = yield call(auth.getRole);
    yield put({
      type: types.GET_ROLE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* addRole({ payload }) {
  try {
    const response = yield call(auth.addRole, payload);
    yield call(getRole);
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* updateRole({ payload }) {
  try {
    const response = yield call(auth.updateRole, payload);
    yield call(getRole);
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* deleteRole({ payload }) {
  try {
    const res = yield call(auth.deleteRole, payload);
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getRole);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}

export function* getPermission() {
  try {
    const response = yield call(auth.getPermission);
    yield put({
      type: types.GET_PERMISSION_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_PERMISSION_ERROR,
      error,
    });
  }
}

export function* getAllPermission({ payload }) {
  try {
    const response = yield call(auth.getAllPermission, payload);
    yield put({
      type: types.GET_ALL_PERMISSION_SUCCESS,
      payload: response.result?.permission,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_PERMISSION_ERROR,
      error,
    });
  }
}
