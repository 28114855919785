import React, { useState, useEffect } from "react";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { getCashAmountCollected } from "../../Services/reportService";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";

export default function CashAmountCollected() {
  const [serviceType, setserviceType] = useState(["delivery", "pickup"]);
  const [summary, setsummary] = useState({
    totalAmount: 0,
    totalDeliveryCharges: 0,
  });
  const [litsData, setlitsData] = useState([]);
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  //three month old date
  var threeMonthOldDate = new Date();
  threeMonthOldDate.setMonth(threeMonthOldDate.getMonth() - 2);
  threeMonthOldDate = `${threeMonthOldDate.getFullYear()}-${
    threeMonthOldDate.getMonth() < 10
      ? "0" + threeMonthOldDate.getMonth()
      : threeMonthOldDate.getMonth()
  }-${
    threeMonthOldDate.getDate() < 10
      ? "0" + threeMonthOldDate.getDate()
      : threeMonthOldDate.getDate()
  }`;
  //today date
  var todayDate = new Date().toISOString().substr(0, 10);
  //one month old date
  var oneMonthOldDate = new Date();
  oneMonthOldDate.setMonth(oneMonthOldDate.getMonth() - 1);
  oneMonthOldDate = `${oneMonthOldDate.getFullYear()}-${
    oneMonthOldDate.getMonth() + 1 < 10
      ? "0" + oneMonthOldDate.getMonth() + 1
      : oneMonthOldDate.getMonth() + 1
  }-${
    oneMonthOldDate.getDate() < 10
      ? "0" + oneMonthOldDate.getDate()
      : oneMonthOldDate.getDate()
  }`;
  const [filter, setfilter] = useState({
    from: oneMonthOldDate,
    to: todayDate,
  });
  const [filterError, setfilterError] = useState({
    from: false,
    to: false,
    OrderServiceOption: false,
  });
  const [showResetButton, setshowResetButton] = useState(false);
  const [loading, setloading] = useState(false);
  //handle the change in filter form
  const handleFilterChange = (e) => {
    setfilter({ ...filter, [e.target.name]: e.target.value });
    let tmpError = { ...filterError };
    if (e.target.name === "from") {
      if (
        new Date(e.target.value).getTime() > new Date(filter["to"]).getTime()
      ) {
        tmpError[e.target.name] = true;
        tmpError["to"] = true;
      } else {
        tmpError[e.target.name] = false;
        tmpError["to"] = false;
      }
      // prevent  date which  older then three months
      if (
        new Date(e.target.value).getTime() <
        new Date(threeMonthOldDate).getTime()
      ) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
      if (e.target.value == "") {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "to") {
      if (
        new Date(filter["from"]).getTime() > new Date(e.target.value).getTime()
      ) {
        tmpError[e.target.name] = true;
        tmpError["from"] = true;
      } else {
        tmpError[e.target.name] = false;
        tmpError["from"] = false;
      }
      // prevent  date which  older then three months
      if (
        new Date(e.target.value).getTime() <
        new Date(threeMonthOldDate).getTime()
      ) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
      if (e.target.value == "") {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    }
    setfilterError(tmpError);
  };
  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let tmpError = { ...filterError };
    let isValidate = true;
    Object.keys(tmpError).map((key) => {
      if (key === "from") {
        //check if null
        //check  min or max
        if (
          new Date(filter[key]).getTime() > new Date(filter["to"]).getTime()
        ) {
          tmpError[key] = true;
          tmpError["to"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["to"] = false;
        }
        // prevent  date which  older then three months
        if (
          new Date(filter[key]).getTime() <
          new Date(threeMonthOldDate).getTime()
        ) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
        // prevent  date which  older then three months
        if (filter[key] === "") {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "to") {
        //check if min or max
        if (
          new Date(filter["from"]).getTime() > new Date(filter[key]).getTime()
        ) {
          tmpError[key] = true;
          tmpError["from"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["from"] = false;
        }
        // prevent  date which  older then three months
        if (
          new Date(filter[key]).getTime() <
          new Date(threeMonthOldDate).getTime()
        ) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
        if (filter[key] == "") {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      }
    });
    setfilterError(tmpError);
    if (isValidate) {
      setloading(true);
      getCashAmountCollected({ ...filter }).then((result) => {
        setloading(false);
        setlitsData(result.result);
        calculateSummary(result.result);
        setshowResetButton(true);
      });
    }
  };
  //reset the filter
  const resetFilter = (e) => {
    e.preventDefault();
    getCashAmountCollected().then((result) => {
      setlitsData(result.result);
      calculateSummary(result.result);
      setshowResetButton(false);
    });
  };
  const handleDownload = (type) => {
    getCashAmountCollected({ ...filter }, type).then((result) => {});
  };
  const columns = [
    {
      dataField: "stripePaymentId",
      text: "Stripe Payment Id",
      sort: true,
    },
    {
      dataField: "amount",
      text: "amount",
      formatter: (cell, row) => {
        return "$" + row.amount;
      },
      filterValue: (cell, row) => {
        return row.amount;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Status",
      formatter: (cell, row) => {
        return "$" + priceMature(row.totalGrossAmount);
      },
      filterValue: (cell, row) => {
        return priceMature(row.totalGrossAmount);
      },
      sort: true,
    },
  ];
  const summaryColoumn = ["Total Amount"];
  const calculateSummary = (result) => {
    let totalAmount = 0;
    result.forEach((item, index) => {
      totalAmount = totalAmount + item.amount;
    });
    setsummary({
      ...summary,
      ["totalAmount"]: totalAmount,
    });
  };
  // this effect will fetch all orders of customer
  useEffect(() => {
    setloading(true);
    getCashAmountCollected({ ...filter }).then((result) => {
      setloading(false);
      setlitsData(result.result);
      calculateSummary(result.result);
      setshowResetButton(true);
    });
  }, []);
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Cash Amount Collected</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                  <h2>Cash Amount Collected</h2>
                  <div>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        handleDownload("pdf");
                      }}
                    >
                      <FaRegFilePdf />
                    </button>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      <FaRegFileExcel />
                    </button>
                  </div>
                </div>
                <div className="card-body actioncard">
                  <div className="row">
                    <div className="col-sm-12">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="col-sm-3">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">From</label>
                              <input
                                type="date"
                                className={`form-control ${
                                  filterError.from ? "invalid-field" : ""
                                }`}
                                name="from"
                                value={filter.from}
                                min={threeMonthOldDate}
                                max={todayDate}
                                onChange={handleFilterChange}
                              />
                              {filterError.from ? (
                                <div className="invalid-feedback">
                                  from date should be lower then to date
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">To</label>
                              <input
                                type="date"
                                className={`form-control ${
                                  filterError.to ? "invalid-field" : ""
                                }`}
                                name="to"
                                value={filter.to}
                                min={threeMonthOldDate}
                                max={todayDate}
                                onChange={handleFilterChange}
                              />
                              {filterError.to ? (
                                <div className="invalid-feedback">
                                  to date should be greater then from date
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3 searhorder-btn">
                            <div className="regBtn">
                              <button className="btn mr-2" disabled={false}>
                                Search
                              </button>

                              {showResetButton ? (
                                <button className="btn" onClick={resetFilter}>
                                  clear
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <BootstrapeTableData
                    data={litsData}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                    isLoading={loading}
                  />
                  <div className="react-bootstrap-table">
                    <table className="table table-bordered">
                      <thead>
                        {summaryColoumn.map((value, index) => {
                          return <td key={index}> {value}</td>;
                        })}
                      </thead>
                      <tbody>
                        <tr>
                          <td key={1}>
                            $
                            {priceMature(
                              summary.totalAmount + summary.totalDeliveryCharges
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
