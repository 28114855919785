import React, { useState, useEffect, memo } from "react";
import { Row, Col, Form, Image } from "react-bootstrap";
import { FaRegImage } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  getManageProfileForm,
  getCountryListAction,
  getStateListAction,
  getCityListAction,
  updateManageProfileForm,
} from "../../Action/manageProfileAction";
import validation from "../../helpers/validation";
import { updateManageProfileservice } from "../../Services/authenticationServices";
import { getPlacesSuggestions } from "../../Services/deliveryAndCharges";

function ManageProfile(props) {
  const dispatch = useDispatch();
  //add mask to the phone number
  const normalizeInput = (value, previousValue) => {
    value = value.toString();
    if (!value || value === "") return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [profileForm, setProfileForm] = useState({
    fname: "",
    lname: "",
    email: "",
    contactNumber: "",
    country: "",
    state: "",
    city: "",
    street: "",
    icon: "",
  });
  const [searchList, setSearchList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [errors, setErrors] = useState({});
  const [placeSuggestion, setplaceSuggestion] = useState([]);
  const [placeSuggestionTool, setplaceSuggestionTool] = useState({
    show: false,
    loading: false,
    timeout: 0,
  });
  const [deliverChargesError, setdeliverChargesError] = useState(false);

  //mount city state , country
  useEffect(() => {
    const userId = localStorage.getItem("userid");
    dispatch(getManageProfileForm({ userId: userId }));
    dispatch(getCountryListAction());
    dispatch(getStateListAction({}));
    dispatch(getCityListAction({}));
  }, [dispatch]);

  useEffect(() => {
    const { manageProfileData, countrtyList, stateList, cityList } =
      getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const {
        contactNumber,
        email,
        fname,
        lname,
        restorentStatus,
        icon,
        address,
      } = manageProfileData.result;
      setSearchList({
        countryList: countrtyList,
        stateList: stateList,
        cityList: cityList,
      });
      setProfileForm((prevState) => ({
        ...prevState,
        contactNumber: normalizeInput(contactNumber || " "),
        email,
        fname,
        lname,
        country: address?.country,
        state: address?.state,
        city: address?.city,
        street: address?.street,
        icon,
      }));
    }
  }, [getManageProfile]);
  //handle change in profile form
  const manageProfileForm = (e) => {
    let tmp = { ...profileForm };
    if (e.target.name === "contactNumber") {
      tmp[e.target.name] = normalizeInput(e.target.value);
    } else if (e.target.name == "street") {
      tmp[e.target.name] = e.target.value;
      handlePlaceSuggestionChange(e.target.value);
    } else if (e.target.name == "icon") {
      tmp[e.target.name] = e.target.files[0];
    } else {
      tmp[e.target.name] = e.target.value;
    }
    setProfileForm(tmp);
  };
  /*** this function will handle the chenges in street field */
  const handlePlaceSuggestionChange = (text) => {
    clearTimeout(placeSuggestionTool.timeout);
    let tmp = setTimeout(() => {
      if (!placeSuggestionTool.show) {
        setplaceSuggestionTool({
          ...placeSuggestionTool,
          show: true,
          loading: true,
        });
      }
      getPlacesSuggestions({ input: text })
        .then((res) => {
          setplaceSuggestion(res.result ?? []);
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: true,
          });
        })
        .catch((error) => {
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: false,
          });
        });
      if (deliverChargesError) {
        setdeliverChargesError(false);
      }
    }, 500);
    setplaceSuggestionTool({ ...placeSuggestionTool, ["timeout"]: tmp });
  };
  /** this function will handle the click of place suggestion list  */
  const handlePlaceSuggestionClick = (addressString, fullAddressString) => {
    setProfileForm({
      ...profileForm,
      ["street"]: fullAddressString,
    });
    setplaceSuggestionTool({
      ...placeSuggestionTool,
      loading: false,
      show: false,
    });
  };
  //handle submit
  const onSubmitProfile = (e) => {
    e.preventDefault();
    let tmpProfile = { ...profileForm };
    tmpProfile.contactNumber = tmpProfile.contactNumber.replace(/[()  -]/g, "");
    const isValid = validation(tmpProfile);
    setErrors(isValid);
    if (Object.keys(isValid).length < 1) {
      let formDataObject = new FormData();
      Object.keys(tmpProfile).forEach((key) => {
        formDataObject.append(key, tmpProfile[key]);
      });
      dispatch(
        updateManageProfileForm({
          userId: localStorage.getItem("userid"),
          data: formDataObject,
        })
      );
    }
  };
  /** filterize the state and city */
  var state = searchList.countryList.filter(
    (item) => item.countryName === profileForm.country
  )[0];
  var city = searchList.stateList.filter(
    (item) => item.stateName === profileForm.state
  )[0];
  return (
    <div className="main-content">
      <div className="page-title">
        <h2>Manage Restaurant Profile</h2>
      </div>

      <div className="dashbaord-card">
        <Row>
          <Col md={12}>
            <div className="card customCard">
              <div className="card-body">
                <Form onSubmit={onSubmitProfile}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Restaurant First Name </Form.Label>
                        <Form.Control
                          name="fname"
                          type="text"
                          placeholder="First Name"
                          value={profileForm.fname}
                          onChange={manageProfileForm}
                        />
                        {errors.fname && (
                          <p className="error-validation">{errors.fname}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Restaurant Last Name</Form.Label>
                        <Form.Control
                          name="lname"
                          type="text"
                          placeholder="Last Name"
                          value={profileForm.lname}
                          onChange={manageProfileForm}
                        />
                        {errors.lname && (
                          <p className="error-validation">{errors.lname}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Restaurant Email</Form.Label>
                        <Form.Control
                          name="email"
                          type="text"
                          value={profileForm.email}
                          disabled={profileForm.email ? true : false}
                          placeholder="Email"
                          onChange={manageProfileForm}
                        />
                        {errors.email && (
                          <p className="error-validation">{errors.email}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Restaurant Contact Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact Number"
                          name="contactNumber"
                          value={profileForm.contactNumber}
                          onChange={manageProfileForm}
                        />
                        {errors.contactNumber && (
                          <p className="error-validation">
                            {errors.contactNumber}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Street </Form.Label>
                        <Form.Control
                          name="street"
                          type="text"
                          value={profileForm.street}
                          placeholder="Street"
                          onChange={manageProfileForm}
                        />
                        {placeSuggestionTool.show ? (
                          <div className="autocomplete-items">
                            {placeSuggestionTool.loading ? (
                              <div>loading...</div>
                            ) : (
                              <div className="list-group">
                                {placeSuggestion.map((item, index) => {
                                  return (
                                    <li
                                      className="list-group-item list-group-item-action flex-column align-items-start"
                                      key={index}
                                      onClick={() => {
                                        handlePlaceSuggestionClick(
                                          item.structured_formatting.main_text,
                                          item.description
                                        );
                                      }}
                                    >
                                      <strong>
                                        {item.structured_formatting.main_text}
                                      </strong>
                                      <p>{item.description}</p>
                                    </li>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {errors.street && (
                          <p className="error-validation">{errors.street}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <select
                          name="country"
                          className="form-control"
                          value={profileForm.country}
                          onChange={manageProfileForm}
                        >
                          <option value="">--select--</option>
                          {searchList.countryList &&
                            searchList.countryList.map((item) => (
                              <option key={item._id} value={item.countryName}>
                                {item.countryName}
                              </option>
                            ))}
                        </select>
                        {errors.country && (
                          <p className="error-validation">{errors.country}</p>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <select
                          name="state"
                          className="form-control"
                          onChange={manageProfileForm}
                          value={profileForm.state}
                        >
                          <option value="">--select--</option>
                          {state &&
                            searchList.stateList.map((item) => {
                              return state._id === item.country ? (
                                <option key={item._id} value={item.stateName}>
                                  {item.stateName}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                        </select>
                        {errors.state && (
                          <p className="error-validation">{errors.state}</p>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <select
                          name="city"
                          className="form-control"
                          onChange={manageProfileForm}
                          value={profileForm.city}
                        >
                          <option value="">--select--</option>
                          {city &&
                            searchList.cityList.map((item) => {
                              return city._id === item.state ? (
                                <option key={item._id} value={item.cityName}>
                                  {item.cityName}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                        </select>

                        {errors.city && (
                          <p className="error-validation">{errors.city}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Icon</Form.Label>
                        <Form.Control
                          name="icon"
                          type="file"
                          onChange={manageProfileForm}
                        />
                      </Form.Group>
                      {profileForm.icon instanceof File ? (
                        <Image
                          className="imageGrid"
                          src={URL.createObjectURL(profileForm.icon)}
                        />
                      ) : (
                        <Image
                          className="imageGrid"
                          src={profileForm.icon}
                          alt={profileForm.icon}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="text-right">
                      <div className="regBtn">
                        <button className="btn">Update</button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default memo(ManageProfile);
