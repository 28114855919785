import * as api from "./api";

export const getMenuItemsSrvice = async () => {
  try {
    const res = await api.get("manageMenu/getMenuItem");
    return res;
  } catch (error) {
    return error;
  }
};

// post
export const postMenuItemsSrvice = async (payload) => {
  const { data } = payload;
  try {
    const res = await api.post(`manageMenu/createMenuItem`, data);
    return res;
  } catch (error) {
    return error;
  }
};

// delete
export const deleteMenuItemsSrvice = async (payload) => {
  const { itemId } = payload;
  try {
    const res = await api.deleteRec(`manageMenu/deleteMenuItem/${itemId}`);
    return res;
  } catch (error) {
    return error;
  }
};

// update
export const updateMenuItemsSrvice = async (payload) => {
  const { itemId, data } = payload;
  try {
    const res = await api.put(`manageMenu/updateMenuItem/${itemId}`, data);
    return res;
  } catch (error) {
    return error;
  }
};

//add price variations
export const postPriceVariationsItmService = async (payload) => {
  const { data } = payload;
  try {
    const res = await api.post(`manageMenu/addVariationItemType`, data);
    return res;
  } catch (error) {
    return error;
  }
};

//update price variations
export const updatePriceVariationsItmService = async (payload) => {
  const { data } = payload;
  try {
    const res = await api.put(`manageMenu/UpdateVariationItemType`, data);
    return res;
  } catch (error) {
    return error;
  }
};

//update price variations
export const getUpsellItemsService = async () => {
  try {
    const res = await api.get(`manageMenu/getUpsellItems`);
    return res;
  } catch (error) {
    return error;
  }
};

// get all the items with variations
export const getAllVariationItemList = async () => {
  try {
    let res = await api.get(
      `productCategory/getAllVariationItem`
    );
    return res;
  } catch (err) {
    return err;
  }
};