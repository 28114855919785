import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageRestaurentServices";
import { Store } from "react-notifications-component";
/** get restorant time  */
export function* getRestaurentTiming() {
  try {
    const response = yield call(auth.getTiming);
    yield put({
      type: types.GET_RESTAURENT_TIMING_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: types.GET_RESTAURENT_TIMING_ERROR,
      error,
    });
  }
}
/** update restorant time  */
export function* updateRestaurentTiming({ payload }) {
  try {
    const response = yield call(auth.updateTiming, payload);
    yield call(getRestaurentTiming);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (error) {
    Store.addNotification({
      message: "something went wrong ",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}
