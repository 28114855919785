import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { FaRegEdit } from "react-icons/fa";
import { getcrewOrDriverOrder } from "../../../Action/crewOrDriverAction";
import ManageDriverOrderPopup from "./driverOrderPopup";
import ManageDriverIndex from "../manageDriverIndex";
import { useParams } from "react-router-dom";
let pageToLoad;
const DriverOrders = function DriverOrders(props) {
  const { userId } = useParams();
  const roleName = "driver";
  const responseData = useSelector((state) => state.crewOrDriverOrder);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
  });
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);
  const handleToggleCheckBox = () => {
    const { crewOrDriverOrderList } = responseData;
    if (isTodayCheckBox) {
      setLitsData(
        crewOrDriverOrderList
          .reverse()
          .filter((v) => v.OrderStatusCode !== "delivered")
      );
    } else {
      setLitsData(
        crewOrDriverOrderList
          .reverse()
          .filter(
            (v) => v.OrderStatusCode !== "delivered" && isToday(v.createdAt)
          )
      );
    }
    clearInterval(pageToLoad);
    setisTodayCheckBox(!isTodayCheckBox);
  };
  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };
  /** this function will handle the  open and close of the popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
        paymentData: {},
      });
    }
  };
  const [showModal, setShowModal] = useState("no");
  /** this will gives callback function for on and off  the model */
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  /*** this will dispatch  action for get the driver or crew order list  */
  useEffect(() => {
    isTodayCheckBox
      ? dispatch(
          getcrewOrDriverOrder({
            _id: userId,
            role: roleName,
            collectionName: "order",
          })
        )
      : dispatch(
          getcrewOrDriverOrder({
            _id: userId,
            role: roleName,
            collectionName: "AllOrders",
          })
        );

    // update the list in every 5 sec
    isTodayCheckBox
      ? (pageToLoad = setInterval((e) => {
          try {
            if (document.getElementById("search-bar-0").value.length < 1) {
              dispatch(
                getcrewOrDriverOrder({
                  _id: userId,
                  role: roleName,
                  collectionName: "order",
                })
              );
            }
          } catch (err) {
            //
          }
        }, 20000))
      : (pageToLoad = setInterval((e) => {
          try {
            if (document.getElementById("search-bar-0").value.length < 1) {
              dispatch(
                getcrewOrDriverOrder({
                  _id: userId,
                  role: roleName,
                  collectionName: "AllOrders",
                })
              );
            }
          } catch (err) {
            //
          }
        }, 20000));
  }, [isTodayCheckBox]);
  /** this effect will normalize the the  order list object  */
  useEffect(() => {
    const { crewOrDriverOrderList, loading, tableLading } = responseData;
    if (crewOrDriverOrderList) {
      if (roleName === "crew") {
        setLitsData(
          crewOrDriverOrderList
            .reverse()
            .filter((v) => v.OrderStatusCode !== "ready")
        );
      } else {
        if (isTodayCheckBox) {
          setLitsData(
            crewOrDriverOrderList
              .reverse()
              .filter(
                (v) => v.OrderStatusCode !== "delivered" && isToday(v.createdAt)
              )
          );
        } else {
          setLitsData(
            crewOrDriverOrderList
              .reverse()
              .filter((v) => v.OrderStatusCode !== "delivered")
          );
        }
      }
    }
  }, [responseData]);

  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
          paymentData: data.payments[0],
        });
        addNewModal();
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => actionData(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
    {
      dataField: "gratuityAmount",
      text: "Tip Amount",
      formatter: (cell, row) => {
        return "$" + cell;
      },
      filterValue: (cell, row) => {
        return "$" + cell;
      },
      sort: true,
    },
    {
      dataField: "driverReimbursement",
      text: "Driver Fee",
      formatter: (cell, row) => {
        return "$" + cell;
      },
      filterValue: (cell, row) => {
        return "$" + cell;
      },
      sort: true,
    },
    {
      dataField: "randomOrderId",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date Of Orders",
      formatter: (cell, row) => {
        return new Date(cell).toUTCString();
      },
      filterValue: (cell, row) => {
        return new Date(cell).toUTCString();
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Method",
      formatter: (cell, row) => {
        return (
          <span key={Date.now()}>
            {row.payments[0].paymentMethod === "cod"
              ? "Cash On Delivery"
              : "Pay By Card"}
          </span>
        );
      },
      filterValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : "Pay By Card";
      },
      sortValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : "Pay By Card";
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Status",
      formatter: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      filterValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      sortValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      sort: true,
    },
  ];
  return (
    <>
      <ManageDriverIndex>
        <div className="order-wrapper">
          {/* Code was commented on 19 jan 2023 check previous code to undo Today's Order Checkbox changes */}
          {/* <div className="">
            <span>
              <b>Todays Orders</b>{" "}
              <input
                type="checkbox"
                defaultChecked={isTodayCheckBox}
                onClick={handleToggleCheckBox}
              />
            </span>
          </div> */}
          {
            <BootstrapeTableData
              data={litsData}
              columns={columns}
              keyfield={"_id"}
              searchBar={true}
              isLoading={responseData.loading}
            />
          }
        </div>
        {showModal && (
          <ManageDriverOrderPopup
            showModal={showModal}
            closeModal={addNewModalMemorized}
            editData={editData}
            role={roleName}
            userId={userId}
            isTodayCheckBox={isTodayCheckBox}
          />
        )}
      </ManageDriverIndex>
    </>
  );
};

export default DriverOrders;
