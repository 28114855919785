import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch, useSelector } from "react-redux";
import "./manageOrder.css";
import {
  addCrewOrDriver,
  updateCrewOrDriver,
  updateCrewOrDriverOtp,
} from "../../Action/crewOrDriverAction";
import {
  getCountryListAction,
  getStateListAction,
  getCityListAction,
} from "../../Action/manageProfileAction";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaRedo } from "react-icons/fa";

const manageCrewAndDriver = (props) => {
  const dispatch = useDispatch();
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [searchList, setSearchList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  /** this effect normalize the country state city object  */
  useEffect(() => {
    //get country state and cities
    const { countrtyList, stateList, cityList } = getManageProfile;
    setSearchList({
      countryList: countrtyList,
      stateList: stateList,
      cityList: cityList,
    });
  }, [getManageProfile]);
  /*** this effect will dispatch the country state city array  */
  useEffect(() => {
    dispatch(getCountryListAction());
    dispatch(getStateListAction({}));
    dispatch(getCityListAction({}));
  }, [dispatch]);

  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    contactNumber: "",
    country: "",
    state: "",
    city: "",
    role: "driver",
    street: "",
  });
  /**normalize the props  */
  const {
    showModal,
    closeModal,
    editData: { edit, data },
  } = props;

  /** this effect will get address object from props */
  useEffect(() => {
    if (edit) {
      var address = {};
      try {
        address = data.address[0] ?? {};
      } catch (err) {
        //
      }
      let tmpData = data;
      delete tmpData.address;
      delete address._id;
      setUser({
        ...tmpData,
        ...address,
        ["contactNumber"]: normalizeInput(tmpData.contactNumber.toString()),
      });
    } else {
      setUser({
        fname: "",
        lname: "",
        email: "",
        contactNumber: "",
        country: "",
        state: "",
        city: "",
        role: "driver",
        street: "",
      });
    }
  }, [data]);

  const [userError, setUserError] = useState({
    fname: false,
    lname: false,
    email: false,
    contactNumber: false,
    country: false,
    state: false,
    city: false,
    role: false,
    street: false,
  });
  /*** handle close model */
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  /** this will add the mask on phone number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /** handle the change in the form fileds */
  const handleUserChange = (e) => {
    if (e.target.name === "contactNumber") {
      setUser({ ...user, [e.target.name]: normalizeInput(e.target.value) });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
    var tmpError = { ...userError };
    if (e.target.name === "contactNumber") {
      if (e.target.value.toString().length != 14) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "fname") {
      if (e.target.value.length > 10) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "lname") {
      if (e.target.value.length > 10) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
    } else {
      tmpError[e.target.name] = false;
    }
    setUserError(tmpError);
  };
  /** this function will add the new user  **/
  const handleAddUser = () => {
    //this will handle user state
    var isValidate = true;
    var tmpError = { ...userError };
    for (var key in user) {
      if (key === "contactNumber") {
        if (user[key].toString().length != 14) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "email") {
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            user[key]
          )
        ) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (key === "fname") {
        if (!user[key]) {
          tmpError[key] = true;
          isValidate = false;
        } else if (user[key].length > 10) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (key === "lname") {
        if (!user[key]) {
          tmpError[key] = true;
          isValidate = false;
        } else if (user[key].length > 10) {
          tmpError[key] = true;
          isValidate = false;
        }
      } else if (user[key] === "" || user[key] == undefined) {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    }
    setUserError(tmpError);
    //let update into redux
    if (isValidate) {
      if (edit) {
        dispatch(
          updateCrewOrDriver({
            ...user,
            ["contactNumber"]: user.contactNumber.replace(/[()  -]/g, ""),
          })
        );
      } else {
        dispatch(
          addCrewOrDriver({
            ...user,
            ["contactNumber"]: user.contactNumber.replace(/[()  -]/g, ""),
          })
        );
      }
      closeModal();
      setUser({
        fname: "",
        lname: "",
        email: "",
        contactNumber: "",
        country: "",
        state: "",
        city: "",
        role: "driver",
        street: "",
      });
      setUserError({
        fname: false,
        lname: false,
        email: false,
        contactNumber: false,
        country: false,
        state: false,
        city: false,
        role: false,
        street: false,
      });
    }
  };
  /*** normaize the value  */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const handlePrice = (p) => {
    if (typeof p == "undefined") {
      return 0;
    } else if (p == undefined) {
      return 0;
    } else if (p == "") {
      return 0;
    } else {
      return p;
    }
  };
  const updateCrewOrDriverOtpHandler = (_id) => {
    dispatch(updateCrewOrDriverOtp({ _id }));
    closeModal();
  };
  /** filterization of city and state **/
  var State = searchList.countryList.filter(
    (item) => item.countryName === user.country
  )[0];
  var City = searchList.stateList.filter(
    (item) => item.stateName === user.state
  )[0];
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={
            edit === true ? "Edit Crew/Driver User" : "Add Crew/Driver User"
          }
          edit={edit}
        >
          <div className="px-3 py-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="deliveryOption container">
                  <div className="form-row mt-3">
                    {edit == false ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Type</label>
                          <select
                            value={user.role}
                            className={`form-control ${
                              userError.role ? "invalid-field" : ""
                            }`}
                            name="role"
                            onChange={(e) => {
                              handleUserChange(e);
                            }}
                          >
                            <option value="driver">Driver</option>
                            <option value="crew">Crew</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          value={user.fname}
                          className={`form-control ${
                            userError.fname ? "invalid-field" : ""
                          }`}
                          placeholder="First Name"
                          name="fname"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={user.lname}
                          className={`form-control ${
                            userError.lname ? "invalid-field" : ""
                          }`}
                          placeholder="Last Name"
                          name="lname"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          value={user.contactNumber}
                          className={`form-control ${
                            userError.contactNumber ? "invalid-field" : ""
                          }`}
                          placeholder="Mobile Number"
                          name="contactNumber"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Country</label>
                        <select
                          value={user.country}
                          className={`form-control ${
                            userError.country ? "invalid-field" : ""
                          }`}
                          name="country"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        >
                          <option value="">Select your country</option>
                          {searchList.countryList &&
                            searchList.countryList.map((item) => (
                              <option key={item._id} value={item.countryName}>
                                {item.countryName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>State</label>
                        <select
                          value={user.state}
                          className={`form-control ${
                            userError.state ? "invalid-field" : ""
                          }`}
                          name="state"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        >
                          <option value="">Select your State</option>
                          {State &&
                            searchList.stateList.map((item) => {
                              return State._id === item.country ? (
                                <option key={item._id} value={item.stateName}>
                                  {item.stateName}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>City</label>
                        <select
                          value={user.city}
                          className={`form-control ${
                            userError.city ? "invalid-field" : ""
                          }`}
                          name="city"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        >
                          <option value="">Select your city</option>
                          {City &&
                            searchList.cityList.map((item) => {
                              return City._id === item.state ? (
                                <option key={item._id} value={item.cityName}>
                                  {item.cityName}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          value={user.email}
                          className={`form-control ${
                            userError.email ? "invalid-field" : ""
                          }`}
                          placeholder="Email Address"
                          name="email"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Street and Zip code</label>
                        <input
                          type="text"
                          value={user.street}
                          className={`form-control ${
                            userError.street ? "invalid-field" : ""
                          }`}
                          name="street"
                          placeholder="Street and Zip code"
                          onChange={(e) => {
                            handleUserChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {edit ? (
                      <div className="col-sm-6 mt-3 mb-3">
                        <ButtonGroup size={"sm"}>
                          <Button>
                            <span>{user.driverOtp}</span>
                          </Button>
                          <Button
                            varient={"danger"}
                            onClick={(e) =>
                              updateCrewOrDriverOtpHandler(user._id)
                            }
                          >
                            <FaRedo />
                          </Button>
                        </ButtonGroup>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mt-3 mb-3 row">
                      <div className="text-right col-md-12">
                        <div className="regBtn btn_right_pad_1">
                          <button className="btn" onClick={handleAddUser}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(manageCrewAndDriver);
