import React, { useState, useEffect } from "react";
import RightSideModal from "../../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import validation from "../../../helpers/validation";
import { saveDiscount, updateDiscount } from "../../../Action/manageDiscount";

function ManageDiscountPopup(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data: editDataObject },
  } = props;
  const [discount, setdiscount] = useState([
    {
      amount: 0,
      percentage: 0,
    },
  ]);
  const [errors, setErrors] = useState([]);
  //close  the model and clear the state
  const closeModaleWithClearAll = () => {
    closeModal();
    setdiscount([
      {
        amount: 0,
        percentage: 0,
      },
    ]);
    setErrors([]);
  };
  //handle change in delivery charges
  const handleDiscount = (e, index) => {
    setdiscount((prevSate) =>
      prevSate.map((item, i) =>
        i === index ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  //add new row in form
  const addDiscount = () => {
    const addNewValues = [...discount];
    addNewValues.push({
      amount: 0,
      percentage: 0,
    });
    setdiscount(addNewValues);
  };
  //delete row from form
  const deleteDiscountRow = (index) => {
    const deleteValues = [...discount];
    deleteValues.splice(index, 1);
    setdiscount(deleteValues);
  };
  //submit the modifier
  const onSubmitMasterFormModifier = (e, action) => {
    e.preventDefault();
    const isValid = validation(discount);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      discount.map((item, index) => {
        switch (action) {
          case "create":
            dispatch(saveDiscount(item));
            break;
          case "update":
            dispatch(
              updateDiscount({
                data: item,
                id: item._id,
              })
            );
            break;
          default:
            break;
        }
      });
      closeModaleWithClearAll();
    }
  };
  //mount the delivery charges
  useEffect(
    (res) => {
      if (edit) {
        setdiscount([editDataObject]);
      }
    },
    [editDataObject]
  );
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addDiscount}
          title={`${edit ? "Update" : "Add"} Discount`}
          addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_add_master_modifier"}
            onSubmit={(e) =>
              onSubmitMasterFormModifier(e, edit ? "update" : "create")
            }
          >
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th>Amount </th>
                    <th>Percentage </th>
                    {edit === true ? "" : <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(discount).length > 0
                    ? discount.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="number"
                              name="amount"
                              placeholder="Amount"
                              value={item.amount}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDiscount(e, index)}
                            />
                            {errors[index]?.amount && (
                              <p className="error-validation">
                                {errors[index].amount}
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="percentage"
                              placeholder="Percentage"
                              value={item.percentage}
                              min="0"
                              step=".01"
                              onChange={(e) => handleDiscount(e, index)}
                            />
                            {errors[index]?.percentage && (
                              <p className="error-validation">
                                {errors[index].percentage}
                              </p>
                            )}
                          </td>
                          {edit === true ? (
                            ""
                          ) : (
                            <td>
                              <div className="deleteButton_modifier_category">
                                <button
                                  disabled={
                                    Object.keys(discount).length === 1
                                      ? true
                                      : false
                                  }
                                  className="deleteButton_1"
                                  onClick={() => deleteDiscountRow(index)}
                                  type="button"
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">{edit ? "Update" : "Save"}</button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default ManageDiscountPopup;
