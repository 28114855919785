import { useSelector } from "react-redux";
import originallogo from "../assets/images/o_original_logo.jpg";
export default function ApplicationDetail(props) {
  const addressAndPhone = useSelector((state) => state.addressAndPhone);
  if (addressAndPhone.loading) {
    return (
      <>
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </>
    );
  }

  const normalizeInput = (value, previousValue) => {
    value = value?.toString();
    if (!value || value === "") return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
  
  switch (props.type) {
    case "contactNumber":
      let contactNumber = normalizeInput(addressAndPhone.data[props.type])
      return <>{contactNumber} </>;
    case "address":
      return (
        <>{`${addressAndPhone.data?.address?.street}`}</>
      );
    case "icon":
      return addressAndPhone.data[props.type] ? (
        <img
          src={addressAndPhone.data[props.type]}
          className="img-fluid"
          // onError={(e) => {
          //   e.target.error = false;
          //   e.target.src = { originallogo };
          // }}
          alt="Icon"
        />
      ) : (
        <img src={originallogo} className="img-fluid" />
      );
    default:
      return <>{addressAndPhone.data[props.type]} </>;
  }
}
