import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch, useSelector } from "react-redux";
import "./manageOrder.css";
import { getOrderItemData } from "../../Services/manageOrderServices";
import { changeOrderStatus } from "../../Action/manageOrdersAction";
import { getCrewOrDriver } from "../../Action/crewOrDriverAction";
import { useHistory } from "react-router-dom";
import formettedDate from "../../helpers/moomentDate";

// replace banner image hostname
import { changeImageUrl } from "../../helpers/replacehost.js"

const ManageOrderPopup = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderStatus, setorderStatus] = useState({
    OrderStatusCode: "driver",
    userId: "",
    timing: "",
  });
  /*** get crew and driver list from redux */
  var { crewOrDriverList } = useSelector((state) => state.crewOrDriver);
  /*** normalizing the props  */
  const {
    showModal,
    closeModal,
    editData: { edit, data, userData, paymentData },
    socket,
  } = props;
  const [isloading, setisloading] = useState(false);
  const [orderStatusError, setorderStatusError] = useState({
    OrderStatusCode: false,
    userId: false,
    timing: false,
  });
  /** this function will handle order status chnage */
  const handleOrderStatus = (e) => {
    setorderStatusError({
      ...orderStatusError,
      [e.target.name]: e.target.value === "" ? true : false,
    });
    setorderStatus({ ...orderStatus, [e.target.name]: e.target.value });
  };
  const [orderitemData, setorderitemData] = useState([]);
  /*** this function will  close model and clear all state */
  const closeModaleWithClearAll = () => {
    setorderStatus({
      OrderStatusCode: "crew",
      userId: "",
      timing: "",
    });
    closeModal();
  };
  /** assigning address to new variable to preventing exception */
  var address = "";
  try {
    address = data.address[0].street;
  } catch (err) {
    //
  }
  //generate google map url address
  const generateGoogleMapurl = (lat, long) => {
    return `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${long}`;
  };
  /*** this function will get orders details */
  useEffect(() => {
    setisloading(true);
    getOrderItemData(data._id)
      .then((res) => {
        setisloading(false);
        setorderitemData(res.result);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, [data]);
  /** this effect will dispatch the crew and driver */
  useEffect(() => {
    dispatch(getCrewOrDriver());
    //faisal
  }, [orderStatus]);
  useEffect(() => {
    if (socket) {
      socket.on("driverCheckInOut", () => {
        dispatch(getCrewOrDriver());
      });
    }
  }, [socket]);
  useEffect(() => {
    document
      .getElementsByClassName("modalBodyForm_2")[0]
      .scrollIntoView({ behavior: "smooth", block: "end" });
  }, []);
  /** this function will handle fields change */
  const handleChangeOrderStatus = (id) => {
    let validate = true;
    if (orderStatus.userId == "") {
      setorderStatusError({ ...orderStatusError, userId: true });
      validate = false;
    }
    if (validate) {
      dispatch(
        changeOrderStatus({
          id,
          status: "driver",
          userId: orderStatus.userId,
          timing: orderStatus.timing,
        })
      );
      closeModaleWithClearAll();
      setorderStatus({
        OrderStatusCode: "",
        userId: "",
        timing: "",
      });
      setorderStatusError({
        OrderStatusCode: false,
        userId: false,
        timing: false,
      });
    }
  };
  /**  this will filterize values */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /** this function will return the second  row values accourding to the condition */
  const CrewAndDriverSecond = () => {
    if (data.OrderStatusCode === "ready" || data.OrderStatusCode === "driver") {
      return (
        <div className="col-sm-4">
          <div className="form-group">
            <select
              value={orderStatus.userId}
              name="userId"
              onChange={handleOrderStatus}
              className={`form-control ${
                orderStatusError.userId ? "invalid-field" : ""
              }`}
            >
              <option value="" selected>
                Select User
              </option>
              {crewOrDriverList.map((user, index) => {
                return user.role === "driver" && user.checkInOut !== "out" ? (
                  <option key={index} value={user._id}>
                    {user.fname + " " + user.lname}
                  </option>
                ) : (
                  ""
                );
              })}
            </select>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
  const PrintComponent = () => {
    if (data.OrderStatusCode === "ready" || data.OrderStatusCode === "driver") {
      return (
        <div className="col-sm-4">
          <div className="form-group">
            <div className="checoutbtn">
              <button
                className="btn mt-0"
                onClick={() => {
                  history.push(`/orders/${data.randomOrderId}`);
                }}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
  /**  this function will return button name according to the condition  */
  const ButtonName = () => {
    const { OrderStatusCode } = data;
    if (OrderStatusCode === "driver") {
      return "Assign to Driver";
    } else if (OrderStatusCode === "new") {
      return "Send SMS";
    } else {
      return "Assign to Driver";
    }
  };
  /*** this function will return order value according to the condition */
  const ChangeOrderComponent = () => {
    const { OrderStatusCode, OrderServiceOption } = data;
    if (
      OrderServiceOption != "pickup" &&
      (OrderStatusCode === "ready" || OrderStatusCode === "driver")
    ) {
      return (
        <div className="row">
          <CrewAndDriverSecond />
          <div className="col-sm-4">
            <div className="form-group">
              <div className="checoutbtn">
                <button
                  type="submit"
                  className="btn mt-0"
                  onClick={() => {
                    handleChangeOrderStatus(data._id);
                  }}
                >
                  <ButtonName />
                </button>
              </div>
            </div>
          </div>
          <PrintComponent />
        </div>
      );
    } else if (
      OrderServiceOption == "pickup" &&
      (OrderStatusCode === "ready" || OrderStatusCode === "driver")
    ) {
      return (
        <div className="row">
          <PrintComponent />
        </div>
      );
    } else {
      return <></>;
    }
  };
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    value = value.toString();
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={edit === true ? "Order Details" : "Add Modifier Category"}
          addNewButtonName={"Add New"}
          edit={edit}
          className={"order-detailspopup"}
        >
          <div className="order-details-pop px-3 py-1">
            <div className="row">
              <div className="col-md-6">
                <table className="table1 ordertable ordertable-info">
                  <tbody>
                    <tr>
                      <th>Customer Name</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {userData.fname + " " + userData.lname}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Customer Email</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {userData.email}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Customer Contact Number</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {normalizeInput(data.contactNumber)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {data.OrderServiceOption === "pickup"
                          ? "Pickup Address"
                          : "Customer Address"}
                      </th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {address}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Id</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {data.randomOrderId}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table1 ordertable ordertable-info">
                  <tbody>
                    <tr>
                      <th>Order Total</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          ${data.totalGrossAmount}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Service Type</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {data.OrderServiceOption}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status Type</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {paymentData?.paymentStatus}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Date</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {formettedDate(data.createdAt)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="table-respnsive">
              <table className=" table table-bordered checkListWrapper2">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Items</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderitemData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="imgWrapper">
                            {item.itemImage ? (
                              <img
                                src={changeImageUrl(item.itemImage)}
                                alt="item here"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                }}
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                alt="items here"
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="itemsWrapper d-inline-flex align-items-center">
                            <div className="details">
                              <h6>{item.itemName}</h6>
                              <p className="">
                                {item.orderModifierDetail.map(
                                  (modifier, index) => {
                                    return modifier.isDeleted ? (
                                      <div className="order-modifier">
                                        No {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption != " "
                                          ? `( ${modifier.halfToppingOption.toUpperCase()} )`
                                          : ""}
                                        {index ==
                                        item.orderModifierDetail.length - 1
                                          ? ""
                                          : ","}
                                      </div>
                                    ) : (
                                      <div className="order-modifier">
                                        {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption != " "
                                          ? `(${modifier.halfToppingOption.toUpperCase()} ${
                                              modifier.layer == "extra"
                                                ? `x 2`
                                                : ""
                                            })`
                                          : modifier.layer == "extra"
                                          ? `(x 2)`
                                          : ""}
                                        {index ==
                                        item.orderModifierDetail.length - 1
                                          ? ""
                                          : ","}
                                      </div>
                                    );
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="sizes">
                            <span className="customController">
                              {item.varaitionName}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="addmore-row justify-content-center p-0 border-top-0">
                            <div className="control">{item.quantity}</div>
                          </div>
                        </td>
                        <td>${priceMature(item.itemPrice * item.quantity)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="mt-3">
                <div className="">
                  <ul className="list">
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.totalAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(data.discountAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Coupon Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(data.couponAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Tax Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.taxAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Delivery Charges</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.deliveryCharge)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Gross Total</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.totalGrossAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <ChangeOrderComponent />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageOrderPopup);
