import * as types from "./types";


/***
 *  this action will get manage modifier 
 *  */
export const getManagerModifierList = () => ({
    type: types.GET_MANAGE_MODIFIER_LIST
})

/***
 *  this action will delete manage modifier 
 *  */
export const deleteManagerModifierList = (itemId) => ({
    type: types.DELETE_MANAGE_MODIFIER_ITEM,
    payload: itemId,
})

/***
 *  this action will update  manage modifier 
 *  */
export const updateManagerModifierList = (data) => ({
    type: types.UPDATE_MANAGE_MODIFIER_ITEM,
    payload: data,
})

/***
 *  this action will add  modifier 
 *  */
export const createManagerModifierList = (data) => ({
    type: types.CREATE_MANAGE_MODIFIER_ITEM,
    payload: data,
})

/***
 *  this action will get  modifier 
 *  */
export const getModifierTypeManagerModifierList = () => ({
    type: types.MODIFIER_TYPE_MANAGE_MODIFIER_LIST
});


/***
 *  this action will get  variation type list  
 *  */
export const getVariationsTypeManagerModifierList = () => ({
    type: types.VARIATIONS_TYPE_MANAGE_MODIFIER_LIST
});


