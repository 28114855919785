import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/deliveryAndCharges";
import { Store } from 'react-notifications-component';

// get
export function* getDeliveryChargesList() {
  const response = yield call(auth.getDeliveryChargesList);
  try {
    yield put({
      type: types.GET_DELIVERY_CHARGES_SUCCESS,
      payload: response.result,
      msg: response.message
    })
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_CHARGES_ERROR,
      msg: response.message
    })
  }
}
//add delivery charges 
export function* addDeliveryCharges({ payload }) {
  const response = yield call(auth.addDeliveryCharges, payload);
  try {
    yield call(getDeliveryChargesList);
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_CHARGES_ERROR,
      msg: response.message
    })
  }
}
//update delivery charges 
export function* updateDeliveryCharges({ payload }) {
  const response = yield call(auth.updateDeliveryCharges, payload);
  try {
    yield call(getDeliveryChargesList);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_CHARGES_ERROR,
      msg: response.message
    })
  }
}
//delete delivery charges 
export function* deleteDeliveryCharges({ payload }) {
  const response = yield call(auth.deleteDeliveryCharges, payload);
  try {
    yield call(getDeliveryChargesList);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_CHARGES_ERROR,
      msg: response.message
    })
  }
}