import { useEffect, useState, memo } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import keyValue from "../../../helpers/keyValue";
import { ComponentLoader } from "../../../helpers/PageLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import CostumCartModalPopUp from "../cart/CostumCartModalPopUp";

import { changeImageUrl } from "../../../helpers/replacehost.js";

const CatagoryItemSkeleton = () => {
  return (
    <div className="item-dd">
      <div className="product-item">
        <div className="item-proimg">
          <Skeleton height="100%" containerClassName="avatar-skeleton" />
        </div>

        <div className="item-decs">
          <h5>
            <Skeleton width={183} />
          </h5>
          <p className="item-disc">
            <Skeleton width={80} />
          </p>
          <div className="priceitem">
            <Skeleton width={40} />
          </div>
        </div>

        <div className="pro-buybtn">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
const CategorySkeleton = () => {
  return (
    <div className="itemcate">
      <div>
        <Skeleton containerClassName={"category-skeleton"} />
        <div className="skeleton-catname">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
const CategoryCarouselWithtabs = (props) => {
  const [catitemName, setCatitemName] = useState();
  const [categoryItemLoading, setcategoryItemLoading] = useState(false);
  const [categoryItem, setcategoryItem] = useState([]);
  /** normalize the data from the props  */
  const {
    categoryslider,
    categoryItemList,
    tabsPanelClickFunc,
    customizeModal,
    handleDeliveryAndPickupModel,
    handleOutOfStockMemo,
    isLoding,
    handleRestorentClosedModel,
    responseData: { cateitemLoading, cateListLoading },
  } = props;
  useEffect(() => {
    if (JSON.stringify()) categoryItemLoading(cateitemLoading);
  }, [cateitemLoading]);

  /*** these are setting for category slider  */
  const catSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          waitForAnimate: false,
          pauseOnFocus: false,
          pauseOnHover: false,
          swipe: false,
        },
      },
    ],
  };
  /*** these are settings for the items slider  */
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,

        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /** this effect will set the selected  category state */
  useEffect(() => {
    setCatitemName(categoryslider[0]?.CategoryName ?? "category");
  }, [categoryslider]);

  /** handle click of tabs panel */
  const tabsPanelClick = (item) => {
    setCatitemName(item.CategoryName);
    tabsPanelClickFunc(item.UniqueID);
  };
  /**  handle null , undefind value */
  const priceMature = (v) => {
    if (isNaN(v)) {
      return 0;
    } else if (v == undefined) {
      return parseFloat(v).toFixed(2);
    } else if (v == "") {
      return parseFloat(v).toFixed(2);
    } else if (!isNaN(v) && v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <section className="categoreis-section">
          <div className="container">
            <div className="home-categories">
              <div className="section-title">
                <h3>Select Categories</h3>
              </div>
            </div>

            <div className="catelist">
              <Slider {...catSettings}>
                {cateListLoading || isLoding
                  ? [1, 2, 3, 4, 5, 6].map((item) => (
                      <CategorySkeleton key={item} />
                    ))
                  : categoryslider.map((item, i) => (
                      <div className="itemcate" key={item._id}>
                        <div onClick={() => tabsPanelClick(item)}>
                          {item.Image ? (
                            <img
                              src={changeImageUrl(item.Image)}
                              alt="item"
                              className="img-fluid img-loading"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                              }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                              alt="item"
                              className="img-fluid img-loading"
                            />
                          )}
                          <div className="catname">
                            <p>{item.CategoryName}</p>
                          </div>
                        </div>
                      </div>
                    ))}
              </Slider>
            </div>
          </div>
        </section>
        {/*--tabs panel--*/}
        <section className="porduct-section">
          <div className="container">
            <div className="section-title">
              <h3>{catitemName} Item</h3>
            </div>

            <div className="home-product">
              <div className="row">
                <div className="col-md-12 catelist">
                  <Slider {...settings}>
                    {cateitemLoading || isLoding ? (
                      [1, 2, 3, 4, 5, 6].map((item) => (
                        <CatagoryItemSkeleton key={item} />
                      ))
                    ) : categoryItemList &&
                      Object.keys(categoryItemList).length > 0 ? (
                      categoryItemList.map((item, i) => (
                        <div className="item-dd" key={i}>
                          <div className="product-item">
                            <div className="item-proimg">
                              {item.itemImage ? (
                                <img
                                  src={changeImageUrl(item.itemImage)}
                                  className="img-fluid img_category_item img-loading"
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                  className="img-fluid img_category_item img-loading"
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                  }}
                                />
                              )}
                            </div>

                            <div className="item-decs">
                              <h5 title={item.itemName}>{item.itemName}</h5>
                              <p
                                className="item-disc"
                                title={item.itemDescription}
                              >
                                {item.itemDescription}
                              </p>
                              <div className="priceitem">
                                {!!priceMature(item.itemLowPrice) &&
                                  `$ ${priceMature(item.itemLowPrice)} -`}{" "}
                                $ {priceMature(item.itemHighPrice)}
                              </div>
                            </div>

                            <div className="pro-buybtn">
                              {item.outOfStock ? (
                                <NavLink to={"/"}>Out Of Stock</NavLink>
                              ) : (
                                <NavLink
                                  onClick={() =>
                                    customizeModal(
                                      item.itemId,
                                      item.itemName,
                                      false,
                                      null,
                                      item.halfTopping,
                                      item.itemImage
                                    )
                                  }
                                  to={"/"}
                                >
                                  Customize
                                </NavLink>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="item-dd">{keyValue.noproduct}</div>
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="col-md-4 mobilmar">
        <CostumCartModalPopUp
          handleRestorentClosedModel={handleRestorentClosedModel}
          customizeModal={customizeModal}
          handleDeliveryAndPickupModel={handleDeliveryAndPickupModel}
        />
      </div>
    </div>
  );
};

export default memo(CategoryCarouselWithtabs);
