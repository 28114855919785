import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { FaRegEdit, FaCopy } from "react-icons/fa";
import { ComponentLoader } from "../../helpers/PageLoader";
import { useParams } from "react-router-dom";
import { varifyCrewOrDriverOrders } from "../../Services/manageCrewOrDriver";
import {
  getOrdersList,
  pushOrder,
  readOrderStatus,
  updateOrder,
} from "../../Action/manageOrdersAction";
import ManageCrewOrDriverOrderPopup from "./manageCrewOrDriverOrderPopup";
import SocketHandler from "../../helpers/socketHandler";
import ManageNewOrderNotification from "./ManageNewOrderNotification";
import notificationSrc from "../../Assets/notification.mp3";
import formettedDate from "../../helpers/moomentDate";
import o_original_logo from "../../Assets/images/o_original_logo.jpg";
import padlockIcon from "../../Assets/images/padlock.svg";
import ApplicationDetail from "../costumerSiteComponent/helpers/ApplicationDetail";
import { getAddressAndPhone } from "../../Action/authAction";

const notificationAudio = new Audio(notificationSrc);
const ManageCrewHandler = function ManageCrew({ socket }) {
  const { userId, locationId } = useParams();
  const roleName = "crew";
  const audioInterval = useRef(null);
  const [checkInout, setcheckInout] = useState("out");
  const responseData = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [isvalid, setisvalid] = useState(false);
  const [checking, setchecking] = useState(false);
  const [otp, setotp] = useState("");
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);
  const [showNewOrderNotification, setshowNewOrderNotification] =
    useState("no");
  const [newOrderData, setNewOrderData] = useState({});
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
    paymentData: {},
  });
  const prepend = (value, array) => {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  };
  const [orderCounter, setorderCounter] = useState(0);
  useEffect(() => {
    if (isvalid) {
      if (socket) {
        socket.on("newOrder", (result) => {
          let newOrder = { ...result.order };
          newOrder["users"] = [result.user];
          newOrder["payments"] = [result.payment];
          openShowNewOrderNotification(newOrder);
          setorderCounter((c) => c + 1);
          dispatch(pushOrder(newOrder));
          if (audioInterval.current) {
            clearInterval(audioInterval.current);
          }
          audioInterval.current = setInterval(() => {
            playNotification();
          }, 2000);
        });
        socket.on("updateOrder", (result) => {
          dispatch(updateOrder({ ...result }));
          playNotification();
        });
      }
    }
  }, [socket, isvalid]);
  //play notificatiion
  const playNotification = () => {
    notificationAudio.currentTime = 0;
    let playPromise = notificationAudio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          // Automatic playback started!
        })
        .catch(function (error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
    }
  };
  //handle order read
  const orderRead = (type, orderId) => {
    let tmpListData = [...litsData];
    let tmpIndex = tmpListData.findIndex((e) => e._id === orderId);
    if (tmpIndex !== -1) {
      if (tmpListData[tmpIndex].isRead == 0) {
        dispatch(
          readOrderStatus({
            orderId,
            readOrderStatus: type,
          })
        );
      }
    }
  };
  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };
  const handleToggleCheckBox = () => {
    const { orderList } = responseData;
    if (isTodayCheckBox) {
      setLitsData(orderList.reverse());
    } else {
      setLitsData(orderList.reverse().filter((e) => isToday(e.createdAt)));
    }
    setisTodayCheckBox(!isTodayCheckBox);
  };
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
        paymentData: {},
      });
    }
  };
  const [showModal, setShowModal] = useState("no");
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const [user, setuser] = useState({});
  /**  this function will validate the  user */
  const validateUser = (ootp) => {
    setchecking(true);
    varifyCrewOrDriverOrders({
      _id: userId,
      role: roleName,
      driverOtp: ootp,
    })
      .then((res) => {
        sessionStorage.setItem("driverOtp", ootp);
        localStorage.setItem("locationId", res.locationId);
        setchecking(false);
        setisvalid(true);
        setuser(res.result);
        setcheckInout(res.result.checkInOut ? res.result.checkInOut : "out");
        dispatch(getOrdersList(null, "getOrderItem"));
      })
      .catch((err) => {
        setchecking(false);
        setisvalid(false);
      });
  };
  const closeShowNewOrderNotification = () => {
    setshowNewOrderNotification("no");
    setorderCounter(0);
    clearInterval(audioInterval.current);
  };
  const openShowNewOrderNotification = (data) => {
    setNewOrderData(data);
    setshowNewOrderNotification("yes");
  };

  /*** this effect will normalize the  crewDriver order listing  */
  useEffect(() => {
    const { orderList, loading, tableLading } = responseData;
    if (orderList) {
      if (!isTodayCheckBox) {
        setLitsData(orderList.reverse());
      } else {
        setLitsData(orderList.reverse().filter((e) => isToday(e.createdAt)));
      }
      setIsLoading({
        pageLoading: loading,
        tableLoading: tableLading,
      });
    }
  }, [responseData]);

  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
          paymentData: data.payments[0],
        });
        addNewModal();
        orderRead(2, data._id);
        break;
      default:
        break;
    }
  };
  const columns = [
    {
      dataField: "randomOrderId",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "OrderServiceOption",
      text: "Service Option",
      sort: true,
    },
    {
      dataField: "OrderStatusCode",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date Of Orders",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Method",
      formatter: (cell, row) => {
        return (
          <span key={Date.now()}>
            {row.payments[0].paymentMethod === "cod"
              ? "Cash On Delivery"
              : "Pay By Card"}
          </span>
        );
      },
      filterValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : "Pay By Card";
      },
      sortValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : "Pay By Card";
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Status",
      formatter: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      filterValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      sortValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return "";
        }
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => actionData(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  useEffect(() => {
    validateUser(sessionStorage.getItem("driverOtp"));
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    validateUser(otp);
  };
  const rowClasses = (row) => {
    if (row.OrderStatusCode === "new") {
      return "new-order-wrapper";
    } else if (row.OrderStatusCode === "crew") {
      return "new-order-wrapper";
    } else if (row.isRead === 0) {
      return "unread";
    } else {
      return "";
    }
  };
  useEffect(() => {
    localStorage.setItem("locationId", locationId);
    dispatch(getAddressAndPhone());
  }, []);
  return (
    <>
      <header className="">
        <div className="top-head">
          <div className="container">
            <div className="con-head">
              <div className="head-contactinfo">
                <ul>
                  <li>
                    <img
                      src="/static/media/awesome-phone-alt.4ba22d39.svg"
                      className="ficon"
                      alt=""
                    />{" "}
                    <ApplicationDetail type="contactNumber" />
                  </li>
                  <li className="addli">
                    <img
                      src="/static/media/material-location-on.4291049a.svg"
                      className="licon"
                      alt=""
                    />{" "}
                    <ApplicationDetail type="address" />
                  </li>
                </ul>
              </div>
              <div className="dropdown">
                <div className="nav-link useraccount-dropdown">
                  {roleName + "/"}
                  {user.fname ? user.fname + " " + user.lname : "validating"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="order-wrapper">
        {isvalid ? (
          checking === false ? (
            <BootstrapeTableData
              data={litsData}
              columns={columns}
              keyfield={"_id"}
              searchBar={true}
              isLoading={responseData.loading}
              rowClasses={rowClasses}
            />
          ) : (
            <div className="loading-wrapper text-center">
              <h3>Validating</h3>
            </div>
          )
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="loginBox">
                  <div className="loginform">
                    <div className="loginlogo">
                      <img
                        src={o_original_logo}
                        className="img-fluid"
                        alt="o_original_logo"
                      />
                    </div>
                    <div className="logintitle">
                      <h2>Crew</h2>
                      <span>Please enter your OTP</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group has-search">
                            <label>OTP</label>
                            <span className="form-control-feedback">
                              <img src={padlockIcon} alt="" />
                            </span>
                            <input
                              type="password"
                              className={`form-control ${
                                otp.length < 1 ? "invalid-field" : ""
                              } ${otp.length < 1 ? "invalid-field" : ""}`}
                              placeholder="Enter your OTP"
                              name="otp"
                              value={otp}
                              onChange={(e) => setotp(e.target.value)}
                            />
                            {otp.length < 1 ? (
                              <div className="invalid-feedback">
                                {" "}
                                Please enter valid number
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 text-right">
                          <div className="regBtn">
                            <button className="btn" disabled={checking}>
                              {checking ? "loading..." : "Submit"}
                              <img src="images/loginicon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <ManageCrewOrDriverOrderPopup
          showModal={showModal}
          closeModal={addNewModalMemorized}
          editData={editData}
        />
      )}
      {showNewOrderNotification && (
        <ManageNewOrderNotification
          showModal={showNewOrderNotification}
          closeModal={closeShowNewOrderNotification}
          orderCounter={orderCounter}
          data={newOrderData}
          actionData={actionData}
        />
      )}
      <div className="bottom-footer">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <p>© Copyright WebsiteName 2022. All Right Reserved</p>
            </div>
            <div className="col-md-4 text-right">
              <a aria-current="page" className="active" href="/">
                Terms of Use
              </a>
              <a aria-current="page" className="active" href="/">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const ManageCrew = () => {
  return (
    <SocketHandler>
      <ManageCrewHandler />
    </SocketHandler>
  );
};
export default ManageCrew;
