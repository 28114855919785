import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  cateListLoading: false,
  cateitemLoading: false,
  error: {},
  msg: "",
  productCategoryList: [],
  individualCategoryList: [],
  productCateItemList: [],
  productCatItemVariations: [],
  productCatItemModifiers: [],
};

const manageProductCategoryReducer = (state = initialState, action) => {
  //GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID_SUCCESS
  const { payload, msg, error } = action;
  switch (action.type) {
    case types.GET_PRODUCT_CATEGORY:
      return { ...state, loading: true };

    case types.POST_PRODUCT_CATEGORY:
      return { ...state, tableLoading: true };

    case types.DELETE_PRODUCT_CATEGORY:
      return { ...state, tableLoading: false };

    case types.UPDATE_PRODUCT_CATEGORY:
      return { ...state, tableLoading: true };

    case types.GET_INDIVIDUAL_CATEGORY_LIST:
      return { ...state, cateListLoading: true };
    case types.GET_PRODUCT_ITEM_LIST_BYID:
      return { ...state, productCateItemList: [], cateitemLoading: true };
    case types.GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID:
      return { ...state, cateitemLoading: true };
    case types.GET_PRODUCT_CATEGORY_NO_TOKEN:
      return {
        ...initialState,
        loading: true,
        tableLoading: true,
      };
    case types.GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        productCategoryList: payload || [],
        msg: msg,
      };

    case types.GET_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        cateListLoading: false,
        cateitemLoading: false,
        error: error,
      };

    // public
    case types.GET_INDIVIDUAL_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cateListLoading: false,
        msg: msg,
        individualCategoryList: payload || [],
      };

    case types.GET_PRODUCT_ITEM_LIST_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        cateitemLoading: false,
        msg: msg,
        productCateItemList: payload || [],
      };

    case types.GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        cateitemLoading: false,
        msg: msg,
        productCatItemVariations: payload || [],
      };

    case types.GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        cateitemLoading: false,
        msg: msg,
        productCatItemModifiers: payload || [],
      };

    default:
      return state;
  }
};

export default manageProductCategoryReducer;
