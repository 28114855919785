import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiscount, deleteDiscount } from "../../../Action/manageDiscount";
import ConfirmModal from "../../../helpers/ConfirmModal";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ManageCouponPopup from "./manageCouponPopup";
import { getCoupon, deleteCoupon } from "../../../Action/manageCoupon";
import formettedDate from "../../../helpers/moomentDate";
import { updateCouponStatus } from "../../../Services/authenticationServices";
import { getManageProfileForm } from "../../../Action/manageProfileAction";

export default function ManageCoupon() {
  //const [discount, setdiscount] = useState([]);
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [onof, setonof] = useState(false);
  const [showModal, setShowModal] = useState("no");
  const [litsData, setLitsData] = useState([]);
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false,
  });
  const [isNormalCoupon, setNormalCoupon] = useState(false)
  const [deleteItemConfirm, setDeleteItemConfirm] = useState({
    show: false,
    data: {},
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  //open the close model for add and update
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  //update
  const updateItem = (data, action) => {
    switch (action) {
      case "delete":
        setDeleteItemConfirm({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;

      default:
        break;
    }
  };
  const columns = [
    {
      dataField: "couponType",
      text: " Coupon Type",
      formatter: (cell, row) => {
        return cell;
      },
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "couponName",
      text: "Coupon Name",
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "couponMinimumAmount",
      text: "Minimum Spend Amount",
      formatter: (cell, row) => {
        return cell;
      },
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "deductionAmount",
      text: "Amount($ or %)",
      formatter: (cell, row) => {
        if (row.deductionType == "amount") {
          return "$" + cell;
        } else {
          return cell + "%";
        }
      },
      filterValue: (cell, row) => {
        return cell + (row.deductionType == "amount" ? "$" : "%");
      },
      sort: true,
    },
    {
      dataField: "couponServiceType",
      text: "Service Type",
      formatter: (cell, row) => {
        return cell;
      },
      filterValue: (cell, row) => {
        return cell;
      },
      sort: true,
    },
    {
      dataField: "limitedDateStart",
      text: "Start Date",
      formatter: (cell, row) => {
        if (cell && row.couponType === 'limited') {
          
          return formettedDate(cell);
        } else {
          return "N/A";
        }
      },
      filterValue: (cell, row) => {
        if (cell) {
          return formettedDate(cell);
        } else {
          return "N/A";
        }
      },
      sort: true,
    },
    {
      dataField: "limitedDateEnd",
      text: "End Date",
      formatter: (cell, row) => {
        if (cell && row.couponType === 'limited') {
          return formettedDate(cell);
        } else {
          return "N/A";
        }
      },
      filterValue: (cell, row) => {
        if (cell) {
          return formettedDate(cell);
        } else {
          return "N/A";
        }
      },
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => {
                setNormalCoupon(!row?.itemizedCoupon)
                updateItem(row, "edit")
                }}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  const SwitchOnOf = () => {
    updateCouponStatus({
      couponStatus: !(onof || false),
      userId: localStorage.getItem("userid"),
    }).then((res) => {
      setonof(!onof);
    });
  };
  //handle delete close model
  const handleCloseAlert = () => {
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  //handle delete model success
  const handleSuccessAlert = () => {
    dispatch(deleteCoupon({ id: deleteItemConfirm.data._id }));
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  useEffect(() => {
    dispatch(getCoupon());
    const userId = localStorage.getItem("userid");
    dispatch(getManageProfileForm({ userId: userId }));
  }, []);

  useEffect(() => {
    const { couponList, loading, tableLoading } = coupon;
    if (couponList) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading,
      });
      setLitsData(couponList.reverse());
    }
  }, [coupon]);

  useEffect(() => {
    const { manageProfileData } = getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const { couponStatus } = manageProfileData.result;
      setonof(couponStatus);
    }
  }, [getManageProfile]);
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Coupon</h2>
                  <div className="row">
                    <div className="col-sm-6">
                      <button className="btn custombtn" onClick={addNewModal}>
                        Add
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <label className="switch" onChange={SwitchOnOf}>
                        <input type="checkbox" checked={onof} />
                        <span className="slider round"></span>
                      </label>
                      {/* <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                          onChange={SwitchOnOf}
                          checked={onof}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          ON/OFF
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="card-body actioncard">
                  {isLoding.pageLoding === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal === "yes" && (
        <ManageCouponPopup
          showModal={showModal}
          closeModal={addNewModal}
          editData={editData}
          isNormalCoupon={isNormalCoupon}
        />
      )}

      {deleteItemConfirm && (
        <ConfirmModal
          show={deleteItemConfirm.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
}
