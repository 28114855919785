import React from "react";
import './assets/css/style.css';
import './assets/css/responsive.css';

const MainParent = (OriganalComponent) => {
  class UpdatedComponent extends React.Component {
      render() {
        return (
            <div className="main">
               <OriganalComponent {...this.props}/>
            </div>
          );
      }
  }
  return UpdatedComponent;
};

export default MainParent;
