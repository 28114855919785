import * as types from "../Action/types";

/***
 * Reducer for user login
 * ** */
const initialState = {
  userData: JSON.parse(localStorage.getItem("userData") || "{}"),
  loading: false,
  isLoggedIn: false,
  userDetails: {},
  error: {},
  permission: JSON.parse(localStorage.getItem("userPermission") || "[]"),
};
function userLoginReducer(state = initialState, action) {
  const { response, payload } = action;
  switch (action.type) {
    case types.LOGIN_USER:
      return { ...state, loading: true };
    case types.LOGIN_USER_SUCCESS:
      localStorage.setItem("userData", JSON.stringify(response));
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userData: { ...response },
      };
    case types.SET_USER:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userDetails: payload,
      };
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        userDetails: {},
        userData: {},
      };
    case types.LOGIN_USER_ERROR:
      return { ...state, loading: false, isLoggedIn: false, error: response };
    case types.GET_ALL_PERMISSION:
      return { ...state, loading: true };
    case types.GET_ALL_PERMISSION_SUCCESS:
      localStorage.setItem("userPermission", JSON.stringify(payload));
      return { ...state, loading: false, permission: payload };
    case types.SET_USER_INFORMATION:
      let userData = JSON.parse(localStorage.getItem("userData"));
      Object.keys(payload).map((key) => {
        userData[key] = payload[key];
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      return { ...state, userData, uniqueIndex: Date.now() };

    default:
      return state;
  }
}

export default userLoginReducer;
