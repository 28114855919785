import React, { forwardRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import awesomePhoneicon from "../assets/images/awesome-phone-alt.svg";
import materiallocation from "../assets/images/material-location-on.svg";
import originallogo from "../assets/images/o_original_logo.jpg";
import feathermenu from "../assets/images/feather-menu.svg";
import LoginPopup from "./Login";
import {
  getAddressAndPhone,
  logOutCustomer,
  selectLocation,
} from "../../../Action/authAction";
import feathershoppingcart from "../assets/images/feather-shopping-cart.svg";
import Registration from "./Registration";
import QuickOrder from "./quickOrder";
import { getProductCategoryPublicAction } from "../../../Action/manageProductCategoryAction";
import { getHomeBanner } from "../../../Action/homeBannerActions";
import { flushCart } from "../../../Action/cartAction";
import ApplicationDetail from "../helpers/ApplicationDetail";
import { FaGreaterThan } from "react-icons/fa";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isLocation, setIsLocation] = useState(null);
  const [togleNav, setTogleNav] = useState(false);
  const customer = useSelector((state) => state.customer);
  const location = useSelector((state) => state.location);
  const cart = useSelector((state) => state.cart);
  const [locationPermission, setLocationPermission] = useState(true);
  useEffect(() => {
    const lol = navigator.geolocation.getCurrentPosition(
      function (position) {
        setIsLocation(true);
      },
      function (error) {
        if (error.code == error.PERMISSION_DENIED) {
          setIsLocation(false);
        }
      }
    );
  }, [locationPermission]);
  const navtoggle = forwardRef(({ children, onClick }, ref) => (
    <div
      className="nav-link dropdown-toggle useraccount-dropdown"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  const [loginPopup, setLoginPopup] = useState("no");
  const [registrationPopup, setRegistrationPopup] = useState("no");
  const [quickOrderPopup, setquickOrderPopup] = useState("no");
  const [customerContainer, setcustomerContainer] = useState(false);
  const [setcustomerContainerDefaultTab] = useState("profile");
  const [locationDetail, setlocationDetail] = useState({});
  /**close and open the login model */
  const closeModal = useCallback(() => {
    setLoginPopup((prevState) => (prevState === "yes" ? "no" : "yes"));
  });
  /** close and open the registration model */
  const closeRegistrationModel = useCallback(() => {
    setRegistrationPopup((prevState) => (prevState === "yes" ? "no" : "yes"));
  });

  /** close and open the registration model */
  const closequickOrderPopup = useCallback(() => {
    setquickOrderPopup((prevState) => (prevState === "yes" ? "no" : "yes"));
  });
  /**  close and open and set default key of tab in model */
  const closeCustomerContainer = useCallback((defaultTab = "profile") => {
    setcustomerContainerDefaultTab(defaultTab);
    setcustomerContainer(!customerContainer);
  });

  /**  handle toggle button and dropdown  */
  const togglebutton = () => {
    setTogleNav((prevState) => !prevState);
  };
  const [user, setuser] = useState({
    phoneNumber: "",
    address: "",
  });
  const [coor, setcoor] = useState({
    latitude: 0,
    longitude: 0,
  });
  const handleLogout = () => {
    dispatch(logOutCustomer());
  };
  const handleJumpToOverlay = () => {
    if (!!localStorage.getItem("cart")) {
      localStorage.setItem("currentlocId", localStorage.getItem("locationId"));
    }
    localStorage.setItem("locationId", null);
    dispatch(selectLocation(null));
    history.push("/");
  };
  const handleChangeLocation = (e) => {
    if (
      e !== localStorage.getItem("locationId") &&
      !!localStorage.getItem("cart")
    ) {
      localStorage.removeItem("cart");
    }
    setLocationPermission(!locationPermission);
    if (pathname === "/wallet" && e === localStorage.getItem("locationId")) {
      localStorage.setItem("wallet", true);
    } else {
      dispatch(selectLocation(e));
      localStorage.setItem("locationId", e);
      localStorage.removeItem("wallet");
      window.location.reload();
    }
    /** fetch category again  */
    dispatch(getProductCategoryPublicAction());
    /** fetch banner again  */
    dispatch(getHomeBanner());
    /** flush the cart */
    // dispatch(flushCart());
    /** get fetch address and phoneNumber */
    dispatch(getAddressAndPhone());
  };
  //Sort location in dropdown according to distance
  location.location.sort((p1, p2) =>
    distance(
      p1.addresses[0]?.latitude,
      coor.latitude,
      p1.addresses[0]?.longitude,
      coor.longitude
    ) <
    distance(
      p2.addresses[0]?.latitude,
      coor.latitude,
      p2.addresses[0]?.longitude,
      coor.longitude
    )
      ? -1
      : distance(
          p1.addresses[0]?.latitude,
          coor.latitude,
          p1.addresses[0]?.longitude,
          coor.longitude
        ) >
        distance(
          p2.addresses[0]?.latitude,
          coor.latitude,
          p2.addresses[0]?.longitude,
          coor.longitude
        )
      ? 1
      : 0
  );
  useEffect(() => {
    setlocationDetail(
      location.location.filter((e) => e._id == location.locationId)[0]
    );
  }, [location.locationId]);
  useEffect(() => {
    dispatch(getAddressAndPhone());
  }, []);
  var total_product = 0;
  /** count the total products in cart  */
  cart.map((cartItem, pindex) => {
    total_product = total_product + cartItem?.quantity;
  });
  function distance(lat1, lat2, lon1, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 3956;

    // calculate the result
    let v = c * r;
    if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    }
    return v;
  }
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((e) => {
      setcoor({
        latitude: e.coords.latitude,
        longitude: e.coords.longitude,
      });
    });
  }, []);
  return (
    <>
      <header className="header">
        <div className="top-head">
          <div className="container">
            <div className="con-head">
              <div className="head-contactinfo">
                <ul>
                  <li>
                    <img src={awesomePhoneicon} className="ficon" alt="" />{" "}
                    <ApplicationDetail type="contactNumber" />
                  </li>
                  {isLocation ? (
                    <>
                      {" "}
                      <li className="addli d-flex p-1">
                        <Dropdown>
                          <Dropdown.Toggle variant="success">
                            <img
                              src={materiallocation}
                              className="licon"
                              alt=""
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="location-dropdown">
                            {location.location.map((item, index) => (
                              <Dropdown.Item
                                key={index}
                                value={item._id}
                                onClick={(e) => handleChangeLocation(item._id)}
                              >
                                <div className="d-flex flex-row align-items-center w-100">
                                  <div className="locationImg">
                                    {item.icon ? (
                                      <img
                                        src={item.icon}
                                        className="img-fluid"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = originallogo;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={item.icon}
                                        className="img-fluid"
                                      />
                                    )}
                                  </div>

                                  <div className="locationName">
                                    <p>{item.addresses[0]?.street} </p>
                                    <i>
                                      <b>
                                        {distance(
                                          item.addresses[0]?.latitude,
                                          coor.latitude,
                                          item.addresses[0]?.longitude,
                                          coor.longitude
                                        )}{" "}
                                        miles
                                      </b>
                                    </i>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>

                        {/* Old Dropdown */}
                        {/* <select
                      className={"custom-select custom-select-sm"}
                      defaultValue={location.locationId}
                      onChange={handleChangeLocation}
                    >
                      {location.location.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.addresses[0].street}
                        </option>
                      ))}
                    </select> */}
                      </li>
                      <li className="">
                        <ApplicationDetail type="address" />
                      </li>
                    </>
                  ) : (
                    <li className="">
                      <img src={materiallocation} className="licon" alt="" />{" "}
                      <ApplicationDetail type="address" />
                    </li>
                  )}
                </ul>
              </div>
              <div className={"d-flex f-right"}>
                <div>
                  {customer.isCustomerLoggedIn ? (
                    <Dropdown>
                      <Dropdown.Toggle as={navtoggle}>
                        {customer.customer.fname}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="acntdropdown">
                        <Dropdown.Item>
                          <NavLink to="/profile">Profile</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to="/wallet">Wallet</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to="/order-history">Order history</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to="/change-password">
                            Change Password
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <li onClick={handleLogout}>Logout</li>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <ul className="navbar-nav mobileView-hideNav">
                      <li
                        className="nav-item d-flex"
                        style={{ listStyle: "none", color: "white" }}
                      >
                        <button
                          id="login-button"
                          style={{ color: "white" }}
                          className="btn"
                          onClick={() =>
                            setLoginPopup((prevState) =>
                              prevState === "yes" ? "no" : "yes"
                            )
                          }
                        >
                          LOGIN
                        </button>
                        <button
                          id="registration-button"
                          onClick={closeRegistrationModel}
                          style={{ color: "white" }}
                          className="btn"
                        >
                          REGISTER
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                {/* Home button existing functionality - Back to Overlay Page  */}
                <div className="mobileView-hideNav">
                  <button
                    id="registration-button"
                    onClick={handleJumpToOverlay}
                    style={{ color: "white" }}
                    className="btn"
                  >
                    LOCATIONS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg menuitems">
            <span
              className="cart-added-item d-sm-none d-block"
              onClick={(e) => {
                document
                  .getElementsByClassName("cart-section")[0]
                  .scrollIntoView();
              }}
            >
              <span className="added-count"> {total_product} </span>
              <img src={feathershoppingcart} alt="" />
            </span>
            <NavLink className="logo" to="/">
              <ApplicationDetail type="icon" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              onClick={togglebutton}
            >
              <img src={feathermenu} alt="" />
            </button>
            <div
              className="collapse navbar-collapse"
              style={{ display: togleNav ? "block" : "none" }}
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink
                    exact={true}
                    activeClassName={"active"}
                    className="nav-link"
                    to="/"
                  >
                    Menu
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink
                      exact={true}
                      activeClassName={"active"}
                      className="nav-link"
                      to="/aboutus"
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact={true}
                      activeClassName={"active"}
                      className="nav-link"
                      to="/career"
                    >
                      Career
                    </NavLink>
                  </li> */}

                <li className="nav-item mobileViewShow_nav">
                  <a
                    className="nav-link link-pointer"
                    activeClassName={"active"}
                    id="login-button"
                    onClick={() =>
                      setLoginPopup((prevState) =>
                        prevState === "yes" ? "no" : "yes"
                      )
                    }
                  >
                    Login
                  </a>
                </li>
                <li className="nav-item mobileViewShow_nav">
                  <a
                    className="nav-link link-pointer"
                    activeClassName={"active"}
                    id="registration-button"
                    onClick={closeRegistrationModel}
                  >
                    Register
                  </a>
                </li>
                <li className="nav-item mobileViewShow_nav">
                  <a
                    className="nav-link link-pointer"
                    activeClassName={"active"}
                    id="registration-button"
                    onClick={handleJumpToOverlay}
                  >
                    Locations
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    onClick={closequickOrderPopup}
                    className="nav-link link-pointer"
                    activeClassName={"active"}
                  >
                    Repeat last Order
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {loginPopup === "yes" && (
        <LoginPopup
          showModal={loginPopup}
          closeModal={closeModal}
          closeRegistrationModel={closeRegistrationModel}
        />
      )}
      {registrationPopup === "yes" && (
        <Registration
          showModal={registrationPopup}
          closeModal={closeRegistrationModel}
          closeLoginModal={closeModal}
        />
      )}
      {quickOrderPopup === "yes" && (
        <QuickOrder
          showModal={quickOrderPopup}
          closeModal={closequickOrderPopup}
          setModalClosePopup={setquickOrderPopup}
        />
      )}
    </>
  );
};
export default Header;
