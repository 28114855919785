import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  crewOrDriverOrderList: [],
  msg: "",
};

function manageOrderReducerOrder(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_Crew_OR_DRIVER_ORDER:
      return { ...state, loading: true };
    case types.GET_Crew_OR_DRIVER_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        crewOrDriverOrderList: payload || [],
        msg: msg,
      };
    case types.READ_ORDER_STATUS_CREW_OR_DRIVER_SUCCESS:
      let tmpList = { ...state };
      let tmpIndex = tmpList.crewOrDriverOrderList.findIndex(
        (e) => e._id === payload.orderId
      );
      if (tmpIndex !== -1) {
        tmpList.crewOrDriverOrderList[tmpIndex].isRead =
          payload.readOrderStatus;
      }
      tmpList.crewOrDriverOrderList.reverse();
      return { ...tmpList, loading: false, tableLoading: true };
    case types.GET_Crew_OR_DRIVER_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}
export default manageOrderReducerOrder;
