import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getUpsellItemsService } from "../../../Services/manageMenuService";
import emptyBox from "../assets/images/empty-box.png";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js"

export default function ItemSuggestionBox(props) {
  const { customizeModalMemorized } = props;
  const [isloading, setisloading] = useState(true);
  const [product, setproduct] = useState([]);
  useEffect(() => {
    setisloading(true);
    getUpsellItemsService()
      .then((res) => {
        setproduct(res.result || []);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, []);

  /**  handle null , undefind value */
  const priceMature = (v) => {
    if (isNaN(v)) {
      return 0;
    } else if (v == undefined) {
      return parseFloat(v).toFixed(2);
    } else if (v == "") {
      return parseFloat(v).toFixed(2);
    } else if (!isNaN(v) && v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };

  return (
    <div className="sugg-row">
      {isloading ? (
        [0, 1].map((item, index) => {
          return (
            <div className="sugg-item-col mb-3" key={index}>
              <div className="w-100">
                <h6></h6>
              </div>
              {[0, 1, 2].map((item, index) => {
                return (
                  <div className="sugg-item" key={index}>
                    <div className="sugg-item-img">
                      <Skeleton
                        circle
                        height="90%"
                        width="90%"
                        containerClassName="avatar-skeleton"
                      />
                    </div>
                    <p className="text-center mt-2">
                      <Skeleton count={2} width={90} />
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : [...product.map((item) => item.menu)].flat().length < 1 ? (
        <div className="text__wrpr2">
          <img src={emptyBox} />
        </div>
      ) : (
        product?.map((cat, index) => {
          return cat?.menu.length < 1 ? (
            <></>
          ) : (
            <div className="sugg-item-col mb-3" key={index}>
              <div className="w-100">
                <h6>{cat.CategoryName}</h6>
              </div>
              {cat.menu.length < 1 ? (
                <div>There are none..</div>
              ) : (
                cat?.menu.map((item, index) => (
                  <div className="sugg-item" key={index}>
                    <div className="sugg-item-img">
                      <img
                        src={changeImageUrl(item.itemImage)}
                        alt="Items here"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                        }}
                      />
                    </div>
                    <p className="text-center mb-0">{item.itemName}</p>
                    <button
                      to="/"
                      className="btn"
                      onClick={(e) => {
                        customizeModalMemorized(
                          item.itemId,
                          item.itemName,
                          false,
                          null,
                          item.halfTopping,
                          item.itemImage
                        );
                      }}
                    >
                      $ {priceMature(item.variationDetails.itemPrice)}
                    </button>
                  </div>
                ))
              )}
            </div>
          );
        })
      )}
    </div>
  );
}
