import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
    getManagerModifierList,
    getModifierTypeManagerModifierList,
    getVariationsTypeManagerModifierList,
    deleteManagerModifierList,
} from "../../Action/manageModifierAction";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import ConfirmModal from "../../helpers/ConfirmModal";
import AddModalRightAnimation from "./calculatorModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal, Row, FormLabel, Form, Col } from "react-bootstrap";

const foodCalculator = (props) => {
    const dispatch = useDispatch();
    const dataResponse = useSelector((state) => state.manageModifier);
    const [animationModal, setAnimationModal] = useState("no");
    const [litsData, setLitsData] = useState([]);
    const [showModal, setShowModal] = useState({ show: false, data: "" });
    const [editModifierData, setEditModifierData] = useState({
        edit: false,
        editData: {},
    });
    const {
        // animationModal: showModal,
        closeNewMenu,
        // editModifierData: { edit, editData },
        variationsTypeList,
        modifierTypeList,
        // litsData,
    } = props;

    const menuItemModifierTypeChange = (e, index) => {
        setMenuItemRow((prevState) =>
            prevState.map((item, i) =>
                i === index ? { ...item, modifiersId: e } : item
            )
        );
    };


    return (
        <>
            <div className="main-content">
                <div className="page-title">
                    <h2>Food Calculator</h2>
                </div>

                <div className="mb-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card customCard">
                                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                                    <h2>Food Calculator</h2>
                                    <div className="regBtn add_small_button">
                                        <button className="btn" >
                                            Add New
                                        </button>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Modifier Name</th>
                                                <th>Unit</th>
                                                <th>Price($)</th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <td>
                                                <Typeahead
                                                    id={"modifiersId"}
                                                    labelKey="MadifierName"
                                                    className="Typeahead_multiple_select"
                                                    filterBy={["MadifierName"]}
                                                    onChange={(e) =>
                                                        menuItemModifierTypeChange(e, index)
                                                    }
                                                    options={modifierTypeList ?? []}
                                                    placeholder={`Search & Select`}
                                                // selected={modifiersId}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    name="Price"
                                                    placeholder="0"
                                                />
                                                <select
                                                    name={"varaitionName"}
                                                    className="form-control"
                                                >
                                                    <option value={""}>LB</option>
                                                    <option value={""}>Pound</option>

                                                </select>
                                            </td>

                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    // value={item.Price}
                                                    name="Price"
                                                    placeholder="0.00"
                                                />
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                <h4>Total</h4>  
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default foodCalculator;
