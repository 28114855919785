import * as api from "./api";
/**
 * get all item
 */
export const getDiscount = () => {
  try {
    return api.get("discount/discount");
  } catch (error) {
    return error;
  }
};

/***
 * add new item
 */
export const addDiscount = (params) => {
  try {
    return api.post("discount/discount", params);
  } catch (error) {
    return error;
  }
};

/***
 * update new item
 */
export const updateDiscount = (params) => {
  const { data, id } = params;
  try {
    return api.put(`discount/discount/${id}`, data);
  } catch (error) {
    return error;
  }
};

/***
 * update new item
 */
export const deleteDiscount = (params) => {
  const { id } = params;
  try {
    return api.deleteRec(`discount/discount/${id}`);
  } catch (error) {
    return error;
  }
};
