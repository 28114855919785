import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import ManageDeliveryChargesPopup from "./manageDeliveryChargesPopup";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import {
  getDeliveryCharges,
  deleteDeliveryCharges,
} from "../../../Action/DeliveryChargesAction";
import { PageLoader, ComponentLoader } from '../../../helpers/PageLoader';
import ConfirmModal from "../../../helpers/ConfirmModal";

const ManageDeliveryCharges = () => {
  const dispatch = useDispatch();
  const responseData = useSelector((state) => state.deliveryAndCharges);
  const [showModal, setShowModal] = useState("no");
  const [litsData, setLitsData] = useState([]);
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false
  });
  const [deleteItemConfirm, setDeleteItemConfirm] = useState({
    show: false,
    data: {},
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  //open the close model for add and update
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  const columns = [
    {
      dataField: "min",
      text: "Miles",
      formatter: (cell, row) => {
        return `${row.min} to ${row.max} miles`
      },
      filterValue: (cell, row) => {
        return `${row.min} to ${row.max} miles`
      },
      sort: true,
    },
    {
      dataField: "price",
      text: "Charges",
      formatter: (cell, row) => {
        return '$' + cell;
      },
      filterValue: (cell, row) => {
        return '$' + cell;
      },
      sort: true,
    },
    {
      dataField: "driverReimbursement",
      text: "Driver Fee",
      formatter: (cell, row) => {
        return '$' + cell;
      },
      filterValue: (cell, row) => {
        return '$' + cell;
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => updateItem(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];

  // mount
  useEffect(() => {
    dispatch(getDeliveryCharges());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { deliverAndChargesList, loading, tableLoading } = responseData;
    if (deliverAndChargesList) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading
      })
      setLitsData(deliverAndChargesList.reverse());
    }
  }, [responseData]);
  //update
  const updateItem = (data, action) => {
    switch (action) {
      case "delete":
        setDeleteItemConfirm({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;

      default:
        break;
    }
  };
  //handle delete close model
  const handleCloseAlert = () => {
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  //handle delete model success  
  const handleSuccessAlert = () => {
    dispatch(
      deleteDeliveryCharges({ _id: deleteItemConfirm.data._id })
    );
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Delivery Charges</h2>
                  <button className="btn custombtn" onClick={addNewModal}>
                    Add Delivery Charges
                  </button>
                </div>
                <div className="card-body actioncard">
                  {
                    (isLoding.pageLoding === false)
                      ? (<BootstrapeTableData
                        data={litsData}
                        columns={columns}
                        keyfield={"_id"}
                        searchBar={true}
                        isLoading={isLoding.tableLoading}
                      />)
                      : <ComponentLoader size={40} />
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ManageDeliveryChargesPopup
          showModal={showModal}
          closeModal={addNewModal}
          editData={editData}
        />
      )}

      {deleteItemConfirm && (
        <ConfirmModal
          show={deleteItemConfirm.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageDeliveryCharges;
