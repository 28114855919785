import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import longArrowAltDown from "../../../Assets/images/awesome-long-arrow-alt-down.svg";
import totalOrder from "../../../Assets/images/total-order.svg";
import newOrder from "../../../Assets/images/new-order.svg";
import ManageDriverIndex from "../manageDriverIndex";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getcrewOrDriverOrder,
  driverCheckInOut,
} from "../../../Action/crewOrDriverAction";
import { driverOrderHistory } from "../../../Services/manageCrewOrDriver";
const DriverDashBoard = () => {
  const responseData = useSelector((state) => state.crewOrDriverOrder);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const roleName = "driver";
  const [order, setOrder] = useState({
    deliveredOrder: 0,
    newOrder: 0,
  });
  const dashCardData = [
    {
      totalOrderName: "New Order",
      totalOrderNo: order.newOrder,
      longArrowIcon: longArrowAltDown,
      cardSign: totalOrder,
      uri: `/driver/orders/${userId}/`,
    },
    {
      totalOrderName: "Delivered Orders",
      totalOrderNo: order.deliveredOrder,
      longArrowIcon: longArrowAltDown,
      cardSign: newOrder,
      uri: `/driver/history/${userId}/`,
    },
  ];
  /** this function will dispatch the action for the get the list of crew and driver orders and order history as well */
  const repectFunction = () => {
    dispatch(
      getcrewOrDriverOrder({
        _id: userId,
        role: roleName,
        collectionName: "order",
      })
    );
    driverOrderHistory(userId)
      .then((res) => {
        let delivaredOrder = res.result.filter(
          (res) => res.OrderStatusCode === "delivered"
        );
        setOrder({ ...order, deliveredOrder: delivaredOrder.length });
      })
      .catch((err) => {});
  };
  /** update the list in every 5 sec */
  useEffect(() => {
    // repectFunction();
    setInterval((e) => {
      repectFunction();
    }, 5000);
  }, []);
  /*** normalize  order  list object  */
  useEffect(() => {
    const { crewOrDriverOrderList, loading, tableLading } = responseData;
    setOrder({
      ...order,
      newOrder: crewOrDriverOrderList.filter(
        (res) => res.OrderStatusCode !== "delivered"
      ).length,
    });
  }, [responseData]);

  return (
    <>
      <ManageDriverIndex>
        <Row>
          {dashCardData.map((item, i) => (
            <Col md={6} key={i}>
              <div className="dash-card">
                <div className="dash-value">
                  <h3>{item.totalOrderName}</h3>
                  <h2>{item.totalOrderNo}</h2>
                  <Link to={item.uri}>
                    <img src={item.longArrowIcon} alt="" />
                  </Link>
                </div>
                <div className="cardicon">
                  <img src={item.cardSign} alt="" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </ManageDriverIndex>
    </>
  );
};

export default DriverDashBoard;
