import React from "react";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import keyValue from "./keyValue";
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from "react-bootstrap";
import overlayFactory from "react-bootstrap-table2-overlay";

// replace hostname of urls
import { changeHostName } from "./replacehost.js"

const { SearchBar } = Search;

export default function BootstrapeTableData(props) {
  const { data, columns, keyfield, searchBar, isLoading, rowClasses } = props;
  const perPage = 10;
  const paginationTrueFalse = data.length > perPage ? false : true;
  const totalLength = data.length;
  const arrayofPagelist = [];

  let totalValue = totalLength;
  for (let i = 0; i < Math.ceil(totalLength / perPage); i++) {
    if (totalValue && arrayofPagelist.length === 0) {
      totalValue = 5;
      arrayofPagelist.push(totalValue);
    }
    if (totalValue && arrayofPagelist.length > 0 && totalValue < totalLength) {
      totalValue = totalValue + totalValue;
      arrayofPagelist.push(totalValue);
    }
  }
  arrayofPagelist.pop();
  const sizePerPageListData = arrayofPagelist.map((item) => {
    let perPageList = {
      text: String(item),
      value: Number(item),
    };
    return perPageList;
  });
  sizePerPageListData.push({
    text: `All`,
    value: Number(totalLength),
  });

  const pagination = paginationFactory({
    custom: paginationTrueFalse,
    sizePerPage: 10,
    showTotal: true,
    hideSizePerPage: data.length === 0 ? true : false,
    sizePerPageList: sizePerPageListData,
  });

  const loadingTable = overlayFactory({
    spinner: true,
    active: false,
    styles: {
      color: "rgba(0,0,0,0.2)",
      background: "rgba(0,0,0,0.2)",
    },
  });
  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField={keyfield}
        data={changeHostName(data)}
        columns={columns}
        search
      >
        {(props) => (
          <>
            {searchBar === true ? (
              <Row>
                <Col md={{ offset: 6 }}>
                  <div className="SearchBoxTable">
                    <SearchBar {...props.searchProps} />
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <div className="customtable table-responsive custom-table">
              <BootstrapTable
                {...props.baseProps}
                noDataIndication={keyValue.noRecordes}
                pagination={pagination}
                loading={isLoading ?? false}
                overlay={loadingTable}
                rowClasses={rowClasses}
              />
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
}
