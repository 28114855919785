import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import { FaRegImage, FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import ManageProductCategoryModal from "./ManageProductCategoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductCategoryAction,
  deleteProductCategoryAction,
} from "../../Action/manageProductCategoryAction";
import ConfirmModal from "../../helpers/ConfirmModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";

const ManageCategory = () => {
  const dispatch = useDispatch();
  const dataResponse = useSelector((state) => state.productCat);
  const [manageCategory, setManageCategory] = useState("no");
  const [listItem, setListItem] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    data: {},
  });
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false,
  });
  const [editModal, setEditModal] = useState({
    edit: false,
    editData: {},
  });

  const columns = [
    {
      dataField: "CategoryName",
      text: "Category Name",
      sort: true,
    },
    {
      dataField: "Description",
      text: "Category Description",
      sort: true,
    },
    {
      dataField: "Image",
      text: "Category Image",
      formatter: (cell) =>
        cell !== undefined ? (
          <Image className="imageGrid" src={cell} alt={cell} />
        ) : (
          <FaRegImage />
        ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            {/* <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li> */}
                        <li>
              <label
                className="switch"
                onChange={() => updateItem(row, "delete")}
              >
                <input type="checkbox" defaultChecked={row.isActive} />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              <Button onClick={() => updateItem(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  // mount
  useEffect(() => {
    dispatch(getProductCategoryAction());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { productCategoryList, loading, tableLoading } = dataResponse;
    if (productCategoryList) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading,
      });
      setListItem(productCategoryList.reverse());
     
    }
  }, [dataResponse]);
  //add row 
  const addNewModal = () => {
    setManageCategory((prevState) => (prevState === "yes" ? "no" : "yes"));
    setEditModal({
      edit: false,
      editData: {},
    });
  };
  //update the form
  const updateItem = (data, action) => {
    switch (action) {
      case "edit":
        setEditModal({
          edit: true,
          editData: data,
        });
        setManageCategory("yes");
        break;
      // case "delete":
      //   setConfirmModal({
      //     show: true,
      //     data: data,
      //   });
      //   break;
      case "delete":
        dispatch(
          deleteProductCategoryAction({
            itemId: data._id ,
          })
        );
        break;
      default:
        break;
    }
  };
  //handle close of delete
  const handleCloseAlert = () => {
    setConfirmModal((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };
  //handle success of delete 
  const handleSuccessAlert = () => {
    dispatch(deleteProductCategoryAction({ itemId: confirmModal.data._id }));
    setConfirmModal({
      show: false,
      data: {},
    });
  };
  if (isLoding.pageLoding === true) {
    return <PageLoader size={50} />;
  }

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Product Category</h2>
        </div>

        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Product Category</h2>
                  <button
                    onClick={() => addNewModal()}
                    className="btn custombtn"
                  >
                    Add Category
                  </button>
                </div>
                <div className="card-body actioncard">
                  {isLoding.pageLoding === false ? (
                    <BootstrapeTableData
                      data={listItem}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {manageCategory && (
        <ManageProductCategoryModal
          editModal={editModal}
          closeModal={addNewModal}
          showModal={manageCategory}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          show={confirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageCategory;
``