import { Button } from "react-bootstrap";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getPermission } from "../../Action/manageRolesAndPermission";
import ManageRoleAndPermissionPopup from "./manageRolePopup";
import ConfirmModal from "../../helpers/ConfirmModal";
import { deleteRole } from "../../Action/manageRolesAndPermission";
import "./manageRole.css";
export default function manageRoles() {
  const dispatch = useDispatch();
  const roleAndPermission = useSelector((state) => state.roleAndPermission);
  const roleAndPermission2 = JSON.parse(localStorage.getItem("userPermission"));
  const [roles, setroles] = useState([]);
  const [addnewModal, setaddnewModal] = useState("no");
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    data: {},
  });
  const columns = [
    {
      dataField: "roleName",
      text: "Role Name",
      formatter: (cell, row) => {
        return row.roleName;
      },
      filterValue: (cell, row) => {
        return row.roleName;
      },
      sort: true,
    },
    {
      dataField: "permission",
      text: "Permissions",
      formatter: (cell, row) => {
        let p = "";
        row.permission.map((item, index) => {
          p += item.pageName;
          if (index != row.permission.length - 1) p += ",";
        });
        return p;
      },
      filterValue: (cell, row) => {
        let p = "";
        row.permission.map((item, index) => {
          p += item.pageName;
          if (index != row.permission.length - 1) p += ",";
        });
        return p;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button>
                <FaRegEdit
                  onClick={(e) => manageMenuTableAction(row, "edit")}
                />
              </Button>
            </li>
            <li>
              <label
                className="switch"
                onChange={(e) => manageMenuTableAction(row, "delete")}
              >
                <input type="checkbox" defaultChecked={row.isActive} />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(getRole());
    dispatch(getPermission());
  }, []);
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  const showOrHideModal = () => {
    setaddnewModal((prevState) => (prevState === "yes" ? "no" : "yes"));
  };
  //handle rejection from delete model
  const handleCloseAlert = () => {
    setConfirmModal((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };
  //handle success from delete model
  const handleSuccessAlert = () => {
    dispatch(
      deleteRole({
        _id: confirmModal.data._id,
        isActive: confirmModal.data.isActive,
      })
    );
    setConfirmModal({
      show: false,
      data: {},
    });
  };
  //handle various action from table
  const manageMenuTableAction = (data, action) => {
    switch (action) {
      case "create":
        setEditData({
          edit: false,
          data: data,
        });
        showOrHideModal();
        break;
      case "delete":
        dispatch(
          deleteRole({
            _id: data._id,
            isActive: !data.isActive,
          })
        );
        break;
      case "edit":
        setEditData({
          edit: true,
          data,
        });
        showOrHideModal();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setroles(roleAndPermission.roles ?? []);
  }, [roleAndPermission]);
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Roles</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2">
                  <h2>Manage Roles</h2>
                  <button
                    className="btn custombtn"
                    onClick={(e) => manageMenuTableAction({}, "create")}
                  >
                    Add New Role
                  </button>
                </div>
                <div className="card-body">
                  <BootstrapeTableData
                    data={roles}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                    classes="roles-table"
                    //isLoading={}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addnewModal == "yes" && (
        <ManageRoleAndPermissionPopup
          permissionList={
            roleAndPermission2.length > 0
              ? roleAndPermission2
              : roleAndPermission.permission
          }
          showModal={addnewModal}
          closeModal={showOrHideModal}
          editData={editData}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          show={confirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
}
