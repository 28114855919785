import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getCustomizeVariationItemListByIdService, getProductCategoryItemListByIdService } from '../../../Services/managePropductCategoryServices'

const CouponItem = ({ index, item, selectedItem, onUpdateItem, coupon, handleITMChange, handleVarChange, handleTypeChange, productVars }) => {
    const [selectedCategory, setCategory] = useState('')
    const selectRef = useRef(null)
    const [productItems, setItems] = useState([])
    const [productVariations, setVariations] = useState([])
    const [currentItem, setItem] = useState(item)

    //   const { allMenuData } = useSelector((state) => state.manageMenu); 
    // console.log(item, 'this is current item')
    // Function to fetch items
    const fetchItems = async (catId) => {
        const data = { itemId: catId }
        const res = await getProductCategoryItemListByIdService(data)
        setItems(res.result)
    }

    // Function to fetch variations
    const fetchVariations = async (itemId) => {
        const res = await getCustomizeVariationItemListByIdService(itemId)
        // console.log(res)
        // const { VariationDetails } = res?.result?.at(0);
        const varDetails = res?.result?.at(0)?.VariationDetails;
        // console.log(varDetails, 'variation result')
        setVariations(varDetails)
    }

    const { productCategoryList, productCateItemList } = useSelector(
        (state) => state.productCat
    );

    function handleCategoryChange(e) {
        // setActiveCategory(e.target.value)
        fetchItems(e.target.value)
        // console.log(e.target.value, 'selected category value')
        const updatedItem = {             
            ...item,
            itemId:"",
            itemName:"",
            variationId:"",
            variationName:"",
            }
        setItem(updatedItem)
        // console.log(updatedItem, 'updated item')
        // onUpdateItem(updatedItem)
        // dispatch(getProductCategoryItemListById({ itemId: e.target.value }));
        let tempCategory = { ...coupon };
        tempCategory.couponCombo[e.target.id].categoryId = e.target.value;
        // setcoupon(tempCategory);
    }

      function handleItemChange(e) {
        fetchVariations(e.target.value)
        handleITMChange(e)
    // const { VariationDetails } = allMenuData.find(vl => vl.itemId === e.target.value)
    const updatedVar = {             
        ...item,
        variationId:"",
        variationName:"",
         }
    setItem(updatedVar)
    // onUpdateItem(updatedVar)
    // fetchVariations(e.target.value)
    // if (VariationDetails) {
    //   setSelectedItem(VariationDetails);
    // }
    // let tempCategory = { ...coupon };

    // tempCategory.couponCombo[e.target.id].itemId = e.target.value;
    // tempCategory.couponCombo[e.target.id].itemName =
    //   e.target.options[e.target.selectedIndex].text;
    // setcoupon(tempCategory);
      }

    function handleVariationChange(e) {
        let tempCategory = { ...coupon };
        // console.log(e.target.value, 'new var value')
        // fetchVariations(e.target.value)
        tempCategory.couponCombo[e.target.id].variationId = e.target.value;
        tempCategory.couponCombo[e.target.id].variationName =
            e.target.options[e.target.selectedIndex].text;
        // setcoupon(tempCategory);
    }


    //   useEffect(() => {
    //     if (productCategoryList?.length > 0) {
    //         fetchItems(productCategoryList?.at(index)?.UniqueID)

    //     }
    //   }, [productCategoryList])

    //   useEffect(() => {
    //     // console.log(productItems, 'item id')
    //     if(productItems?.length > 0) {
    //         fetchVariations(productItems?.at(index)?.itemId)
    //     }
    //   }, [productItems])

    useEffect(() => {
        fetchItems(item?.categoryId)
    }, [item])

    useEffect(() => {
        if(!currentItem.itemId) return
        fetchVariations(currentItem.itemId)
    },[])

    //   modified
    // useEffect(() => {
    //     console.log(productItems?.at(index), 'current index')
    //     // fetchVariations(productItems?.at(index)?.itemId)
    // }, [productItems])

    // useEffect(() => {
    //     setVariations(productVars)
    //     console.log(productVars,'productVars')
    // }, [productVars])


    //   useEffect(() => console.log(currentItem, 'current selected item'),[currentItem])
    // console.log(productItems, 'productItems')

    return (
        <React.Fragment key={index}>
            <div className="selectForm_Box">
                <label>Category</label>

                <select
                    name="category"
                    onChange={handleCategoryChange}
                    id={index}
                >
                    <option name="category" value="select" id={null}>
                        Select
                    </option>

                    {productCategoryList.filter(vl => vl.isActive).map((value, i) => {
                        return (
                            item.categoryId == value.UniqueID ?
                                <option
                                    name="category"
                                    value={value.UniqueID}
                                    selected
                                    id={index}
                                >
                                    {value.CategoryName}
                                </option>
                                : <option
                                    name="category"
                                    value={value.UniqueID}
                                    id={index}
                                >
                                    {value.CategoryName}
                                </option>
                        );
                    })}
                </select>
                <label>Menu Item</label>
                <select onChange={handleItemChange}
                    id={index}
                    name='item'>
                    {currentItem?.itemId ? (
                        <option value={currentItem?.itemName} selected style={{ display: 'none' }}>{currentItem?.itemName}</option>
                    ) : (
                        <option value="select">Select</option>
                    )}

                    {productItems?.length && productItems?.map((v, i) => {
                        return (
                            <option name={v?.itemName} value={v?.itemId}>
                                {v?.itemName}
                            </option>
                        );
                    })}
                </select>
                <label>Variation</label>
                <select
                    name="variation"
                    onChange={handleVariationChange}
                    id={index}
                >
                    {currentItem?.variationId ? (
                        <option value={currentItem.variationName} selected style={{ display: 'none' }}>{currentItem.variationName}</option>
                    ) :
                        <option value="select" selected>Select</option>
                    }
                    {productVariations && productVariations?.map((g, i) => {
                        return (
                            <option name={g.variationName} value={g?.variationId} >
                                {g.variationName}
                            </option>
                        );
                    })}

                </select>
            </div>
            {/* {console.log(coupon.couponCombo, "LOG: coupon.couponCombo",)}
            {console.log(coupon.couponCombo[index],index, "LOG: single coupon.couponCombo",)} */}

            {coupon.couponCombo.length > 1 && index !== coupon.couponCombo.length - 1 &&
                
                <div className="selectForm_Box_optional">
                    <select
                        name={"type"}
                        id={index}
                        ref={selectRef}
                        onChange={handleTypeChange}
                    >
                        {coupon.couponCombo[index + 1].type === 1 || coupon.couponCombo[index + 1].type === 0 ?
                            (() => {
                               if(index === 0){
                                if(coupon.couponCombo[index].type === 0){
                                    return <option value={item.type} selected style={{ display: "none" }}>OR</option>                                    
                                }
                              
                                //     return <option value={item.type} selected style={{ display: "none" }}>AND</option>
                                // }
                                
                            } else if(index === 1){
                                   if(coupon.couponCombo[index + 1].type === 1 ){
                                    return <option value={item.type} selected style={{ display: "none" }}>AND</option>                                    
                                    }
                                   else{
                                       return <option value={item.type} selected style={{ display: "none" }}>OR</option>
                                   }
                                   
                                }else if(index === 2){
                                    if(coupon.couponCombo[index].type === 1){
                                       return <option value={item.type} selected style={{ display: "none" }}>AND</option>                
                                   }
                                  
                                    if(coupon.couponCombo[index].type === 0){
                                       return <option value={item.type} selected style={{ display: "none" }}>OR</option>                
                                   }
                                //    else{
                                //        return <option value={item.type} selected style={{ display: "none" }}>AND</option>
                                //    }

                               } 
                            })()    

                            // <option value={item.type} selected style={{ display: "none" }}>{(coupon.couponCombo[index + 1].type_text == "AND" && coupon.couponCombo[index + 1].type == 0) ? "AND" : coupon.couponCombo[index].type == 1 ? "AND" : "OR"}</option>
                            // <option value={item.type} selected style={{ display: "none" }}>{(coupon.couponCombo[index + 1].type_text == "AND" && coupon.couponCombo[index + 1].type == 0) ? "AND" : coupon.couponCombo[index + 1].type == 1 ? "AND" : "OR"}</option>
                            :
                            <option value={null} selected style={{ display: "none" }}>AND</option>
                        }
                        <option value="1">AND</option>
                        <option value="0">OR</option>
                    </select>
                </div>}
        </React.Fragment>
    )
}

export default CouponItem