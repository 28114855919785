import backgroundImg from "../assets/images/h1_bg-1.png";
import { NavLink } from "react-router-dom";

export default function InnerBaner(props) {
  /** normalize the props */
  const { title } = props;
  return (
    <>
      <section
        className="innerbanner"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <div className="inneroverlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-caption text-center">
                <h3>{title}</h3>
                <nav>
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <NavLink to={"/"}>Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {title}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
