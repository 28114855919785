import React, { useState, useEffect } from "react";
import {
  getManageProfileForm,
  updateManageProfileStripeKey,
} from "../../../Action/manageProfileAction";
import { useDispatch, useSelector } from "react-redux";
export default function ManageStripe() {
  const locationId = localStorage.getItem("locationId");
  const dispatch = useDispatch();
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [stripeKey, setStripeKey] = useState({
    publicStripeKey: "",
    privateStripeKey: "",
  });
  const userId = localStorage.getItem("userid");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (stripeKey.publicStripeKey && stripeKey.privateStripeKey) {
      locationId
        ? dispatch(
            updateManageProfileStripeKey({
              userId: locationId,
              data: { stripeKey },
            })
          )
        : dispatch(
            updateManageProfileStripeKey({
              userId: localStorage.getItem("userid"),
              data: { stripeKey },
            })
          );
    }
  };
  const handleChange = (e) => {
    setStripeKey({ ...stripeKey, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    dispatch(getManageProfileForm({ userId: locationId }));
  }, []);
  useEffect(() => {
    const { manageProfileData } = getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const { publicStripeKey, privateStripeKey } = manageProfileData.result;
      setStripeKey({
        ...stripeKey,
        ["publicStripeKey"]: publicStripeKey,
        ["privateStripeKey"]: privateStripeKey,
      });
    }
  }, [getManageProfile]);
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Stripe Key</h2>
                </div>
                <div className="card-body actioncard">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group d-flex justify-content-around flex-column">
                          <label>
                            Publishable Key
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Publishable Stripe Key"
                              value={stripeKey.publicStripeKey}
                              name="publicStripeKey"
                              onChange={handleChange}
                            />
                          </label>
                          {!stripeKey.publicStripeKey && (
                            <p className="error-validation">
                              this field is required
                            </p>
                          )}
                          {/* SecondKey */}
                          <label>
                            Private Key
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Private Stripe Key"
                              value={stripeKey.privateStripeKey}
                              name="privateStripeKey"
                              onChange={handleChange}
                            />
                          </label>
                          {!stripeKey.privateStripeKey && (
                            <p className="error-validation">
                              this field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 d-flex mb-4 align-items-end">
                        <div className="regBtn">
                          <button className="btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
