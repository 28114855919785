import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  discountList: [],
  msg: "",
};
function manageDiscountReducer(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_DISCOUNT:
      return { ...state, loading: true };
    case types.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        discountList: payload || [],
        msg: msg,
      };
    default:
      return state;
  }
}
export default manageDiscountReducer;
