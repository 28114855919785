import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  msg: "",
  masterModifierList: [],
};
const manageMasterModifierReducer = (state = initialState, action) => {
  const { payload, msg, error } = action;
  switch (action.type) {
    case types.GET_MASTER_MODIFIER_LIST:
      return { ...state, loading: true };

    case types.CREATE_MASTER_MODIFIER_LIST_ITEM:
      return { ...state, tableLoading: true };
    case types.UPDATE_MASTER_MODIFIER_LIST_ITEM:
      return { ...state, tableLoading: true };
    case types.DELETE_MASTER_MODIFIER_LIST_ITEM:
      return { ...state, tableLoading: true };

    case types.GET_MASTER_MODIFIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading:false,
        masterModifierList: payload,
        msg: msg,
      };

    case types.GET_MASTER_MODIFIER_LIST_ERROR:
      return { ...state, loading: false, error: error };

    default:
      return state;
  }
};

export default manageMasterModifierReducer;
