import * as types from "./types";

/***
 * this action will get restaurant timing 
 */
export const getRestaurentTiming = () =>({
    type: types.GET_RESTAURENT_TIMING
});

/***
 * this action will update restaurant timing 
 */
export const updateRestaurentTiming = (payload) =>({
    type: types.UPDATE_RESTAURENT_TIMING,
    payload
});