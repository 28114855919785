import { useDispatch, useSelector } from "react-redux";
import React, { forwardRef, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import NormalModal from "../../../helpers/NormalModal";
import envelopeIcon from "../../../Assets/images/envelope.svg";
import o_original_logo from "../../../Assets/images/o_original_logo.jpg";
import padlockIcon from "../../../Assets/images/padlock.svg";
import loginBg from "../../../Assets/images/Login-bg.svg";
import { loginCustomerAction, setUser } from "../../../Action/authAction";
import awesomePhoneicon from "../assets/images/awesome-phone-alt.svg";
import {
  getAllProductCategoryItemOnCustomerUI,
  getQuickOrder,
} from "../../../Services/manageOrderServices";
import {
  getCustomizeModifierItemListByIdService,
  getCustomizeModifierItemListByIdServicePromised,
} from "../../../Services/managePropductCategoryServices";
import { createItemToCart } from "../../../Action/cartAction";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { Store } from "react-notifications-component";
export default function QuickOrder(props) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const { showModal, closeModal } = props;
  const [user, setuser] = useState({
    contactNumber: "",
  });
  const [errors, setErrors] = useState({
    contactNumber: false,
  });
  //isloading
  const [isloading, setisloading] = useState(false);
  //handle change of customer
  const handleChange = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: validatecontactNumber(e.target.value) ? false : true,
    });
    setuser({ ...user, [e.target.name]: normalizeInput(e.target.value) });
  };
  //close model and clear the state
  const closeModelClearAll = () => {
    closeModal();
  };
  const objectWithoutKey = (object, key) => {
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  };
  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatecontactNumber(user.contactNumber)) {
      setisloading(true);
      getQuickOrder({
        contactNumber: user.contactNumber.replace(/[()  -]/g, ""),
      })
        .then((res) => {
          //get variation items price data
          let tmpCart = res.data.result;
          let cart = [];
          getAllProductCategoryItemOnCustomerUI()
            .then(async (itemRes) => {
              let allItemPriceData = itemRes.data.result;
              for (let i = 0; i < tmpCart.length; i++) {
                let item = allItemPriceData.filter(
                  (e) => e.itemId == tmpCart[i].itemId && !e?.outOfStock
                  )?.[0];
                  if (item) {
                  let pushableItem = {
                    halfTopping: item.halfTopping,
                    uniqueId: Math.random().toString(36).slice(2),
                    itemId: tmpCart[i].itemId,
                    itemImage: item.itemImage,
                    itemName: tmpCart[i].itemName,
                    itemPrice: 0,
                    modifier: [],
                    quantity: tmpCart[i].quantity,
                    variationName: tmpCart[i].varaitionName,
                    variationId: tmpCart[i].variationId,
                  };

                  for (
                    let q = 0;
                    q < tmpCart[i].orderModifierDetail.length;
                    q++
                  ) {
                    pushableItem.modifier.push({
                      modifierCatgegoryId:
                        tmpCart[i].orderModifierDetail[q].modifierCatgegoryId,
                      modifierId: tmpCart[i].orderModifierDetail[q].modifierId,
                      modifierName:
                        tmpCart[i].orderModifierDetail[q].modifierName,
                      modifierPrice:
                        tmpCart[i].orderModifierDetail[q].modifierPrice,
                      topping:
                        tmpCart[i].orderModifierDetail[q].halfToppingOption ==
                          " "
                          ? ""
                          : tmpCart[i].orderModifierDetail[q].halfToppingOption,
                      isDefault: tmpCart[i].orderModifierDetail[q].isDefault,
                      isDeleted: tmpCart[i].orderModifierDetail[q].isDeleted,
                      layer:
                        tmpCart[i].orderModifierDetail[q]?.layer || "regular",
                    });
                  }
                
                  //item match now we will calculate price
                  //check if variation exists
                  let variationIndex = item.itemPrice.findIndex(
                    (e) =>
                      e.variationId === tmpCart[i].variationId && !e.outOfStock
                  );

                  if (variationIndex != -1) {
                    pushableItem.itemPrice =
                      item.itemPrice[variationIndex].variationPrice;
                    let modifierList =
                      await getCustomizeModifierItemListByIdServicePromised(
                        item.itemId
                      );
                    modifierList = modifierList.result?.[0]?.ModifierDetails;

                    if (modifierList) {
                      let deleteAbleIndexes = [];
                      for (let j = 0; j < pushableItem.modifier.length; j++) {
                        let ModifierVariationIndex = modifierList.findIndex(
                          (e) =>
                            e.modifiersId ==
                            pushableItem.modifier[j].modifierId &&
                            e.isDefault == pushableItem.modifier[j].isDefault
                        );
                        if (ModifierVariationIndex != -1) {
                          let ModifierVariationChildIndex = modifierList[
                            ModifierVariationIndex
                          ].modifierPriceData.findIndex(
                            (e) => e.varationsId == tmpCart[i].variationId
                          );
                          if (ModifierVariationChildIndex != -1) {
                            let modifierPrice =
                              modifierList[ModifierVariationIndex]
                                .modifierPriceData[ModifierVariationChildIndex]
                                .Price;
                                let isDefault =
                                modifierList[ModifierVariationIndex].isDefault;
                                
                            let layer =
                              modifierList[ModifierVariationIndex]
                                .modifierPriceData[ModifierVariationChildIndex]
                                .isDouble;
                            pushableItem.modifier[j].modifierPrice =
                              modifierPrice;
                            pushableItem.modifier[j].isDefault = isDefault;

                            // commented below code to avoid default price of modifier getting added in repeat order

                            // if (!isDefault) {
                            //   if (
                            //     pushableItem.modifier[j].topping == "r" ||
                            //     pushableItem.modifier[j].topping == "l"
                            //   ) {
                            //     if (
                            //       layer &&
                            //       pushableItem.modifier[j].layer == "extra"
                            //     ) {
                        
                            //       pushableItem.itemPrice += modifierPrice;
                            //     } else {
                            
                            //       pushableItem.itemPrice += modifierPrice / 2;
                            //       pushableItem.modifier[j].modifierPrice = modifierPrice / 2
                            //     }
                            //   } else {
                            //     if (
                            //       layer &&
                            //       pushableItem.modifier[j].layer == "extra"
                            //     ) {
                            //       pushableItem.itemPrice += modifierPrice * 2;
                            //       pushableItem.modifier[j].modifierPrice = modifierPrice * 2
                            //     } else {
                             
                            //       pushableItem.itemPrice += modifierPrice;
                            //     }
                            //   }
                            // } else {
                            //   if (
                            //     pushableItem.modifier[j].topping == "r" ||
                            //     pushableItem.modifier[j].topping == "l"
                            //   ) {
                            //     if (
                            //       layer &&
                            //       pushableItem.modifier[j].layer == "extra"
                            //     ) {
                            //       pushableItem.itemPrice += modifierPrice / 2;
                            //       pushableItem.modifier[j].modifierPrice = modifierPrice / 2
                            //     } else {
                            //       pushableItem.itemPrice += modifierPrice / 2;
                            //       pushableItem.modifier[j].modifierPrice = modifierPrice / 2
                            //     }
                            //   } else {
                            //     if (
                            //       layer &&
                            //       pushableItem.modifier[j].layer == "extra"
                            //     ) {
                            //       pushableItem.itemPrice += modifierPrice * 2;
                            //       pushableItem.modifier[j].modifierPrice = modifierPrice * 2
                            //     } else {
                            //       pushableItem.itemPrice += modifierPrice;
                            //     }
                            //   }
                            // }
                          }
                        } else {
                          //collecting modifiersId for to delete later once loop is complete
                          //we are using modifier id instead of indexes
                          deleteAbleIndexes.push(
                            pushableItem.modifier[j].modifierId
                          );
                        }
                      }
                      //delete the useless items
                      for (let q = 0; q < deleteAbleIndexes.length; q++) {
                        pushableItem.modifier.splice(
                          pushableItem.modifier.findIndex(
                            (e) => e.modifierId == deleteAbleIndexes[q]
                          ),
                          1
                        );
                      }
                    }
                    //add data to the cart item
                    cart.push(pushableItem);
                  } else {
                    Store.addNotification({
                      message: "Item Variation is out of stock",
                      type: "danger",
                      insert: "top",
                      container: "top-center",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 4000,
                        onScreen: true,
                      },
                    });
                  }
                } else {
                  Store.addNotification({
                    message: "This item is out of stock",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true,
                    },
                  });
                }
              }
              dispatch(createItemToCart([]));
              dispatch(createItemToCart(cart));
              closeModal();
              setisloading(false);
            })
            .catch((err) => {
              setisloading(false);
              Store.addNotification({
                message: "Somthing went wrong",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                },
              });
            });
        })
        .catch((err) => {
          setisloading(false);
          Store.addNotification({
            message: "Order not found",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
            },
          });
        });
    } else {
      setErrors({ contactNumber: true });
    }
  };
  const validatecontactNumber = (value, previousValue) => {
    value = value.replace(/[()  -]/g, "");
    if (value.length < 10) {
      return false;
    }
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return false;
      if (cvLength < 7) return false;
      let firstPhase = currentValue.slice(0, 3);
      let secondPhase = currentValue.slice(3, 6);
      let thiredPhase = currentValue.slice(6, 10);
      if (
        firstPhase == "000" ||
        (firstPhase == secondPhase &&
          secondPhase == thiredPhase.slice(0, -1)) ||
        secondPhase + thiredPhase == "0000000"
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  /**  this function will add mask on phone number input field */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      sizeModal={"50"}
      title=""
    >
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="loginform">
            <div className="loginlogo">
              <img
                src={o_original_logo}
                className="img-fluid"
                alt="o_original_logo"
              />
            </div>
            {isloading ? (
              <ComponentLoader />
            ) : (
              <>
                <div className="logintitle">
                  <h2>Quick Order</h2>
                  {/* <span>Please Login to Continue</span> */}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="form-group has-search">
                        <label>Phone Number </label>
                        <span className="form-control-feedback address-span">
                          <img
                            className="login-email"
                            src={awesomePhoneicon}
                            alt=""
                          />
                        </span>
                        <input
                          type="tel"
                          className={`form-control ${errors.contactNumber ? "invalid-field" : ""
                            }`}
                          placeholder="Enter your contact Number"
                          name="contactNumber"
                          value={user.contactNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 text-right">
                      <div className="regBtn">
                        <button className="btn">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </NormalModal>
  );
}