import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteHomeBanner,
  getHomeBanner,
  changeHomeBannerIndex,
} from "../../../Action/homeBannerActions";
import HomeBannerPopup from "./HomeBannerPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ConfirmModal from "../../../helpers/ConfirmModal";
import "./homeBanner.css";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js"

export default function HomeBanner() {
  const [showModal, setShowModal] = useState("no");
  const [indexing, setindex] = useState("");
  const homeBanner = useSelector((state) => state.homeBanner);
  const dispatch = useDispatch();
  const [data, setdata] = useState([
    {
      indexing: 1,
      homeBanner: "",
    },
    {
      indexing: 2,
      homeBanner: "",
    },
    {
      indexing: 3,
      homeBanner: "",
    },
    {
      indexing: 4,
      homeBanner: "",
    },
    {
      indexing: 5,
      homeBanner: "",
    },
  ]);
  const handleOpenClose = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
  };
  const [deleteItemConfirm, setDeleteItemConfirm] = useState({
    show: false,
    data: {},
  });
  //handle delete model success
  const handleSuccessAlert = () => {
    dispatch(deleteHomeBanner(deleteItemConfirm.data));
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  //handle delete close model
  const handleCloseAlert = () => {
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  //update
  const updateItem = (data, action) => {
    switch (action) {
      case "delete":
        setDeleteItemConfirm({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setindex(data);
        handleOpenClose();
        break;
      default:
        break;
    }
  };
  const handleDragEnd = (e) => {
    dispatch(
      changeHomeBannerIndex({
        sourceIndex: e.draggableId,
        targetIndex: e.destination.index,
      })
    );
  };
  useEffect(() => {
    dispatch(getHomeBanner());
  }, []);
  useEffect(() => {
    homeBanner.homeBanner.sort((a, b) => {
      if (a.indexing < b.indexing) {
        return -1;
      }
      if (a.indexing > b.indexing) {
        return 1;
      }
      return 0;
    });
    setdata(homeBanner.homeBanner);
  }, [homeBanner]);
  const columns = [
    {
      dataField: "indexing",
      text: "Index",
      sort: true,
    },
    {
      dataField: "bannerImage",
      text: "Driver Fee",
      formatter: (cell, row) => {
        return <img src={cell} className="homeBanner-img" />;
      },
      filterValue: (cell, row) => {
        return <img src={cell} className="homeBanner-img" />;
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button
                onClick={() => {
                  updateItem(row.indexing, "delete");
                }}
              >
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => updateItem(row.indexing, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Home Banner</h2>
                  <button className="btn custombtn" onClick={handleOpenClose}>
                    Add Home Banner
                  </button>
                </div>
                <div className="card-body actioncard">
                  {/* {homeBanner.isloading === false ? (
                    <BootstrapeTableData
                      data={data}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={homeBanner.isloading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )} */}
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <ul
                          className="list-group droppable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {data.map((item, index) => {
                            return (
                              <Draggable
                                key={item.indexing}
                                draggableId={item.indexing.toString()}
                                index={item.indexing}
                              >
                                {(provided) => (
                                  <li
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                    key={index}
                                    draggable={"true"}
                                    id={item.indexing}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <img
                                      className="homeBanner-img"
                                      src={item?.bannerImage === undefined ? item.bannerImage : changeImageUrl(item.bannerImage)}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                      }}
                                    />
                                    <ul className="actionListTable">
                                      <li>{item.indexing}</li>
                                      <li>
                                        <Button
                                          onClick={() => {
                                            updateItem(item.indexing, "delete");
                                          }}
                                        >
                                          <FaRegTrashAlt />
                                        </Button>
                                      </li>
                                      <li>
                                        <Button
                                          onClick={() =>
                                            updateItem(item.indexing, "edit")
                                          }
                                        >
                                          <FaRegEdit />
                                        </Button>
                                      </li>
                                    </ul>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal === "yes" && (
        <HomeBannerPopup
          showModal={showModal}
          closeModal={handleOpenClose}
          indexing={indexing}
        />
      )}
      {deleteItemConfirm && (
        <ConfirmModal
          show={deleteItemConfirm.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
}
