import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { getMenuItems, deleteMenuItems } from "../../Action/menuItemsAction";
import AddModalRightAnimation from "./AddModal";
import { FaRegTrashAlt, FaRegEdit, FaRegImage } from "react-icons/fa";
import { Image } from "react-bootstrap";
import {
  getModifierTypeManagerModifierList,
  getVariationsTypeManagerModifierList,
} from "../../Action/manageModifierAction";
import { getIndividualProductCategoryList } from "../../Action/manageProductCategoryAction";
import AddVirationModal from "./AddVirationModal";
import ConfirmModal from "../../helpers/ConfirmModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";

const ManageMenu = (props) => {
  const dispatch = useDispatch();
  const dataResponse = useSelector((state) => state.manageMenu);
  const dataResponseModifier = useSelector((state) => state.manageModifier);
  const dataRespCategory = useSelector((state) => state.productCat);
  const [litsData, setListData] = useState([]);
  const [animationModal, setAnimationModal] = useState("no");
  const [addViration, setAddViration] = useState("no");
  const [editDataVariation, setEditDataVariation] = useState({
    edit: false,
    data: {},
  });
  // const [isLoding, setIsLoding] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    data: {},
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });

  const columns = [
    {
      dataField: "CategoryName",
      text: "Category Name",
      sort: true,
    },
    {
      dataField: "itemName",
      text: "Item Name",
    },
    {
      dataField: "itemDescription",
      text: "Description",
    },
    {
      dataField: "ModifierDetails",
      text: "Modifiers",
      formatter: (cell) => {
        return (
          <ul className="modifierList">
            {cell.map((item) => (
              <li key={item._id}>{item.ModifierName}</li>
            ))}
          </ul>
        );
      },
    },
    {
      dataField: "VariationDetails",
      text: "Variation/Price ($)",
      formatter: (cell) => {
        return (
          <ul className="modifierList">
            {Object.keys(cell).length > 0 ? (
              cell.map((item) => (
                <li key={item.variationId}>
                  {`${item.variationName.charAt(0).toUpperCase()}-${
                    item.variationPrice
                  }`}
                </li>
              ))
            ) : (
              <li>{"No Variation/Price"}</li>
            )}
          </ul>
        );
      },
    },
    {
      dataField: "itemImage",
      text: "Image",
      formatter: (cell) =>
        cell !== undefined ? (
          <Image className="imageGrid" src={cell} alt={cell} />
        ) : (
          <FaRegImage />
        ),
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable variation_btn">
            <li>
              <Button onClick={() => manageMenuTableAction(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => manageMenuTableAction(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
            <li className="btn_price">
              <Button
                onClick={() => manageMenuTableAction(row, "addVariation")}
              >
                Price/Variation
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];

  // mount
  useEffect(() => {
    dispatch(getModifierTypeManagerModifierList());
    dispatch(getVariationsTypeManagerModifierList());
    dispatch(getIndividualProductCategoryList());
    dispatch(getMenuItems());
  }, [dispatch]);
  // this will save listing state
  useEffect(() => {
    const { manageMenuData } = dataResponse;
    if (manageMenuData) {
      setListData(
        manageMenuData.reverse().filter((v) => v.menuitems.isActive === true)
      );
    }
  }, [dataResponse]);
  //add new row in  the row
  const addNewMenu = () => {
    setAnimationModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (animationModal === "yes" && editData.edit === true) {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  //handle various action from table
  const manageMenuTableAction = (data, action) => {
    switch (action) {
      case "addVariation":
        addVariationsMemorizedModal();
        setEditDataVariation({
          edit: Object.keys(data.VariationDetails).length > 0,
          data: data,
        });
        break;
      case "delete":
        setConfirmModal({
          show: true,
          data: data,
        });
        break;

      case "edit":
        const dataEdit = litsData.filter((item) => item._id === data._id);
        setEditData({
          edit: true,
          data: dataEdit[0],
        });
        addNewMenu();
        break;

      default:
        break;
    }
  };
  //open add variation model
  const addVariationsMemorizedModal = useCallback(() => {
    addVirationModal();
    if (
      addViration === "yes" &&
      Object.keys(editDataVariation.data).length > 0
    ) {
      setEditDataVariation({
        edit: false,
        data: {},
      });
    }
  }, [editDataVariation, addViration]);
  //open add variation model
  const addVirationModal = () => {
    setAddViration((prevState) => (prevState === "yes" ? "no" : "yes"));
  };
  //handle rejection from delete model
  const handleCloseAlert = () => {
    setConfirmModal((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };
  //handle success from delete model
  const handleSuccessAlert = () => {
    dispatch(deleteMenuItems({ itemId: confirmModal.data.itemId }));
    setConfirmModal({
      show: false,
      data: {},
    });
  };
  //mount the listdata module
  const listDataOfMenu = litsData.map((item, index) => ({
    CategoryName: item.productcategory.CategoryName,
    itemName: item.menuitems.itemName,
    itemDescription: item.menuitems.itemDescription,
    itemImage: item.menuitems.itemImage,
    ModifierDetails: item.ModifierDetails,
    VariationDetails: item.VariationDetails,
    itemId: item.menuitems.itemId,
    _id: item._id,
  }));

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Menu Items</h2>
        </div>

        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Menu Item List</h2>
                  <button className="btn custombtn" onClick={addNewMenu}>
                    Add Menu Items
                  </button>
                </div>
                <div className="card-body">
                  <BootstrapeTableData
                    data={listDataOfMenu}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {animationModal && (
        <AddModalRightAnimation
          show={animationModal}
          animationModal={animationModal}
          closeNewMenu={addNewMenu}
          modifierTypeList={dataResponseModifier.modifierTypeList}
          individualCategoryList={dataRespCategory.individualCategoryList}
          editData={editData}
        />
      )}
      {addViration && (
        <AddVirationModal
          showModal={addViration}
          closeModal={addVariationsMemorizedModal}
          variationsTypeList={dataResponseModifier.variationsTypeList}
          editDataVariation={editDataVariation}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          show={confirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageMenu;
