import React from "react";
import HeaderComponent from "../Header/Header";
import SidebarComponent from "../Header/Sidebar";

const AdminContainer = ({ children }) => {
  return (
    <>
      <div className="wraper">
        <div className="mainsection">
          {/* sidebaar */}
          <SidebarComponent />
          {/* Header Top */}
          <HeaderComponent />
          {/*  Main Content Wrapper */}
          <div className="right-panel" id="rightContentPanel">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminContainer;
