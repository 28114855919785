import * as api from "./api";

export const getCustomerWallet = function () {
  return api.get("wallet");
};

export const getCustomerWalletById = function (id) {
  return api.get("wallet/" + id);
};

export const createWallet = function (id) {
  return api.post("wallet/createWallet", { _id: id });
};

export const addCredit = function (data) {
  return api.post("wallet/addCredit", data);
};

export const refundOrder = function (data) {
  return api.post("wallet/refundOrder", data);
};

export const editAmount = function (data) {
  return api.post("wallet/editAmount", data);
};
