import React, { useState, useEffect } from "react";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { getSalesReportByServiceType } from "../../Services/reportService";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formettedDate from "../../helpers/moomentDate";
import moment from "moment";
export default function SalesByServiceType() {
  const [serviceType, setserviceType] = useState(["delivery", "pickup", "all"]);
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);


  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const handleToggleCheckBox = (checked) => {
    setloading(true);
    if (!checked) {
      setfilter({});
      setfilter({
        ...filter,
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY"),
      });
      getSalesReportByServiceType({
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY"),
        OrderServiceOption: filter.OrderServiceOption,
        collectionName: "AllOrders",
      }).then((result) => {
        setloading(false);
        setlitsData(result.result.reverse());
        calculateSummary(result.result);
        setshowResetButton(false);
        setisTodayCheckBox(false);
      });
    } else {
      setfilter({
        ...filter,
        from: new Date(),
        to: new Date(),
      });
      getSalesReportByServiceType({
        from: new Date(),
        to: new Date(),
        OrderServiceOption: filter.OrderServiceOption,
        collectionName: "order",
      }).then((result) => {
        setloading(false);
        setlitsData(result.result.reverse());
        calculateSummary(result.result);
        setshowResetButton(false);
        setisTodayCheckBox(true);
      });
    }
  };

  const [summary, setsummary] = useState({
    totalAmount: 0,
    totalDeliveryCharges: 0,
    totalTaxAmount: 0,
    totalDiscountAmount: 0,
    totalCouponAmount: 0,
    totalDriverReimbursement: 0,
    totalGratuity: 0,
    totalGrossSales: 0,
    totalNetSales: 0,
    totalSales: 0,
    totalDepositAmount: 0,
    totalPaidFromWallet: 0,
    totalPaidFromCard: 0,
  });
  const [litsData, setlitsData] = useState([]);
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  //three month old date
  var threeMonthOldDate = new Date();
  threeMonthOldDate.setMonth(threeMonthOldDate.getMonth() - 2);
  threeMonthOldDate = `${threeMonthOldDate.getFullYear()}-${threeMonthOldDate.getMonth() < 10
    ? "0" + threeMonthOldDate.getMonth()
    : threeMonthOldDate.getMonth()
    }-${threeMonthOldDate.getDate() < 10
      ? "0" + threeMonthOldDate.getDate()
      : threeMonthOldDate.getDate()
    }`;
  //today date
  var todayDate = new Date().toISOString().substr(0, 10);
  //one month old date
  var oneMonthOldDate = new Date();
  oneMonthOldDate.setMonth(oneMonthOldDate.getMonth());
  oneMonthOldDate = `${oneMonthOldDate.getFullYear()}-${oneMonthOldDate.getMonth() < 10
    ? "0" + oneMonthOldDate.getMonth()
    : oneMonthOldDate.getMonth()
    }-${oneMonthOldDate.getDate() < 10
      ? "0" + oneMonthOldDate.getDate()
      : oneMonthOldDate.getDate()
    }`;
  const [filter, setfilter] = useState({
    from: new Date(),
    to: new Date(),
    OrderServiceOption: "all",
  });
  const [filterError, setfilterError] = useState({
    from: false,
    to: false,
    OrderServiceOption: false,
  });
  const [showResetButton, setshowResetButton] = useState(false);
  const [loading, setloading] = useState(false);
  //handle the change in filter form
  const handleFilterChange = (type, value) => {
    setfilter({ ...filter, [type]: value });
    let tmpError = { ...filterError };
    if (type === "from") {
      let fDate = moment(value).format("YYYY-MM-DD");
      let sDate = moment(filter["to"]).format("YYYY-MM-DD");
      if (moment(fDate).isAfter(sDate, "day")) {
        tmpError[type] = true;
        tmpError["to"] = true;
      } else {
        tmpError[type] = false;
        tmpError["to"] = false;
      }
    } else if (type === "to") {
      let fDate = moment(filter["from"]).format("YYYY-MM-DD");
      let sDate = moment(value).format("YYYY-MM-DD");
      if (moment(fDate).isAfter(sDate, "day")) {
        tmpError[type] = true;
        tmpError["from"] = true;
      } else {
        tmpError[type] = false;
        tmpError["from"] = false;
      }
    }
    setfilterError(tmpError);
  };
  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let tmpError = { ...filterError };
    let isValidate = true;
    Object.keys(tmpError).map((key) => {
      if (key === "from") {
        //check if null
        //check  min or max
        let fDate = moment(filter[key]).format("YYYY-MM-DD");
        let sDate = moment(filter["to"]).format("YYYY-MM-DD");
        if (moment(fDate).isAfter(sDate, "day")) {
          tmpError[key] = true;
          tmpError["to"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["to"] = false;
        }
      } else if (key === "to") {
        //check if min or max
        let fDate = moment(filter["from"]).format("YYYY-MM-DD");
        let sDate = moment(filter["to"]).format("YYYY-MM-DD");
        if (moment(fDate).isAfter(sDate, "day")) {
          tmpError[key] = true;
          tmpError["from"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["from"] = false;
        }
      }
    });
    setfilterError(tmpError);
    if (isValidate) {
      setloading(true);
      if (isTodayCheckBox) {
        getSalesReportByServiceType({
          ...filter,
          collectionName: "order",
        }).then((result) => {
          setloading(false);
          setlitsData(result.result.reverse());
          calculateSummary(result.result);
          setshowResetButton(true);
        });
      } else {
        getSalesReportByServiceType({
          ...filter,
          collectionName: "AllOrders",
        }).then((result) => {
          setloading(false);
          setlitsData(result.result.reverse());
          calculateSummary(result.result);
          setshowResetButton(true);
        });
      }
    }
  };

  const filterFinalData = () => {
    if (!isTodayCheckBox) {
      const data = ({
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY"),
        OrderServiceOption: "all"
      })
      setfilter(data)
      return data;

    } else {
      const result = {
        from: new Date(),
        to: new Date(),
        OrderServiceOption: "all"
      };
      setfilter(result)
      return result;
    }
  }

  const handleInt = (e) => Number(e) || 0;

  //reset the filter
  const resetFilter = (e) => {
    setloading(true)
    e.preventDefault();
    let data = filterFinalData()
    const col = !isTodayCheckBox ? "allOrders" : "order";
    getSalesReportByServiceType({ ...data, collectionName: col }).then((result) => {
      setloading(false);
      setfilter(data)
      setlitsData(result.result.reverse());
      calculateSummary(result.result);
      setshowResetButton(false);
    });

    // getSalesReportByServiceType({ ...data, collection: col }).then((result) => {
    //   setlitsData(result.result);
    //   console.log(result.result,"result.result");
    //   calculateSummary(result.result);
    //   setshowResetButton(false);
    // });
  };

  const handleDownload = (type) => {
    if (!litsData.length < 1) {
      if (isTodayCheckBox) {
        getSalesReportByServiceType({ ...filter }, type, "order").then(
          (result) => { }
        );
      } else {
        getSalesReportByServiceType({ ...filter }, type, "AllOrders").then(
          (result) => { }
        );
      }
    }
  };
  const columns = [
    {
      dataField: "randomOrderId",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Order Date",
      formatter: (cell, row) => {
        return formettedDate(row?.payments[0]?.createdAt);
      },
      filterValue: (cell, row) => {
        return formettedDate(row?.payments[0]?.createdAt);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Order Service Type",
      formatter: (cell, row) => {
        return row?.OrderServiceOption;
      },
      filterValue: (cell, row) => {
        return row?.OrderServiceOption;
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Sub Total",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.totalAmount));
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(handleInt(row?.totalGrossAmount));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Discount Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.discountAmount));
      },
      filterValue: (cell, row) => {
        return priceMature(handleInt(row?.discountAmount));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Coupon Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.couponAmount));
      },
      filterValue: (cell, row) => {
        return priceMature(handleInt(row?.couponAmount));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Tax Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.taxAmount));
      },
      filterValue: (cell, row) => {
        return priceMature(handleInt(row?.taxAmount));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Gratuity Amount",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.gratuityAmount));
      },
      filterValue: (cell, row) => {
        return priceMature(handleInt(row?.gratuityAmount));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Delivery Fee",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.deliveryCharge));
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(handleInt(row?.deliveryCharge));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Driver Reimbursement",
      formatter: (cell, row) => {
        return "$" + priceMature(handleInt(row?.driverReimbursement));
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(handleInt(row?.driverReimbursement));
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Gross Sales",
      formatter: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) + handleInt(row?.deliveryCharge)
          )
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) + handleInt(row?.deliveryCharge)
          )
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Net Sales",
      formatter: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) +
            handleInt(row?.deliveryCharge) -
            handleInt(row?.discountAmount) -
            handleInt(row?.couponAmount) -
            handleInt(row?.driverReimbursement)
          )
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) +
            handleInt(row?.deliveryCharge) -
            handleInt(row?.discountAmount) -
            handleInt(row?.couponAmount) -
            handleInt(row?.driverReimbursement) -
            handleInt(row?.payments?.[0]?.totalPaidFromWallet)
          )
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Total Sales",
      formatter: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) +
            handleInt(row?.deliveryCharge) +
            handleInt(row?.taxAmount) -
            handleInt(row?.discountAmount) -
            handleInt(row?.couponAmount) -
            handleInt(row?.driverReimbursement)
          )
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" +
          priceMature(
            handleInt(row?.totalAmount) +
            handleInt(row?.deliveryCharge) +
            handleInt(row?.taxAmount) -
            handleInt(row?.discountAmount) -
            handleInt(row?.couponAmount) -
            handleInt(row?.driverReimbursement)
          )
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Type",
      formatter: (cell, row) => {
        return row?.payments?.[0]?.paymentType || "card";
      },
      filterValue: (cell, row) => {
        return row?.payments?.[0]?.paymentType || "card";
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Paid From Wallet",
      formatter: (cell, row) => {
        return (
          "$" + priceMature(handleInt(row?.payments?.[0]?.totalPaidFromWallet))
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" + priceMature(handleInt(row?.payments?.[0]?.totalPaidFromWallet))
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Paid From Card",
      formatter: (cell, row) => {
        return (
          "$" + priceMature(handleInt(row?.payments?.[0]?.totalPaidFromCard))
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" + priceMature(handleInt(row?.payments?.[0]?.totalPaidFromCard))
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Deposit Amount",
      formatter: (cell, row) => {
        return (
          "$" +
          (row?.payments?.[0]?.paidWholeFromWallet
            ? "0.00"
            : priceMature(
              handleInt(row?.totalAmount) +
              handleInt(row?.deliveryCharge) +
              handleInt(row?.taxAmount) -
              handleInt(row?.discountAmount) -
              handleInt(row?.couponAmount) +
              //handleInt(row?.driverReimbursement) -
              handleInt(row?.gratuityAmount) -
              handleInt(row?.payments?.[0]?.totalPaidFromWallet)
            ))
        );
      },
      filterValue: (cell, row) => {
        return (
          "$" +
          (row?.payments?.[0]?.paidWholeFromWallet
            ? "0.00"
            : priceMature(
              handleInt(row?.totalAmount) +
              handleInt(row?.deliveryCharge) +
              handleInt(row?.taxAmount) -
              handleInt(row?.discountAmount) -
              handleInt(row?.couponAmount) +
              //handleInt(row?.driverReimbursement) -
              handleInt(row?.gratuityAmount) -
              handleInt(row?.payments?.[0]?.totalPaidFromWallet)
            ))
        );
      },
      sort: true,
    },
  ];
  const summaryColoumn = [
    "Total Subtotal",
    "Total Discount",
    "Total Coupon Amount",
    "Total Tax",
    "Total Gratuity",
    "Total Delivery Fee",
    "Total Driver Reimbursement",
    "Total Gross Sales",
    "Total Net Sales",
    "Total Sales",
    "Total Paid From Wallet",
    "Total Paid From Card",
    "Total Deposit Amount",
  ];

  const calculateSummary = (result) => {
    let totalAmount = 0;
    let totalDeliveryCharges = 0;
    let totalTaxAmount = 0;
    let totalDiscountAmount = 0;
    let totalCouponAmount = 0;
    let totalDriverReimbursement = 0;
    let totalGratuity = 0;
    let totalGrossSales = 0;
    let totalNetSales = 0;
    let totalSales = 0;
    let totalDepositAmount = 0;
    let totalPaidFromWallet = 0;
    let totalPaidFromCard = 0;
    result.forEach((item, index) => {
      totalAmount = totalAmount + handleInt(item.totalAmount);
      totalDiscountAmount =
        totalDiscountAmount + handleInt(item.discountAmount);
      totalCouponAmount = totalCouponAmount + handleInt(item.couponAmount);
      totalDeliveryCharges =
        totalDeliveryCharges + handleInt(item.deliveryCharge);
      totalTaxAmount = totalTaxAmount + handleInt(item.taxAmount);
      totalDriverReimbursement =
        totalDriverReimbursement + handleInt(item.driverReimbursement);
      totalGratuity = totalGratuity + handleInt(item.gratuityAmount);
      totalGrossSales =
        totalGrossSales +
        handleInt(item.totalAmount) +
        handleInt(item.deliveryCharge);

      totalNetSales =
        totalNetSales +
        handleInt(item.totalAmount) +
        handleInt(item.deliveryCharge) -
        handleInt(item.discountAmount) -
        handleInt(item.couponAmount) -
        handleInt(item.driverReimbursement);

      totalSales =
        totalSales +
        item.totalAmount +
        handleInt(item.deliveryCharge) +
        handleInt(item.taxAmount) -
        handleInt(item.discountAmount) -
        handleInt(item.couponAmount) -
        handleInt(item.driverReimbursement);

      totalPaidFromWallet += handleInt(item.payments?.[0]?.totalPaidFromWallet);

      totalPaidFromCard += handleInt(item.payments?.[0]?.totalPaidFromCard);

      totalDepositAmount =
        totalDepositAmount +
        (item.payments?.[0]?.paidWholeFromWallet
          ? 0
          : handleInt(item.totalAmount) +
          handleInt(item.deliveryCharge) +
          handleInt(item.taxAmount) -
          handleInt(item.discountAmount) -
          handleInt(item.couponAmount) +
          // handleInt(item.driverReimbursement) -
          handleInt(item.gratuityAmount) -
          handleInt(item.payments?.[0]?.totalPaidFromWallet));
    });
    setsummary({
      ...summary,
      ["totalAmount"]: totalAmount,
      ["totalDeliveryCharges"]: totalDeliveryCharges,
      ["totalTaxAmount"]: totalTaxAmount,
      ["totalDiscountAmount"]: totalDiscountAmount,
      ["totalCouponAmount"]: totalCouponAmount,
      ["totalDriverReimbursement"]: totalDriverReimbursement,
      ["totalGratuity"]: totalGratuity,
      ["totalGrossSales"]: totalGrossSales,
      ["totalNetSales"]: totalNetSales,
      ["totalSales"]: totalSales,
      ["totalDepositAmount"]: totalDepositAmount,
      ["totalPaidFromWallet"]: totalPaidFromWallet,
      ["totalPaidFromCard"]: totalPaidFromCard,
    });
  };

  // this effect will fetch all orders of customer
  useEffect(() => {
    setloading(true);
    getSalesReportByServiceType({ ...filter, collectionName: "order" }).then((result) => {
      setloading(false);
      setlitsData(result.result.reverse());
      calculateSummary(result.result);
      setshowResetButton(false);
    });
  }, []);

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Sales by service type</h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                  <h2>Sales by service type</h2>
                  <div>
                    <button
                      className="btn custombtn mr-2"
                      onClick={() => {
                        handleDownload("pdf");
                      }}
                    >
                      <FaRegFilePdf />
                    </button>
                    <button
                      className="btn custombtn"
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      <FaRegFileExcel />
                    </button>
                  </div>
                </div>
                <div className="card-body actioncard">
                  <div className="row">
                    <div className="col-sm-12">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="col-sm-3">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">From</label>
                              {isTodayCheckBox ? (
                                <DatePicker
                                  className={`form-control ${filterError.from ? "invalid-field" : ""
                                    }`}
                                  name="from"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date()}
                                  readOnly
                                />
                              ) : (
                                <DatePicker
                                  className={`form-control ${filterError.from ? "invalid-field" : ""
                                    }`}
                                  name="from"
                                  dateFormat="MM/dd/yyyy"
                                  selected={moment(filter.from).toDate()}
                                  minDate={moment().subtract("3", "M").toDate()}
                                  maxDate={moment().subtract("1", "d").toDate()}
                                  onChange={(value) => {
                                    handleFilterChange("from", value);
                                  }}
                                />
                              )}
                              {filterError.from ? (
                                <div className="invalid-feedback">
                                  from date should be lower than to date
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group d-flex align-items-center">
                              <label className="mr-2 mb-0">To</label>
                              {isTodayCheckBox ? (
                                <DatePicker
                                  className={`form-control ${filterError.to ? "invalid-field" : ""
                                    }`}
                                  name="to"
                                  dateFormat="MM/dd/yyyy"
                                  selected={new Date()}
                                  readOnly
                                />
                              ) : (
                                <DatePicker
                                  className={`form-control ${filterError.to ? "invalid-field" : ""
                                    }`}
                                  name="to"
                                  dateFormat="MM/dd/yyyy"
                                  selected={moment(filter.to).toDate()}
                                  minDate={moment().subtract("3", "M").toDate()}
                                  maxDate={moment().subtract("1", "d").toDate()}
                                  onChange={(value) => {
                                    handleFilterChange("to", value);
                                  }}
                                />
                              )}
                              {filterError.to ? (
                                <div className="invalid-feedback">
                                  to date should be Greater than from date
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <select
                              name={"OrderServiceOption"}
                              className="form-control"
                              onChange={(e) => {
                                handleFilterChange(
                                  "OrderServiceOption",
                                  e.target?.value
                                );
                              }}
                              defaultValue={filter.OrderServiceOption}
                              value={filter.OrderServiceOption}
                            >
                              <option value={""}>Select Service Type</option>
                              {serviceType.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {capitalizeFirstLetter(item)}
                                  </option>
                                );
                              })}
                            </select>
                            {filterError.OrderServiceOption ? (
                              <div className="invalid-feedback">
                                this is required
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-3 searhorder-btn">
                            <div className="regBtn">
                              <button className="btn mr-2" disabled={false}>
                                Search
                              </button>

                              {showResetButton ? (
                                <button className="btn" onClick={resetFilter}>
                                  clear
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="text-right mb-3">
                    <div className="today_order-wrapper">
                      <input
                        type="checkbox"
                        defaultChecked={isTodayCheckBox}
                        onClick={(e) => handleToggleCheckBox(e.target.checked)}
                      />
                      <b className="ml-1">Today's Orders</b>{" "}
                    </div>
                  </div>
                  <BootstrapeTableData
                    data={litsData}
                    columns={columns}
                    keyfield={"_id"}
                    searchBar={true}
                    isLoading={loading}
                  />
                  {litsData.length > 0 ? (
                    <div className=" tablecstmresponsive">
                      <div className="react-bootstrap-table">
                        <table className="table table-bordered tableLayoutUN">
                          <thead className={"font-weight-bold"}>
                            {summaryColoumn.map((value, index) => {
                              return <td key={index}> {value}</td>;
                            })}
                          </thead>
                          <tbody>
                            <tr>
                              <td key={1}>
                                ${priceMature(summary.totalAmount)}
                              </td>
                              <td key={3}>
                                ${priceMature(summary.totalDiscountAmount)}
                              </td>
                              <td key={3}>
                                ${priceMature(summary.totalCouponAmount)}
                              </td>
                              <td key={2}>
                                ${priceMature(summary.totalTaxAmount)}
                              </td>
                              <td key={4}>
                                ${priceMature(summary.totalGratuity)}
                              </td>
                              <td key={4}>
                                ${priceMature(summary.totalDeliveryCharges)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary.totalDriverReimbursement)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary.totalGrossSales)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary.totalNetSales)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary.totalSales)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary?.totalPaidFromWallet)}
                              </td>
                              <td key={5}>
                                ${priceMature(summary.totalPaidFromCard)}
                              </td>

                              <td key={5}>
                                ${priceMature(summary.totalDepositAmount)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
