import { useEffect, useState, cloneElement } from "react";
import { SOCKET_URL } from "../Utils/config";
import io from "socket.io-client";

export default function SocketHandler(props) {
  const [socketObj, setSocketObj] = useState(null);
  useEffect(() => {
    const socket = io(SOCKET_URL);
    socket.emit("join", {
      locationId: props?.locationId || localStorage.getItem("locationId"),
    });
    setSocketObj(socket);
  }, []);
  return cloneElement(props.children, { socket: socketObj });
}
