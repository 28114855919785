import * as types from './types';

/***
 * this action will get master modifier 
 */
export const getMasterModifierListAction = () => ({
    type:types.GET_MASTER_MODIFIER_LIST
})

/***
 * this action will delete master modifier 
 */
export const deleteMasterModifierListItemAction = (itemId) => ({
    type:types.DELETE_MASTER_MODIFIER_LIST_ITEM,
    payload:itemId
})


/***
 * this action will add master modifier 
 */
export const createMasterModifierListItemAction = (data) => ({
    type:types.CREATE_MASTER_MODIFIER_LIST_ITEM,
    payload:data
})

/***
 * this action will update master modifier 
 */
export const updateMasterModifierListItemAction = (data) => ({
    type:types.UPDATE_MASTER_MODIFIER_LIST_ITEM,
    payload:data
})