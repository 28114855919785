import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch } from "react-redux";
import { ComponentLoader } from "../../helpers/PageLoader";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { Col, Row } from "react-bootstrap";
import formettedDate from "../../helpers/moomentDate";
import { FaTrashAlt, FaPencilAlt, FaRegEdit } from "react-icons/fa";
import {
  getCustomerWalletById,
  createWallet,
  addCredit,
  editAmount,
} from "../../Services/walletServices";
const ManageWalletPopup = (props) => {
  const dispatch = useDispatch();
  const { showModal, closeModal, data, permission } = props;
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  const [wallet, setwallet] = useState(null);
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const [amount, setamount] = useState(0);
  const [user, setuser] = useState({});
  const [isEditingAmount, setisEditingAmount] = useState(false);
  const [editamount, seteditamount] = useState("");
  const [editamounterror, seteditamounterror] = useState(false);
  const [editamountrunning, seteditamountrunning] = useState(false);
  const [isrunning, setisrunning] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount < 1) {
      seterror({ ...error, amount: true });
    } else {
      setisrunning(true);
      addCredit({
        walletId: wallet._id,
        amount,
      }).then(() => {
        setisrunning(false);
        setamount(0);
        seterror({ ...error });
        getWallet();
      });
    }
  };
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const handleChangeAmount = (e) => {
    setamount(e.target.value);
    seterror({ ...error, amount: e.target.value < 1 ? true : false });
  };
  const handleCreateWallet = () => {
    setloading(true);
    createWallet(data._id)
      .then((res) => {
        if (res.result) {
          setwallet({
            ...res.result,
            history: [...res.result.history].reverse(),
          });
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };
  const getWallet = (id) => {
    setloading(true);
    getCustomerWalletById(data._id).then((res) => {
      if (res.result) {
        setwallet({
          ...res.result,
          history: [...res.result.history].reverse(),
        });
      }
      setloading(false);
    });
  };
  useEffect(() => {
    if (data) {
      getWallet();
      setuser(data);
    }
  }, [data]);
  const handleSubmitEditAmount = (e) => {
    e.preventDefault();
    seteditamountrunning(true);
    editAmount({ walletId: wallet._id, amount: editamount })
      .then((res) => {
        getWallet();
        seteditamountrunning(false);
        toggleSetEditAmount();
      })
      .catch((err) => {
        seteditamountrunning(true);
      });
  };
  const toggleSetEditAmount = (e) => {
    if (!isEditingAmount) {
      seteditamount(Number(wallet.amount));
    }
    setisEditingAmount(!isEditingAmount);
  };
  const handleChangeEditAmount = (e) => {
    seteditamount(e.target.value);
    seteditamounterror(e.target.value < 1 ? true : false);
  };
  const columns = [
    {
      dataField: "order",
      text: "Order",
      formatter: (cell, row) => {
        return cell?.randomOrderId;
      },
      filterValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sortValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sort: true,
    },
    {
      dataField: "user",
      text: "Added By",
      formatter: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      filterValue: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      sortValue: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      sort: true,
    },
    {
      dataField: "transactionAmount",
      text: "Transaction Amount",
      formatter: (cell, row) => {
        return row.transactionType == "credited" ? (
          <p className="green"> {"+$" + priceMature(cell)}</p>
        ) : (
          <p className="red">{"-$" + priceMature(cell)}</p>
        );
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sortValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sort: true,
    },

    {
      dataField: "transactionType",
      text: "Transaction Type",
      sort: true,
    },
    {
      dataField: "transactionDate",
      text: "Transaction Date",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
  ];
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={user.fname + "`s Wallet"}
        >
          <div>
            {!loading ? (
              wallet ? (
                <>
                  <div className="dashbaord-card">
                    <Row>
                      <Col md={12} key={2}>
                        <div className="text-center">
                          <div className="d-flex justify-content-between m-3 flex-wrpmobile">
                            {isEditingAmount ? (
                              <form onSubmit={handleSubmitEditAmount}>
                                <div className="input-group mb-3">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      editamounterror ? "r-border" : ""
                                    }`}
                                    placeholder="$0.00"
                                    min="0"
                                    max={1000}
                                    step=".1"
                                    value={editamount}
                                    onChange={handleChangeEditAmount}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-secondary"
                                      type="submit"
                                      disabled={editamountrunning}
                                    >
                                      {editamountrunning ? "Saving..." : "Save"}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            ) : (
                              <div className="input-group">
                                <h2>${priceMature(wallet.amount)}</h2>
                                {wallet?.amount > 0 ? (
                                  <div className="input-group-append">
                                    <FaPencilAlt
                                      onClick={toggleSetEditAmount}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                            <span className="w-100">
                              {permission.update ? (
                                <form onSubmit={handleSubmit}>
                                  <div className="input-group flex-nowrap justify-content-end mb-3">
                                    <input
                                      type="number"
                                      className={`form-control max-w80 ${
                                        error.amount ? "r-border" : ""
                                      }`}
                                      placeholder="$0.00"
                                      min="0"
                                      max={1000}
                                      step=".1"
                                      onChange={handleChangeAmount}
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-secondary"
                                        type="submit"
                                        disabled={isrunning}
                                      >
                                        {isrunning ? "Adding..." : "Add Amount"}
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card customCard">
                          <div className="card-title titlewithbtn pb-2">
                            <h2>Recent Transactions</h2>
                          </div>
                          <div className="card-body">
                            <div className="m-10 modalordertablecustom">
                              <BootstrapeTableData
                                data={wallet.history}
                                columns={columns}
                                keyfield={"_id"}
                                searchBar={true}
                                isLoading={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p>{user.fname + " " + user.lname} doesn't have any wallet</p>
                  {permission.add ? (
                    <div className="checoutbtn">
                      <button className="btn" onClick={handleCreateWallet}>
                        Create new wallet
                      </button>
                    </div>
                  ) : (
                    <>Permission Denied</>
                  )}
                </div>
              )
            ) : (
              <ComponentLoader />
            )}
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageWalletPopup);
