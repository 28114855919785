import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, Col } from "react-bootstrap";
import validation from "../../helpers/validation";
import { useDispatch } from "react-redux";
import {
  updateVariation,
  addVariation,
  deleteVariation,
} from "../../Action/manageVariationAction";

function AddVariationModal(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data },
  } = props;
  const [variation, setVariation] = useState([
    {
      variationName: "",
      variationDescription: "",
    },
  ]);

  const [errors, setErrors] = useState([]);
  //mount
  useEffect(() => {
    if (edit) {
      setVariation((prevState) =>
        prevState.map((item) => ({
          ...item,
          variationName: data.variationName ?? "",
          variationDescription: data.variationDescription ?? "",
        }))
      );
    }
  }, [edit, data]);
  //close the model and clear state
  const closeModaleWithClearAll = () => {
    setVariation([
      {
        variationName: "",
        variationDescription: "",
      },
    ]);
    closeModal();
    setErrors([]);
  };
  //handle change
  const handleChangeVariation = (e, index) => {
    setVariation((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  //add new row in form
  const addNewitemRow = () => {
    const addNewValue = [...variation];
    addNewValue.push({
      variationName: "",
      variationDescription: "",
    });
    setVariation(addNewValue);
  };
  //delete row from item
  const deleteItemrow = (index) => {
    const deletevalue = [...variation];
    const deleteErrors = [...errors];
    deletevalue.splice(index, 1);
    deleteErrors.splice(index, 1);
    setVariation(deletevalue);
    setErrors(deleteErrors);
  };
  //handle the submit
  const handleSubmit = (e, action) => {
    e.preventDefault();
    const isValid = validation(variation);
    console.log(isValid, "------ Found isValid");
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      for (let i = 0; i < variation.length; i++) {
        switch (action) {
          case "create":
            dispatch(addVariation({ data: variation[i] }));
            break;
          case "update":
            dispatch(
              updateVariation({
                data: variation[0],
                id: data._id,
              })
            );
            break;
          default:
            break;
        }
      }
      closeModaleWithClearAll();
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addNewitemRow}
          title={edit === true ? "Update Variation" : "Add Variation"}
          addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_modifier_category"}
            method="post"
            onSubmit={(e) =>
              handleSubmit(e, edit === true ? "update" : "create")
            }
          >
            <div className="table-responsive">
              <table className="table text-nowrap update_modify_categ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    {edit ? "" : <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(variation).length > 0
                    ? variation.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="text"
                              name="variationName"
                              value={item.variationName}
                              placeholder="Variation Name"
                              onChange={(e) => handleChangeVariation(e, index)}
                            />
                            {errors[index]?.variationName && (
                              <p className="error-validation">
                                {errors[index].variationName}
                              </p>
                            )}
                          </td>
                          <td className="range_sec">
                            <Form.Control
                              type="text"
                              name="variationDescription"
                              value={item.variationDescription}
                              placeholder="Variation description"
                              onChange={(e) => handleChangeVariation(e, index)}
                            />
                            {errors[index]?.variationDescription && (
                              <p className="error-validation">
                                {errors[index].variationDescription}
                              </p>
                            )}
                          </td>
                          {edit ? (
                            ""
                          ) : (
                            <td>
                              <div className="deleteButton_modifier_category">
                                <button
                                  className="deleteButton_1"
                                  disabled={
                                    Object.keys(variation).length === 1
                                      ? true
                                      : false
                                  }
                                  onClick={() => deleteItemrow(index)}
                                  type="button"
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">
                    {edit === true ? "Update" : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default memo(AddVariationModal);
