import * as api from "./api";
/**
 * get all item
 */
export const getCoupon = () => {
  try {
    return api.get("coupon/coupon");
  } catch (error) {
    return error;
  }
};

/***
 * add new item
 */
export const addCoupon = (params) => {
  try {
    return api.post("coupon/coupon", params);
  } catch (error) {
    return error;
  }
};

/***
 * update new item
 */
export const updateCoupon = (params) => {
  const { data, id } = params;
  try {
    delete data._id;
    return api.put(`coupon/coupon/${id}`, data);
  } catch (error) {
    return error;
  }
};

/***
 * update new item
 */
export const deleteCoupon = (params) => {
  const { id } = params;
  try {
    return api.deleteRec(`coupon/coupon/${id}`);
  } catch (error) {
    return error;
  }
};

/***
 * add new item
 */
export const getSingleCoupon = (params) => {
  try {
    return api.post("coupon/getCoupon", params);
  } catch (error) {
    return error;
  }
};
