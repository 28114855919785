import Skeleton from "react-loading-skeleton";
import "./overlay.css";
import "react-loading-skeleton/dist/skeleton.css";
import originallogo from "../assets/images/o_original_logo-removebg-preview.png";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocation, selectLocation } from "../../../Action/authAction";
import { FaGreaterThan } from "react-icons/fa";
import CopyRight from "../footer/copyRight";
import o_original_logo from "./o_original_logo.jpg";

export default function Overlay() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const [coor, setcoor] = useState({
    latitude: 0,
    longitude: 0,
  });
  useEffect(() => {
    dispatch(getLocation());
  }, []);
  //Sort location in dropdown according to distance
  location.location.sort((p1, p2) =>
    distance(
      p1.addresses[0]?.latitude,
      coor.latitude,
      p1.addresses[0]?.longitude,
      coor.longitude
    ) <
      distance(
        p2.addresses[0]?.latitude,
        coor.latitude,
        p2.addresses[0]?.longitude,
        coor.longitude
      )
      ? -1
      : distance(
        p1.addresses[0]?.latitude,
        coor.latitude,
        p1.addresses[0]?.longitude,
        coor.longitude
      ) >
        distance(
          p2.addresses[0]?.latitude,
          coor.latitude,
          p2.addresses[0]?.longitude,
          coor.longitude
        )
        ? 1
        : 0
  );
  const handleChooseLocation = (locationId) => {    
    const curLoc = localStorage.getItem("currentlocId");
    if (locationId !== curLoc) {
      localStorage.removeItem("cart")
    }
    if (location) dispatch(selectLocation(locationId));
  };

  function distance(lat1, lat2, lon1, lon2) {
    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 3956;

    // calculate the result
    let v = c * r;
    if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    }
    return v;
  }
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((e) => {
      setcoor({
        latitude: e.coords.latitude,
        longitude: e.coords.longitude,
      });
    });
  }, []);
  return (
    <>
      <div
        id="myNav"
        className="overlay"
        style={{ backgroundColor: "rgb(48 119 49)" }}
      >
        <div className=" bg-white h-100 h-mobile-auto overlay-inner">
          <div className="p-5 h-100 custom-overflow-wrapper">
            <div className="welcome-logo">
              <img className="img-fluid" src={o_original_logo} />
            </div>
            <h3 className="display-4">
            Confirm Location Selection
            </h3>
            {location.loading ? (
              <div className="text-center">
                <div
                  className="spinner-border text-success"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row  mt-5">
                {location.location?.map((item, index) => (
                  <div
                    className="col-md-4 locationcard"
                    key={index}
                    onClick={(e) => handleChooseLocation(item._id)}
                  >
                    <div className="card overlay-box">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="locationImg">
                            {item.icon ? (
                              <img
                                src={item.icon}
                                className="img-fluid"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = originallogo;
                                }}
                              />
                            ) : (
                              <img src={item.icon} className="img-fluid" />
                            )}
                          </div>
                          <div className="locationName d-flex justify-content-between">
                            <p>{item.addresses[0]?.street} </p>
                            <i>
                              <b>
                                {distance(
                                  item.addresses[0]?.latitude,
                                  coor.latitude,
                                  item.addresses[0]?.longitude,
                                  coor.longitude
                                )}{" "}
                                miles
                              </b>
                            </i>
                          </div>
                        </div>

                        <span>
                          <FaGreaterThan />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <CopyRight />
    </>
  );
}
