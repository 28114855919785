import React from 'react';
import InnerBanner from '../helpers/InnerBanner';
import { Image } from 'react-bootstrap';
import imageWp from '../assets/images/w-pizza.jpg';
import TestimonialCarousal from '../helpers/TestimonialCarousal'

const Aboutus = () => {
    return (
      <>
       <InnerBanner title={'About us'} />
        <section className="aboutus">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="about-img">
								<Image src={imageWp} className="img-fluid" /> 
							</div>
						</div>

						<div className="col-md-8">
							<div className="about-content">						
								<div className="section-title">
									<h3>How it Started</h3>
								</div>
								<p>Oliver's Pizza Inc. was founded by Dominick Oliver in 1980. The franchise organization operates carry out and quick serve stores in Michigan. The corporate history is closely linked with the personal history of Mr. Oliver, who began his professional career as a local pizza franchise owner in 1974. In 1980 he took over a troubled pizza franchise on the east side of Detroit. Later that year he opened Oliver's Pizza restaurant in Sterling Heights MI, followed by two more locations the next year. All operating under the same principle, serve delicious food at an affordable price, in a warm, clean atmosphere. Principles that Mr. Oliver still believes in today.</p>

								<ul>
									<li>We serve the best food in town.</li>
									<li>Always Fresh!!</li>
									<li>Fast and Friendly service.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
		</section>
        <TestimonialCarousal />
      </>
    )
}

export default Aboutus;