import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SalesOverviewGraph from "./SalesOverviewGraph";

import longArrowAltDown from "../../Assets/images/awesome-long-arrow-alt-down.svg";
import totalOrder from "../../Assets/images/total-order.svg";
import newOrder from "../../Assets/images/new-order.svg";
import kichanIcon from "../../Assets/images/kichan.svg";
import {
  getDashboardData,
  getChartsData,
} from "../../Services/dashboardServices";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);
function Dashboard() {
  const login = useSelector((state) => state.login);
  /** build an state array for  render the div on dashboard */
  const [dashboard, setdashboard] = useState([
    {
      totalOrderName: "Total Order",
      totalOrderNo: "",
      longArrowIcon: longArrowAltDown,
      cardSign: totalOrder,
      uri: "/manage-orders",
    },
    {
      totalOrderName: "New Order",
      totalOrderNo: "",
      longArrowIcon: longArrowAltDown,
      cardSign: newOrder,
      uri: "/manage-orders",
    },
    // {
    //   totalOrderName: "Order in Crew",
    //   totalOrderNo: "",
    //   longArrowIcon: longArrowAltDown,
    //   cardSign: kichanIcon,
    //   uri: "/manage-orders",
    // },
    {
      totalOrderName: "Net Sales",
      totalOrderNo: "",
      // longArrowIcon: longArrowAltDown,
      // cardSign: kichanIcon,
      // uri: "/manage-orders",
    },
  ]);
  const [labels, setlabels] = useState([]);
  const [datasets, setdatasets] = useState([]);
  const [pieDatasets, setpieDatasets] = useState([]);
  const [dailySalesDatasets, setdailySalesDatasets] = useState([]);
  const [dailySaleslabels, setdailySaleslabels] = useState([]);
  /* filteriazation of price */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /** this effect will  get the dashboard data  */
  useEffect(() => {
    getDashboardData().then((res) => {
      var allOrder = 0;
      var crewOrder = 0;
      var newOrder = 0;
      var totalSales = 0;
      var totalDiscount = 0;
      var netSales = 0;
      var totalDriverFee = 0;
      var totalDeliveryCharge = 0;
      var totalCouponAmount = 0;
      res.result.map((item) => {
        if (item.OrderStatusCode === "new") {
          newOrder = newOrder + 1;
        } else if (item.OrderStatusCode === "crew") {
          crewOrder = crewOrder + 1;
        } else if (
          item.OrderStatusCode === "ready" ||
          item.OrderStatusCode === "delivered"
        ) {
          totalSales = totalSales + item.totalAmount;
          totalDiscount = totalDiscount + item.discountAmount;
          totalDeliveryCharge = totalDeliveryCharge + item.deliveryCharge;
          totalDriverFee = totalDriverFee + item.driverReimbursement;
          totalCouponAmount = totalCouponAmount + item.couponAmount;
          netSales =
            totalSales +
            totalDeliveryCharge -
            totalDriverFee -
            totalDiscount -
            totalCouponAmount;
        }
      });
      let tmpDashboardData = [...dashboard];
      let index = "";
      index = tmpDashboardData.findIndex(
        (e) => e.totalOrderName === "Total Order"
      );
      tmpDashboardData[index].totalOrderNo = res.result.length;
      //new order
      index = tmpDashboardData.findIndex(
        (e) => e.totalOrderName === "New Order"
      );
      tmpDashboardData[index].totalOrderNo = newOrder;
      //crew orders
      // index = tmpDashboardData.findIndex(
      //   (e) => e.totalOrderName === "Order in Crew"
      // );
      // tmpDashboardData[index].totalOrderNo = crewOrder;
      index = tmpDashboardData.findIndex(
        (e) => e.totalOrderName === "Net Sales"
      );
      tmpDashboardData[index].totalOrderNo = priceMature(netSales);

      setdashboard(tmpDashboardData);
    });
    getChartsData().then((res) => {
      let data = res.result?.itemSaleByVariation;
      let OrderServiceOption = res.result?.OrderServiceOption;
      let dailySales = res.result?.dailySales;
      setlabels(data.labels);
      setdatasets(data.datasets);
      setpieDatasets(OrderServiceOption);
      setdailySalesDatasets(dailySales.datasets);
      setdailySaleslabels(dailySales.labels);
    });
  }, []);
  return (
    <div className="main-content">
      <div className="page-title">
        <h2>Dashboard</h2>
      </div>
      <div className="dashbaord-card">
        <Row>
          {dashboard.map((item, i) => (
            <Col md={4} key={i}>
              <div className="dash-card">
                <div className="dash-value">
                  <h3>{item.totalOrderName}</h3>
                  <h2>{item.totalOrderNo}</h2>
                  <Link to={item.uri}>
                    <img src={item.longArrowIcon} alt="" />
                  </Link>
                </div>
                <div className="cardicon">
                  <img src={item.cardSign} alt="" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <div className="card customCard">
            <div className="card-title p-3">
              <h2>Order Service Option</h2>
            </div>
            <div className="card-body" style={{ minHeight: 400 }}>
              <Pie
                data={{
                  labels: ["Total delivery", "Total pickup"],
                  datasets: pieDatasets,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card customCard">
            <div className="card-title p-3">
              <h2>Sales Overview</h2>
            </div>
            <div className="card-body" style={{ minHeight: 400 }}>
              <Line
                data={{
                  labels: dailySaleslabels,
                  datasets: dailySalesDatasets,
                }}
                options={{
                  responsive: true,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: function (item, data) {
                          return [
                            "Total Orders: " + item.raw[0],
                            "Total Sale: $" + priceMature(item.raw[1]),
                          ];
                        },
                      },
                    },
                  },
                }}
                scales={{
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card customCard">
            <div className="card-title p-3">
              <h2>Sales by variation </h2>
            </div>
            <div className="card-body" style={{ minHeight: 300 }}>
              <Bar
                data={{
                  labels,
                  datasets,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;

// this is our backup
// import React, { useState, useEffect } from "react";
// import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import SalesOverviewGraph from "./SalesOverviewGraph";

// import longArrowAltDown from "../../Assets/images/awesome-long-arrow-alt-down.svg";
// import totalOrder from "../../Assets/images/total-order.svg";
// import newOrder from "../../Assets/images/new-order.svg";
// import kichanIcon from "../../Assets/images/kichan.svg";
// import {
//   getDashboardData,
//   getChartsData,
// } from "../../Services/dashboardServices";
// import { Bar, Pie, Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   PointElement,
//   LineElement,
// } from "chart.js";
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
//   PointElement,
//   LineElement
// );
// function Dashboard() {
//   /** build an state array for  render the div on dashboard */
//   const [dashboard, setdashboard] = useState([
//     {
//       totalOrderName: "Total Order",
//       totalOrderNo: "",
//       longArrowIcon: longArrowAltDown,
//       cardSign: totalOrder,
//       uri: "/manage-orders",
//     },
//     {
//       totalOrderName: "New Order",
//       totalOrderNo: "",
//       longArrowIcon: longArrowAltDown,
//       cardSign: newOrder,
//       uri: "/manage-orders",
//     },
//     {
//       totalOrderName: "Order in Crew",
//       totalOrderNo: "",
//       longArrowIcon: longArrowAltDown,
//       cardSign: kichanIcon,
//       uri: "/manage-orders",
//     },
//   ]);
//   const [labels, setlabels] = useState([]);
//   const [datasets, setdatasets] = useState([]);
//   const [pieDatasets, setpieDatasets] = useState([]);
//   const [dailySalesDatasets, setdailySalesDatasets] = useState([]);
//   const [dailySaleslabels, setdailySaleslabels] = useState([]);
//   /* filteriazation of price */
//   const priceMature = (v) => {
//     if (v == undefined) {
//       return parseFloat(0).toFixed(2);
//     } else if (v == "") {
//       return parseFloat(0).toFixed(2);
//     } else if (isNaN(v)) {
//       return parseFloat(0).toFixed(2);
//     } else if (v.toString().indexOf(".") != -1) {
//       return parseFloat(v).toFixed(2);
//     } else {
//       return parseFloat(v).toFixed(2);
//     }
//   };
//   /** this effect will  get the dashboard data  */
//   useEffect(() => {
//     getDashboardData().then((res) => {
//       var allOrder = 0;
//       var crewOrder = 0;
//       var newOrder = 0;
//       res.result.map((item) => {
//         if (item.OrderStatusCode === "new") {
//           newOrder = newOrder + 1;
//         } else if (item.OrderStatusCode === "crew") {
//           crewOrder = crewOrder + 1;
//         }
//       });
//       let tmpDashboardData = [...dashboard];
//       let index = "";
//       index = tmpDashboardData.findIndex(
//         (e) => e.totalOrderName === "Total Order"
//       );
//       tmpDashboardData[index].totalOrderNo = res.result.length;
//       //new order
//       index = tmpDashboardData.findIndex(
//         (e) => e.totalOrderName === "New Order"
//       );
//       tmpDashboardData[index].totalOrderNo = newOrder;
//       //crew orders
//       index = tmpDashboardData.findIndex(
//         (e) => e.totalOrderName === "Order in Crew"
//       );
//       tmpDashboardData[index].totalOrderNo = crewOrder;

//       setdashboard(tmpDashboardData);
//     });
//     getChartsData().then((res) => {
//       let data = res.result?.itemSaleByVariation;
//       let OrderServiceOption = res.result?.OrderServiceOption;
//       let dailySales = res.result?.dailySales;
//       setlabels(data.labels);
//       setdatasets(data.datasets);
//       setpieDatasets(OrderServiceOption);
//       setdailySalesDatasets(dailySales.datasets);
//       setdailySaleslabels(dailySales.labels);
//     });
//   }, []);
//   return (
//     <div className="main-content">
//       <div className="page-title">
//         <h2>Dashboard</h2>
//       </div>
//       <div className="dashbaord-card">
//         <Row>
//           {dashboard.map((item, i) => (
//             <Col md={4} key={i}>
//               <div className="dash-card">
//                 <div className="dash-value">
//                   <h3>{item.totalOrderName}</h3>
//                   <h2>{item.totalOrderNo}</h2>
//                   <Link to={item.uri}>
//                     <img src={item.longArrowIcon} alt="" />
//                   </Link>
//                 </div>
//                 <div className="cardicon">
//                   <img src={item.cardSign} alt="" />
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </div>
//       <div className="row mb-3">
//         <div className="col-md-4">
//           <div className="card customCard">
//             <div className="card-title p-3">
//               <h2>Order Service Option</h2>
//             </div>
//             <div className="card-body" style={{ minHeight: 400 }}>
//               <Pie
//                 data={{
//                   labels: ["Total delivery", "Total pickup"],
//                   datasets: pieDatasets,
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="col-md-8">
//           <div className="card customCard">
//             <div className="card-title p-3">
//               <h2>Sales Overview</h2>
//             </div>
//             <div className="card-body" style={{ minHeight: 400 }}>
//               <Line
//                 data={{
//                   labels: dailySaleslabels,
//                   datasets: dailySalesDatasets,
//                 }}
//                 options={{
//                   responsive: true,
//                   plugins: {
//                     tooltip: {
//                       callbacks: {
//                         label: function (item, data) {
//                           return [
//                             "Total Orders: " + item.raw[0],
//                             "Total Sale: $" + priceMature(item.raw[1]),
//                           ];
//                         },
//                       },
//                     },
//                   },
//                 }}
//                 scales={{
//                   yAxes: [
//                     {
//                       ticks: {
//                         beginAtZero: true,
//                       },
//                     },
//                   ],
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12">
//           <div className="card customCard">
//             <div className="card-title p-3">
//               <h2>Sales by variation </h2>
//             </div>
//             <div className="card-body" style={{ minHeight: 300 }}>
//               <Bar
//                 data={{
//                   labels,
//                   datasets,
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Dashboard;
