import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../Reducer";
import rootSaga from "../Saga";
import { composeWithDevTools } from "redux-devtools-extension";

// saga middle ware
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  compose(composeWithDevTools(applyMiddleware(sagaMiddleware)))
);

// run saga
sagaMiddleware.run(rootSaga);

export default store;
