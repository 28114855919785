import * as api from "./api";

//get
export const getCrewOrDriverList = () => {
  return api.get("/user/getCrewOrDriver");
};
//add
export const addCrewOrDriver = (params) => {
  return api.post("/user/createCrewOrDriver", params);
};
//update
export const updateCrewOrDriver = (params) => {
  return api.put("/user/updateCrewOrDriver/" + params._id, params);
};
//varify the the crew or driver
export const varifyCrewOrDriverOrders = (params) => {
  return api.post("/user/varifyCrewOrDriver", params);
};
//get list of crew or driver orders
export const getcrewOrderDriverOrderList = (params) => {
  return api.getWithMultiQuery(
    `/order/getOrder/${params._id}?collectionName=${params.collectionName}&&`
  );
};
//save check in and checkout of the driver
export const checkInOut = (params) => {
  return api.post(`/driver/checkInOut`, params);
};
//get checkin and out history
export const checkInOutHistory = (userId) => {
  return api.get(`/driver/checkInOutHistory/${userId}`);
};
//get driver history
export const driverHistory = (userId) => {
  return api.get(`/driver/driverHistory/${userId}`);
};
//get driver order history
export const driverOrderHistory = (userId) => {
  return api.get(`/driver/driverOrderHistory/${userId}`);
};

//get driver qr code
export const getCrewOrDriverQrCode = (data) => {
  return api.newPost(`/user/getCrewOrDriverQrCode`, data);
};

//generate the otp
export const getGenerateOtp = (payload) => {
  return api.post(`/user/generateOtp`, payload);
};
