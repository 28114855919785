import * as api from './api';


// get
export const getManageModifierSrvice = async () => {
    try {
        const res = await api.get('varationModifiers/getModifiersItem');
        return res;
    }
     catch(error) {
        return error;
     }
}


// delete
export const deleteManageModifierSrvice = async (itemId) => {
    try {
        const res = await api.deleteRec(`varationModifiers/deleteMOdifiersItem/${itemId}`);
        return res;
    }
     catch(error) {
        return error;
     }
}

// update
export const updateManageModifierSrvice = async (payload) => {
    const {itemId, data} = payload;
    try {
        const res = await api.put(`varationModifiers/updateModifiersItem/${itemId}`, data);
        return res;
    }
     catch(error) {
        return error;
     }
}

// create
export const createManageModifierSrvice = async (payload) => {
    const { data } = payload;
     try {
        const res = await api.post(`varationModifiers/createVarationModifiers`, data);
        return res;
    }
     catch(error) {
        return error;
     }
}


// get Modifier List
export const getModifierManageModifierListSrvice = async () => {
    try {
        const res = await api.get('managedMasterModifier/getAllModifierMasterItem');
        return res;
    }
     catch(error) {
        return error;
     }
}

// get Variations List
export const getVariationsManageModifierListSrvice = async () => {
    try {
        const res = await api.get('varation/getAllVariation');
        return res;
    }
     catch(error) {
        return error;
    }
}



