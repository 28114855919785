import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
  getManagerModifierList,
  getModifierTypeManagerModifierList,
  getVariationsTypeManagerModifierList,
  deleteManagerModifierList,
} from "../../Action/manageModifierAction";
import AddModalRightAnimation from "./AddModal";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import ConfirmModal from "../../helpers/ConfirmModal";
import "./ManageModifier.css";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";

const ManageModifier = (props) => {
  const dispatch = useDispatch();
  const dataResponse = useSelector((state) => state.manageModifier);
  const [animationModal, setAnimationModal] = useState("no");
  const [litsData, setLitsData] = useState([]);
  const [showModal, setShowModal] = useState({ show: false, data: "" });
  const [editModifierData, setEditModifierData] = useState({
    edit: false,
    editData: {},
  });
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false,
  });

  const columns = [
    {
      dataField: "Modifires",
      text: "Modifier Name",
      sort: true,
      formatter: (cell) => cell,
    },
    {
      dataField: "Variations",
      text: "Variations  Name",
      sort: true,
      formatter: (cell) => cell,
    },
    {
      dataField: "isDouble",
      text: "is Double",
      formatter: (cell) => (cell === true ? "Yes" : "No"),
      filterValue: (cell) => (cell === true ? "Yes" : "No"),
    },
    {
      dataField: "halfToping",
      text: "Half Topping",
      formatter: (cell) => (cell === true ? "Yes" : "No"),
      filterValue: (cell) => (cell === true ? "Yes" : "No"),
    },
    {
      dataField: "Price",
      text: "Price ($)",
      formatter: (cell) => {
        if (cell !== undefined) {
          if (!(cell % 1) === true && cell?.toString().length <= 2) {
            return `${cell}.00`;
          } else {
            return parseFloat(cell).toFixed(2);
          }
        } else {
          return `0.00`;
        }
      },
      filterValue: (cell) => {
        if (cell !== undefined) {
          if (!(cell % 1) === true && cell?.toString().length <= 2) {
            return `${cell}.00`;
          } else {
            return parseFloat(cell).toFixed(2);
          }
        } else {
          return `0.00`;
        }
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => updateItem(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  //mount
  useEffect(() => {
    dispatch(getManagerModifierList());
    dispatch(getModifierTypeManagerModifierList());
    dispatch(getVariationsTypeManagerModifierList());
  }, [dispatch]);
  //mount state from reducer
  useEffect(() => {
    const { manageModfierItemAll, loading, tableLoading } = dataResponse;
    if (Object.keys(manageModfierItemAll).length > 0) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading,
      });
      const dataList = manageModfierItemAll.map((item) => ({
        ...item,
        Modifires: item.Modifires.MadifierName,
        Variations: item.Variations.varationsName,
      }));
      setLitsData(dataList.reverse());
    } else {
      setLitsData([]);
    }
  }, [dataResponse]);
  //update
  const updateItem = (data, action) => {
    if (action === "edit") {
      const editDataFind = dataResponse.manageModfierItemAll.filter(
        (item) => item._id === data._id
      );
      setEditModifierData({
        edit: true,
        editData: editDataFind[0],
      });
      setAnimationModal("yes");
    }
    if (action === "delete") {
      setShowModal({ show: true, data: data });
    }
  };
  //handle rejection from delete model
  const handleCloseAlert = () => {
    setShowModal((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };
  //handle success from delete model
  const handleSuccessAlert = () => {
    dispatch(deleteManagerModifierList({ itemId: showModal.data._id }));
    setShowModal(false);
  };
  // open model for adding the item
  const addNewMenu = () => {
    setAnimationModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    setEditModifierData({
      edit: false,
      editData: {},
    });
  };

  if (isLoding.pageLoding === true) {
    return <PageLoader size={50} />;
  }

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Modifier Price</h2>
        </div>

        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Modifier Price</h2>
                  <button className="btn custombtn" onClick={addNewMenu}>
                    Add Modifier Price
                  </button>
                </div>
                <div className="card-body actioncard">
                  {isLoding.pageLoding === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {animationModal && (
        <AddModalRightAnimation
          variationsTypeList={dataResponse.variationsTypeList}
          modifierTypeList={dataResponse.modifierTypeList}
          editModifierData={editModifierData}
          animationModal={animationModal}
          closeNewMenu={addNewMenu}
          litsData={litsData}
        />
      )}
      {showModal && (
        <ConfirmModal
          show={showModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageModifier;
