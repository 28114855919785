import * as api from "./api";

//get  product category
export const getProductCategoryService = async () => {
  try {
    let res = await api.get("productCategory/getProductCategory");
    return res;
  } catch (err) {
    return err;
  }
};

// get product category
export const getProductCategoryPublicService = async () => {
  try {
    let res = await api.get("productCategory/getProductCategoryOnCustomerUI");
    return res;
  } catch (err) {
    return err;
  }
};

// add product category
export const postProductCategoryService = async (payload) => {
  const { data } = payload;
  try {
    let res = await api.post("productCategory/addProductCategoryType", data);
    return res;
  } catch (err) {
    return err;
  }
};
// delete product category
export const deleteProductCategoryService = async (payload) => {
  const { itemId } = payload;
  try {
    let res = await api.patchRec(
      `productCategory/deleteProductCategoryItem/${itemId}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
// update product category
export const updateProductCategoryService = async (payload) => {
  const { data, itemId } = payload;
  try {
    let res = await api.put(
      `productCategory/updateProductCategory/${itemId}`,
      data
    );
    return res;
  } catch (err) {
    return err;
  }
};

// get individual
export const getIndividualProductCategoryService = async () => {
  try {
    let res = await api.get(`manageMenu/getAllCategoryItem`);
    return res;
  } catch (err) {
    return err;
  }
};

// get Product Category Item List By id
export const getProductCategoryItemListByIdService = async (payload) => {
  const { itemId } = payload;
  try {
    let res = await api.get(
      `productCategory/getProductCategoryItemOnCustomerUI/${itemId}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

// get customize item variation Item List By id
export const getCustomizeVariationItemListByIdService = async (payload) => {
  try {
    let res = await api.get(
      `productCategory/getCustomizeVariationItem/${payload}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

// get customize item variation Item List By id
export const getCustomizeModifierItemListByIdService = async (payload) => {
  try {
    let res = await api.get(
      `productCategory/getCustomizeModifierdata/${payload}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

// get customize item variation Item List By id
export const getCustomizeModifierItemListByIdServicePromised = async (
  payload
) => {
  try {
    return api.get(`productCategory/getCustomizeModifierdata/${payload}`);
  } catch (err) {
    return err;
  }
};
