import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  manageModfierItemAll: [],
  modifierTypeList: [],
  variationsTypeList: [],
  msg: "",
};
function manageModifierReducer(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_MANAGE_MODIFIER_LIST:
      return { ...state, loading: true };

    case types.DELETE_MANAGE_MODIFIER_ITEM:
      return { ...state, tableLoading: true };
    case types.UPDATE_MANAGE_MODIFIER_ITEM:
      return { ...state, tableLoading: true };
    case types.CREATE_MANAGE_MODIFIER_ITEM:
      return { ...state, tableLoading: true };

    case types.GET_MANAGE_MODIFIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        manageModfierItemAll: payload || [],
        msg: msg,
      };
    case types.MODIFIER_TYPE_MANAGE_MODIFIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        modifierTypeList: payload || [],
        msg: msg,
      };
    case types.VARIATIONS_TYPE_MANAGE_MODIFIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        variationsTypeList: payload || [],
        msg: msg,
      };
    case types.GET_MANAGE_MODIFIER_LIST_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };

    default:
      return state;
  }
}

export default manageModifierReducer;
