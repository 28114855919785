import React from "react";
import { Form } from "react-bootstrap";
import InnerBanner from '../helpers/InnerBanner';

export default function Career() {
  return (
    <>
      <InnerBanner title="Career" />
      <section className="location-section">
        <div className="container">
          <div className="section-title pt-4 pb-3">
            <h3>
              CAREER <span className="carrer_heading">HELP WANTED</span>
            </h3>
          </div>

          <div className="location-list">
            <div className="row">
              <div className="col-md-12">
                <div className="carrer-item">
                  <div className="loc-map">
                    <Form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <Form.Label>Name *</Form.Label>
                          <Form.Control type="text" placeholder="First Name" />
                        </div>
                        <div className="form-group col-md-6">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" placeholder="Last Name" />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <Form.Label>Phone *</Form.Label>
                          <Form.Control type="text" placeholder="(###)" />
                        </div>
                        <div className="form-group col-md-2">
                          <Form.Control className="mt-7" type="text" placeholder="(###)" />
                        </div>
                        <div className="form-group col-md-2">
                          <Form.Control className="mt-7" type="text" placeholder="(###)" />
                        </div>
                      </div>

                      <div className="form-group">
                        <Form.Label>Location applying for? *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Cross roads and city."
                        />
                      </div>
                      <div className="form-group">
                        <Form.Label>Why we should hire you? *</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={3}
                          placeholder="Tell us why you're awesome, and availability."
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <Form.Label>Position applying for. *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Can you deliver?"
                          />
                        </div>
                      </div>

                      <button type="submit" className="btn carrer-btn ">
                        Sign in
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
