import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NormalModal from "../../../helpers/NormalModal";
import envelopeIcon from "../../../Assets/images/envelope.svg";
import o_original_logo from "../../../Assets/images/o_original_logo.jpg";
import padlockIcon from "../../../Assets/images/padlock.svg";
import userImage from "../../../Assets/images/user.png";
import awesomePhoneicon from "../assets/images/awesome-phone-alt.svg";
import materiallocation from "../assets/images/material-location-on.svg";
import {
  registrationCustomerAction,
  setUser,
} from "../../../Action/authAction";
import { getPlacesSuggestions } from "../../../Services/deliveryAndCharges";

export default function Registration(props) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const { showModal, closeModal, closeLoginModal } = props;
  const [user, setuser] = useState({
    fname: "",
    lname: "",
    // streetAddress:"",
    email: "",
    contactNumber: "",
    street: "",
    password: "",
    cPassword: "",
  });
  const [errors, setErrors] = useState({
    fname: false,
    lname: false,
    email: false,
    contactNumber: false,
    // streetAddress:false,
    street: false,
    password: false,
    cPassword: false,
  });
  const [placeSuggestion, setplaceSuggestion] = useState([]);
  const [placeSuggestionTool, setplaceSuggestionTool] = useState({
    show: false,
    loading: false,
    timeout: 0,
  });
  /**  this function will add mask on phone number input field */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  const [deliverChargesError, setdeliverChargesError] = useState(false);
  /*** this function will handle the chenges in street field */
  const handlePlaceSuggestionChange = (text) => {
    clearTimeout(placeSuggestionTool.timeout);
    let tmp = setTimeout(() => {
      if (!placeSuggestionTool.show) {
        setplaceSuggestionTool({
          ...placeSuggestionTool,
          show: true,
          loading: true,
        });
      }
      getPlacesSuggestions({ input: text })
        .then((res) => {
          setplaceSuggestion(res.result ?? []);
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: true,
          });
        })
        .catch((error) => {
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: false,
          });
        });
      if (deliverChargesError) {
        setdeliverChargesError(false);
      }
    }, 500);
    setplaceSuggestionTool({ ...placeSuggestionTool, ["timeout"]: tmp });
  };
  /** this function will handle the click of place suggestion list  */
  const handlePlaceSuggestionClick = (addressString, fullAddressString) => {
    setuser({ ...user, ["street"]: fullAddressString });
    setplaceSuggestionTool({
      ...placeSuggestionTool,
      loading: false,
      show: false,
    });
  };
  /** validate the contact number  */
  const contains = function (charValue) {
    //eslint-disable-next-line
    let pval = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return !pval.test(charValue);
  };
  //handle change of customer
  const handleChange = (e) => {
    let isValidate = true;
    var tmpError = { ...errors };
    if (e.target.name === "contactNumber") {
      setuser({ ...user, [e.target.name]: normalizeInput(e.target.value) });
    } else if (e.target.name === "street") {
      setuser({ ...user, [e.target.name]: e.target.value });
      handlePlaceSuggestionChange(e.target.value);
    } else {
      setuser({ ...user, [e.target.name]: e.target.value });
    }

    if (e.target.name === "contactNumber") {
      if (e.target.value.toString().length != 14 || contains(e.target.value)) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      //eslint-disable-next-line
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
      isValidate = false;
    } else if (e.target.name === "password") {
      if (e.target.value.toString().length < 6) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
      if (
        e.target.value.toString().length !== user["cPassword"].toString().length
      ) {
        tmpError["cPassword"] = true;
        isValidate = false;
      } else {
        tmpError["cPassword"] = false;
      }
    } else if (e.target.name === "cPassword") {
      if (
        e.target.value.toString().length !== user["password"].toString().length
      ) {
        tmpError["cPassword"] = true;
        isValidate = false;
      } else {
        tmpError["cPassword"] = false;
      }
    } else {
      tmpError[e.target.name] = false;
    }
    setErrors(tmpError);
  };
  //close model and clear the state
  const closeModelClearAll = () => {
    closeModal();
    setUser({
      fname: "",
      lname: "",
      email: "",
      contactNumber: "",
      street: "",
      // streetAddress:"",
      password: "",
      cPassword: "",
    });
  };
  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    var isValidate = true;
    var tmpError = { ...errors };
    for (let key in tmpError) {
      if (key === "contactNumber") {
        if (user[key].toString().length != 14) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "email") {
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            user[key]
          )
        ) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "password") {
        if (user[key].toString().length < 6) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
        if (
          user[key].toString().length !== user["cPassword"].toString().length
        ) {
          tmpError["cPassword"] = true;
          isValidate = false;
        } else {
          tmpError["cPassword"] = false;
        }
      } else if (key === "cPassword") {
        if (
          user[key].toString().length !== user["password"].toString().length
        ) {
          tmpError["cPassword"] = true;
          isValidate = false;
        } else {
          tmpError["cPassword"] = false;
        }
      } else if (user[key] === "" || user[key] == undefined) {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    }
    setErrors(tmpError);
    if (isValidate)
      dispatch(
        registrationCustomerAction({
          ...user,
          ["contactNumber"]: user.contactNumber.replace(/[()  -]/g, ""),
        })
      );
  };
  useEffect(() => {
    if (customer.isCustomerLoggedIn === true) {
      closeModelClearAll();
    }
  }, [customer]);
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      sizeModal={"50"}
      title=""
    >
      <div className="row">
        <div className="col-md-12">
          <div className="loginform">
            {/* <div className="loginlogo">
                            <img
                                src={o_original_logo}
                                className="img-fluid"
                                alt="o_original_logo"
                            />
                        </div> */}
            <div className="logintitle">
              <h2>User signup</h2>
              {/* <span>Please fill the form</span> */}
            </div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>First Name</label>
                    <span className="form-control-feedback">
                      <img src={userImage} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.fname ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your first name"
                      name="fname"
                      value={user.fname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>Last Name</label>
                    <span className="form-control-feedback">
                      <img src={userImage} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.lname ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your Last name"
                      name="lname"
                      value={user.lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>Email </label>
                    <span className="form-control-feedback">
                      <img className="login-email" src={envelopeIcon} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your email"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>Contact Number</label>
                    <span className="form-control-feedback address-span">
                      <img src={awesomePhoneicon} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.lname ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your Contact Number"
                      name="contactNumber"
                      value={user.contactNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>Password </label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type="password"
                      className={`form-control ${
                        errors.password ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your password"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      readOnly="readonly"
                      onFocus={(e) => e.target.removeAttribute("readonly")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group has-search">
                    <label>Confirm Password</label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type="password"
                      className={`form-control ${
                        errors.cPassword ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your password"
                      name="cPassword"
                      value={user.cPassword}
                      onChange={handleChange}
                      readOnly="readonly"
                      onFocus={(e) => e.target.removeAttribute("readonly")}
                    />
                  </div>
                </div>

                {/* <div className="col-md-12">
                  <div className="form-group has-search">
                    <label>Street Number</label>
                    <span className="form-control-feedback address-span">
                      <img src={materiallocation} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.streetAddress ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your street number"
                      name="streetAddress"
                      value={user.streetAddress}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}


                <div className="col-md-12">
                  <div className="form-group has-search">
                    <label>Address</label>
                    <span className="form-control-feedback address-span">
                      <img src={materiallocation} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.street ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your address"
                      name="street"
                      value={user.street}
                      onChange={handleChange}
                    />
                    {placeSuggestionTool.show ? (
                      <div className="autocomplete-items">
                        {placeSuggestionTool.loading ? (
                          <div>loading...</div>
                        ) : (
                          <div className="list-group">
                            {placeSuggestion.map((item, index) => {
                              return (
                                <li
                                  className="list-group-item list-group-item-action flex-column align-items-start"
                                  key={index}
                                  onClick={() => {
                                    handlePlaceSuggestionClick(
                                      item.structured_formatting.main_text,
                                      item.description
                                    );
                                  }}
                                >
                                  <strong>
                                    {item.structured_formatting.main_text}
                                  </strong>
                                  <p>{item.description}</p>
                                </li>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12  text-right">
                  {/* <div className="regBtn">
                                        <button className="btn"
                                            onClick={() => {
                                                closeLoginModal();
                                                closeModal();
                                            }
                                            }
                                            disabled={customer.loading}>
                                            Login
                                        </button>
                                    </div> */}
                  <div className="regBtn">
                    <button className="btn" disabled={customer.loading}>
                      {customer.loading ? "Registering.." : "Register"}{" "}
                      <img src="images/loginicon.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </NormalModal>
  );
}
