const validation = (values) => {
  let keyCheck;
  Array.isArray(values) === true
    ? (keyCheck = values)
    : (keyCheck = Object.keys(values));
  let errors = {};
  if (!values.email && keyCheck.includes("email")) {
    errors.email = requiredString("email", "required");
  } else if (
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email) &&
    keyCheck.includes("email")
  ) {
    errors.email = requiredString("email", "mustemail");
  }
  if (!values.password && keyCheck.includes("password")) {
    errors.password = requiredString("password", "required");
  }
  if (!values.city && keyCheck.includes("city")) {
    errors.city = requiredString("city", "required");
  }
  if (!values.contactNumber && keyCheck.includes("contactNumber")) {
    errors.contactNumber = requiredString("contact number", "required");
  }

  if (!values.country && keyCheck.includes("country")) {
    errors.country = requiredString("country", "required");
  }
  if (!values.fname && keyCheck.includes("fname")) {
    errors.fname = requiredString("first name", "required");
  }
  if (!values.postalcode && keyCheck.includes("postalcode")) {
    errors.postalcode = requiredString("postal code", "required");
  }
  if (!values.state && keyCheck.includes("state")) {
    errors.state = requiredString("state", "required");
  }
  if (!values.amount && keyCheck.includes("amount")) {
    errors.amount = requiredString("amount", "required");
  }
  if (!values.percentage && keyCheck.includes("percentage")) {
    errors.percentage = requiredString("percentage", "required");
  }
  if (!values.roleName && keyCheck.includes("roleName")) {
    errors.roleName = requiredString("Role Name", "required");
  }
  if (!values.roleDiscription && keyCheck.includes("roleDiscription")) {
    errors.roleDiscription = requiredString("roleDescription", "required");
  }
  if (!values.permission && values?.permission?.length < 1) {
    errors.permission = requiredString("permission", "required");
  }
  if (!values.variationName && keyCheck.includes("variationName")) {
    // errors.variationName = requiredString("variationName", "required");
    errors.variationName = requiredString("Variation name", "required");
  }
  if (
    !values.variationDescription &&
    keyCheck.includes("variationDescription")
  ) {
    errors.variationDescription = requiredString(
      "Variation description",
      "required"
    );
    // errors.variationDescription = requiredString(
    //   "variationDescription",
    //   "required"
    // );
  }
  const arrayErrorsReturnm =
    Array.isArray(values) === true &&
    keyCheck.map((itemRow, i) => {
      let arrayErrors = {};
      let arraykeyCheck = Object.keys(itemRow);
      if (!itemRow.categoryofitem && arraykeyCheck.includes("categoryofitem")) {
        arrayErrors.categoryofitem = requiredString("category", "required");
      }
      if (
        !itemRow.itemdescription &&
        arraykeyCheck.includes("itemdescription")
      ) {
        arrayErrors.itemdescription = requiredString("description", "required");
      }
      if (
        !itemRow.itemDescription &&
        arraykeyCheck.includes("itemDescription")
      ) {
        arrayErrors.itemDescription = requiredString("description", "required");
      }
      if (!itemRow.priceItem && arraykeyCheck.includes("priceItem")) {
        arrayErrors.priceItem = requiredString("price", "required");
      }
      if (!itemRow.itemname && arraykeyCheck.includes("itemname")) {
        arrayErrors.itemname = requiredString("name", "required");
      }
      if (!itemRow.itemName && arraykeyCheck.includes("itemName")) {
        arrayErrors.itemName = requiredString("name", "required");
      }
      if (
        arraykeyCheck.includes("categoryname") &&
        itemRow.categoryname.length === 0
      ) {
        arrayErrors.categoryname = requiredString("category", "required");
      }
      if (!itemRow.productname && arraykeyCheck.includes("productname")) {
        arrayErrors.productname = requiredString("product", "required");
      }
      if (
        !itemRow.MadifierCategoryName &&
        arraykeyCheck.includes("MadifierCategoryName")
      ) {
        arrayErrors.MadifierCategoryName = requiredString(
          "modifier category name",
          "required"
        );
      }
      if (!itemRow.Description && arraykeyCheck.includes("Description")) {
        arrayErrors.Description = requiredString("description", "required");
      }
      if (!itemRow.Price && arraykeyCheck.includes("Price")) {
        arrayErrors.Price = requiredString("price", "required");
      }
      if (
        arraykeyCheck.includes("modifiersId") &&
        itemRow.modifiersId.length === 0
      ) {
        arrayErrors.modifiersId = requiredString("modifier", "required");
      }
      if (
        arraykeyCheck.includes("varationsId") &&
        itemRow.varationsId.length === 0
      ) {
        arrayErrors.varationsId = requiredString("variation type", "required");
      }
      if (!itemRow.CategoryName && arraykeyCheck.includes("CategoryName")) {
        arrayErrors.CategoryName = requiredString("category name", "required");
      }
      if (!itemRow.MadifierName && arraykeyCheck.includes("MadifierName")) {
        arrayErrors.MadifierName = requiredString("modifier name", "required");
      }
      if (
        arraykeyCheck.includes("MadifierCategoryID") &&
        itemRow.MadifierCategoryID.length === 0
      ) {
        arrayErrors.MadifierCategoryID = requiredString(
          "modifier category name",
          "required"
        );
      }
      if (
        arraykeyCheck.includes("categoryId") &&
        itemRow.categoryId.length === 0
      ) {
        arrayErrors.categoryId = requiredString("category name", "required");
      }
      if (arraykeyCheck.includes("itemPrice") && !itemRow.itemPrice) {
        arrayErrors.itemPrice = requiredString("price", "required");
      }
      if (
        arraykeyCheck.includes("variationId") &&
        itemRow.variationId.length === 0
      ) {
        arrayErrors.variationId = requiredString("variation", "required");
      }
      if (!itemRow.variationPrice && arraykeyCheck.includes("variationPrice")) {
        arrayErrors.variationPrice = requiredString("price", "required");
      }
      if (
        !itemRow.driverReimbursement &&
        arraykeyCheck.includes("driverReimbursement")
      ) {
        arrayErrors.driverReimbursement = requiredString(
          "driver Charges",
          "required"
        );
      }
      if (!itemRow.price && arraykeyCheck.includes("price")) {
        arrayErrors.price = requiredString("price", "required");
      }
      if (itemRow.isDefault == true) {
        if (itemRow.min < 1) {
          arrayErrors.min = "Minimum should greater than 0";
        } else if (itemRow.min > itemRow.max) {
          arrayErrors.min = "Maximum should be greater than Minimum";
          arrayErrors.max = "Maximum should be greater than Minimum";
        }
      }
      if (arraykeyCheck.includes("driverReimbursement")) {
        if (!itemRow.min) {
          arrayErrors.min = "Minimum should greater than 0";
        } else if (Number(itemRow.min) < 0) {
          arrayErrors.min = "Minimum should greater than 0";
        } else if (Number(itemRow.min) > Number(itemRow.max)) {
          arrayErrors.min = "Maximum should be greater than Minimum";
          arrayErrors.max = "Maximum should be greater than Minimum";
        }
      }
      if (!itemRow.amount && arraykeyCheck.includes("amount")) {
        if (Number(itemRow.amount) < 1) {
          arrayErrors.amount = "amount should greater than 0";
        }
      } else if (itemRow.amount && arraykeyCheck.includes("amount")) {
        if (Number(itemRow.amount) < 1) {
          arrayErrors.amount = "amount should greater than 0";
        }
      }
      if (!itemRow.percentage && arraykeyCheck.includes("percentage")) {
        if (Number(itemRow.percentage) < 1) {
          arrayErrors.percentage = "percentage should greater than 0";
        }
        if (Number(itemRow.percentage) > 99) {
          arrayErrors.percentage = "percentage should lower than 100";
        }
      }
      if (arraykeyCheck.includes("percentage")) {
        if (Number(itemRow.percentage) < 1) {
          arrayErrors.percentage = "percentage should greater than 0";
        }
        if (Number(itemRow.percentage) > 99) {
          arrayErrors.percentage = "percentage should lower than 100";
        }
      }
      if (!itemRow.roleName && arraykeyCheck.includes("roleName")) {
        arrayErrors.roleName = requiredString("Role Name", "required");
      }
      if (
        !itemRow.roleDiscription &&
        arraykeyCheck.includes("roleDiscription")
      ) {
        arrayErrors.roleDiscription = requiredString(
          "roleDescription",
          "required"
        );
      }
      if (itemRow.permission && itemRow.permission.length < 1) {
        arrayErrors.permission = requiredString("permission", "required");
      }
      if (!itemRow.variationName && arraykeyCheck.includes("variationName")) {
        arrayErrors.variationName = requiredString(
          "Variation name",
          "required"
        );
      }
      if (
        !itemRow.variationDescription &&
        arraykeyCheck.includes("variationDescription")
      ) {
        arrayErrors.variationDescription = requiredString(
          "Variation description",
          "required"
        );
      }
      return arrayErrors;
    });
  if (arrayErrorsReturnm !== false) {
    errors = arrayErrorsReturnm;
  }

  return errors;
};

const requiredString = (feild, required) => {
  if (required === "required") {
    return `${feild} is required`;
  }
  if (required === "mustemail") {
    return `${feild} is invalid`;
  }
};

export default validation;
