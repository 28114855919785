import * as types from "./types";

/***
 * this item will get order list
 */
export const getOrdersList = (data, collectionName) => ({
  type: types.GET_ORDER_LIST,
  payload: data,
  param: collectionName,
});

/***
 * this item will get order list
 */
export const getOrdersListArchive = (data) => ({
  type: types.GET_ORDER_LIST_ARCHIVE,
  payload: data,
});
/***
 * this item will update order status
 */
export const changeOrderStatus = (data) => ({
  type: types.CHANGE_ORDER_STATUS,
  payload: data,
});

/***
 * this item will update order status
 */
export const readOrderStatus = (data) => ({
  type: types.READ_ORDER_STATUS,
  payload: data,
});

export const pushOrder = (data) => ({
  type: types.PUSH_ORDER_TO_LIST,
  payload: data,
  msg: " ",
});

export const updateOrder = (data) => ({
  type: types.UPDATE_ORDER_TO_LIST,
  payload: data,
  msg: " ",
});


/***
 * this item will get all orders list from order details table
 */
export const getOrdersDetails = () => ({
    type: types.GET_ORDER_REQUEST,
});
