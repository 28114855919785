import * as api from './api';
/** this service will get  the variation */
export const getVariation = async () => {
    return await api.get('varation/getAllVariation');
}
/** this service will update  the variation */
export const updateVariation = async (params) => {
    return await api.put(`varation/updateVariationItem/${params.id}`, params.data);
}
/** this service will add  the variation */
export const addVariation = async (params) => {
    return await api.post('varation/createVaration', params.data)
}
/** this service will delete  the variation */
export const deleteVariation = async (params) => {
    return await api.deleteRec(`varation/deleteVariationItem/${params.id}`);
}