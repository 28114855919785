import * as types from "./types";
/***
 * this item will get Kitchen and driver users
 */
export const getRole = () => ({
  type: types.GET_ROLE,
});

/***
 * this item will get Kitchen and driver users
 */
export const getPermission = () => ({
  type: types.GET_PERMISSION,
});

/***
 * this item will add the roles
 */
export const addRole = (payload) => ({
  type: types.ADD_ROLE,
  payload,
});

/***
 * this item will add the roles
 */
export const updateRole = (payload) => ({
  type: types.UPDATE_ROLE,
  payload,
});

/***
 * this item will add the roles
 */
export const deleteRole = (payload) => ({
  type: types.DELETE_ROLE,
  payload,
});

/***
 * this item will add the roles
 */
export const getAllPermission = (payload) => ({
  type: types.GET_ALL_PERMISSION,
  payload,
});
