import * as api from "./api";

// get
export const getMasterModifierService = async () => {
  try {
    const res = api.get(`managedMasterModifier/getModifierMasterAndCategoryRelation`);
    return res;
  } catch (err) {
    return err;
  }
};

// delete
export const deleteMasterModifierListItemService = async (payload) => {
  const { itemId } = payload;
  try {
    const res = api.deleteRec(`managedMasterModifier/deleteModifierMaster/${itemId}`);
    return res;
  } catch (err) {
    return err;
  }
};

// Create
export const createMasterModifierListItemService = async (payload) => {
    const { data } = payload;
    try {
      const res = api.post(`managedMasterModifier/addManagedModiFiersMaster`, data);
      return res;
    } catch (err) {
      return err;
    }
};

// update
export const updateMasterModifierListItemService = async (payload) => {
    const { data, itemId } = payload;
    try {
      const res = api.put(`managedMasterModifier/updateModifierMasterItem/${itemId}`, data);
      return res;
    } catch (err) {
      return err;
    }
};