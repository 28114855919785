import * as api from "./api";

export const getRole = async function () {
  return await api.get("/rolesAndPermission/getRole");
};

export const getPermission = async function () {
  return await api.get("/rolesAndPermission/getPermission");
};

export const addRole = async function (payload) {
  return await api.post("/rolesAndPermission/addRole", payload);
};

export const updateRole = async function ({ _id, data }) {
  delete data._id;
  return await api.put(`/rolesAndPermission/updateRole/${_id}`, data);
};

export const deleteRole = async function (payload) {
  return await api.put(`/rolesAndPermission/activate/${payload._id}`, {
    isActive: payload.isActive,
  });
};

export const getAllPermission = async function (payload) {
  return await api.get("/rolesAndPermission/getAllPermission/" + payload);
};
