import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactNotifications } from 'react-notifications-component'
// import ReactNotification from "react-notifications-component";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAuthorization } from "../Services/api";
import { checkCookie } from "../Utils/cookies";
import { setUser, logOutUser, getAddressAndPhone } from "../Action/authAction";
import { getAllPermission } from "../Action/manageRolesAndPermission";
import MainRouter from "./";

class App extends Component {
  componentDidMount() {
    const token = checkCookie();
    if (token !== null) {
      setAuthorization(token);
      try {
        const verifyToken = jwt.verify(token, "secretkEy-InR5cCI6IkpXV");
        this.props.setUser(verifyToken);
      } catch (err) {
        this.props.logOutUser();
      }
      if (this.props.login.permission?.length < 1) {
        this.props.getAllPermission();
      }
    }
    /** fetch location */
    this.props.getAddressAndPhone();
  }

  render() {
    return (
      <>
        <Router>
          <ReactNotifications />
          <MainRouter />
        </Router>
      </>
    );
  }
}

const mapDispatchToPops = (dispatch) => ({
  ...bindActionCreators(
    {
      setUser,
      logOutUser,
      getAllPermission,
      getAddressAndPhone,
    },
    dispatch
  ),
});
const mapStateToProps = (state) => ({
  login: state.login,
});
export default connect(mapStateToProps, mapDispatchToPops)(App);
