import React from "react";

const NotFoundPage = () => {
  return (
    <div className="main-content">
      <div className="page-title">
        <h2>404 page not found</h2>
        <p>Not match this Url ({window.location.href})</p>
      </div>      
    </div>
  );
};

export default NotFoundPage;
