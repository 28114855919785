import { useState, useEffect } from "react";
import NormalModal from "../../../helpers/NormalModal";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { store } from "react-notifications-component";
import { useDispatch } from "react-redux";
import { updateHomeBanner } from "./../../../Action/homeBannerActions";
export default function HomeBannerPopup(props) {
  const dispatch = useDispatch();
  const { showModal, closeModal, sizeModal } = props;
  const [data, setdata] = useState({
    indexing: props.indexing,
    bannerImage: null,
  });
  const [error, seterror] = useState({
    indexing: false,
    bannerImage: false,
  });
  useEffect(() => {
    //faisal
  }, []);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleFileChange = (e) => {
    seterror({ ...error, bannerImage: false });
    setdata({ ...data, ["bannerImage"]: e.target.files[0] });
  };
  const handleSubmit = () => {
    //prepare the data
    if (!data.indexing) {
      seterror({ ...error, indexing: true });
    } else if (!data.bannerImage) {
      seterror({ ...error, bannerImage: true });
    } else {
      const form = new FormData();
      form.append("indexing", data.indexing);
      form.append("bannerImage", data.bannerImage);
      dispatch(updateHomeBanner(form));
      closeModal();
    }
  };
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      sizeModal={sizeModal}
      title={"Upload Home Banner"}
    >
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <b>
            <i>Image Should be 1935* 873</i>
          </b>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <input
              type="file"
              className={`form-control ${
                error.bannerImage ? "invalid-field" : ""
              }`}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <select
              className={`form-control ${
                error.indexing ? "invalid-field" : ""
              }`}
              onChange={(e) => {
                seterror({
                  ...error,
                  bannerImage: e.target.value ? false : true,
                });
                setdata({ ...data, indexing: e.target.value });
              }}
              defaultValue={data.indexing}
            >
              <option value="">Select index</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <button
          className="btn btn-success upload-button"
          onClick={handleSubmit}
        >
          Upload
        </button>
      </div>
    </NormalModal>
  );
}
