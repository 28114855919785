import * as api from "./api";
export const getSalesReportByServiceType = (
  params,
  isDownload,
  collectionName
) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getSalesReportByServiceType/?${
          new URLSearchParams({
            ...params,
            download: isDownload,
            collectionName: collectionName,
          }).toString() || ""
        }`
      );
    } else {
      api
        .newPost(`/report/getSalesReportByServiceType/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};
export const getDriverReport = (params, isDownload, collectionName) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getDriverReport/?${
          new URLSearchParams({
            ...params,
            download: isDownload,
            collectionName: collectionName,
          }).toString() || ""
        }`
      );
    } else {
      api
        .newPost(`/report/getDriverReport/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};
export const getCreditCardAmountCollected = (params, isDownload) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getcreditCardAmountCollectedReport/?${
          new URLSearchParams({ ...params, download: isDownload }).toString() ||
          ""
        }`
      );
    } else {
      api
        .newPost(`/report/getcreditCardAmountCollectedReport/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};
export const getCashAmountCollected = (params, isDownload) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getcashAmountCollectedReport/?${
          new URLSearchParams({ ...params, download: isDownload }).toString() ||
          ""
        }`
      );
    } else {
      api
        .newPost(`/report/getcashAmountCollectedReport/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};

export const getDeliveryFeeCollected = (params, isDownload) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getdeliveryFeeReport/?${
          new URLSearchParams({ ...params, download: isDownload }).toString() ||
          ""
        }`
      );
    } else {
      api
        .newPost(`/report/getdeliveryFeeReport/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};

export const getSalesReportByServiceTypeArchive = (params, isDownload) => {
  return new Promise((resolve, reject) => {
    if (isDownload) {
      api.downloadFile(
        `report/getSalesReportByServiceTypeArchive/?${
          new URLSearchParams({ ...params, download: isDownload }).toString() ||
          ""
        }`
      );
    } else {
      api
        .newPost(`/report/getSalesReportByServiceTypeArchive/`, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err.response));
    }
  });
};
