import * as api from "./api";
/**
 * get all homebanner
 */
export const getHomeBanner = () => {
  try {
    return api.get("homeBanner/getHomeBanner");
  } catch (error) {
    return error;
  }
};

export const updateHomeBanner = (payload) => {
  try {
    return api.post("homeBanner/updateHomeBanner", payload);
  } catch (error) {
    return error;
  }
};
export const changeHomeBannerIndexing = (payload) => {
  try {
    return api.post("homeBanner/changeHomeBannerIndexing", payload);
  } catch (error) {
    return error;
  }
};
export const deleteHomeBanner = (payload) => {
  try {
    return api.deleteRec("homeBanner/deleteHomeBanner/" + payload);
  } catch (error) {
    return error;
  }
};
