import * as types from "../Action/types";

const initialState = {
  loading: false,
  error: {},
  manageProfileData: [],
  countrtyList: [],
  stateList: [],
  cityList: [],
  msg: "",
};
function manageProfileReducer(state = initialState, action) {
  const { response, payload, msg } = action;
  switch (action.type) {
    case types.MANAGE_PROFILE_FORM_GET:
      return { ...state, loading: true };
    case types.MANAGE_PROFILE_FORM_GET_SUCCESS:
      return { ...state, loading: false, manageProfileData: response };
    case types.MANAGE_PROFILE_FORM_GET_ERROR:
      return { ...state, loading: false, error: response };

    // country
    case types.GET_COUNTRY_LIST:
      return { ...state, loading: true };
    case types.GET_COUNTRY_SUCCESS:
      return { ...state, loading: false, countrtyList: payload, msg: msg };
    case types.GET_COUNTRY_ERROR:
      return { ...state, loading: false, error: payload, msg: msg };

  // state
  case types.GET_STATE_LIST:
    return { ...state, loading: true };
  case types.GET_STATE_SUCCESS:
    return { ...state, loading: false, stateList: payload, msg: msg };
  case types.GET_STATE_ERROR:
    return { ...state, loading: false, error: payload, msg: msg };
  
  // city
  case types.GET_CITY_LIST:
    return { ...state, loading: true };
  case types.GET_CITY_SUCCESS:
    return { ...state, loading: false, cityList: payload, msg: msg };
  case types.GET_CITY_ERROR:
    return { ...state, loading: false, error: payload, msg: msg };

    default:
      return state;
  }
}

export default manageProfileReducer;
