import { React, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import bill from "../../../../Assets/images/bill.png";
import BootstrapeTableData from "../../../../helpers/BootstrapeTableData";
import formettedDate from "../../../../helpers/moomentDate";
import { ComponentLoader } from "../../../../helpers/PageLoader";
import { getCustomerWallet } from "../../../../Services/walletServices";

import "./wallet.css";
export default function Wallet() {
  const customer = useSelector((state) => state.customer);
  const [isLoading, setisLoading] = useState({
    tableLoading: false,
  });
  const [wallet, setwallet] = useState({
    amount: 0,
    history: [],
  });

  const location = useSelector((state) => state.location);
  const arr = location.location
  const historyarr = wallet?.history
  // const found = arr.find(e => e?._id == historyarr[historyarr.length - 1]?.user);
  const found = arr.find(e => e?._id == localStorage.getItem("locationId"));
  // const loc = found?.addresses[0]?.street
  const loc = found?.addresses[0]?.street;

  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const columns = [
    {
      dataField: "order",
      text: "Order",
      formatter: (cell, row) => {
        return cell?.randomOrderId;
      },
      filterValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sortValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sort: true,
    },
    {
      dataField: "user",
      text: "Added By",
      formatter: () => {
        return historyarr.length > 0 ? loc : "N/A"
      },
      filterValue: () => {
        return historyarr.length > 0 ? loc : "N/A"
      },
      sortValue: () => {
        return historyarr.length > 0 ? loc : "N/A"
      },
      sort: true,
    },
    // {
    //   dataField: "user",
    //   text: "Added By",
    //   formatter: (cell, row) => {
    //     return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
    //   },
    //   filterValue: (cell, row) => {
    //     return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
    //   },
    //   sortValue: (cell, row) => {
    //     return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
    //   },
    //   sort: true,
    // },
    {
      dataField: "transactionAmount",
      text: "Transaction Amount",
      formatter: (cell, row) => {
        return row.transactionType == "credited" ? (
          <p className="green"> {"+$" + priceMature(cell)}</p>
        ) : (
          <p className="red">{"-$" + priceMature(cell)}</p>
        );
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sortValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sort: true,
    },

    {
      dataField: "transactionType",
      text: "Transaction Type",
      sort: true,
    },
    {
      dataField: "transactionDate",
      text: "Transaction Date",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
  ];
  useEffect(() => {
    getCustomerWallet().then((res) => {
      let result = res.result;
      if (result) {
        setwallet({
          amount: result.amount,
          history: [...result.history].reverse(),
        });
      }
    });
  }, [location]);
  return (
    <div className="main-content">
      <div className="page-title">
        <h2>Wallet</h2>
      </div>
      <div className="dashbaord-card">
        <Row>
          <Col md={12} key={2}>
            <div className="text-center">
              <div className=" ">
                <h2>Welcome {!localStorage.getItem("wallet") && customer.customer.fname}</h2>
                <h1>${!localStorage.getItem("wallet") ? priceMature(wallet.amount) : 0.00}</h1>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="mb-2">
        <div className="row">
          <div className="col-md-12">
            <div className="card customCard">
              <div className="card-title titlewithbtn pb-2">
                <h2>Recent Transactions</h2>
              </div>        
              <div className="card-body">
                <div className="m-10">
                  <BootstrapeTableData
                    data={wallet.history}
                    columns={!localStorage.getItem("wallet") ? columns : []}
                    keyfield={"_id"}
                    searchBar={true}
                    isLoading={isLoading.tableLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
