import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { ComponentLoader } from "../../../helpers/PageLoader";
import Slider from "react-slick";
import { getHomeBanner } from "../../../Action/homeBannerActions";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js"

const Banner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const homeBanner = useSelector((state) => state.homeBanner);
  const [data, setdata] = useState([]);
  useEffect(() => {
    setdata(homeBanner.homeBanner);
  }, [homeBanner]);
  useEffect(() => {
    dispatch(getHomeBanner());
  }, []);
  /*** these are settings for the items slider  */
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return location.pathname !== "/checkout" ? (
    <section className="homebanner homebanner2">
      <div className="containerr">
        {homeBanner.isloading === true ? (
          <ComponentLoader size={40} />
        ) : (
          <Slider {...settings}>
            {data?.map((item, i) => (
              <div className="banner-cara" key={item.indexing}>
                <div>
                  {item.bannerImage ? (
                    <img
                      src={changeImageUrl(item.bannerImage)}
                      alt="item"
                      className="img-fluid"
                      onError={(e) => {
                        e.target.onerror = null;
                        // e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                        e.target.src = `https://stagingapi-olivers.culinaryrobotics.io/images/35336e3d-1de7-4967-8523-f90154ddc26c-1685615513642.jpg`;
                      }}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                      alt="item"
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  ) : (
    ""
  );
};

export default Banner;
