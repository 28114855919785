import React, { useState, useSelector } from "react";
import { Tabs, Tab } from "react-bootstrap";
import HomeBanner from "./homeBanner/HomeBanner";
import ManageCoupon from "./manageCoupon/manageCoupon";
import ManageDeliveryCharges from "./manageDeliveryCharges/manageDeliveryCharges";
import ManageDiscount from "./manageDiscount/manageDiscount";
import ManageMinimumPurchase from "./manageMinimumPurchase/manageMinimumPurchase";
import ManageRestaurentTiming from "./manageRestoaurentTiming/manageRestaurentTiming";
import ManageTax from "./manageTax/manageTax";
import ManageStripe from "./manageStripe/manageStripe";
export default function Settings() {
  return (
    <>
      <Tabs defaultActiveKey="deliveryCharges" className="mb-3">
        <Tab eventKey="deliveryCharges" title="Delivery Charges">
          <ManageDeliveryCharges />
        </Tab>
        <Tab eventKey="restaurentTiming" title="Restaurant Timing">
          <ManageRestaurentTiming />
        </Tab>
        <Tab eventKey="discount" title="Discount">
          <ManageDiscount />
        </Tab>
        <Tab eventKey="coupon" title="Coupon">
          <ManageCoupon />
        </Tab>
        <Tab eventKey="tax" title="Tax">
          <ManageTax />
        </Tab>
        <Tab eventKey="minimumpurchase" title="Minimum Purchase">
          <ManageMinimumPurchase />
        </Tab>
        <Tab eventKey="homeBanner" title="Home Banner">
          <HomeBanner />
        </Tab>
        <Tab eventKey="stripe" title="Stripe Key">
          <ManageStripe />
        </Tab>
      </Tabs>
    </>
  );
}
