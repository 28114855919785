import * as types from "./types";

/***
 * this action will get home banner  list
 */
export const getHomeBanner = () => ({
  type: types.GET_HOME_BANNER,
});

/***
 * this action will update home banner  list
 */
export const updateHomeBanner = (payload) => ({
  type: types.UPDATE_HOME_BANNER,
  payload,
});

/***
 * this action will update home banner  list
 */
export const deleteHomeBanner = (payload) => ({
  type: types.DELETE_HOME_BANNER,
  payload,
});

/***
 * this action will update home banner  list
 */
export const changeHomeBannerIndex = (payload) => ({
  type: types.CHANGE_HOME_BANNER_INDEXING,
  payload,
});

/***
 * this action will update home banner  list
 */
export const changeHomeBannerFlag = (payload) => ({
  type: types.CHANGE_HOME_BANNER_FLAG,
  payload,
});
