import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageCrewOrDriver";
import { Store } from "react-notifications-component";
import { readOrderStatus } from "../Services/manageOrderServices";

// get crew or  driver listing
export function* getCrewOrDriverList() {
  const response = yield call(auth.getCrewOrDriverList);
  try {
    yield put({
      type: types.GET_Crew_OR_DRIVER_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_Crew_OR_DRIVER_ERROR,
      msg: response.message,
    });
  }
}
//get crew or driver order listing
export function* getCrewOrDriverOrderList(params) {
  const response = yield call(auth.getcrewOrderDriverOrderList, params.payload);
  try {
    yield put({
      type: types.GET_Crew_OR_DRIVER_ORDER_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_Crew_OR_DRIVER_ORDER_ERROR,
      msg: response.message,
    });
  }
}
//add crew or driver
export function* addCrewOrDriver(params) {
  const { payload } = params;
  try {
    const response = yield call(auth.addCrewOrDriver, payload);
    if (response.status === 200) {
      yield call(getCrewOrDriverList);
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (err) {
    Store.addNotification({
      message: "User already exists",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}
//update crew or driver
export function* updateCrewOrDriver(params) {
  const { payload } = params;
  const response = yield call(auth.updateCrewOrDriver, payload);
  try {
    if (response.status === 200) {
      yield call(getCrewOrDriverList);
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (err) {
    yield put({
      type: types.UPDATE_Crew_OR_DRIVER_ERROR,
      error: err,
    });
    Store.addNotification({
      message: response.message,
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}
//checkin and checkout the driver
export function* checkInOut(params) {
  const { payload } = params;
  try {
    const response = yield call(auth.checkInOut, payload);
    if (response.status === 200) {
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (err) {
    Store.addNotification({
      message: "???",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

// change read order status
export function* readCrewOrDriverOrderStatus(params) {
  const { payload } = params;
  const response = yield call(readOrderStatus, payload);
  try {
    if (response.status === 200) {
      yield put({
        type: types.READ_ORDER_STATUS_CREW_OR_DRIVER_SUCCESS,
        payload,
        msg: response.message,
      });
    }
  } catch (err) {
    yield put({
      type: types.READ_ORDER_STATUS_CREW_OR_DRIVER_FAILED,
      msg: response.message,
    });
  }
}

//update crew or driver otp
export function* updateCrewOrDriverOtp(params) {
  const { payload } = params;
  const response = yield call(auth.getGenerateOtp, payload);
  try {
    if (response.status === 200) {
      yield call(getCrewOrDriverList);
      Store.addNotification({
        message: "Otp Generate Successfully",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (err) {
    Store.addNotification({
      message: "Otp Generation failed",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}
