import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateManageProfileservice } from "../../../Services/authenticationServices";
import TimePicker from "react-time-picker";

import { getManageProfileForm } from "../../../Action/manageProfileAction";
import "./manageTiming.css";
import {
  getRestaurentTiming,
  updateRestaurentTiming,
} from "../../../Action/manageRestaurentTiming";
import { ComponentLoader } from "../../../helpers/PageLoader";
export default function ManageRestaurentTiming() {
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const { restaurentTiming, loading } = useSelector(
    (state) => state.restaurentTiming
  );
  const [onof, setonof] = useState(false);
  const [error, seterror] = useState([]);
  const [days, setdays] = useState([
    {
      name: "Monday",
      startTime: "",
      endTime: "",
    },
    {
      name: "Tuesday",
      startTime: "",
      endTime: "",
    },
    {
      name: "Thursday",
      startTime: "",
      endTime: "",
    },
    {
      name: "Friday",
      startTime: "",
      endTime: "",
    },
    {
      name: "Saturday",
      startTime: "",
      endTime: "",
    },
    {
      name: "Sunday",
      startTime: "",
      endTime: "",
    },
  ]);
  const dispatch = useDispatch();
  //mount on and off
  useEffect(() => {
    const { manageProfileData } = getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const { restorentStatus } = manageProfileData.result;
      setonof(restorentStatus);
    }
  }, [getManageProfile]);
  //mount days
  useEffect(() => {
    setdays(sort_days(restaurentTiming));
  }, [restaurentTiming]);
  //dispatch on and off and restuarant timing
  useEffect(() => {
    const userId = localStorage.getItem("userid");
    dispatch(getManageProfileForm({ userId: userId }));
    dispatch(getRestaurentTiming());
  }, [dispatch]);
  //handle on and off
  const SwitchOnOf = () => {
    updateManageProfileservice({
      restorentStatus: !onof,
      userId: localStorage.getItem("userid"),
    }).then((res) => {
      setonof(!onof);
    });
  };
  //handle change on restourant timing
  const handleChange = (type, value, index) => {
    let tmpData = [...days];
    try {
      var currentTime = tmpData[index];
      var startTimeObject = currentTime.startTime.split(":");
      var startTime =
        Number(startTimeObject[0]) * 60 + Number(startTimeObject[1]);
      var endTimeObject = currentTime.endTime.split(":");
      var endTime = Number(endTimeObject[0]) * 60 + Number(endTimeObject[1]);
      if (startTime < endTime) {
        let tmperror = [...error];
        var deleteableIndex = tmperror.indexOf(index);
        if (deleteableIndex > -1) {
          tmperror.splice(deleteableIndex, 1);
        }
        seterror(tmperror);
      } else {
        if (error.indexOf(index) === -1) {
          seterror([...error, index]);
        }
      }
    } catch (err) {
      if (error.indexOf(index) === -1) {
        seterror([...error, index]);
      }
    }
    tmpData[index][type] = value === "00:00" ? "24:00" : value;
    setdays(tmpData);
  };
  //handle submit
  const handleSubmit = () => {
    dispatch(updateRestaurentTiming(days));
  };
  //sort the days
  const sort_days = function (days) {
    var day_of_week = new Date().getDay();
    var list = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var sorted_list = list
      .slice(day_of_week)
      .concat(list.slice(0, day_of_week));
    return days.sort(function (a, b) {
      return sorted_list.indexOf(a.name) > sorted_list.indexOf(b.name);
    });
  };
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Restaurant Timing</h2>
                </div>
                <div className="card-body actioncard">
                  {loading ? (
                    <ComponentLoader />
                  ) : (
                    <Row>
                      <Col md={12}>
                        <ul className="list-group days-listing"></ul>
                      </Col>
                      <Col md={12}>
                        <ul className="list-group days-listing restaurent_time">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span>Restaurant ON /OFF</span>
                            <span className="on_off_rest">
                              <label className="switch" onChange={SwitchOnOf}>
                                <input type="checkbox" checked={onof} />
                                <span className="slider round"></span>
                              </label>
                            </span>
                          </li>
                          <hr />
                          <li className="list-group-item d-flex justify-content-between align-items-center g-head">
                            <span>Days</span>
                            <span>Start Time</span>
                            <span>End Time</span>
                          </li>
                          {days.map((item, index) => {
                            return (
                              <li
                                className={`time-setting list-group-item d-flex justify-content-between align-items-center ${
                                  error.indexOf(index) !== -1
                                    ? "invalid-field"
                                    : ""
                                }`}
                                key={index}
                              >
                                <span>{item.name}</span>
                                <span className="schedule_time">
                                  <TimePicker
                                    format="HH:mm"
                                    onChange={(e) => {
                                      handleChange("startTime", e, index);
                                    }}
                                    value={item.startTime}
                                    disableClock={true}
                                  />
                                </span>
                                <span>
                                  <TimePicker
                                    format="HH:mm"
                                    onChange={(e) => {
                                      handleChange("endTime", e, index);
                                    }}
                                    value={item.endTime}
                                    disableClock={true}
                                  />
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="card-footer f-left">
                  <div
                    className="checoutbtn"
                    style={{ width: "100px", float: "right" }}
                  >
                    <button
                      disabled={error.length > 0}
                      className="btn"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
