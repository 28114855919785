import * as types from "./types";
/***
 * this action will get crew and driver
 */
export const getCrewOrDriver = (data) => ({
  type: types.GET_Crew_OR_DRIVER,
  payload: data,
});
/***
 * this action will add crew and driver
 */
export const addCrewOrDriver = (data) => ({
  type: types.ADD_Crew_OR_DRIVER,
  payload: data,
});
/***
 * this action will update crew and driver
 */
export const updateCrewOrDriver = (data) => ({
  type: types.UPDATE_Crew_OR_DRIVER,
  payload: data,
});

/***
 * this action will get crew and driver
 */
export const getcrewOrDriverOrder = (data) => ({
  type: types.GET_Crew_OR_DRIVER_ORDER,
  payload: data,
});
/***
 * this action will check in and out the driver user
 */
export const driverCheckInOut = (data) => ({
  type: types.DRIVER_CHECK_IN_OUT,
  payload: data,
});

/***
 * read status of crew or driver`s order
 */

export const readCrewOrDriverOrderStatus = (data) => ({
  type: types.READ_ORDER_STATUS_CREW_OR_DRIVER,
  payload: data,
});

/***
 * read status of crew or driver`s order
 */

export const updateCrewOrDriverOtp = (data) => ({
  type: types.UPDATE_CREW_OR_DRIVER_OTP,
  payload: data,
});
