import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmModal = (props) => {
    const { show, handleCloseAlert, handleSuccessAlert } = props;
  return (
    <>
    <Modal show={show} onHide={handleCloseAlert}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Do you want to delete this item ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAlert}>
           No
        </Button> &nbsp;
        <Button variant="primary" onClick={handleSuccessAlert}>
           Yes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
};

export default ConfirmModal;
