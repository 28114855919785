import * as types from "./types";


// GET
export const getMenuItems = () => ({
    type: types.GET_MENU_ITEMS,
});

// GET ITEMS WITH VARIATIONS
export const getMenuItemsWithVariations = () => ({
    type: types.GET_MENU_ITEMS_VARIATIONS,
});

// POST
export const postMenuItems = (data) => ({
    type: types.POST_MENU_ITEMS,
    payload: data,
});

// DELETE
export const deleteMenuItems = (itemId) => ({
    type: types.DELETE_MENU_ITEMS,
    payload: itemId,
});

// UPDATE
export const updateMenuItemsList = (data) => ({
    type: types.UPDATE_MENU_ITEMS,
    payload: data,
});


// POST- add new Price Variations
export const postPriceVariationAction = (data) => ({
    type: types.POST_PRICE_VARIATIONS_ITEM,
    payload: data,
});

// UPDATE- Price Variations
export const updatePriceVariationAction = (data) => ({
    type: types.UPDATE_PRICE_VARIATIONS_ITEM,
    payload: data,
});
