import { call, put, all } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/authenticationServices";
import { Store } from "react-notifications-component";
/** get reStorent profile data  */
export function* getManageProfileData({ payload }) {
  try {
    const response = yield call(auth.getManageProfileservice, payload);
    yield all([
      put({
        type: types.SET_USER_INFORMATION,
        payload: { icon: response.result.icon },
      }),
      put({
        type: types.MANAGE_PROFILE_FORM_GET_SUCCESS,
        response,
      }),
    ]);
  } catch (error) {
    yield put({
      type: types.MANAGE_PROFILE_FORM_GET_ERROR,
      error,
    });
  }
}
/*** update restourant data  */
export function* updateManageProfileData({ payload }) {
  try {
    const response = yield call(auth.updateManageProfileservice2, payload);
    Store.addNotification({
      message: "Restaurant Profile updated",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getManageProfileData, { payload });
  } catch (error) {
    yield put({
      type: types.MANAGE_PROFILE_FORM_UPDATE_ERROR,
      error,
    });
  }
}
/*** update restourant data  */
export function* updateManageProfileDataTax({ payload }) {
  try {
    const response = yield call(auth.updateManageProfileserviceTax, payload);
    Store.addNotification({
      message: "Tax updated",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getManageProfileData);
  } catch (error) {
    yield put({
      type: types.MANAGE_PROFILE_FORM_UPDATE_ERROR,
      error,
    });
  }
}
/***Update stripe key */
export function* updateManageProfileStripeKey({ payload }) {
  try {
    const response = yield call(auth.updateManageProfileStripeKey, payload);
    Store.addNotification({
      message: "Stripe Key updated",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    //Stripe Key
    yield call(getManageProfileData);
  } catch (error) {
    yield put({
      type: types.MANAGE_PROFILE_FORM_UPDATE_ERROR,
      error,
    });
  }
}
/***Update stripe key */
/*** update restourant data  */
export function* updateManageProfileMinimumPurchase({ payload }) {
  try {
    const response = yield call(auth.updateManageProfileserviceTax, payload);
    Store.addNotification({
      message: "Minimum Purchase Price updated",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield call(getManageProfileData);
  } catch (error) {
    yield put({
      type: types.MANAGE_PROFILE_FORM_UPDATE_ERROR,
      error,
    });
  }
}
/** get country */
export function* getCountryData() {
  const response = yield call(auth.getCountryDataService);
  try {
    yield put({
      type: types.GET_COUNTRY_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_COUNTRY_ERROR,
      payload: err,
    });
  }
}
/** get states */
export function* getStateData(payload) {
  const response = yield call(auth.getStateDataService, payload);
  try {
    yield put({
      type: types.GET_STATE_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_STATE_ERROR,
      payload: err,
    });
  }
}
/*** get city  */
export function* getCityData({ payload }) {
  const response = yield call(auth.getCityDataService, payload);
  try {
    yield put({
      type: types.GET_CITY_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_CITY_ERROR,
      payload: err,
    });
  }
}
