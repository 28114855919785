import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  users: [],
  msg: "",
};
function manageUsers(state = initialState, action) {
  const { response, payload, msg } = action;
  switch (action.type) {
    case types.GET_USER:
      return { ...state, loading: true };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        users: payload.result || [],
        msg: msg,
      };
    case types.GET_USER_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}

export default manageUsers;
