import * as api from "./api";

/** this service will create all orders  */
export const createOrder = async (data) => {
  return await api.post("/order/createOrder", data);
};

/** this service get all orders  */
export const getOrderlist = async (collectionName) => {
  return await api.get(`/order/${collectionName.param}/`);
};
/** this service get all orders  */
export const getAllOrderList = async () => {
  return await api.get("/order/getOrderItem/");
};
/** this service get all orders archive */
export const getOrderlistArchive = async () => {
  return await api.get("/order/getOrderItemArchive/");
};
/** get all the details of an single order */
export const getOrderItemData = async (id) => {
  return await api.get("/order/getOrderItem/" + id);
};
/** get all the details of an single order */
export const getOrderItemDataArchive = async (id) => {
  return await api.get("/order/getOrderItemArchive/" + id);
};
/** get  single order */
export const getSingleOrder = async (id) => {
  return await api.get("/order/getOrderForCustomer/" + id);
};
/**  this service will use to change the order status */
export const changeOrderStatus = async ({
  id,
  status,
  userId,
  timing,
  collectionName,
}) => {
  return await api.put("/order/updateOrderItem/" + id, {
    OrderStatusCode: status,
    assignTo: userId,
    timing,
    collectionName,
  });
};

/*** get orfers of customers  you can password filter date here to get filterize result */
export const getCustomerOrder = async (params) => {
  return new Promise((resolve, reject) => {
    api
      .newPost("/order/getCustomerOrders/", params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err.response));
  });
};

/** this service change read order status  */
export const readOrderStatus = async (params) => {
  return await api.post("/order/readOrderStatus/", params);
};

/** this service will assign order to driver via otp  */
export const assignOrderToDriver = async (params) => {
  return await api.newPost("/order/assignOrderToDriver/", params);
};

/** this service will assign order to driver via otp  */
export const getQuickOrder = async (params) => {
  return await api.newPost("/order/getQuickOrder", params);
};

/** this service will assign order to driver via otp  */
export const getAllProductCategoryItemOnCustomerUI = async () => {
  return await api.newPost(
    "/productCategory/getAllProductCategoryItemOnCustomerUI"
  );
};

/** this service will use to get all the order data from order details table  */
export const getAllOrderDetails = async () => {
    return await api.get("/orderDetails/getOrderDetailsItem");
};