import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import padlockIcon from "../../../../Assets/images/padlock.svg";
import { changePassword } from "../../../../Action/authAction";
export default function ChangePassword() {
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [password, setpassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, seterrors] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
    isNew: false,
  });
  const [eye, seteye] = useState({
    currentPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  //handle change in form
  const handleChange = (e) => {
    setpassword({
      ...password,
      [e.target.name]: e.target.value,
    });
    let tmpError = { ...errors };
    if (e.target.name === "newPassword") {
      //password should have  6 length
      if (e.target.value.length < 6) {
        tmpError[e.target.name] = true;
      } else {
        tmpError[e.target.name] = false;
      }
      //should not same as current password
      if (e.target.value === password["currentPassword"]) {
        tmpError["isNew"] = true;
      } else {
        tmpError["isNew"] = false;
      }
      if (e.target.value !== password["confirmPassword"]) {
        tmpError["confirmPassword"] = true;
      } else {
        tmpError["confirmPassword"] = false;
      }
    } else if (e.target.name === "confirmPassword") {
      if (e.target.value !== password["newPassword"]) {
        tmpError["confirmPassword"] = true;
      } else {
        tmpError["confirmPassword"] = false;
      }
    } else if (e.target.value == "") {
      tmpError[e.target.name] = true;
    } else {
      tmpError[e.target.name] = false;
    }
    seterrors(tmpError);
  };
  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValidate = true;
    let tmpError = { ...errors };
    Object.keys(tmpError).map((key) => {
      if (key === "newPassword") {
        //should not same as current password
        if (password[key] === password["currentPassword"]) {
          tmpError["isNew"] = true;
          isValidate = false;
        } else {
          tmpError["isNew"] = false;
        }
        //password should have  6 length
        if (password[key].length < 6) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
        //password and confirm password should be same
        if (password[key] !== password["confirmPassword"]) {
          tmpError["confirmPassword"] = true;
          isValidate = false;
        } else {
          tmpError["confirmPassword"] = false;
        }
      } else if (key === "confirmPassword") {
        if (password[key] !== password["newPassword"]) {
          tmpError["confirmPassword"] = true;
          isValidate = false;
        } else {
          tmpError["confirmPassword"] = false;
        }
      } else if (password[key] == "") {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    });
    seterrors(tmpError);
    if (isValidate) dispatch(changePassword(password));
  };
  const handleEyeChange = (e) => {
    seteye({ ...eye, [e]: !eye[e] });
  };
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-12">
          <div className="loginform">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group has-search">
                    <label>Current Password</label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type={eye.currentPassword ? "password" : "text"}
                      className={`form-control ${
                        errors.currentPassword ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your current password"
                      name="currentPassword"
                      value={password.currentPassword}
                      onChange={handleChange}
                    />
                    <div
                      className="eyeicon"
                      onClick={() => handleEyeChange("currentPassword")}
                    >
                      <i
                        className={`fa fa-eye${
                          eye.currentPassword ? "" : "-slash"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group has-search">
                    <label>New Password</label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type={eye.newPassword ? "password" : "text"}
                      className={`form-control ${
                        errors.newPassword ? "invalid-field" : ""
                      } ${errors.isNew ? "invalid-field" : ""}`}
                      placeholder="Enter your new password"
                      name="newPassword"
                      value={password.newPassword}
                      onChange={handleChange}
                    />
                    <div
                      className="eyeicon"
                      onClick={() => handleEyeChange("newPassword")}
                    >
                      <i
                        className={`fa fa-eye${
                          eye.newPassword ? "" : "-slash"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    {errors.isNew ? (
                      <div className="invalid-feedback">
                        {" "}
                        New password should not same as current password
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group has-search">
                    <label>Confirm Password</label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type={eye.confirmPassword ? "password" : "text"}
                      className={`form-control ${
                        errors.confirmPassword ? "invalid-field" : ""
                      }`}
                      placeholder="Re-enter your password"
                      name="confirmPassword"
                      value={password.confirmPassword}
                      onChange={handleChange}
                    />
                    <div
                      className="eyeicon"
                      onClick={() => handleEyeChange("confirmPassword")}
                    >
                      <i
                        className={`fa fa-eye${
                          eye.confirmPassword ? "" : "-slash"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-right">
                  <div className="regBtn">
                    <button className="btn" disabled={customer.loading}>
                      {customer.loading ? "Submit.." : "Submit"}
                      <img src="images/loginicon.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
