import React, { useEffect, memo } from "react";
import { Modal, Row, Col } from "react-bootstrap";

const NormalModal = (props) => {
  const {
    showModal,
    closeModal,
    children,
    sizeModal,
    title,
    footer,
    classes,
    mainClass,
  } = props;

  useEffect(() => {
    if (showModal === "yes") {
      document.body.classList.add("modal-open");
      document.body.style.overflow = "hidden";
    } else {
      document.body.classList.remove("modal-open");
      document.body.style.overflow = null;
    }
  }, [showModal]);
  return (
    <>
      <Modal
        size={sizeModal}
        animation={true}
        backdrop={false}
        show={showModal === "yes" ? true : false}
        onHide={closeModal}
        dialogClassName="modal-dialog-centered"
        className={`shared-normal-model ${mainClass}`}
      >
        <Modal.Header
          className={`modal-header position-relative ${classes}`}
          closeButton
        >
          <Modal.Title id="example-modal-sizes-title-sm">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes}>{children}</Modal.Body>
        {footer ? footer : ""}
      </Modal>
      {showModal === "yes" ? (
        <div className="right-modal-backdrop_2"></div>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(NormalModal);
