import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import googleicon from "../assets/images/googleicon.svg";
import twitter from "../assets/images/twitter.svg";
import linkdin from "../assets/images/linkdin.svg";
import facebook from "../assets/images/facebook.svg";
import footerlogo from "../assets/images/footerlogo.svg";
import ApplicationDetail from "../helpers/ApplicationDetail";
import CopyRight from "./copyRight";
const FooterSec = (props) => {
  return (
    <footer className="footersection mt-5">
      <div className="container">
        <div className="row pb-5">
          <div className="col-md-5">
            <div className="footer-contact">
              <div className="footerlogo">
                <img src={footerlogo} alt="" />
                <div className="ml-2">
                  <h3>
                    <ApplicationDetail type="contactNumber" />
                  </h3>
                  <h5>
                    <ApplicationDetail type="address" />
                  </h5>
                </div>
              </div>
              <ul>
                <li>
                  <NavLink to="">
                    <img src={googleicon} alt="" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <img src={twitter} alt="" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <img src={linkdin} alt="" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <img src={facebook} alt="" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </footer>
  );
};

export default FooterSec;
