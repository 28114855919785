import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { REACT_APP_STRIPE_KEY } from "../../../Utils/config";
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { newPost, post } from "../../../Services/api";
import "./stripe.css";
import { Store } from "react-notifications-component";
import { FaCreditCard, FaWallet } from "react-icons/fa";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
const StripeForm = (props) => {
  const {
    stripe,
    elements,
    data,
    wallet,
    orderData,
    paymentSuccessfulPopup,
    isLoading,
    isComplete,
    isFailed,
  } = props;
  const [isDisabled, setisDisabled] = useState(true);
  const customer = useSelector((state) => state.customer);
  /** set the form state accourding to the props */
  const [formfield, setformfield] = useState({
    firstName: data.firstName ? data.firstName : "",
    lastName: data.lastName ? data.lastName : "",
    email: data.email ? data.email : "",
    contactNumber: data.contactNumber ? data.contactNumber : "",
  });
  const [deliveryerror, setdeliveryerror] = useState({
    firstName: false,
    lastName: false,
    contactNumber: false,
    email: false,
  });
  const [paidFromWallet, setpaidFromWallet] = useState(0);
  const [paymentType, setpaymentType] = useState("card");
  const [paywholefromwallet, setpaywholefromwallet] = useState(false);
  /** validate the phone number  */
  const contains = function (charValue) {
    //eslint-disable-next-line
    let pval = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return !pval.test(charValue);
  };
  /*** this will add  the mask in according to US number format **/
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /** validate a phone number  */
  const validatePhoneNumber = (value, previousValue) => {
    value = value.replace(/[()  -]/g, "");
    if (value.length < 10) {
      return false;
    }
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return false;
      if (cvLength < 7) return false;
      let firstPhase = currentValue.slice(0, 3);
      let secondPhase = currentValue.slice(3, 6);
      let thiredPhase = currentValue.slice(6, 10);
      if (
        firstPhase == "000" ||
        (firstPhase == secondPhase &&
          secondPhase == thiredPhase.slice(0, -1)) ||
        secondPhase + thiredPhase == "0000000"
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  /** this will handle the change of the form field  */
  const handleFormFieldChange = (e) => {
    setformfield({ ...formfield, [e.target.name]: e.target.value });
    var isValidate = true;
    var tmpError = { ...deliveryerror };
    if (e.target.name === "contactNumber") {
      setformfield({
        ...formfield,
        [e.target.name]: normalizeInput(e.target.value),
      });
    } else {
      setformfield({ ...formfield, [e.target.name]: e.target.value });
    }
    if (e.target.name === "contactNumber") {
      if (!validatePhoneNumber(e.target.value.toString())) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      //eslint-disable-next-line
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
      isValidate = false;
    } else {
      tmpError[e.target.name] = false;
    }
    setdeliveryerror(tmpError);
  };
  /**  this will handle  the submit  */
  const handleSubmit = async (e) => {
    e.preventDefault();
    var isValidate = true;
    var tmpError = { ...deliveryerror };
    for (var key in formfield) {
      if (key === "contactNumber") {
        if (!validatePhoneNumber(formfield[key].toString())) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      } else if (key === "email") {
        //eslint-disable-next-line
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formfield[key]
          )
        ) {
          tmpError[e.target.name] = true;
          isValidate = false;
        } else {
          tmpError[e.target.name] = false;
        }
      } else if (formfield[key] === "" || formfield[key] == undefined) {
        tmpError[key] = true;
        isValidate = false;
      } else {
        tmpError[key] = false;
      }
    }
    setdeliveryerror(tmpError);
    if (!isValidate) {
      return;
    }
    isLoading();
    let resultModify = { user: {} };
    (resultModify.user.address = {
      city: data.city ? data.city : "unknown",
      state: data.state ? data.state : "unknown",
      country: data.country ? data.country : "US",
      line1: data.address ? data.address : "unknown",
    }),
      (resultModify.user.name = formfield.firstName
        ? formfield.firstName + " " + formfield.firstName
        : "unknown name");
    resultModify.user.email = formfield.email
      ? formfield.email
      : `unknown${Date.now()}@gmail.com`;
    resultModify.totalGrossAmount = data.totalGrossAmount;
    /* we need to update order data here becuse user can update detail in payment */
    orderData.firstName = formfield.firstName
      ? formfield.firstName
      : "unknown first name";
    orderData.lastName = formfield.lastName
      ? formfield.lastName
      : "unknown last name ";
    orderData.email = formfield.email
      ? formfield.email
      : `unknown${Date.now()}@gmail.com`;
    (orderData.contactNumber = formfield.contactNumber
      ? formfield.contactNumber.replace(/[()  -]/g, "")
      : ""),
      (orderData.walletId = wallet?._id);

    orderData.paidWholeFromWallet = paidFromWallet == data.totalGrossAmount;
    orderData.totalPaidFromWallet = paidFromWallet;
    orderData.totalPaidFromCard = priceMature(
      data.totalGrossAmount - paidFromWallet
    );
    orderData.paymentType =
      paymentType == "wallet"
        ? orderData.paidWholeFromWallet
          ? "wallet"
          : "partial"
        : "card";
    resultModify.orderData = orderData;
    resultModify.paymentMethod = "coc";
    if (resultModify.orderData.paidWholeFromWallet) {
      isLoading();
      newPost("/payment/payfromwallet", resultModify)
        .then(function (res) {
          isComplete();
          paymentSuccessfulPopup(res.data.result.order.randomOrderId);
        })
        .catch(function (err) {
          //payment failed
          let errResponse = err.debugger;
          let message = "";
          if (errResponse?.err?.type) {
            message = errResponse?.err?.message;
          } else {
            message = "Payment Failed";
          }
          isFailed();
          Store.addNotification({
            message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    } else {
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      resultModify.token = result?.token?.id;
      if (result.error) {
        isFailed();
        Store.addNotification({
          message: result.error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true,
          },
        });
      } else {
        isLoading();
        newPost("/payment/createPayment", resultModify)
          .then(function (res) {
            isComplete();
            paymentSuccessfulPopup(res.data.result.order.randomOrderId);
          })
          .catch(function (err) {
            //payment failed
            let errResponse = err.response.data;
            let message = "";
            if (errResponse.err.type) {
              message = errResponse.err.message;
            } else {
              message = "Payment Failed";
            }
            isFailed();
            Store.addNotification({
              message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          });
      }
    }
  };
  /** this will handle on change event in in stipe */
  const handleCardChange = (e) => {
    if (e.complete) {
      if (e.error !== undefined) {
        setisDisabled(true);
      } else {
        setisDisabled(false);
      }
    } else {
      setisDisabled(true);
    }
  };
  /** normalize the input  */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /**
   *
   */
  const handlePaymentOptionClick = () => {
    setpaymentType((pre) => (paymentType == "card" ? "wallet" : "card"));

    if (paymentType == "card") {
      //handle wallet
      if (data.totalGrossAmount <= wallet.amount) {
        setpaidFromWallet(Number(priceMature(data.totalGrossAmount)));
      } else {
        setpaidFromWallet(Number(priceMature(wallet.amount)));
      }
    } else {
      setpaidFromWallet(0);
    }
  };
  useEffect(() => {
    setformfield({
      firstName: data.firstName ? data.firstName : "",
      lastName: data.lastName ? data.lastName : "",
      email: data.email ? data.email : "",
      // contactNumber: data.contactNumber ? data.contactNumber : "",
      contactNumber:  "",
    });
  }, [data]);
  useEffect(() => {
    if (paymentType == "wallet") {
      setpaymentType("card");
    }
  }, [data.totalGrossAmount]);
  // console.log(paymentType);
  return (
    <div className="sr-combo-inputs">
      <form onSubmit={handleSubmit}>
        <div className="sr-combo-inputs-row">
          <input
            type="text"
            id="name"
            name="firstName"
            placeholder="Name"
            autoComplete="cardholder"
            className={`sr-input form-control ${
              deliveryerror.firstName ? "invalid-field" : ""
            }`}
            value={formfield.firstName}
            onChange={handleFormFieldChange}
            disabled={customer.isCustomerLoggedIn}
          />
        </div>
        <div className="sr-combo-inputs-row">
          <input
            type="text"
            id="name"
            name="lastName"
            placeholder="Last Name"
            autoComplete="cardholder"
            className={`sr-input form-control ${
              deliveryerror.lastName ? "invalid-field" : ""
            }`}
            value={formfield.lastName}
            onChange={handleFormFieldChange}
            disabled={customer.isCustomerLoggedIn}
          />
        </div>
        <div className="sr-combo-inputs-row">
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            autoComplete="cardholder"
            className={`sr-input form-control ${
              deliveryerror.email ? "invalid-field" : ""
            }`}
            value={formfield.email}
            onChange={handleFormFieldChange}
            disabled={customer.isCustomerLoggedIn}
          />
        </div>
        <div className="sr-combo-inputs-row">
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            placeholder="Contact  Number"
            autoComplete="cardholder"
            className={`sr-input form-control ${
              deliveryerror.contactNumber ? "invalid-field" : ""
            }`}
            value={formfield.contactNumber}
            onChange={handleFormFieldChange}
            // disabled={customer.isCustomerLoggedIn}
          />
        </div>
        {priceMature(wallet?.amount) > 0 ? (
          <div className="mb-3 alert" role="alert">
            <div className="d-flex justify-content-around align-self-start">
              <span className="flex-fill">
                <FaWallet fontSize={35} />
              </span>
              <span className="flex-fill h5">Pay from wallet</span>
              <span>
                <input
                  key={123}
                  type="radio"
                  name="paymentType"
                  value="wallet"
                  checked={paymentType === "wallet"}
                  onClick={handlePaymentOptionClick}
                />
              </span>
            </div>
            <div className="TotalMain mt-3">
              <div>
                {" "}
                <h3>Total wallet Amount</h3>
              </div>
              <div>
                <p>${priceMature(wallet.amount)}</p>
              </div>
            </div>
            {paymentType === "wallet" ? (
              <div className="TotalMain mt-3">
                <div>
                  {" "}
                  <h3>Deduct from wallet</h3>
                </div>
                <div>
                  <p>${priceMature(paidFromWallet)}</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {paidFromWallet < data.totalGrossAmount &&
            paymentType === "wallet" ? (
              <div className="r-text">
                You need to pay more $
                {priceMature(data.totalGrossAmount - paidFromWallet)} from card
              </div>
            ) : (
              ""
            )}
            {paidFromWallet == Number(priceMature(data.totalGrossAmount)) &&
            paymentType === "wallet" ? (
              <div className="checoutbtn">
                <button className="btn">Place Order</button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <></>
        )}
        <hr />
        <div className="alert">
          <div className="sr-combo-inputs-row">
            <div className="d-flex justify-content-around align-self-center mb-4">
              <span className="flex-fill">
                <FaCreditCard fontSize={35} />
              </span>
              <span className="flex-fill h5">Pay from card</span>
              <span>
                <input
                  key={1234}
                  type="radio"
                  name="paymentType"
                  value="card"
                  checked={paymentType === "card"}
                  onClick={handlePaymentOptionClick}
                />
              </span>
            </div>
            {paidFromWallet != Number(priceMature(data.totalGrossAmount)) ? (
              <CardElement
                hidePostalCode={true}
                onChange={handleCardChange}
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    visibility: "hidden",
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            ) : (
              <></>
            )}
            {paidFromWallet != Number(priceMature(data.totalGrossAmount)) ? (
              <div className="checoutbtn">
                <button type="submit" className="btn" disabled={isDisabled}>
                  Pay ${priceMature(data.totalGrossAmount - paidFromWallet)}
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default function Stripe(props) {
  return (
    <Elements stripe={props.stripePromise}>
      <ElementsConsumer>
        {(ctx) => {
          return <StripeForm {...ctx} {...props} />;
        }}
      </ElementsConsumer>
    </Elements>
  );
}
