import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch } from "react-redux";
import "./managepayment.css";
import { refundPayment } from "../../Action/paymentAction";
const ManagePaymentPopup = (props) => {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data },
  } = props;
  var userData = {}
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  const [option, setoption] = useState({ f: true, p: false });
  const [amount, setamount] = useState(0);
  const [error, seterror] = useState(false);
  const activeOption = (op) => {
    if (op === "f") {
      setoption({
        f: true,
        p: false
      });
    }
    else if (op === "p") {
      setoption({
        f: false,
        p: true
      });
    }
  }
  const handleSubmit = (e) => {
    if (option.p) {
      if (amount < 1) {
        seterror(true);
        return;
      }
      else if (amount > data.amount) {
        seterror(true);
        return
      }
    }
    dispatch(refundPayment({ type: option.f ? "full" : "partially", paymentId: data._id, stripePaymentId: data.stripePaymentId, amount: amount }));
    setoption({ f: true, p: false });
    setamount(0);
    closeModaleWithClearAll();
  }
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={
            edit === true ? "Edit payment" : ""
          }
          edit={edit}
        >
          <div className="order-show-wrapper px-3 py-1">
            <form onSubmit={e => { e.preventDefault() }} >
              <div className="row">
                <div className="col-lg-12">
                  <div className="chooseOption container">
                    <div className="row">
                      <div className="col-6">
                        <div className={`select1 ${option.f ? 'active' : ''}`} onClick={() => activeOption("f")}>
                          {/* <img className="mr-2" src={deliveryPNG} alt='delivery' /> */}
                          <h3>Full Refund </h3>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={`select1 ${option.p ? 'active' : ''}`} onClick={() => activeOption("p")}>
                          {/* <img className="mr-2" src={takeover} alt='delivery' /> */}
                          <h3>Choose Amount</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container type-body">
                    {
                      option.f ? (
                        <div className="checoutbtn">
                          <button className="btn" onClick={handleSubmit}  >Refund</button>
                        </div>
                      ) :
                        (
                          <div className="">
                            <input type="number" min="0" step=".01" value={amount} className={`form-control ${error ? "invalid-field" : ""}`} onChange={(e) => { setamount(e.target.value); if (e.target.value < 1) { seterror(true); } else { seterror(false) } }} />
                            <div className="checoutbtn" >
                              <button className="btn" onClick={handleSubmit} >Refund</button>
                            </div>
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </RightSideModal>
      )}
    </>
  );
}

export default memo(ManagePaymentPopup);
