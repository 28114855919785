import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { useDispatch } from "react-redux";
import { ComponentLoader } from "../../helpers/PageLoader";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { Col, Row } from "react-bootstrap";
import formettedDate from "../../helpers/moomentDate";
import {
  getCustomerWalletById,
  createWallet,
  addCredit,
} from "../../Services/walletServices";
const ManageOrderRefund = (props) => {
  const dispatch = useDispatch();
  const { showModal, closeModal, data, permission } = props;
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  const [wallet, setwallet] = useState(null);
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const [amount, setamount] = useState(0);
  const [user, setuser] = useState({});
  const totalAmount = data.payments?.[0]?.amount || 0;
  const userId = data.users?.[0]?._id;
  const [isrunning, setisrunning] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount > max || amount <= 0) {
      seterror({ ...error, amount: true });
    } else {
      setisrunning(true);
      addCredit({
        walletId: wallet._id,
        amount,
        orderId: data._id,
      }).then(() => {
        setamount("");
        setisrunning(false);
        seterror({ ...error });
        getWallet();
      });
    }
  };
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  const [max, setmax] = useState(Number(priceMature(totalAmount || 0)));
  const handleChangeAmount = (e) => {
    setamount(e.target.value);
    seterror({
      ...error,
      amount: e.target.value > max || amount <= 0 ? true : false,
    });
  };
  const handleCreateWallet = () => {
    setloading(true);
    createWallet(data.users?.[0]?._id)
      .then((res) => {
        let refunded = 0;
        res.result?.history?.map((item) => {
          if (item?.order?._id == data._id)
            refunded += Number(item.transactionAmount);
        });
        setmax(totalAmount - refunded);
        setwallet({
          ...res.result,
          history: [...res.result.history].reverse(),
        });
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };
  const getWallet = (id) => {
    setloading(true);
    getCustomerWalletById(userId).then((res) => {
      let refunded = 0;
      if (res.result) {
        res.result?.history?.map((item) => {
          if (
            item?.order?._id == data._id &&
            item.transactionType == "credited"
          )
            refunded += Number(priceMature(item.transactionAmount));
        });
        setmax(Number(priceMature(totalAmount)) - refunded);
        setwallet({
          ...res.result,
          history: [...res.result.history].reverse(),
        });
      }
      setloading(false);
    });
  };
  useEffect(() => {
    if (userId) {
      getWallet();
    }
    setuser(data.users?.[0]);
  }, [data]);
  const columns = [
    {
      dataField: "order",
      text: "Order",
      formatter: (cell, row) => {
        return cell?.randomOrderId;
      },
      filterValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sortValue: (cell, row) => {
        return cell?.randomOrderId;
      },
      sort: true,
    },
    {
      dataField: "user",
      text: "Added By",
      formatter: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      filterValue: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      sortValue: (cell, row) => {
        return cell?.fname ? cell?.fname + " " + cell?.lname : "N/A";
      },
      sort: true,
    },
    {
      dataField: "transactionAmount",
      text: "Transaction Amount",
      formatter: (cell, row) => {
        return row.transactionType == "credited" ? (
          <p className="green"> {"+$" + priceMature(cell)}</p>
        ) : (
          <p className="red">{"-$" + priceMature(cell)}</p>
        );
      },
      filterValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sortValue: (cell, row) => {
        return "$" + priceMature(cell);
      },
      sort: true,
    },

    {
      dataField: "transactionType",
      text: "Transaction Type",
      sort: true,
    },
    {
      dataField: "transactionDate",
      text: "Transaction Date",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
  ];
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={user.fname + "`s Wallet"}
        >
          <div>
            {!loading ? (
              wallet ? (
                <>
                  <div className="dashbaord-card">
                    <Row>
                      <Col md={12} key={2}>
                        <div className="mt-3">
                          <ul className="list">
                            <li>
                              <div className="TotalMain">
                                <div>
                                  <h3>Order Id </h3>
                                </div>
                                <div>
                                  <p>{data.randomOrderId}</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="TotalMain">
                                <div>
                                  <h3>Total Order Amount</h3>
                                </div>
                                <div>
                                  <p>
                                    ${priceMature(data?.payments?.[0]?.amount)}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="TotalMain">
                                <div>
                                  <h3>Total Refund Amount</h3>
                                </div>
                                <div>
                                  <p>${priceMature(totalAmount - max)}</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md={12} key={2}>
                        <div className="text-center">
                          <div className="d-flex justify-content-between align-items-baseline m-3">
                            <h2>${priceMature(wallet.amount)}</h2>
                            <span>
                              {permission.update ? (
                                <form onSubmit={handleSubmit}>
                                  <div className="input-group mb-3">
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        error.amount ? "r-border" : ""
                                      }`}
                                      placeholder="$0.00"
                                      min="0"
                                      max={1000}
                                      step=".01"
                                      onChange={handleChangeAmount}
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-success"
                                        type="submit"
                                        disabled={isrunning}
                                      >
                                        {isrunning ? "Refunding.." : "Refund"}
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card customCard">
                          <div className="card-title titlewithbtn pb-2">
                            <h2>Recent Transactions</h2>
                          </div>
                          <div className="card-body">
                            <div className="m-10 modalordertablecustom">
                              <BootstrapeTableData
                                data={wallet.history}
                                columns={columns}
                                keyfield={"_id"}
                                searchBar={true}
                                isLoading={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p>{user.fname + " " + user.lname} doesn't have any wallet</p>
                  {permission.add ? (
                    <div className="checoutbtn">
                      <button className="btn" onClick={handleCreateWallet}>
                        Create new wallet
                      </button>
                    </div>
                  ) : (
                    <>Permission Denied</>
                  )}
                </div>
              )
            ) : (
              <ComponentLoader />
            )}
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageOrderRefund);
