import * as types from "./types";

/***
 * this action will get profile form
 */
export const getManageProfileForm = (data) => ({
  type: types.MANAGE_PROFILE_FORM_GET,
  payload: data,
});
/***
 * this action will get profile form  tax
 */
export const updateManageProfileFormTax = (data) => ({
  type: types.MANAGE_PROFILE_FORM_UPDATE_TAX,
  payload: data,
});
/***
 * this action will update stripe key
 */
export const updateManageProfileStripeKey = (data) => ({
  type: types.MANAGE_PROFILE_FORM_UPDATE_STRIPEKEY,
  payload: data,
});

/***
 * this action will get profile form  tax
 */
export const updateManageProfileMinimumPurchase = (data) => ({
  type: types.MANAGE_PROFILE_FORM_UPDATE_MINIMUMPURCHASE,
  payload: data,
});
/***
 * this action will update profile form
 */
export const updateManageProfileForm = (data) => ({
  type: types.MANAGE_PROFILE_FORM_UPDATE,
  payload: data,
});

/***
 * this action will get country list
 */
export const getCountryListAction = () => ({
  type: types.GET_COUNTRY_LIST,
});

/***
 * this action will get state list
 */
export const getStateListAction = (countryId) => ({
  type: types.GET_STATE_LIST,
  payload: countryId,
});

/***
 * this action will get city list
 */
export const getCityListAction = (stateId) => ({
  type: types.GET_CITY_LIST,
  payload: stateId,
});
