import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageMasterModifierServices";
import { Store } from "react-notifications-component";

//get 
export function* getMasterModifierListSaga() {
  try {
    const res = yield call(auth.getMasterModifierService);
    yield put({
      type: types.GET_MASTER_MODIFIER_LIST_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_MASTER_MODIFIER_LIST_ERROR,
      error: error,
    });
  }
}

// delete
export function* deleteMasterModifierListItemSaga({ payload }) {
  try {
    const res = yield call(auth.deleteMasterModifierListItemService, payload);
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getMasterModifierListSaga);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MASTER_MODIFIER_LIST_ERROR,
      error: error,
    });
  }
}

// Create
export function* createMasterModifierListItemSaga({ payload }) {
  try {
    const res = yield call(auth.createMasterModifierListItemService, payload);
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getMasterModifierListSaga);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MASTER_MODIFIER_LIST_ERROR,
      error: error,
    });
  }
}

// update
export function* updateMasterModifierListItemSaga({ payload }) {
  try {
    const res = yield call(auth.updateMasterModifierListItemService, payload);
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getMasterModifierListSaga);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_MASTER_MODIFIER_LIST_ERROR,
      error: error,
    });
  }
}
