import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ComponentLoader } from "../../../helpers/PageLoader";
import {
  getRestaurentTiming,
  updateRestaurentTiming,
} from "../../../Action/manageRestaurentTiming";
import ApplicationDetail from "../helpers/ApplicationDetail";
const RestorentClosed = ({ show, handleRestorentClosedModel }) => {
  const dispatch = useDispatch();
  const { restaurentTiming, loading } = useSelector(
    (state) => state.restaurentTiming
  );
  useEffect(() => {
    dispatch(getRestaurentTiming());
  }, []);
  /** convert the 24 hours time to the 12 hours and AM PM too */
  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time ? time : "00:00";
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      let tmp = +time[0] % 12;
      time[0] = tmp.toString().length === 1 ? `0${tmp}` : tmp || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  return (
    <>
      <div
        className={`modal custommodal custommodel1 ${show ? "showModel" : ""}`}
        id="checkoutPopUp"
        show={{ top: "15px !importent", left: "15px !importend" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header position-relative">
              <h4 className="modal-title"> Restaurant Order Timing </h4>
              <button
                onClick={handleRestorentClosedModel}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body  text-center closed-rest">
              <h1>We are closed now.</h1>

              <div className="row">
                <div className="col-sm-12 test-center">
                  <h2> MORE INFORMATION </h2>
                  <hr />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-6 text-center p-40">
                  <h3>Delivery/ Pickup </h3>
                  {loading ? (
                    <ComponentLoader />
                  ) : (
                    restaurentTiming.map((item, index) => {
                      return (
                        <li
                          className="dayslist-time list-group-item d-flex justify-content-between align-items-center"
                          key={index}
                        >
                          <span>{item.name}</span>
                          <span>{tConvert(item.startTime)}</span>
                          <span>{tConvert(item.endTime)}</span>
                        </li>
                      );
                    })
                  )}
                </div>

                <div className="col-sm-6">
                  <div className="resto-close-address">
                    <h3>Location</h3>
                    <div>
                      <ApplicationDetail type="address" /> <tr />
                      Phone : <ApplicationDetail type="contactNumber" /> <tr />
                      Email : <ApplicationDetail type="email" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RestorentClosed;
