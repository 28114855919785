import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  couponList: [],
  msg: "",
};
function manageDiscountReducer(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_COUPON:
      return { ...state, loading: true };
    case types.GET_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        couponList: payload || [],
        msg: msg,
      };
    default:
      return state;
  }
}
export default manageDiscountReducer;
