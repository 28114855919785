import * as types from "../Action/types";
var CryptoJS = require("crypto-js");

/***
 * Reducer for user login
 * ** */
const initialState = [];
const secret = "my-secret-sdfd213eerew21321";

function manageCartReducer(state = initialState, action) {
  const { type, payload } = action;
  var index = "";
  var tmpIndex = "";
  let stringData = null;
  let encryptedData = null;
  switch (action.type) {
    case types.CREATE_ITEM_TO_CART:
      let con = state.concat(payload);
      stringData = JSON.stringify(con);
      encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
      localStorage.setItem("cart", encryptedData);
      return con;
    case types.INCREMENT_ITEM_TO_CART:
      tmpIndex = [...state];
      index = tmpIndex.findIndex((v) => v.uniqueId === payload);
      if (index !== -1) {
        tmpIndex[index].quantity = tmpIndex[index].quantity + 1;
        stringData = JSON.stringify(tmpIndex);
        encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
        localStorage.setItem("cart", encryptedData);
        return tmpIndex;
      }
      break;
    case types.UPDATE_ITEM_TO_CART:
      tmpIndex = [...state];
      index = tmpIndex.findIndex((v) => v.uniqueId === payload.uniqueId);
      if (index !== -1) {
        tmpIndex[index] = payload;
        stringData = JSON.stringify(tmpIndex);
        encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
        localStorage.setItem("cart", encryptedData);
        return tmpIndex;
      }
      break;
    case types.DELETE_ITEM_TO_CART:
      tmpIndex = [...state];
      index = tmpIndex.findIndex((v) => v.uniqueId === payload);
      if (index !== -1) {
        tmpIndex[index] = payload;
        tmpIndex.splice(index, 1);
        stringData = JSON.stringify(tmpIndex);
        encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
        localStorage.setItem("cart", encryptedData);
        return tmpIndex;
      }
      break;
    case types.DECREMENT_ITEM_TO_CART:
      tmpIndex = [...state];
      index = tmpIndex.findIndex((v) => v.uniqueId === payload);
      if (index !== -1) {
        tmpIndex[index].quantity = tmpIndex[index].quantity - 1;
        stringData = JSON.stringify(tmpIndex);
        encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
        localStorage.setItem("cart", encryptedData);
        return tmpIndex;
      }
      break;
    case types.DELETE_MODIFIER_TO_CART:
      tmpIndex = [...state];
      index = tmpIndex.findIndex((v) => v.uniqueId === payload.uniqueId);
      if (index !== -1) {
        let filteredModifiered = tmpIndex[index].modifier.filter(
          (v) => v.modifierId !== payload.modifierId
        );
        tmpIndex[index].modifier = filteredModifiered;
        tmpIndex[index].itemPrice =
          tmpIndex[index].itemPrice - payload.modifierPrice;
        stringData = JSON.stringify(tmpIndex);
        encryptedData = CryptoJS.AES.encrypt(stringData, secret).toString();
        localStorage.setItem("cart", encryptedData);
        return tmpIndex;
      }
      break;
    case types.FLUSH_CART:
      localStorage.setItem("cart", []);
      return [];
    default:
      let bytes = localStorage.getItem("cart")
        ? CryptoJS.AES.decrypt(localStorage.getItem("cart"), secret)
        : "";
      return localStorage?.getItem("cart")
        ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        : [];
  }
}
export default manageCartReducer;

// function manageCartReducer(state = initialState, action) {
//   const { type, payload } = action;
//   var index = "";
//   var tmpIndex = "";
//   switch (action.type) {
//     case types.CREATE_ITEM_TO_CART:
//       let con = state.concat(payload);
//       localStorage.setItem("cart", JSON.stringify(con));
//       return con;
//     case types.INCREMENT_ITEM_TO_CART:
//       tmpIndex = [...state];
//       index = tmpIndex.findIndex((v) => v.uniqueId === payload);
//       if (index !== -1) {
//         tmpIndex[index].quantity = tmpIndex[index].quantity + 1;
//         localStorage.setItem("cart", JSON.stringify(tmpIndex));
//         return tmpIndex;
//       }
//       break;
//     case types.UPDATE_ITEM_TO_CART:
//       tmpIndex = [...state];
//       index = tmpIndex.findIndex((v) => v.uniqueId === payload.uniqueId);
//       if (index !== -1) {
//         tmpIndex[index] = payload;
//         localStorage.setItem("cart", JSON.stringify(tmpIndex));
//         return tmpIndex;
//       }
//       break;
//     case types.DELETE_ITEM_TO_CART:
//       tmpIndex = [...state];
//       index = tmpIndex.findIndex((v) => v.uniqueId === payload);
//       if (index !== -1) {
//         tmpIndex[index] = payload;
//         tmpIndex.splice(index, 1);
//         localStorage.setItem("cart", JSON.stringify(tmpIndex));
//         return tmpIndex;
//       }
//       break;
//     case types.DECREMENT_ITEM_TO_CART:
//       tmpIndex = [...state];
//       index = tmpIndex.findIndex((v) => v.uniqueId === payload);
//       if (index !== -1) {
//         tmpIndex[index].quantity = tmpIndex[index].quantity - 1;
//         localStorage.setItem("cart", JSON.stringify(tmpIndex));
//         return tmpIndex;
//       }
//       break;
//     case types.DELETE_MODIFIER_TO_CART:
//       tmpIndex = [...state];
//       index = tmpIndex.findIndex((v) => v.uniqueId === payload.uniqueId);
//       if (index !== -1) {
//         let filteredModifiered = tmpIndex[index].modifier.filter(
//           (v) => v.modifierId !== payload.modifierId
//         );
//         tmpIndex[index].modifier = filteredModifiered;
//         tmpIndex[index].itemPrice =
//           tmpIndex[index].itemPrice - payload.modifierPrice;
//         localStorage.setItem("cart", JSON.stringify(tmpIndex));
//         return tmpIndex;
//       }
//       break;
//     case types.FLUSH_CART:
//       localStorage.setItem("cart", []);
//       return [];
//     default:
//       return localStorage.getItem("cart")
//         ? JSON.parse(localStorage.getItem("cart"))
//         : [];
//   }
// }

// export default manageCartReducer;
