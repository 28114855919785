import { useDispatch, useSelector } from "react-redux";
import React, { forwardRef, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import NormalModal from "../../../helpers/NormalModal";
import envelopeIcon from "../../../Assets/images/envelope.svg";
import o_original_logo from "../../../Assets/images/o_original_logo.jpg";
import padlockIcon from "../../../Assets/images/padlock.svg";
import loginBg from "../../../Assets/images/Login-bg.svg";
import { loginCustomerAction, setUser } from "../../../Action/authAction";
export default function Login(props) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const { showModal, closeModal, closeRegistrationModel } = props;
  const [user, setuser] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  //handle change of customer
  const handleChange = (e) => {
    setErrors({ ...errors, [e.target.name]: e.target.value ? false : true });
    setuser({ ...user, [e.target.name]: e.target.value });
  };
  //close model and clear the state
  const closeModelClearAll = () => {
    closeModal();
    setUser({
      email: "",
      password: "",
    });
  };
  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginCustomerAction({ ...user }));
  };
  if (customer.isCustomerLoggedIn) {
    closeModelClearAll();
  }
  return (
    <NormalModal
      showModal={showModal}
      closeModal={closeModal}
      sizeModal={"50"}
      title=""
    >
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="loginform">
            {/* <div className="loginlogo">
                            <img
                                src={o_original_logo}
                                className="img-fluid"
                                alt="o_original_logo"
                            />
                        </div> */}
            <div className="logintitle">
              <h2>Login</h2>
              {/* <span>Please Login to Continue</span> */}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 ">
                  <div className="form-group has-search">
                    <label>Email </label>
                    <span className="form-control-feedback">
                      <img className="login-email" src={envelopeIcon} alt="" />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your email"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group has-search">
                    <label>Password </label>
                    <span className="form-control-feedback">
                      <img src={padlockIcon} alt="" />
                    </span>
                    <input
                      type="password"
                      className={`form-control ${
                        errors.password ? "invalid-field" : ""
                      }`}
                      placeholder="Enter your password"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3 d-flex justify-content-around align-self-start">
                <div className="regBtn">
                  <button type="submit" className="btn">
                    Login
                  </button>
                </div>
                <div className="regBtn">
                  <Link to="/forgot-password">
                    <button className="btn">Forgot Password </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </NormalModal>
  );
}
