import React from "react";
import Slider from "react-slick";
import { changeImageUrl } from "../../../helpers/replacehost.js"

const TestimonialCarousal = () => {
  const settings = {
    centerMode: true,
    centerPadding: "40px",
    dots: true,
    slidesToShow: 3,
    infinite: true,
    arrows: false,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const dataTestimonial = [1, 2, 3, 4, 5, 6, 7, 8].map((index) => ({
    imgUri: "https://www.w3schools.com/howto/img_avatar.png",
    title: "Will Smith",
    desc: "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy text, this is simply copy that serves to fill a space without actually saying anything meaningful...",
  }));
  return (
    <>
      <section className="feeback-section">
        <div className="container">
          <div className="section-title pt-4 pb-3">
            <h3>Happy Client Say?</h3>
          </div>
        </div>
        <div className="testimonial-reel">
          <Slider {...settings}>
            {dataTestimonial.map((item, i) => (
              <div className="box" key={i}>
                <figure className="image">
                  <img
                    className="img-fluid rounded-circle"
                    src={changeImageUrl(item.imgUri)}
                    alt=""
                  />
                </figure>
                <div className="test-component">
                  <article className="test-title">
                    <h4>{item.title}</h4>
                  </article>
                  <article className="test-content">
                    <p>{item.desc}</p>
                  </article>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div></div>
      </section>
    </>
  );
};

export default TestimonialCarousal;
