import React from "react";
import ReactDOM from "react-dom";
import App from "./Container/App";
import { Provider } from "react-redux";

import store from "./Store/configureStore";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Assets/style/style.css";
import "./Assets/style/responsive.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-notifications-component/dist/theme.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
