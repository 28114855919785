import React, { useState, Fragment } from "react";
import loginBg from "../../Assets/images/Login-bg.svg";
import o_original_logo from "../../Assets/images/o_original_logo.jpg";
import { fireForgotMail } from "../../Services/authenticationServices";
import { Store } from "react-notifications-component";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setemail] = useState("");
  const [error, seterror] = useState({
    isEmpty: false,
    isloading: false,
  });
  /**
   *
   * @param {object of elemenmt } e
   * handle change  of form
   */
  const handleChange = (e) => {
    setemail(e.target.value);
    seterror({ ...error, ["isEmpty"]: e.target.value === "" ? true : false });
  };
  /**
   *
   * @param {object of element} e
   * handle submit of form
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      seterror({
        ...error,
        ["isEmpty"]: true,
      });
    } else {
      seterror({
        ...error,
        ["isloading"]: true,
      });
      fireForgotMail({
        email,
      })
        .then((res) => {
          seterror({
            ...error,
            ["isloading"]: false,
          });
          Store.addNotification({
            message: res.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        })
        .catch((err) => {
          seterror({
            ...error,
            ["isloading"]: false,
          });
          Store.addNotification({
            message: err.message || "email not found",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        });
    }
  };
  return (
    <section
      className="loginSec"
      style={{ backgroundImage: `url(${loginBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="loginBox">
              <div className="loginform">
                <div className="loginlogo">
                  <img
                    src={o_original_logo}
                    className="img-fluid"
                    alt="o_original_logo"
                  />
                </div>
                <div className="logintitle">
                  <h2>Forgot Password</h2>
                  <span>Please enter your registered email </span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name=""
                          id=""
                          className={`form-control ${
                            error.isEmpty ? "invalid-field" : ""
                          }`}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row forgot-btn-grp">
                    <div className="col-sm-6 text-center">
                      <div className="regBtn">
                        <button className="btn" disabled={error.isloading}>
                          {error.isloading ? "Submit..." : "Submit"}{" "}
                          <img src="images/loginicon.svg" alt="" />
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6 text-center">
                      <div className="regBtn">
                        <Link to="/" className="forglink">
                          <button className="btn"> Home</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
