import React, { useState, useEffect } from "react";
import {
  getManageProfileForm,
  updateManageProfileMinimumPurchase,
} from "../../../Action/manageProfileAction";
import { useDispatch, useSelector } from "react-redux";
export default function ManageMinimumPurchase() {
  const dispatch = useDispatch();
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [minimumPurchase, setminimumPurchase] = useState(0);
  const userId = localStorage.getItem("userid");
  const locationId = localStorage.getItem("locationId");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (minimumPurchase) {
      locationId
        ? dispatch(
            updateManageProfileMinimumPurchase({
              userId: locationId,
              data: { minimumPurchase },
            })
          )
        : dispatch(
            updateManageProfileMinimumPurchase({
              userId: localStorage.getItem("userid"),
              data: { minimumPurchase },
            })
          );
    }
  };
  const handleChange = (e) => {
    setminimumPurchase(e.target.value);
  };
  useEffect(() => {
    dispatch(getManageProfileForm({ userId: userId }));
  }, []);
  useEffect(() => {
    const { manageProfileData } = getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const { minimumPurchase } = manageProfileData.result;
      setminimumPurchase(minimumPurchase);
    }
  }, [getManageProfile]);
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Minimum Purchase</h2>
                </div>
                <div className="card-body actioncard">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            value={minimumPurchase}
                            name="minimumPurchase"
                            min="0"
                            step=".01"
                            onChange={handleChange}
                          />
                          {!minimumPurchase && (
                            <p className="error-validation">
                              this field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="regBtn">
                          <button className="btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
