import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import AddMasterModifierModal from "./AddMasterModifierModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import {
  getMasterModifierListAction,
  deleteMasterModifierListItemAction,
} from "../../Action/manageMasterModifierAction";
import { getModifierCategoryListAction } from "../../Action/manageModifierCategoryAction";
import ConfirmModal from "../../helpers/ConfirmModal";
import { PageLoader, ComponentLoader } from '../../helpers/PageLoader';

const ManageMasterModifier = () => {
  const dispatch = useDispatch();
  const responseData = useSelector((state) => state.masterModi);
  const [showModal, setShowModal] = useState("no");
  const [litsData, setLitsData] = useState([]);
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false
  });
  const [deleteItemConfirm, setDeleteItemConfirm] = useState({
    show: false,
    data: {},
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  /** this function will handle the the add or update flag and open the popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  const columns = [
    {
      dataField: "ModifiersCategory",
      text: "Modifier Category Name",
      sort: true,
      formatter: (cell) => cell.MadifierCategoryName,
    },
    {
      dataField: "MadifierName",
      text: "Modifier Name",
      sort: true,
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => updateItem(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];

  // mount
  useEffect(() => {
    dispatch(getMasterModifierListAction());
    dispatch(getModifierCategoryListAction());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { masterModifierList, loading, tableLoading } = responseData;
    if (masterModifierList) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading
      })
      setLitsData(masterModifierList.reverse());
    }
  }, [responseData]);

  /** this function will update the form */
  const updateItem = (data, action) => {
    switch (action) {
      case "delete":
        setDeleteItemConfirm({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;

      default:
        break;
    }
  };
  /** this function handle delete close  alert  */
  const handleCloseAlert = () => {
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  /** this function handle delete success  alert  */
  const handleSuccessAlert = () => {
    dispatch(
      deleteMasterModifierListItemAction({ itemId: deleteItemConfirm.data._id })
    );
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  /** show the loading if loading is true in the page */
  if (isLoding.pageLoding === true) {
    return <PageLoader size={50} />
  }

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Master Modifier</h2>
        </div>

        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Master Modifiers</h2>
                  <button className="btn custombtn" onClick={addNewModal}>
                    Add Modifier
                  </button>
                </div>
                <div className="card-body actioncard">
                  {
                    (isLoding.pageLoding === false)
                      ? (<BootstrapeTableData
                        data={litsData}
                        columns={columns}
                        keyfield={"_id"}
                        searchBar={true}
                        isLoading={isLoding.tableLoading}
                      />)
                      : <ComponentLoader size={40} />
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <AddMasterModifierModal
          showModal={showModal}
          closeModal={addNewModal}
          editData={editData}
        />
      )}

      {deleteItemConfirm && (
        <ConfirmModal
          show={deleteItemConfirm.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageMasterModifier;
