import { call, put } from "redux-saga/effects";
import * as auth from "../Services/managePropductCategoryServices";
import * as types from "../Action/types";
import { Store } from "react-notifications-component";

//get item category 
export function* getProductCategoryList() {
  let res = yield call(auth.getProductCategoryService);
  try {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}

// get Public 
export function* getProductCategoryPublicList() {
  let res = yield call(auth.getProductCategoryPublicService);
  try {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}
/** add product category  */
export function* postProductCategoryList({ payload }) {
  let res = yield call(auth.postProductCategoryService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getProductCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}
/*** delete product category  */
export function* deleteProductCategoryList({ payload }) {
  let res = yield call(auth.deleteProductCategoryService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getProductCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
    else {
      Store.addNotification({
        message: 'failed to delete the category',
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}
/*** update product category */
export function* updateProductCategoryList({ payload }) {
  let res = yield call(auth.updateProductCategoryService, payload);
  try {
    if (res.status === 200 && res.resultCode === 0) {
      yield call(getProductCategoryList);
      Store.addNotification({
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}

//get product category 
export function* getIndividualProductCategoryList() {
  try {
    let res = yield call(auth.getIndividualProductCategoryService);
    yield put({
      type: types.GET_INDIVIDUAL_CATEGORY_LIST_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}

// get Product item list by id
export function* getProductCategoryItemListByIdSaga({ payload }) {
  try {
    const res = yield call(auth.getProductCategoryItemListByIdService, payload);
    yield put({
      type: types.GET_PRODUCT_ITEM_LIST_BYID_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}


// get modifer item list by id
export function* getCustomizeItemVariationListByIdSaga({ payload }) {
  try {
    const res = yield call(auth.getCustomizeVariationItemListByIdService, payload);
    yield put({
      type: types.GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}


// get modifer item list by id
export function* getCustomizeItemModifierListByIdSaga({ payload }) {
  try {
    const res = yield call(auth.getCustomizeModifierItemListByIdService, payload);
    yield put({
      type: types.GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID_SUCCESS,
      payload: res.result,
      msg: res.message,
    });
  } catch (error) {
    yield put({
      type: types.GET_PRODUCT_CATEGORY_ERROR,
      error: error,
    });
  }
}

