import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  orderList: [],
  msg: "",
  orderDetails: null
};

function manageOrderReducer(state = initialState, action) {
  const { payload, msg } = action;
  let tmpList = {};
  let index = "";
  switch (action.type) {
    case types.GET_ORDER_LIST:
      return { ...state, loading: true };
    case types.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        orderList: payload || [],
        msg: msg,
      };
    case types.GET_MANAGE_MODIFIER_LIST_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    case types.CHANGE_ORDER_STATUS:
      return { ...state, loading: false, tableLoading: true };
    case types.READ_ORDER_STATUS_SUCCESS:
      tmpList = { ...state };
      let tmpIndex = tmpList.orderList.findIndex(
        (e) => e._id === payload.orderId
      );
      if (tmpIndex !== -1) {
        tmpList.orderList[tmpIndex].isRead = payload.readOrderStatus;
      }
      tmpList.orderList.reverse();
      return { ...tmpList, loading: false, tableLoading: true };
    case types.PUSH_ORDER_TO_LIST:
      tmpList = { ...state };
      tmpList.orderList.push(payload);
      return { ...tmpList, loading: false, tableLoading: true };
    case types.UPDATE_ORDER_TO_LIST:
      tmpList = { ...state };
      index = tmpList.orderList.findIndex((e) => (e._id = payload._id));
      if (index !== -1) {
        tmpList.orderList[index].OrderStatusCode = payload.OrderStatusCode;
      }
      return { ...tmpList, loading: false, tableLoading: true };

     // reducer to get all orders from alldetails table
     case types.GET_ORDER_DETAILS: 
       return {
        ...state,
        orderDetails: payload
       }
    default:
      return state;
  }
}

export default manageOrderReducer;
