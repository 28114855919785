import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageModifierService";
import { Store } from 'react-notifications-component';

// get
export function* getManageModifierList() {
   const response = yield call(auth.getManageModifierSrvice);
   try {
      yield put({
        type: types.GET_MANAGE_MODIFIER_LIST_SUCCESS,
        payload: response.result,
        msg: response.message
      })
   } catch(err) {
    yield put({
        type: types.GET_MANAGE_MODIFIER_LIST_ERROR,
        msg: response.message
      })
   }
}

// delete
export function* deleteManageModifierList(payload) {
   const { itemId } = payload.payload
   const response = yield call(auth.deleteManageModifierSrvice, itemId);
   if(response.status === 200 &  response.resultCode === 0) {
      yield call(getManageModifierList); 
      Store.addNotification({    
         message: "Item Deleted Successfully!",
         type: "success",
         insert: "top",
         container: "top-center",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true
         }
       }); 
   }
  
}

// update 
export function* updateManageModifierList({payload}) {
   const response = yield call(auth.updateManageModifierSrvice, payload);  
   try{
      if(response.status === 200 &  response.resultCode === 0) {
         yield call(getManageModifierList); 
         Store.addNotification({    
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          }); 
      }    
   } catch(err) {
      yield put({
         type: types.GET_MANAGE_MODIFIER_LIST_ERROR,
         msg: response.message
       })
   }
}

// create 
export function* createManageModifierList({payload}) { 
   const response = yield call(auth.createManageModifierSrvice, payload);
   try{
      if(response.status === 200 &  response.resultCode === 0) {
         yield call(getManageModifierList); 
         Store.addNotification({    
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          }); 
      }    
   } catch(err) {
      yield put({
         type: types.GET_MANAGE_MODIFIER_LIST_ERROR,
         msg: response.message
       })
   }
   
}




// get modifier list
export function* getModifierManageModifierList() {
   const response = yield call(auth.getModifierManageModifierListSrvice);
   try {
      yield put({
        type: types.MODIFIER_TYPE_MANAGE_MODIFIER_LIST_SUCCESS,
        payload: response.result,
        msg: response.message
      })
   } catch(err) {
    yield put({
        type: types.GET_MANAGE_MODIFIER_LIST_ERROR,
        msg: response.message
      })
   }
}

// get Varitions list
export function* getVariationsManageModifierList() {
   const response = yield call(auth.getVariationsManageModifierListSrvice);   
   try {
      yield put({
        type: types.VARIATIONS_TYPE_MANAGE_MODIFIER_LIST_SUCCESS,
        payload: response.result,
        msg: response.message
      })
   } catch(err) {
    yield put({
        type: types.GET_MANAGE_MODIFIER_LIST_ERROR,
        msg: response.message
      })
   }
}


