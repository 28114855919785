import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiscount, deleteDiscount } from "../../../Action/manageDiscount";
import ConfirmModal from "../../../helpers/ConfirmModal";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ManageDiscountPopup from "./manageDiscountPopup";
import { getManageProfileForm } from "../../../Action/manageProfileAction";
import { updateDiscountStatus } from "../../../Services/authenticationServices";

export default function ManageDiscount() {
  const [onof, setonof] = useState(false);
  const dispatch = useDispatch();
  const discount = useSelector((state) => state.discount);
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const [showModal, setShowModal] = useState("no");
  const [litsData, setLitsData] = useState([]);
  const [isLoding, setIsLoding] = useState({
    pageLoding: false,
    tableLoading: false,
  });
  const [deleteItemConfirm, setDeleteItemConfirm] = useState({
    show: false,
    data: {},
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  //open the close model for add and update
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  //update
  const updateItem = (data, action) => {
    switch (action) {
      case "delete":
        setDeleteItemConfirm({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;

      default:
        break;
    }
  };
  const columns = [
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row) => {
        return `$${cell}`;
      },
      filterValue: (cell, row) => {
        return `$${cell}`;
      },
      sort: true,
    },
    {
      dataField: "percentage",
      text: "Percentage",
      formatter: (cell, row) => {
        return cell + "%";
      },
      filterValue: (cell, row) => {
        return cell + "%";
      },
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => updateItem(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => updateItem(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  const SwitchOnOf = () => {
    updateDiscountStatus({
      discountStatus: !(onof || false),
      userId: localStorage.getItem("userid"),
    }).then((res) => {
      setonof(!onof);
    });
  };
  //handle delete close model
  const handleCloseAlert = () => {
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  //handle delete model success
  const handleSuccessAlert = () => {
    dispatch(deleteDiscount({ id: deleteItemConfirm.data._id }));
    setDeleteItemConfirm({
      show: false,
      data: {},
    });
  };
  useEffect(() => {
    const userId = localStorage.getItem("userid");
    dispatch(getDiscount());
    dispatch(getManageProfileForm({ userId: userId }));
  }, []);
  useEffect(() => {
    const { discountList, loading, tableLoading } = discount;
    if (discountList) {
      setIsLoding({
        pageLoding: loading,
        tableLoading: tableLoading,
      });
      setLitsData(discountList.reverse());
    }
  }, [discount]);
  //mount on and off
  useEffect(() => {
    const { manageProfileData } = getManageProfile;
    if (
      manageProfileData.status === 200 &&
      manageProfileData.resultCode === 0
    ) {
      const { discountStatus } = manageProfileData.result;
      setonof(discountStatus);
    }
  }, [getManageProfile]);
  return (
    <>
      <div className="main-content">
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Discount</h2>
                  <div className="row">
                    <div className="col-sm-6">
                      <button className="btn custombtn" onClick={addNewModal}>
                        Add
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <label className="switch" onChange={SwitchOnOf}>
                        <input type="checkbox" checked={onof} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body actioncard">
                  {isLoding.pageLoding === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ManageDiscountPopup
          showModal={showModal}
          closeModal={addNewModal}
          editData={editData}
        />
      )}

      {deleteItemConfirm && (
        <ConfirmModal
          show={deleteItemConfirm.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
}
