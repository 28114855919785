import React, { useEffect } from "react";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  decrementItemToCart,
  incrementItemToCart,
  deleteItemToCart,
  deleteModifierToCart,
} from "../../../Action/cartAction";
import { checkRestorentStatus } from "../../../Services/authenticationServices";
import feathershoppingcart from "../assets/images/feather-shopping-cart.svg";
import emptyimg from "../assets/images/empty-cart.png";
const CostumCartModalPopUp = ({
  onHideModal,
  customizeModal,
  handleDeliveryAndPickupModel,
  handleRestorentClosedModel,
}) => {
  const dispatch = useDispatch();
  /** this function will handle the increment of  the item */
  const handleIncrement = (id) => {
    dispatch(incrementItemToCart(id));
  };

  /** this function will handle the decrement of  the item */
  const handledecrement = (id) => {
    dispatch(decrementItemToCart(id));
  };
  /** this function will handle delete  the item */
  const handleDeleteItemFromCart = (id) => {
    dispatch(deleteItemToCart(id));
  };
  /** this function will handle the values and filerize them*/
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /** this function will delete the modifier from item in cart */
  const handleDeleteModifier = (uniqueId, modifierId, modifierPrice) => {
    dispatch(deleteModifierToCart({ uniqueId, modifierId, modifierPrice }));
  };
  /** this function will handle click of checkout button */
  const handleCheckOut = () => {
    checkRestorentStatus()
      .then((res) => {
        if (res.result.status) {
          handleDeliveryAndPickupModel();
        } else {
          handleRestorentClosedModel();
        }
      })
      .catch((error) => {});
  };
  const cart = useSelector((state) => state.cart);
  var total = 0;
  var total_product = 0;
  /** count the total products in cart  */
  cart.map((cartItem, pindex) => {
    total_product = total_product + cartItem?.quantity;
  });

  useEffect(() => {
    console.log(cart, "---------- cart items");
  }, [cart]);

  return (
    <>
      <div className="cart-section">
        <div className="cart-header">
          <div className="row">
            <div className="col-6 col-md-6 text-center py-3">
              <span>Your Orders Details</span>
            </div>
            <div className="col-6 col-md-6 right">
              <span className="cart-added-item">
                <span className="added-count"> {total_product} </span>
                <img src={feathershoppingcart} alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="cart-inner">
          {cart.length < 1 ? (
            <>
              <div className="text__wrpr">
                <div className="empty-cart">
                  <img src={emptyimg} />
                </div>
                <h3 className="text__1">Your cart is empty.</h3>
                <span className="text__2">
                  Please add some items from the menu.
                </span>
              </div>
            </>
          ) : (
            cart?.map((cartItem, pindex) => {
              total = total + cartItem?.itemPrice * cartItem?.quantity;
              return (
                <div className="added-addcard" key={pindex}>
                  <div className="addpor">
                    <h2>
                      <div>
                        {cartItem?.itemName} | {cartItem?.variationName}
                      </div>
                      <div className="d-flex">
                        {/* <FaRegEdit
                          className="edit-svg"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            // To store old cart data before changing
                            localStorage.setItem("oldItem", JSON.stringify(cartItem))
                            customizeModal(
                              cartItem?.itemId,
                              cartItem?.itemName,
                              true,
                              cartItem?.uniqueId,
                              cartItem?.halfTopping,
                              cartItem?.itemImage
                            )
                          }}
                        /> */}
                        <FaTrashAlt
                          className="delet-svg"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            handleDeleteItemFromCart(cartItem?.uniqueId);
                          }}
                        />
                      </div>
                    </h2>
                    <div className="custm-added">
                      <ul>
                        {cartItem?.modifier?.map((modifier, index) => {
                          return (
                            <li key={index}>
                              {modifier?.isDeleted ? (
                                <span>
                                  No {modifier.modifierName}{" "}
                                  {modifier.topping
                                    ? `( ${modifier.topping.toUpperCase()} )`
                                    : ""}
                                </span>
                              ) : (
                                <span>
                                  {modifier.isDefault ? (
                                    <span> &nbsp;&nbsp;</span>
                                  ) : (
                                    <span>
                                      <FaTrashAlt
                                        onClick={() => {
                                          handleDeleteModifier(
                                            cartItem?.uniqueId,
                                            modifier.modifierId,
                                            modifier.modifierPrice
                                          );
                                        }}
                                      />
                                      &nbsp;&nbsp;
                                    </span>
                                  )}
                                  {modifier.modifierName}{" "}
                                  {modifier.topping
                                    ? `(${modifier.topping.toUpperCase()} ${
                                        modifier.layer == "extra" ? `x 2` : ""
                                      })`
                                    : modifier.layer == "extra"
                                    ? `(x 2)`
                                    : ""}
                                </span>
                              )}
                              <span className="cstm-price">
                                {modifier.isDeleted
                                  ? ""
                                  : modifier?.layer == "regular" &&
                                    modifier?.isDefault
                                  ? "$ 0.00"
                                  : "$" + priceMature(modifier?.modifierPrice)}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                      <div className="addmore-row">
                        <div className="control">
                          <button
                            className="bttn bttn-left"
                            id="minus"
                            onClick={() => {
                              cartItem?.quantity < 2
                                ? ""
                                : handledecrement(cartItem?.uniqueId);
                            }}
                          >
                            <span>-</span>
                          </button>
                          <input
                            type="number"
                            className="input"
                            id="input"
                            value={cartItem?.quantity}
                            onChange={() => {}}
                            readOnly={true}
                          />
                          <button
                            className="bttn bttn-right"
                            id="plus"
                            onClick={() => {
                              handleIncrement(cartItem?.uniqueId);
                            }}
                          >
                            <span>+</span>
                          </button>
                        </div>
                        <div className="totlpric">
                          $
                          {priceMature(
                            cartItem?.itemPrice * cartItem?.quantity
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div
          className="custm-added custm-added2"
          style={{
            padding: "0px 18px",
          }}
        >
          <ul>
            <li>
              <span>Total</span>
              <span className="cstm-price">${priceMature(total)}</span>
            </li>
          </ul>
        </div>
        <div className="p-3">
          <div className="checoutbtn">
            <button
              disabled={cart.length < 1 ? true : false}
              className="btn"
              onClick={handleCheckOut}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CostumCartModalPopUp;
