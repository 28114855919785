import * as types from "../Action/types";

function userLogoutReducer(state = [], action) {
  const { msg } = action;
  switch (action.type) {
    case types.LOGOUT_USER:
      return { ...state, loading: true,  };
    case types.LOGOUT_USER_SUCCESS:
      return {...state, loading: false, msg: msg };
    case types.LOGOUT_USER_ERROR:
      return {...state, loading: false };
    default:
      return state;
  }
}

export default userLogoutReducer;
