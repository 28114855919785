import React, { useState, useEffect } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import validation from "../../helpers/validation";
import { Typeahead } from "react-bootstrap-typeahead";
import { updateRole, addRole } from "../../Action/manageRolesAndPermission";
import "../manageMenu/ManageMenu.css";
import "./manageRole.css";
function ManageRoleAndPermissionPopup(props) {
  const dispatch = useDispatch();
  /*** normalize the props */
  const {
    showModal,
    closeModal,
    permissionList,
    editData: { edit, data: editDataObject },
  } = props;
  // console.log(permissionList);
  const [role, setrole] = useState([
    {
      roleName: "",
      roleDiscription: "null",
      permission: [],
    },
  ]);
  const [errors, setErrors] = useState([]);
  const [rwdList, setrwdList] = useState({});
  /**handle change read write delete */
  const handleChangeReadWriteDelete = (id, key, action) => {
    let tmpReadWriteDelete = { ...rwdList };
    if (typeof tmpReadWriteDelete[id] !== "undefined")
      tmpReadWriteDelete[id][key] = action;
    //update role state
    let tmpRole = [...role];
    let combineState = [];
    tmpRole[0].permission.map((item) => {
      let index = Object.keys(tmpReadWriteDelete).indexOf(item._id);
      if (index == -1) {
        tmpReadWriteDelete[item._id] = {
          add: false,
          update: false,
          delete: false,
        };
      }
      combineState.push({ ...item, ...tmpReadWriteDelete[item._id] });
    });
    setrwdList(tmpReadWriteDelete);
    tmpRole[0].permission = combineState;
    setrole(tmpRole);
  };
  /*** this function will handle the close of pop */
  const closeModaleWithClearAll = () => {
    closeModal();
    setrole([
      {
        roleName: "",
        roleDiscription: "",
        permission: [],
      },
    ]);
    setErrors([]);
  };
  /** this function handle the change of  fileds  */
  const handleRoleChange = (e, index) => {
    setrole((prevSate) =>
      prevSate.map((item, i) =>
        i === index ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  /**  the function will add new delivery charges row */
  const addnewItem = () => {
    const addNewValues = [...role];
    addNewValues.push({
      roleName: "",
      roleDiscription: "",
      permission: [],
    });
    setrole(addNewValues);
  };
  /** this function will delete the delivery charges row  */
  const deleteItemRow = (index) => {
    const deleteValues = [...role];
    deleteValues.splice(index, 1);
    setrole(deleteValues);
  };
  /** this function will handle the permission change */
  const handlePermissionChange = (e, index) => {
    let tmpRoles = [...role];
    let tmpCombineState = [];
    //update the rwd state
    let tmpReadWriteDelete = { ...rwdList };
    e.map((item) => {
      let index = Object.keys(tmpReadWriteDelete).indexOf(item._id);
      if (index == -1) {
        tmpReadWriteDelete[item._id] = {
          add: false,
          update: false,
          delete: false,
        };
      }
      tmpCombineState.push({ ...item, ...tmpReadWriteDelete[item._id] });
    });
    tmpRoles[index].permission = tmpCombineState;
    setrwdList(tmpReadWriteDelete);
    setrole(tmpRoles);
  };
  /*** this function will  handle the submit of form */
  const onSubmitRole = (e, action) => {
    e.preventDefault();
    const isValid = validation(role);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      role.map((item, index) => {
        switch (action) {
          case "create":
            dispatch(addRole(item));
            break;
          case "update":
            dispatch(
              updateRole({
                data: item,
                _id: item._id,
              })
            );
            break;
          default:
            break;
        }
      });
      closeModaleWithClearAll();
    }
  };
  /** this effect will set the delivery charges array  */
  useEffect(
    (res) => {
      if (edit) {
        setrole([
          {
            _id: editDataObject._id,
            roleName: editDataObject.roleName,
            roleDiscription: editDataObject.roleDiscription,
            permission: [
              ...editDataObject.permission.map((item) => {
                if (item._id) {
                  return item;
                }
              }),
            ],
          },
        ]);
        let tmpReadWriteDelete = { ...rwdList };
        editDataObject.permission.map((item) => {
          tmpReadWriteDelete[item._id] = {
            add: typeof item?.add === "undefined" ? false : item?.add,
            update: typeof item?.update === "undefined" ? false : item?.update,
            delete: typeof item?.delete === "undefined" ? false : item?.delete,
          };
        });
        setrwdList(tmpReadWriteDelete);
      }
    },
    [editDataObject]
  );
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          //addNewitemRow={addnewItem}
          title={`${edit ? "Update" : "Add"} Role `}
          //addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_add_master_modifier"}
            onSubmit={(e) => onSubmitRole(e, edit ? "update" : "create")}
          >
            <div className="table-responsive table-responsive21">
              <table className="table text-nowrap add_menu_info">
                <thead>
                  <tr>
                    <th>Role Name</th>
                    <th>Permission</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(role).length > 0
                    ? role.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="text"
                              name="roleName"
                              placeholder="Role Name"
                              value={item.roleName}
                              onChange={(e) => handleRoleChange(e, index)}
                            />
                            {errors[index]?.roleName && (
                              <p className="error-validation">
                                {errors[index].roleName}
                              </p>
                            )}
                          </td>
                          <td>
                            <div className="uploadImageLarge_1">
                              <div
                                className="rbt modifier-select"
                                tabIndex="-1"
                              >
                                <Typeahead
                                  id="permission"
                                  filterBy={(cItem, index) => {
                                    return (
                                      item.permission.findIndex(
                                        (e) => e.pageName == cItem.pageName
                                      ) == -1
                                    );
                                  }}
                                  labelKey="pageName"
                                  options={permissionList.filter(
                                    (e) =>
                                      e.pageName !=
                                      "Generate Restaurant Reports"
                                  )}
                                  multiple
                                  placeholder="Select Permission"
                                  onChange={(e) =>
                                    handlePermissionChange(e, index)
                                  }
                                  selected={item.permission}
                                />
                                {errors[index]?.permission && (
                                  <p className="error-validation">
                                    {errors[index].permission}
                                  </p>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <div className="table-responsive table-responsive21">
              <table className="table text-nowrap add_menu_info">
                <thead>
                  <tr>
                    <th>Role Name </th>
                    <th>Add</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {role[0].permission.map((item, index) => (
                    <tr key={index}>
                      <td>{item.pageName}</td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            handleChangeReadWriteDelete(
                              item._id,
                              "add",
                              typeof rwdList[item._id]?.add == "undefined"
                                ? false
                                : !rwdList[item._id].add
                            );
                          }}
                          checked={rwdList[item._id]?.add}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            handleChangeReadWriteDelete(
                              item._id,
                              "update",
                              typeof rwdList[item._id]?.update == "undefined"
                                ? false
                                : !rwdList[item._id].update
                            );
                          }}
                          checked={rwdList[item._id]?.update}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            handleChangeReadWriteDelete(
                              item._id,
                              "delete",
                              typeof rwdList[item._id]?.delete == "undefined"
                                ? false
                                : !rwdList[item._id].delete
                            );
                          }}
                          checked={rwdList[item._id]?.delete}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">{edit ? "Update" : "Save"}</button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default ManageRoleAndPermissionPopup;
