import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageCoupon";
import { Store } from "react-notifications-component";

// get
export function* getCoupon() {
  const response = yield call(auth.getCoupon);
  try {
    yield put({
      type: types.GET_COUPON_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    Store.addNotification({
      message: response.message,
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_COUPON_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* addCoupon({ payload }) {
  try {
    const response = yield call(auth.addCoupon, payload);
    yield call(getCoupon);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    if (err?.response?.data?.err?.code == 11000) {
      Store.addNotification({
        message: "Coupon already exists",
        type: "error",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } else {
      Store.addNotification({
        message: err?.response?.data?.message,
        type: "error",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
    yield put({
      type: types.GET_COUPON_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* updateCoupon({ payload }) {
  const response = yield call(auth.updateCoupon, payload);
  try {
    yield call(getCoupon);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    Store.addNotification({
      message: "update coupon Failed",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_COUPON_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* deleteCoupon({ payload }) {
  const response = yield call(auth.deleteCoupon, payload);
  try {
    yield call(getCoupon);
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    Store.addNotification({
      message: "delete coupon Failed",
      type: "error",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put({
      type: types.GET_COUPON_ERROR,
      msg: response.message,
    });
  }
}
