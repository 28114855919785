import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import AddModifierCategoryModal from "./AddModifierCategoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getModifierCategoryListAction,
  deleteModifierCategoryAction,
} from "../../Action/manageModifierCategoryAction";
import ConfirmModal from "../../helpers/ConfirmModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";

const ManageModifierCategory = () => {
  const responseData = useSelector((state) => state.modifierCat);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [showModal, setShowModal] = useState("no");
  const [deletConfirmModal, setDeletConfirmModal] = useState({
    show: false,
    data: [],
  });
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  const columns = [
    {
      dataField: "MadifierCategoryName",
      text: "Modifier Category Name",
      sort: true,
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => actionData(row, "delete")}>
                <FaRegTrashAlt />
              </Button>
            </li>
            <li>
              <Button onClick={() => actionData(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
          </ul>
        );
      },
    },
  ];
  //model the model for update or add  
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  //model the model for update or add  callback 
  const addNewModalMemorized = useCallback(addNewModal, [showModal])
  // mount
  useEffect(() => {
    dispatch(getModifierCategoryListAction());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { modifierCategoryList, loading, tableLading } = responseData;
    if (modifierCategoryList) {
      setLitsData(modifierCategoryList.reverse());
      setIsLoading({
        pageLoading: loading,
        tableLoading: tableLading,
      });
    }
  }, [responseData]);

  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;

      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;

      default:
        break;
    }
  };
  //handle the delete rejection
  const handleCloseAlert = () => {
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  //handle the delete success
  const handleSuccessAlert = () => {
    dispatch(
      deleteModifierCategoryAction({ itemId: deletConfirmModal.data._id })
    );
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };

  if (isLoding.pageLoading === true) {
    return <PageLoader size={50} />;
  }

  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Modifier Category</h2>
        </div>

        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                  <h2>Modifiers Category</h2>
                  <button className="btn custombtn" onClick={addNewModal}>
                    Add Modifier Category
                  </button>
                </div>
                <div className="card-body actioncard">
                  {isLoding.pageLoading === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <AddModifierCategoryModal
          showModal={showModal}
          closeModal={addNewModalMemorized}
          editData={editData}
        />
      )}
      {deletConfirmModal && (
        <ConfirmModal
          show={deletConfirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};

export default ManageModifierCategory;
