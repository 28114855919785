import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { FaRegEdit, FaCopy } from "react-icons/fa";
import { ComponentLoader } from "../../helpers/PageLoader";
import { useParams } from "react-router-dom";
import {
  checkInOut,
  varifyCrewOrDriverOrders,
} from "../../Services/manageCrewOrDriver";
import ManageCrewOrDriverOrderPopup from "./manageCrewOrDriverOrderPopup";
import { NavLink, Link } from "react-router-dom";
import logo_img from "../../Assets/images/o_original_logo.jpg";
import dashboardIcon from "../../Assets/images/004-id-card.svg";
import manageProfile from "../../Assets/images/003-menu.svg";
import menutoogleIcon from "../../Assets/images/menutoogle.svg";
import "./driver/driverdashbaord.css";
import ApplicationDetail from "../costumerSiteComponent/helpers/ApplicationDetail";
import { getAddressAndPhone } from "../../Action/authAction";
const ManageDriverIndex = function ManageDriverIndex({ children }) {
  const { userId } = useParams();
  const roleName = "driver";
  const navData = [
    {
      uri: `/driver/${userId}/`,
      text: "Dashboard",
      icon: dashboardIcon,
    },
    {
      uri: `/driver/orders/${userId}/`,
      text: "Orders",
      icon: manageProfile,
    },
    {
      uri: `/driver/history/${userId}/`,
      text: "Orders History",
      icon: manageProfile,
    },
    {
      uri: `/driver/checkinouthistory/${userId}/`,
      text: "Check In/Out History",
      icon: manageProfile,
    },
  ];
  const [checkInout, setcheckInout] = useState("out");
  const dispatch = useDispatch();
  const [isvalid, setisvalid] = useState(false);
  const [checking, setchecking] = useState(false);
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
  });
  /*** this function will  handle open and close of the popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
      });
    }
  };
  const [showModal, setShowModal] = useState("no");
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const [user, setuser] = useState({});
  /*** this is will valdiate the current user prevent unautharized access  */
  const validateUser = () => {
    setchecking(true);
    varifyCrewOrDriverOrders({
      _id: userId,
      role: roleName,
      active: true,
    })
      .then((res) => {
        setchecking(false);
        setisvalid(true);
        setuser(res.result);
        localStorage.setItem("locationId", res.result.locationId);
        setcheckInout(res.result.checkInOut);
        dispatch(getAddressAndPhone());
      })
      .catch((err) => {
        setisvalid(false);
      });
  };
  /*** fire user validation function */
  useEffect(() => {
    validateUser();
  }, []);
  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
        });
        addNewModal();
        break;
      default:
        break;
    }
  };
  /** this will handle check in and out of driver */
  const handleCheckInOut = () => {
    checkInOut({
      DriverId: userId,
      checkType: checkInout === "in" ? "out" : "in",
    })
      .then(function (res) {
        setcheckInout(checkInout === "in" ? "out" : "in");
      })
      .catch(function (error) {});
  };
  /*** this function will open and close the sidebar */
  const handletoggle = () => {
    var sidebarIns = document.getElementsByTagName("body")[0];
    if (sidebarIns.className.includes("fullwidthPanel")) {
      sidebarIns.className = sidebarIns.className.replace("fullwidthPanel", "");
    } else {
      sidebarIns.className = sidebarIns.className + "" + "fullwidthPanel";
    }
  };

  return (
    <>
      {isvalid ? (
        checking === false ? (
          isLoding.pageLoading === false ? (
            <>
              <div className="mainsection driv-content">
                <div className="left-panel" id="leftmenusection">
                  <div className="logo">
                    <img src={logo_img} className="img-fluid" alt="logo_img" />
                  </div>
                  <div
                    className="manu-section"
                    style={{
                      height: "87vh",
                      overflow: "auto",
                    }}
                  >
                    <ul>
                      {navData.map((item, i) => (
                        <li key={i}>
                          <NavLink activeClassName="active" to={item.uri}>
                            <img src={item.icon} alt="" />{" "}
                            <span> {item.text}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <header className="top-header drv-head" id="TopHeader">
                  <div className="topmenu">
                    <div className="inr-head">
                      <div className="toggleIcon" onClick={handletoggle}>
                        <img
                          src={menutoogleIcon}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <ul className="location-address">
                        <li>
                          <img
                            src="/static/media/awesome-phone-alt.4ba22d39.svg"
                            className="ficon"
                            alt=""
                          />{" "}
                          <ApplicationDetail type="contactNumber" />
                        </li>
                        <li className="addli">
                          <img
                            src="/static/media/material-location-on.4291049a.svg"
                            className="licon"
                            alt=""
                          />
                          <ApplicationDetail type="address" />
                        </li>
                      </ul>
                    </div>

                    <div className="user-section">
                      {roleName + "/"}
                      {user.fname
                        ? user.fname + " " + user.lname
                        : "validating"}
                      <button className="btn" onClick={handleCheckInOut}>
                        {" "}
                        Check-{checkInout === "in" ? "out" : "in"}
                      </button>
                    </div>
                  </div>
                </header>

                <div className="right-panel" id="rightContentPanel">
                  {children}
                </div>
              </div>

              {showModal && (
                <ManageCrewOrDriverOrderPopup
                  showModal={showModal}
                  closeModal={addNewModalMemorized}
                  editData={editData}
                  role={roleName}
                  userId={userId}
                />
              )}
              <div className="bottom-footer">
                <div className="container">
                  <div className="row ">
                    <div className="col-md-8">
                      <p>© Copyright WebsiteName 2022. All Right Reserved</p>
                    </div>
                    <div className="col-md-4 text-right">
                      <a aria-current="page" className="active" href="/">
                        Terms of Use
                      </a>
                      <a aria-current="page" className="active" href="/">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ComponentLoader size={40} />
          )
        ) : (
          <div className="loading-wrapper text-center">
            <h3>Validating</h3>
          </div>
        )
      ) : (
        <div className="loading-wrapper text-center">
          <h3>Unauthrised</h3>
        </div>
      )}
    </>
  );
};
export default ManageDriverIndex;
