import React, { useState, useEffect } from "react";
import { Modal, Row, FormLabel, Form, Col } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import "./manageCalculator.css";
import {
  updateManagerModifierList,
  createManagerModifierList,
} from "../../Action/manageModifierAction";
import { useDispatch } from "react-redux";
import validation from "../../helpers/validation";
import { Typeahead } from "react-bootstrap-typeahead";

const AddModalRightAnimation = (props) => {
  const [menuItemRow, setMenuItemRow] = useState([
    {
      Price: "",
      halfToping: false,
      isDouble: false,
      modifiersId: [],
      varationsId: [],
    },
  ]);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const {
    animationModal: showModal,
    closeNewMenu,
    editModifierData: { edit, editData },
    variationsTypeList,
    modifierTypeList,
    litsData,
  } = props;
  //show and hide  the model on body
  showModal === "yes"
    ? document.body.classList.add("modal-open")
    : document.body.classList.remove("modal-open");
  showModal === "yes"
    ? (document.body.style.overflow = "hidden")
    : document.body.style.removeProperty("overflow");
  //this will add new row in form
  const addMenuItemInfo = () => {
    const values = [...menuItemRow];
    values.push({
      Price: "",
      halfToping: false,
      isDouble: false,
      modifiersId: [],
      varationsId: [],
    });
    setMenuItemRow(values);
  };
  //this will delete the row in form
  const deleteMenuItemInfo = (index) => {
    const removeValues = [...menuItemRow];
    removeValues.splice(index, 1);
    setMenuItemRow(removeValues);
  };
  //handle menu item change
  const menuItemInfoChange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? ["halfToping", "isDouble"].includes(e.target.name)
            ? {
              ...item,
              [e.target.name]: !item[e.target.name],
            }
            : e.target.name === "Price"
              ? { ...item, Price: e.target.value >= 0 ? e.target.value : 0 }
              : {
                ...item,
                [e.target.name]: e.target.value,
              }
          : item
      )
    );
  };

  // modifiertype
  const menuItemModifierTypeChange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, modifiersId: e } : item
      )
    );
  };
  // variationstype
  const menuItemVariationsTypeChange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, varationsId: e } : item
      )
    );
  };
  //update
  const updateMenuItems = (e, action) => {
    e.preventDefault();
    if (action === "create") {
      let data = [];
      menuItemRow.map((Pitem, index) => {
        data = litsData.filter((item) => {
          return (
            item.varationsId === Pitem.varationsId[0]?.VariationID &&
            item.modifiersId === Pitem.modifiersId[0]?.UniqueID
          );
        });
      });

      if (data.length > 0) {
        setErrorMsg("this set price has already set");
        return true;
      } else {
        setErrorMsg("");
      }
    }
    const isValid = validation(menuItemRow);
    setErrors(isValid);
    const checkIsValid = isValid.map((item) =>
      Object.keys(item).length === 0 ? true : false
    );
    if (!checkIsValid.includes(false) === true) {
      switch (action) {
        case "update":
          let dataFormUpdate = menuItemRow.map((item) => {
            item.Price = parseFloat(item.Price).toFixed(2);
            return {
              ...item,
              modifiersId: item.modifiersId[0].UniqueID,
              varationsId: item.varationsId[0].VariationID,
            };
          });
          dispatch(
            updateManagerModifierList({
              itemId: editData._id,
              data: dataFormUpdate[0],
            })
          );
          closeNewMenuWithClearData();
          break;
        case "create":
          let dataFormCreate = menuItemRow.map((item) => {
            item.Price = parseFloat(item.Price).toFixed(2);
            return {
              ...item,
              modifiersId: item.modifiersId[0].UniqueID,
              varationsId: item.varationsId[0].VariationID,
            };
          });
          dispatch(createManagerModifierList({ data: dataFormCreate }));
          closeNewMenuWithClearData();
          break;

        default:
          break;
      }
    }
  };

  // update
  useEffect(() => {
    if (edit) {
      setMenuItemRow((prevState) =>
        prevState.map((item) => ({
          ...item,
          Price: editData.Price,
          halfToping: editData.halfToping,
          isDouble: editData.isDouble || false,
          modifiersId: modifierTypeList.filter(
            (item) => item.UniqueID === editData.modifiersId
          ),
          varationsId: variationsTypeList.filter(
            (item) => item.VariationID === editData.varationsId
          ),
        }))
      );
    }
  }, [editData, edit, modifierTypeList, variationsTypeList]);
  //close model and clear state
  const closeNewMenuWithClearData = () => {
    closeNewMenu();
    setMenuItemRow([
      {
        Price: "",
        halfToping: false,
        isDouble: false,
        modifiersId: [],
        varationsId: [],
      },
    ]);
    setErrors([]);
    setErrorMsg("");
  };
  return (
    <>
      <Modal
        size="lg"
        animation={true}
        backdrop={false}
        className={`modal_styled ${showModal === "yes" ? "transitionStyle" : ""
          }`}
        show={true}
        onHide={closeNewMenuWithClearData}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {`${edit === true ? "Update" : ""} Modifier Price Food Calculators`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {edit === false ? (
            <Row>
              <Col md={12} className="text-right">
                <div className="regBtn add_small_button">
                  <button className="btn" onClick={addMenuItemInfo}>
                    Add New
                  </button>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <div className="modalBodyForm">
            <Form
              onSubmit={(e) =>
                updateMenuItems(e, edit === true ? "update" : "create")
              }
            >
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      {edit === false ? (
                        <>
                          <th>Modifier Name</th>
                          {/* <th>Variation</th> */}
                        </>
                      ) : (
                        ""
                      )}
                      <th>Unit</th>
                      <th>Price($)</th>

                      {edit === false ? <th>Action</th> : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {errorMsg ? (
                      <tr>
                        <span style={{ color: "red" }}>
                          price already set for modifier{" "}
                        </span>
                      </tr>
                    ) : (
                      ""
                    )}
                    {Object.keys(menuItemRow).length > 0
                      ? menuItemRow.map((item, index) => {
                        return (
                          <tr key={index}>
                            {edit === false ? (
                              <>
                                <td>
                                  <Typeahead
                                    id={"modifiersId"}
                                    labelKey="MadifierName"
                                    className="Typeahead_multiple_select"
                                    filterBy={["MadifierName"]}
                                    onChange={(e) =>
                                      menuItemModifierTypeChange(e, index)
                                    }
                                    options={modifierTypeList ?? []}
                                    placeholder={`Search & Select`}
                                    selected={item.modifiersId}
                                  />
                                  {errors[index]?.modifiersId && (
                                    <p className="error-validation">
                                      {errors[index].modifiersId}
                                    </p>
                                  )}
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                            <td>
                            <Form.Control
                                type="number"
                                name="Price"
                                placeholder="0"
                              />
                              <select
                                name={"varaitionName"}
                                className="form-control"
                              >
                                <option value={""}>LB</option>
                                <option value={""}>Pound</option>

                              </select>
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                // value={item.Price}
                                name="Price"
                                placeholder="0.00"
                              />
                            </td>
                            {edit === false ? (
                              <td>
                                <div className="deleteButton_modifier_category">
                                  <button
                                    className="deleteButton_1"
                                    disabled={
                                      Object.keys(menuItemRow).length === 1
                                        ? true
                                        : false
                                    }
                                    type="button"
                                    onClick={() => deleteMenuItemInfo(index)}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>
              </div>
              {Object.keys(menuItemRow).length > 0 ? (
                <Row className="mt-3 mb-3 update_btn">
                  <Col md={12} className="text-right">
                    <div className="regBtn">
                      <button className="btn">
                        {edit === true ? "Update" : "Save"}
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {showModal === "yes" ? <div className="right-modal-backdrop"></div> : ""}
    </>
  );
};

export default AddModalRightAnimation;
