import * as types from './types';

/***
 * this item will get delivery charges 
 */
export const getDeliveryCharges = () => ({
    type:types.GET_DELIVERY_CHARGES
});

/***
 * this item will add delivery charges 
 */
export const addDeliveryCharges = (payload) => ({
    type:types.ADD_DELIVERY_CHARGES,
    payload
});

/***
 * this item will update delivery charges 
 */
export const updateDeliveryCharges = (payload) => ({
    type:types.UPDATE_DELIVERY_CHARGES,
    payload
});
/***
 * this item will delete delivery charges 
 */
export const deleteDeliveryCharges = (payload) => ({
    type:types.DELETE_DELIVERY_CHARGES,
    payload
});