import * as api from "./api";
export const getUser = async function () {
  return await api.get("/users/getUser");
};

export const addUser = async function (payload) {
  return await api.post("/users/addUser", payload);
};

export const updateUsers = async function ({ _id, data }) {
  delete data._id;
  delete data.password;
  return await api.put(`/users/updateUser/${_id}`, data);
};

export const deleteUser = async function (payload) {
  return await api.put(`/users/activeUser/${payload._id}`, {
    active: payload.active,
  });
};

export const getCustomer = async function (payload) {
  return await api.post(`/users/customers`);
};

export const getCustomerExport = async function (type) {
  return await api.downloadFile(
    `users/customers/?${new URLSearchParams({ type: type }).toString() || ""}`
  );
};
