import * as types from "./types";

/***
 * this item will add the coupon
 */
export const getCoupon = (data) => ({
  type: types.GET_COUPON,
});

/***
 * this item will add the coupon
 */
export const addCoupon = (data) => {
  return {
  type: types.ADD_COUPON,
  payload: data,
}};

/***
 * this item will update the coupon
 */
export const updateCoupon = (data) => ({
  type: types.UPDATE_COUPON,
  payload: data,
});

/***
 * this item will delete the coupon
 */
export const deleteCoupon = (data) => ({
  type: types.DELETE_COUPON,
  payload: data,
});
