import * as types from "../Action/types";

var intialState = {
  location: JSON.parse(localStorage.getItem("location") || "[]"),
  locationId: localStorage.getItem("locationId"),
  loading: false,
};
function locationReducer(state = intialState, { type, payload }) {
  switch (type) {
    case types.GET_LOCATION:
      return { ...state, loading: true };
    case types.GET_LOCATION_SUCCESS:
      JSON.stringify(localStorage.setItem("location", JSON.stringify(payload)));
      return { ...state, loading: false, location: payload };
    case types.SELECT_LOCATION:
      localStorage.setItem("locationId", payload);
      // localStorage.setItem("loc-new", payload);
      return { ...state, locationId: payload };
    default:
      return state;
  }
}
export default locationReducer;
