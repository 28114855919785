import * as types from "./types";

/** 
 * this action will get variation list 
*/
export const getVariation = () => ({
    type: types.GET_VARIATION,
});

/** 
 * this action will update variation  
*/
export const updateVariation = (payload) => ({
    type: types.UPDATE_VARIATION,
    payload
});

/** 
 * this action will delete variation  
*/
export const addVariation = (payload) => ({
    type: types.ADD_VARIATION,
    payload
});

/** 
 * this action will delete variation  
*/
export const deleteVariation = (payload) => ({
    type: types.DELETE_VARIATION,
    payload
});