const duplicateValidation = (values) => {
  const { masterModifier, typeHeadCheck } = values;
  let errorsThrow = {
    errorDuplicate: [],
    DuplicateShow: false,
  };
  if (masterModifier) {
    const arrayData = masterModifier.filter(
      (item) =>
        (item.variationId[0]?.VariationID ?? "") ===
        typeHeadCheck[0]?.VariationID
    );
    if (arrayData.length > 1 && typeHeadCheck.length > 0) {
      const errorDuplicate = masterModifier.map((item) => {
        let errosData = [];
        if (
          item.variationId[0]?.VariationID === typeHeadCheck[0]?.VariationID
        ) {
          errosData.variationId = "not accept duplicate value";
        } else {
          errosData.variationId = "";
        }
        return errosData;
      });
      errorsThrow = {
        errorDuplicate: errorDuplicate,
        DuplicateShow: true,
      };
    }
    if (arrayData.length < 1) {
      errorsThrow = {
        errorDuplicate: [],
        DuplicateShow: false,
      };
    }
  }

  return errorsThrow;
};

export default duplicateValidation;
