import * as types from "../Action/types";

var intialState = {
  data: {},
  loading: false,
};
function AddressAndPhone(state = intialState, { type, payload }) {
  switch (type) {
    case types.GET_ADDRESS_AND_PHONE:
      return { ...state, loading: true };
    case types.GET_ADDRESS_AND_PHONE_SUCCESS:
      return { ...state, loading: false, data: payload };
    default:
      return state;
  }
}
export default AddressAndPhone;
