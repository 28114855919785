import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, FormLabel, Col } from "react-bootstrap";
import validation from "../../helpers/validation";
import { useDispatch } from "react-redux";
import "./manageModifierCategory.css";
import {
  postModifierCategoryAction,
  updateModifierCategoryAction,
} from "../../Action/manageModifierCategoryAction";

function AddModifierCategoryModal(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data },
  } = props;
  const [modifierCategory, setModifierCategory] = useState([
    {
      MadifierCategoryName: "",
      Description: "",
      isDefault: false,
      min: 0,
      max: 0,
    },
  ]);

  const [errors, setErrors] = useState([]);
  //this effect will set modifier category state
  useEffect(() => {
    if (edit) {
      setModifierCategory((prevState) =>
        prevState.map((item) => ({
          ...item,
          MadifierCategoryName: data.MadifierCategoryName ?? "",
          Description: data.Description ?? "",
          isDefault: data.isDefault ? data.isDefault : false,
          min: data.min ?? 0,
          max: data.max ?? 0,
        }))
      );
    }
  }, [edit, data]);
  //this function will close model and clear the state
  const closeModaleWithClearAll = () => {
    setModifierCategory([
      {
        MadifierCategoryName: "",
        Description: "",
        isDefault: false,
        min: 0,
        max: 0,
      },
    ]);
    closeModal();
    setErrors([]);
  };
  //handle change  of modifier category
  const onChangemodifierCateory = (e, index) => {
    if (e.target.name === "isDefault") {
      setModifierCategory((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? { ...item, [e.target.name]: !item[e.target.name] }
            : item
        )
      );
    } else {
      setModifierCategory((prevState) =>
        prevState.map((item, i) =>
          i === index ? { ...item, [e.target.name]: e.target.value } : item
        )
      );
    }
  };
  //add new row in form
  const addNewitemRow = () => {
    const addNewValue = [...modifierCategory];
    addNewValue.push({
      MadifierCategoryName: "",
      Description: "",
      isDefault: false,
      min: 0,
      max: 0,
    });
    setModifierCategory(addNewValue);
  };
  //delete row in form
  const deleteItemrow = (index) => {
    const deletevalue = [...modifierCategory];
    const deleteErrors = [...errors];
    deletevalue.splice(index, 1);
    deleteErrors.splice(index, 1);
    setModifierCategory(deletevalue);
    setErrors(deleteErrors);
  };
  //handle submit
  const onSubmitFormmdifierCategory = (e, action) => {
    e.preventDefault();
    const isValid = validation(modifierCategory);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);
    if (Object.keys(arrayIsValid).length === 0) {
      switch (action) {
        case "create":
          dispatch(postModifierCategoryAction({ data: modifierCategory }));
          closeModaleWithClearAll();
          break;
        case "update":
          dispatch(
            updateModifierCategoryAction({
              data: modifierCategory[0],
              itemId: data._id,
            })
          );
          closeModaleWithClearAll();
          break;

        default:
          break;
      }
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addNewitemRow}
          title={
            edit === true ? "Update Modifier Category" : "Add Modifier Category"
          }
          addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_modifier_category"}
            onSubmit={(e) =>
              onSubmitFormmdifierCategory(
                e,
                edit === true ? "update" : "create"
              )
            }
          >
            <div className="table-responsive">
              <table className="table text-nowrap update_modify_categ">
                <thead>
                  <tr>
                    <th>Modifier Category Name</th>
                    <th>Is Default</th>
                    <th>Min </th>
                    <th>Max</th>
                    <th>Description</th>
                    {edit ? "" : <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(modifierCategory).length > 0
                    ? modifierCategory.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="text"
                              name="MadifierCategoryName"
                              value={item.MadifierCategoryName}
                              placeholder="Modifier Category Name"
                              onChange={(e) =>
                                onChangemodifierCateory(e, index)
                              }
                            />
                            {errors[index]?.MadifierCategoryName && (
                              <p className="error-validation">
                                {errors[index].MadifierCategoryName}
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name="isDefault"
                              checked={item.isDefault}
                              onChange={(e) =>
                                onChangemodifierCateory(e, index)
                              }
                            />
                            {errors[index]?.isDefault && (
                              <p className="error-validation">
                                {errors[index].isDefault}
                              </p>
                            )}
                          </td>
                          <td className="range_sec">
                            <Form.Control
                              type="number"
                              name="min"
                              value={item.min}
                              min="0"
                              step=".01"
                              placeholder="Minimum Range"
                              onChange={(e) =>
                                onChangemodifierCateory(e, index)
                              }
                            />
                            {errors[index]?.min && (
                              <p className="error-validation">
                                {errors[index].min}
                              </p>
                            )}
                          </td>
                          <td className="range_sec">
                            <Form.Control
                              type="number"
                              name="max"
                              value={item.max}
                              min="0"
                              step=".01"
                              placeholder="Maximum Range"
                              onChange={(e) =>
                                onChangemodifierCateory(e, index)
                              }
                            />
                            {errors[index]?.max && (
                              <p className="error-validation">
                                {errors[index].max}
                              </p>
                            )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="Description"
                              placeholder="Description"
                              value={item.Description}
                              onChange={(e) =>
                                onChangemodifierCateory(e, index)
                              }
                            />
                            {errors[index]?.Description && (
                              <p className="error-validation">
                                {errors[index].Description}
                              </p>
                            )}
                          </td>
                          {edit ? (
                            ""
                          ) : (
                            <td>
                              <div className="deleteButton_modifier_category">
                                <button
                                  className="deleteButton_1"
                                  disabled={
                                    Object.keys(modifierCategory).length === 1
                                      ? true
                                      : false
                                  }
                                  onClick={() => deleteItemrow(index)}
                                  type="button"
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">
                    {edit === true ? "Update" : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default memo(AddModifierCategoryModal);
