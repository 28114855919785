import React, { useEffect, memo } from "react";
import { Redirect, Route } from "react-router-dom";
import CustomerContainer from "../Component/costumerSiteComponent/customerProtected/customerContainer";
import jwt from "jsonwebtoken";
import { useSelector, useDispatch } from "react-redux";
import { logOutUser } from "../Action/authAction";

const CustomerProtectedRouter = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const token = customer.customerToken;
  const isLoggedIn = customer.isCustomerLoggedIn;
  useEffect(() => {
    jwt.verify(token, "secretkEy-InR5cCI6IkpXV", function (err) {
      if (err && err.message === "jwt expired") {
        dispatch(logOutUser());
      }
    });
  });

  if (token === '' && isLoggedIn === false) {
    return <Redirect to={"/"} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token !== null && isLoggedIn === true ? (
          <CustomerContainer>
            <Component {...props} />
          </CustomerContainer>
        ) : token === null && isLoggedIn === false ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          ""
        )
      }
    />
  );
};

export default memo(CustomerProtectedRouter);
