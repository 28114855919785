import * as types from "../Action/types";

function DelivaryOrPickup(state = {}, { type, payload }) {
  switch (type) {
    case types.UPDATE_DEL_OR_PICKUP:
      return payload;
    default:
      return state;
  }
}
export default DelivaryOrPickup;
