import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import formettedDate from "../../../helpers/moomentDate";
import {
  getOrderItemData,
  getSingleOrder,
} from "../../../Services/manageOrderServices";
import { useHistory } from "react-router-dom";
import "./expiryOrderDetail.css";
import originallogo from "../assets/images/o_original_logo.jpg";
export default function ExpirableOrderDetail({ location }) {
  const [order, setorder] = useState([]);
  const [orderItem, setorderItem] = useState([]);
  const [isloading, setisloading] = useState(false);
  const params = useParams();
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    value = value.toString();
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /**  this will filterize values */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };

  // var streetAddress = "";
  // try {
  //   streetAddress = order?.address[0]?.streetAddress;
  // } catch (err) {
  //   //
  // }
  
  /*** this function will get orders details */
  useEffect(() => {
    setisloading(true);
    getSingleOrder(params.randomOrderId)
      .then((res) => {
        setorder(res.result?.[0] || []);
        res.result?.[0]._id
          ? getOrderItemData(res.result?.[0]._id)
              .then((itemRes) => {
                //setisloading(false);
                setorderItem(itemRes.result);
                setTimeout(() => {
                  window.print();
                }, Number(process.env.REACT_APP_WAIT_FOR_PRINT || 3000));
              })
              .catch((err) => {
                //setisloading(false);
              })
          : "";
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    window.onafterprint = function () {
      window.history.back();
    };
  }, []);
  return (
    <>
      {Object.keys(order).length < 1 ? (
        <>
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1>This Link is Expired</h1>
            </div>
          </div>
        </>
      ) : (
        <div className="print-section">
          <div className="order-details-pop print-order">
            <div className="row mb-3">
              <div className="col-sm-12 text-center">
                <div className={"icon-container"}>
                  <img src={originallogo} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <table className="table1">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order?.users?.[0].fname +
                            " " +
                            order?.users?.[0].lname}
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Email</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order?.users?.[0].email}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Contact Number</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {/* {normalizeInput(order?.users?.[0].contactNumber)} */}
                          {normalizeInput(order?.contactNumber)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order?.address?.[0].street}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Id</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order.randomOrderId}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table1">
                  <tbody>
                    <tr>
                      <th>Order Total</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          ${order.totalGrossAmount}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Service Type</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order.OrderServiceOption}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status </th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {order?.payments?.[0]?.paymentStatus}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Date</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {formettedDate(order.createdAt)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order No.</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {location?.state?.serial}
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Street Number</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {streetAddress ? streetAddress : 'N/A'}
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-respnsive">
              <table className="table table-bordered checkListWrapper2">
                <thead>
                  <tr>
                    <th>Size</th>
                    <th>Items</th>
                    <th>Total Price</th> 
                  </tr>
                </thead>
                <tbody>
                  {orderItem.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="sizes">
                            <span className="customController">
                              <p>
                                {" "}
                                {item.varaitionName} : {item.quantity}
                              </p>
                              <p></p>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="itemsWrapper d-inline-flex align-items-center">
                            <div className="details">
                              <h6>{item.itemName}</h6>
                              <p className="d-flex-sp">
                                <div className="order-modifier-grid">
                                  {item.orderModifierDetail.map(
                                    (modifier, index) => {
                                      return modifier.halfToppingOption.toUpperCase() ===
                                        "F" ||
                                        !["F", "L", "R"].includes(
                                          modifier.halfToppingOption?.toUpperCase()
                                        ) ? (
                                        modifier.isDeleted ? (
                                          <div className="order-modifier">
                                            No {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()})`
                                              : ""}
                                            {index ==
                                            item.orderModifierDetail.length - 1
                                              ? ""
                                              : ","}
                                          </div>
                                        ) : (
                                          <div className="order-modifier">
                                            {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()} ${
                                                  modifier.layer == "extra"
                                                    ? `x 2`
                                                    : ""
                                                })`
                                              : modifier.layer == "extra"
                                              ? `(x 2)`
                                              : ""}
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      );
                                    }
                                  )}
                                </div>

                                <div className="order-modifier-grid">
                                  {item.orderModifierDetail.map(
                                    (modifier, index) => {
                                      return modifier.halfToppingOption.toUpperCase() ===
                                        "L" ? (
                                        modifier.isDeleted ? (
                                          <div className="order-modifier">
                                            No {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()})`
                                              : ""}
                                          </div>
                                        ) : (
                                          <div className="order-modifier">
                                            {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()} ${
                                                  modifier.layer == "extra"
                                                    ? `x 2`
                                                    : ""
                                                })`
                                              : modifier.layer == "extra"
                                              ? `(x 2)`
                                              : ""}
                                            {index ==
                                            item.orderModifierDetail.length - 1
                                              ? ""
                                              : ","}
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      );
                                    }
                                  )}
                                </div>

                                <div className="order-modifier-grid">
                                  {item.orderModifierDetail.map(
                                    (modifier, index) => {
                                      return modifier.halfToppingOption.toUpperCase() ===
                                        "R" ? (
                                        modifier.isDeleted ? (
                                          <div className="order-modifier">
                                            No {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()})`
                                              : ""}
                                            {index ==
                                            item.orderModifierDetail.length - 1
                                              ? ""
                                              : ","}
                                          </div>
                                        ) : (
                                          <div className="order-modifier">
                                            {modifier.modifierName}{" "}
                                            {modifier.halfToppingOption != " "
                                              ? `(${modifier.halfToppingOption.toUpperCase()} ${
                                                  modifier.layer == "extra"
                                                    ? `x 2`
                                                    : ""
                                                })`
                                              : modifier.layer == "extra"
                                              ? `(x 2)`
                                              : ""}
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      );
                                    }
                                  )}
                                </div>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>${priceMature(item.itemPrice * item.quantity)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-6">
                  <ul className="list ml-0 pl-0">
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(order.totalAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(order.discountAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Coupon Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(order.couponAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Tax Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(order.taxAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Delivery Charges</h3>
                        </div>
                        <div>
                          <p>${priceMature(order.deliveryCharge)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Gross Total</h3>
                        </div>
                        <div>
                          <p>${priceMature(order.totalGrossAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="checoutbtn">
                        <button
                          className="btn"
                          onClick={() => {
                            window.print();
                          }}
                        >
                          Print
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 text-center">
                  <img src={order.qrCode} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
