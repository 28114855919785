import React, { memo } from "react";
import { Modal } from "react-bootstrap";

const CostumModal = (props) => {
  const { show, handleClose, title, children, footer } = props;
  
  return (
    <>
      <Modal className={`custommodal left customCartModel`} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(CostumModal);
