import * as types from './types';


/***
 * this item will get modifier category
 */
export const getModifierCategoryListAction = () => ({
    type:types.GET_MODIFIER_CATEGORY_LIST,
})

/***
 * this item will add modifier category
 */
export const postModifierCategoryAction = (data) => ({
    type: types.POST_MODIFIER_CATEGORY_LIST,
    payload:data
});

/***
 * this item will delete modifier category
 */
export const deleteModifierCategoryAction = (itemId) => ({
    type: types.DELETE_MODIFIER_CATEGORY_LIST_ITEM,
    payload:itemId
})

/***
 * this item will update modifier category
 */
export const updateModifierCategoryAction = (data) => ({
    type: types.UPDATE_MODIFIER_CATEGORY_LIST_ITEM,
    payload:data
})

