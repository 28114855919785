import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as manageVariations from "../Services/manageVariationServices";
import { Store } from "react-notifications-component";

/** get variation  */
export function* getVariation() {
    try {
        const response = yield call(manageVariations.getVariation);
        yield put({
            type: types.GET_VARIATION_SUCCESS,
            response,
        });
    } catch (error) {
        yield put({
            type: types.GET_VARIATION_ERROR,
            error,
        });
    }
}
/** update variation  */
export function* updateVariation({ payload }) {
    try {
        const response = yield call(manageVariations.updateVariation, payload);
        yield call(getVariation);
        Store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    } catch (error) {
        Store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
        yield put({
            type: types.UPDATE_VARIATION_ERROR,
            error,
        });
    }
}
/** add variation  */
export function* addVariation({ payload }) {
    try {
        const response = yield call(manageVariations.addVariation, payload);
        yield call(getVariation);
        Store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    } catch (error) {
        yield put({
            type: types.ADD_VARIATION_ERROR,
            error,
        });
    }
}
/** delete variation  */
export function* deleteVariation({ payload }) {
    try {
        const response = yield call(manageVariations.deleteVariation, payload);
        yield call(getVariation);
        Store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    } catch (error) {
        yield put({
            type: types.DELETE_VARIATION_ERROR,
            error,
        });
    }
}