import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  customers: [],
  msg: "",
};
function manageCustomer(state = initialState, action) {
  const { response, payload, msg } = action;
  switch (action.type) {
    case types.GET_CUSTOMER:
      return { ...state, loading: true };
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        customers: payload.result || [],
        msg: msg,
      };
    case types.GET_CUSTOMER_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}

export default manageCustomer;
