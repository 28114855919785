import React, { useState, useEffect } from "react";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { getSalesReportByServiceType } from "../../Services/reportService";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getVariation } from "../../Action/manageVariationAction"
import { getOrdersDetails } from "../../Action/manageOrdersAction";
import { useDispatch, useSelector } from "react-redux";


export default function variationReport() {
    const dispatch = useDispatch();

    const [litsData, setlitsData] = useState([]);
    const [variations, setvariations] = useState([])
    const [selectedVariation, setSelectedVariation] = useState(null)
    const [variationData, setVariationData] = useState([])
    const [showResetButton, setshowResetButton] = useState(false);
    const [loading, setloading] = useState(false);
    const [getAllVariations, setgetAllVariations] = useState([]);

    const responseData = useSelector((state) => state.variation);
    const allOrdersDetails = useSelector((state) => state.order);

    useEffect(() => {
        const { orderDetails } = allOrdersDetails;
        if (!!orderDetails) {
            let { from, to } = filter;
            const finalData = orderDetails.filter(vl => new Date(from) <= new Date(vl.createdAt) && new Date(moment(to).add("1", "d").format("MM/DD/YYYY")) > new Date(vl.createdAt));
            setloading(false);
            setlitsData(finalData.reverse())
            // getSalesReportByServiceType({ ...filter, collectionName: "order" }).then((result) => {
            //     setloading(false);
            //     setlitsData(result.result.reverse().filter(vl => vl?.orderDetails[0] !== undefined));
            //     setshowResetButton(false);
            // });
        }
    }, [allOrdersDetails])


    useEffect(() => {
        const { variation } = responseData;
        if (litsData.length > 0 && variation.length > 0 && !selectedVariation) {
            setvariations(variation.map(a => capitalizeFirstLetter(a.variationName)))
            const result = getVariationReportWithCount(litsData)
            setVariationData(result)
        }
    }, [litsData, responseData, selectedVariation])


    // const allVariations = variation.filter((item,
    //     index) => variation.indexOf(item) === index);

    const getVariationReportWithCount = (data) => {
        const countDict = data.reduce((acc, curr) => {
            if (acc[curr.variationId]) {
                ++acc[curr.variationId];
            } else {
                acc[curr.variationId] = 1;
            }
            return acc;
        }, {});

        const result = data.filter((obj, index) => {
            if (index === data.findIndex(o => obj.variationId === o.variationId)) {
                obj["count"] = countDict[obj.variationId];
                return obj;
            }
        });
        return result;
    }

    // console.log(result, "count dasd")  


    // const variationData = litsData.map(a => ({ vId: a?.orderDetails[0]?.variationId }))

    // const count = {};
    // variationData.forEach(a => {
    //     count[a.vId] = (count[a.vId] || 0) + 1;
    // });
    // console.log(count,"Quantity",variationData);


    // const isToday = (someDate) => {
    //     const today = new Date();
    //     someDate = new Date(someDate);
    //     return (
    //         someDate.getDate() == today.getDate() &&
    //         someDate.getMonth() == today.getMonth() &&
    //         someDate.getFullYear() == today.getFullYear()
    //     );
    // };


    // const handleToggleCheckBox = (checked) => {
    //     if (checked) {
    //         console.log("1111 true", checked) 
    //         setfilter({
    //             ...filter,
    //             from: new Date(),
    //             to: new Date(),
    //         });

    //         getSalesReportByServiceType({
    //             ...filter,
    //             from: new Date(),
    //             to: new Date(),
    //             collectionName: "order",
    //         }).then((result) => {
    //             const finalData = result.result.filter(vl => isToday(vl.orderDetails[0].createdAt));
    //             setloading(false);
    //             setlitsData(finalData.reverse());
    //             setshowResetButton(false);
    //         });
    //     } else if(!checked) {
    //         setfilter({
    //             ...filter,
    //             from: moment().subtract("1", "M").format("MM/DD/YYYY"),
    //             to: moment().subtract("1", "d").format("MM/DD/YYYY"),
    //         });

    //         getSalesReportByServiceType({
    //             from: moment().subtract("1", "M").format("MM/DD/YYYY"),
    //             to: moment().format("MM/DD/YYYY"),
    //             OrderServiceOption: "all",
    //             collectionName: "order",
    //         }).then((result) => {
    //             setloading(false);
    //             setlitsData(result.result.reverse());
    //             setshowResetButton(false);
    //         });
    //     }
    //    setisTodayCheckBox(checked);    
    // };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [filter, setfilter] = useState({
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY")
    });
    const [filterError, setfilterError] = useState({
        from: false,
        to: false,
        varaitionName: false,
    });

    //handle the change in filter form
    const handleFilterChange = (type, value) => {
        setfilter({ ...filter, [type]: value });
        let tmpError = { ...filterError };
        if (type === "from") {
            let fDate = moment(value).format("YYYY-MM-DD");
            let sDate = moment(filter["to"]).format("YYYY-MM-DD");
            if (moment(fDate).isAfter(sDate, "day")) {
                tmpError[type] = true;
                tmpError["to"] = true;
            } else {
                tmpError[type] = false;
                tmpError["to"] = false;
            }
        } else if (type === "to") {
            let fDate = moment(filter["from"]).format("YYYY-MM-DD");
            let sDate = moment(value).format("YYYY-MM-DD");
            if (moment(fDate).isAfter(sDate, "day")) {
                tmpError[type] = true;
                tmpError["from"] = true;
            } else {
                tmpError[type] = false;
                tmpError["from"] = false;
            }

            // const filterVariations = variationData.filter(vl => new Date(vl.createdAt) < new Date(value));
            // setvariations(filterVariations.map(a => a?.orderDetails.some(vl => vl.?.varaitionName))

        }
        setfilterError(tmpError);
        if (type === "varaitionName") {
            setSelectedVariation(value)
        }
    };
    //submit
    const handleSubmit = (e) => {
        e.preventDefault();
        let tmpError = { ...filterError };
        let isValidate = true;
        Object.keys(tmpError).map((key) => {
            if (key === "from") {
                //check if null
                //check  min or max
                let fDate = moment(filter[key]).format("YYYY-MM-DD");
                let sDate = moment(filter["to"]).format("YYYY-MM-DD");
                if (moment(fDate).isAfter(sDate, "day")) {
                    tmpError[key] = true;
                    tmpError["to"] = true;
                    isValidate = false;
                } else {
                    tmpError[key] = false;
                    tmpError["to"] = false;
                }
            } else if (key === "to") {
                //check if min or max
                let fDate = moment(filter["from"]).format("YYYY-MM-DD");
                let sDate = moment(filter["to"]).format("YYYY-MM-DD");
                if (moment(fDate).isAfter(sDate, "day")) {
                    tmpError[key] = true;
                    tmpError["from"] = true;
                    isValidate = false;
                } else {
                    tmpError[key] = false;
                    tmpError["from"] = false;
                }
            }
        });
        setfilterError(tmpError);
        if (isValidate) {
            setloading(true);
            const { orderDetails } = allOrdersDetails;
            if (!!orderDetails) {
                let { from, to } = filter;
                const fData = orderDetails.filter(vl => new Date(from) <= new Date(vl.createdAt) && new Date(moment(to).add("1", "d").format("MM/DD/YYYY")) >= new Date(vl.createdAt));
                const result = getVariationReportWithCount(fData)
                const filterData = !!selectedVariation ? result.filter(vl => vl.varaitionName === selectedVariation) : result;
                setVariationData(filterData.reverse());
                setshowResetButton(true);
                setloading(false);
            }

        }
    };

    //reset the filter
    const resetFilter = (e) => {
        e.preventDefault();
        const { orderDetails } = allOrdersDetails;
        if (!!orderDetails) {
            setfilter({
                from: moment().subtract("1", "M").format("MM/DD/YYYY"),
                to: moment().subtract("1", "d").format("MM/DD/YYYY")
            })
            const finalData = orderDetails.filter(vl => new Date(moment().subtract("1", "M").format("MM/DD/YYYY")) <= new Date(vl.createdAt) && new Date(moment().subtract("0", "d").format("MM/DD/YYYY")) >= new Date(vl.createdAt));
            const result = getVariationReportWithCount(finalData)
            setvariations(variations)
            setVariationData(result.reverse());
            setshowResetButton(false);
        }
        // getOrdersDetails({
        //     from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        //     to: moment().format("MM/DD/YYYY"),
        // }).then((result) => {
        //     console.log(result.result,"getOrdersDetails");
        //     // setVariationData();
        //     setshowResetButton(false);
        // });
    };
    // const handleDownload = (type) => {
    //     if (!litsData.length < 1) {
    //         getSalesReportByServiceType({ ...filter }, type, "order").then(
    //             (result) => { }
    //         );
    //     }
    // };

    const columns = [
        {
            dataField: Math.floor(1000 + Math.random() * 9000).toString(),
            text: "Item Name",
            formatter: (cell, row) => {
                return row.itemName
            },
            filterValue: (cell, row) => {
                return row.itemName
            },
            sort: true,
        },
        {
            dataField: Math.floor(1000 + Math.random() * 9000).toString(),
            text: "Variation Name",
            formatter: (cell, row) => {
                return row.varaitionName
            },
            filterValue: (cell, row) => {
                return row.varaitionName
            },
            sort: true,
        },
        {
            dataField: Math.floor(1000 + Math.random() * 9000).toString(),
            text: "Quantity",
            formatter: (cell, row) => {
                return row.count;
            },
            filterValue: (cell, row) => {
                return row.count
            },
            sort: true,
        },
    ];

    // this effect will fetch all orders of customer
    useEffect(() => {
        //   if(isTodayCheckBox){ 
        setloading(true);
        dispatch(getOrdersDetails())
        // getSalesReportByServiceType({ ...filter, collectionName: "order" }).then((result) => {
        //     setloading(false);
        //     setlitsData(result.result.reverse().filter(vl => vl?.orderDetails[0] !== undefined));
        //     setshowResetButton(false);
        // });
        //    }
    }, []);

    useEffect(() => {
        setloading(true);
        dispatch(getVariation());
    }, []);



    return (
        <>
            <div className="main-content">
                <div className="page-title">
                    <h2>Variation Report</h2>
                </div>
                <div className="mb-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card customCard">
                                <div className="card-title titlewithbtn pb-2 custom-mobileheading">
                                    <h2>Variation Report</h2>
                                    <div>
                                        {/* <button
                                            className="btn custombtn mr-2"
                                            onClick={() => {
                                                handleDownload("pdf");
                                            }}
                                        >
                                            <FaRegFilePdf />
                                        </button>
                                        <button
                                            className="btn custombtn"
                                            onClick={() => {
                                                handleDownload("excel");
                                            }}
                                        >
                                            <FaRegFileExcel />
                                        </button> */}
                                    </div>
                                </div>
                                <div className="card-body actioncard">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-row">
                                                    <div className="col-sm-3">
                                                        <div className="form-group d-flex align-items-center">
                                                            <label className="mr-2 mb-0">From</label>
                                                            <DatePicker
                                                                className={`form-control ${filterError.from ? "invalid-field" : ""
                                                                    }`}
                                                                name="from"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={moment(filter.from).toDate()}
                                                                minDate={moment().subtract("3", "M").toDate()}
                                                                maxDate={moment().subtract("1", "d").toDate()}
                                                                onChange={(value) => {
                                                                    handleFilterChange("from", value);
                                                                }}
                                                            />
                                                            {filterError.from ? (
                                                                <div className="invalid-feedback">
                                                                    from date should be lower than to date
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group d-flex align-items-center">
                                                            <label className="mr-2 mb-0">To</label>
                                                            <DatePicker
                                                                className={`form-control ${filterError.to ? "invalid-field" : ""
                                                                    }`}
                                                                name="to"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={moment(filter.to).toDate()}
                                                                minDate={moment().subtract("3", "M").toDate()}
                                                                maxDate={moment().subtract("1", "d").toDate()}
                                                                onChange={(value) => {
                                                                    handleFilterChange("to", value);
                                                                }}
                                                            />
                                                            {filterError.to ? (
                                                                <div className="invalid-feedback">
                                                                    to date should be Greater than from date
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <select
                                                            name={"varaitionName"}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                handleFilterChange(
                                                                    "varaitionName",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            defaultValue={filter.varaitionName}
                                                        >
                                                            <option disabled selected>Select Variation</option>
                                                            <option value={""}>All</option>
                                                            {variations.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        {filterError.varaitionName ? (
                                                            <div className="invalid-feedback">
                                                                this is required
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className="col-sm-3 searhorder-btn">
                                                        <div className="regBtn">
                                                            <button className="btn mr-2" disabled={false}>
                                                                Search
                                                            </button>

                                                            {showResetButton ? (
                                                                <button className="btn" onClick={resetFilter}>
                                                                    clear
                                                                </button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="text-right mb-3">
                                        <div className="today_order-wrapper">
                                            <input
                                                type="checkbox"
                                                defaultChecked={isTodayCheckBox}
                                                onClick={(e) => handleToggleCheckBox(e.target.checked)}
                                            />
                                            <b className="ml-1">Today's Orders</b>{" "}
                                        </div>
                                    </div> */}
                                    &nbsp;
                                    <BootstrapeTableData
                                        data={variationData}
                                        columns={columns}
                                        keyfield={"_id"}
                                        searchBar={true}
                                        isLoading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
