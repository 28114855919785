import * as types from "../Action/types";

var intialState = {
  homeBanner: [],
  isloading: false,
};
function HomeBannerReducer(state = intialState, { type, payload }) {
  switch (type) {
    case types.GET_HOME_BANNER:
      return { ...state, isloading: true };
    case types.GET_HOME_BANNER_SUCCESS:
      return { ...state, isloading: false, homeBanner: payload };
    default:
      return state;
  }
}
export default HomeBannerReducer;
