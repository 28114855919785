import axios from "axios";
import { DEPLOY_PLATFORM } from "../Utils/config";

/***
 * Here,Below statement will set the header for Axios
 * ** */
axios.defaults.baseURL = DEPLOY_PLATFORM;

export const setAuthorization = (token) => {
  if (token !== null) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

/***
 * Get function will use to get data
 * ** */
export const get = async (url, config) => {
  let locationId = localStorage.getItem("locationId");
  url = url + "?locationId=" + locationId;
  return (await axios.get(url, config)).data;
};

export const getWithMultiQuery = async (url, config) => {
  let locationId = localStorage.getItem("locationId");
  url = url + "locationId=" + locationId;
  return (await axios.get(url, config)).data;
};

export const newGet = async (url, config) => {
  let locationId = localStorage.getItem("locationId");
  url = url + "?locationId=" + locationId;
  return await axios.get(url, config);
};
export const downloadFile = async (url, config) => {
  let locationId = localStorage.getItem("locationId");
  url = url + "&locationId=" + locationId;
  window.open(axios.defaults.baseURL + url);
};
/***
 * Post function will use to save data
 * ** */
export const post = async (url, data) => {
  let locationId = localStorage.getItem("locationId");
  
  if (data instanceof FormData) {
    data.append("locationId", locationId);
  } else if (Array.isArray(data)) {
    data = data.map((item) => ({
      ...item,
      locationId,
    }));
  } else {
    data = {
      ...data,
      locationId,
    };
  }
  return (await axios.post(url, data)).data;
};

export const newPost = async (url, data) => {
  let locationId = localStorage.getItem("locationId");
  if (data instanceof FormData) {
    data.append("locationId", locationId);
  } else if (Array.isArray(data)) {
    data = data.map((item) => ({
      ...item,
      locationId,
    }));
  } else {
    data = {
      ...data,
      locationId,
    };
  }
  return await axios.post(url, data);
};
/***
 * Put function will use to update data
 * ** */
export const put = async (url, data) => {
  let locationId = localStorage.getItem("locationId");
  if (data instanceof FormData) {
    data.append("locationId", locationId);
  } else if (Array.isArray(data)) {
    data = data.map((item) => ({
      ...item,
      locationId,
    }));
  } else {
    data = {
      ...data,
      locationId,
    };
  }
  return (await axios.put(url, data)).data;
};

/***
 * DeleteRec function will use to delete data
 * ** */
export const deleteRec = async (url) => {
  // let locationId = localStorage.getItem("locationId");
  // url = url + "&locationId=" + locationId;
  return (await axios.delete(url)).data;
};

// patch records
export const patchRec = async (url) => {
  // let locationId = localStorage.getItem("locationId");
  // url = url + "&locationId=" + locationId;
  return (await axios.patch(url)).data;
};

