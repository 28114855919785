import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateDelAndPick } from "../../../Action/checkoutAction";
import {
  getCityDataService,
  getCountryDataService,
  getCustomerAddressAndPhoneNumber,
  getStateDataService,
} from "../../../Services/authenticationServices";
import deliveryPNG from "../assets/images/delivery.png";
import takeover from "../assets/images/takeOver.png";
import "./customCart.css";
import awesomeusercircle from "../assets/images/awesome-user-circle.svg";
import envelopeIcon from "../../../Assets/images/envelope.svg";
import materiallocation from "../assets/images/material-location-on.svg";
import awesomePhoneicon from "../assets/images/awesome-phone-alt.svg";
import userImage from "../../../Assets/images/user.png";

import {
  getCountryListAction,
  getStateListAction,
  getCityListAction,
} from "../../../Action/manageProfileAction";
import {
  calculateDeliveryCharges,
  getPlacesSuggestions,
  getFormatedAddress,
} from "../../../Services/deliveryAndCharges";
import { ComponentLoader } from "../../../helpers/PageLoader";
import { getAddressAndPhone } from "../../../Action/authAction";

const DeliveryAndPickup = ({
  show,
  handleDeliveryAndPickupModel,
  data = {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [country, setcountry] = useState([]);
  const [state, setstate] = useState([]);
  const [city, setcity] = useState([]);
  const [isready, setisready] = useState(false);
  const [finalAddress, setFinalAddress] = useState(null);
  const getManageProfile = useSelector((state) => state.getManageProfile);
  const customer = useSelector((state) => state.customer);
  const cart = useSelector((state) => state.cart);
  const addressAndPhone = useSelector((state) => state.addressAndPhone);
  const [isAskForConfirmation, setisAskForConfirmation] = useState(
    customer.isCustomerLoggedIn ? false : data.data ? false : true
  );
  const [searchList, setSearchList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [placeSuggestion, setplaceSuggestion] = useState([]);
  const [placeSuggestionTool, setplaceSuggestionTool] = useState({
    show: false,
    loading: false,
    timeout: 0,
  });
  const [subtotal, setsubtotal] = useState(0);
  const [minimumPurchase, setminimumPurchase] = useState(0);
  const [isloading, setisloading] = useState(false);
  /** this effect will get country cities status */
  useEffect(() => {
    dispatch(getCountryListAction());
    dispatch(getStateListAction({}));
    dispatch(getCityListAction({}));
  }, [dispatch]);

  /** this effect will handle array of  country cities status */
  useEffect(() => {
    //get country state and cities
    const { countrtyList, stateList, cityList } = getManageProfile;
    setSearchList({
      countryList: countrtyList,
      stateList: stateList,
      cityList: cityList,
    });
  }, [getManageProfile]);

  const [address, setaddress] = useState({
    addressId: "",
    address: "",
  });
  /** this effect will get restaurent address and phone number  */
  useEffect(() => {
    setisloading(addressAndPhone.loading);
    try {
      setisloading(false);
      let result = addressAndPhone.data;
      setaddress({
        addressId: result.address._id,
        address: `${result.address.street} , ${result.address.city}, ${result.address.state}`,
      });
      setpickup({
        storeLocation: `${result.address.street}, ${result.address.state}`,
        addressId: result.address._id,
      });
      setminimumPurchase(result.minimumPurchase || 0);
    } catch (error) {
      setisloading(false);
    }
  }, [addressAndPhone]);

  const [delivery, setdelivery] = useState(
    data.type === "delivery"
      ? data.data
      : {
          firstName: "",
          lastName: "",
          // streetAddress:"",
          contactNumber: "",
          email: "",
          city: " ",
          street: "",
          deliveryCharge: 0,
          driverReimbursement: 0,
          fullAddress: "",
        }
  );

  const [deliveryerror, setdeliveryerror] = useState({
    firstName: false,
    lastName: false,
    contactNumber: false,
    email: false,
    // streetAddress:false,
    // country: false,
    // state: false,
    // city: false,
    street: false,
  });
  const [pickup, setpickup] = useState(
    data.type === "pickup"
      ? data.data
      : { storeLocation: address.address, addressId: address.addressId }
  );
  const [pickuperror, setpickuperror] = useState({
    storeLocation: false,
  });
  const [option, setOption] = useState(
    data.type === "delivery"
      ? {
          del: true,
          pick: false,
        }
      : {
          del: false,
          pick: true,
        }
  );
  const [deliverChargesError, setdeliverChargesError] = useState(false);
  /**  this effect will get country data service */
  useEffect(() => {
    getCountryDataService().then((c) => {
      setcountry(c.result);
    });
  }, []);
  /**
   * this effect will calculate sub-total
   */
  useEffect(() => {
    let total = 0;
    cart.map((cartItem, index) => {
      total = total + cartItem.itemPrice * cartItem.quantity;
    });
    setsubtotal(total);
  }, [cart]);
  /*** this effect will pre populate the customer data if logged in  */
  useEffect(() => {
    if (customer.isCustomerLoggedIn) {
      if (!data.data) {
        if (option.del === true && option.pick === false) {
          getCustomerAddressAndPhoneNumber().then(async (res) => {
            let tmpDelivery = { ...delivery };
            tmpDelivery.firstName = res.result.fname;
            tmpDelivery.lastName = res.result.lname;
            tmpDelivery.email = res.result.email;
            tmpDelivery.contactNumber = normalizeInput(
              res.result.contactNumber.toString()
            );
            tmpDelivery.street = res.result.street;
            // tmpDelivery.streetAddress = res.result.streetAddress;
            tmpDelivery.fullAddress = res.result.street;
            setdelivery({ ...delivery, ...tmpDelivery });
            const formattedAddress = await getFormatedAddress({
              addressLines: res.result.street,
            });
            setFinalAddress(formattedAddress.result);
          });
        }
      }
    }
  }, [customer, option]);
  /**  this function will add mask on phone number input field */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  const validatePhoneNumber = (value, previousValue) => {
    value = value.replace(/[()  -]/g, "");
    if (value.length < 10) {
      return false;
    }
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return false;
      if (cvLength < 7) return false;

      let firstPhase = currentValue.slice(0, 3);
      let secondPhase = currentValue.slice(3, 6);
      let thiredPhase = currentValue.slice(6, 10);
      if (
        firstPhase == "000" ||
        /** Commented this line to avoid QA issue 977 */
        // (firstPhase == secondPhase &&
        //   secondPhase == thiredPhase.slice(0, -1)) ||
        secondPhase + thiredPhase == "0000000"
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  /** handle check of delivery and pickup */
  const activeOption = (e) => {
    if (minimumPurchase > subtotal) {
      return;
    }
    const status = e;
    if (status == "pickup") {
      setOption({
        del: false,
        pick: true,
      });
    } else {
      setOption({
        del: true,
        pick: false,
      });
    }
  };
  /*** this will calculte delivery charges  */
  const handleCalculateDeliveryCharges = (deliveryAddr) => {
    setdeliverChargesError(false);
    if (option.del === true && option.pick === false) {
      calculateDeliveryCharges(deliveryAddr)
        .then((res) => {
          let response = res.result;
          setdelivery({
            ...deliveryAddr,
            deliveryCharge: response.price,
            driverReimbursement: response.driverReimbursement,
          });
          dispatch(
            updateDelAndPick({
              type: "delivery",
              data: deliveryAddr,
            })
          );
          data.data
            ? handleDeliveryAndPickupModel()
            : history.push("/checkout");
        })
        .catch((error) => {
          //will handle error ;
          setdeliverChargesError(true);
        });
    } else {
      dispatch(
        updateDelAndPick({
          type: "pickup",
          data: pickup,
        })
      );
      data.data ? handleDeliveryAndPickupModel() : history.push("/checkout");
    }
  };

  /** handle submit the form  */
  const handleNextStage = () => {
    if (option.del === true && option.pick === false) {
      //this will handle delivery state
      var isValidate = true;
      var tmpError = { ...deliveryerror };
      for (key in delivery) {
        if (key === "contactNumber") {
          if (!validatePhoneNumber(delivery[key].toString())) {
            tmpError[key] = true;
            isValidate = false;
          } else {
            tmpError[key] = false;
          }
        } else if (delivery[key] === "" || delivery[key] == undefined) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      }
      setdeliveryerror(tmpError);
    } else {
      //this will handle delivery state
      isValidate = true;
      tmpError = { ...pickuperror };
      for (var key in pickup) {
        if (pickup[key] === "" || pickup[key] == undefined) {
          tmpError[key] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
        }
      }
      // update into redux
      if (isValidate)
        dispatch(
          updateDelAndPick({
            type: "pickup",
            data: pickup,
          })
        );
    }
    if (isValidate)
      handleCalculateDeliveryCharges({
        ...delivery,
        street: finalAddress,
        fullAddress: finalAddress,
      });
  };
  /** validate the contact number  */
  const contains = function (charValue) {
    //eslint-disable-next-line
    let pval = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return !pval.test(charValue);
  };
  /** this function will handle the changes of  delivery  side */
  const handleDeliveryChange = (e) => {
    if (delivery.street === "") {
      setFinalAddress(null);
    }
    var isValidate = true;
    var tmpError = { ...deliveryerror };
    if (e.target.name === "contactNumber") {
      setdelivery({
        ...delivery,
        [e.target.name]: normalizeInput(e.target.value),
      });
    } else if (e.target.name === "street") {
      setdelivery({ ...delivery, [e.target.name]: e.target.value });
      handlePlaceSuggestionChange(e.target.value);
    } else {
      setdelivery({ ...delivery, [e.target.name]: e.target.value });
    }
    if (e.target.name === "contactNumber") {
      if (!validatePhoneNumber(e.target.value.toString())) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.name === "email") {
      //eslint-disable-next-line
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.target.value
        )
      ) {
        tmpError[e.target.name] = true;
        isValidate = false;
      } else {
        tmpError[e.target.name] = false;
      }
    } else if (e.target.value === "" || e.target.value == undefined) {
      tmpError[e.target.name] = true;
      isValidate = false;
    } else {
      tmpError[e.target.name] = false;
    }
    setdeliveryerror(tmpError);
  };
  /** this function will handle the changes of  pickup  side */
  const handlePickChange = (e) => {
    setpickup({ ...pickup, [e.target.name]: e.target.value });
  };
  /*** this function will handle the chenges in street field */
  const handlePlaceSuggestionChange = (text) => {
    clearTimeout(placeSuggestionTool.timeout);
    let tmp = setTimeout(() => {
      if (!placeSuggestionTool.show) {
        setplaceSuggestionTool({
          ...placeSuggestionTool,
          show: true,
          loading: true,
        });
      }
      getPlacesSuggestions({ input: text })
        .then((res) => {
          setplaceSuggestion(res.result ?? []);
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: true,
          });
        })
        .catch((error) => {
          setplaceSuggestionTool({
            ...placeSuggestionTool,
            loading: false,
            show: false,
          });
        });
      if (deliverChargesError) {
        setdeliverChargesError(false);
      }
    }, 500);
    setplaceSuggestionTool({ ...placeSuggestionTool, ["timeout"]: tmp });
  };
  /** this function will handle the click of place suggestion list  */
  const handlePlaceSuggestionClick = async (
    addressString,
    fullAddressString
  ) => {
    setdelivery({
      ...delivery,
      ["street"]: fullAddressString,
      ["fullAddress"]: fullAddressString,
    });
    setplaceSuggestionTool({
      ...placeSuggestionTool,
      loading: false,
      show: false,
    });
    const formattedAddress = await getFormatedAddress({
      addressLines: fullAddressString,
    });
    setFinalAddress(formattedAddress.result);
  };

  /** this fucntion will close the model */
  const handleCloseModel = () => {
    handleDeliveryAndPickupModel();
  };
  const handleisAskConfirmation = (optionString) => {
    if (optionString === "login") {
      document.getElementById("login-button").click();
      setisAskForConfirmation(false);
    } else {
      setisAskForConfirmation(false);
    }
  };
  /** filterization of state and city according the to the country and state */
  //var State = searchList.countryList.filter(item => item.countryName === delivery.country)[0];
  //var City = searchList.stateList.filter(item => item.stateName === delivery.state)[0];
  return (
    <>
      <div
        className={`modal custommodal custommodel1 ${show ? "showModel" : ""}`}
        id="checkoutPopUp"
        show={{ top: "15px !importent", left: "15px !importend" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header position-relative">
              <h4 className="modal-title"> Service Options </h4>
              <button
                onClick={handleCloseModel}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body serviceoption-modal">
              {isloading ? (
                <ComponentLoader />
              ) : isAskForConfirmation ? (
                <>
                  <div className="row">
                    <div className="col-6">
                      <div
                        className={`select1 select1-box-c`}
                        onClick={() => handleisAskConfirmation("login")}
                      >
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <h3 className="serviceoption-head">Login</h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`select1 select1-box-c`}
                        onClick={() => handleisAskConfirmation("guest")}
                      >
                        <i
                          className="fa fa-shopping-cart"
                          aria-hidden="true"
                        ></i>
                        <h3 className="serviceoption-head">Guest</h3>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="chooseOption container">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className={`select1 ${option.del ? "active" : ""}`}
                          onClick={() => activeOption("delivery")}
                        >
                          <img
                            className="mr-2"
                            src={deliveryPNG}
                            alt="delivery"
                          />
                          <h3>Delivery</h3>
                        </div>
                        {minimumPurchase > subtotal ? (
                          <div className="c-red">
                            Minimum purchase should be more than $
                            {minimumPurchase} for delivery
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-6">
                        <div
                          className={`select1 ${option.pick ? "active" : ""}`}
                          onClick={() => activeOption("pickup")}
                        >
                          <img className="mr-2" src={takeover} alt="delivery" />
                          <h3>PickUp</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="selectedDetails mt-5">
                    {option.del ? (
                      <div className="deliveryOption container">
                        <div className="form-row mt-3">
                          <div className="col-md-6">
                            <div className="form-group has-search">
                              <span className="form-control-feedback">
                                <img src={userImage} alt="" />
                              </span>
                              <input
                                type="text"
                                value={delivery.firstName}
                                className={`form-control ${
                                  deliveryerror.firstName ? "invalid-field" : ""
                                }`}
                                placeholder="First Name"
                                name="firstName"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                                disabled={customer.isCustomerLoggedIn}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group has-search">
                              <span className="form-control-feedback">
                                <img src={userImage} alt="" />
                              </span>
                              <input
                                type="text"
                                value={delivery.lastName}
                                className={`form-control ${
                                  deliveryerror.lastName ? "invalid-field" : ""
                                }`}
                                placeholder="Last Name"
                                name="lastName"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                                disabled={customer.isCustomerLoggedIn}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group has-search">
                              <span className="form-control-feedback address-span">
                                <img src={awesomePhoneicon} alt="" />
                              </span>
                              <input
                                type="tel"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                value={delivery.contactNumber}
                                className={`form-control ${
                                  deliveryerror.contactNumber
                                    ? "invalid-field"
                                    : ""
                                }`}
                                placeholder="Contact Number"
                                name="contactNumber"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                                disabled={customer.isCustomerLoggedIn}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group has-search">
                              <span className="form-control-feedback">
                                <img src={envelopeIcon} alt="" />
                              </span>
                              <input
                                type="email"
                                value={delivery.email}
                                className={`form-control ${
                                  deliveryerror.email ? "invalid-field" : ""
                                }`}
                                placeholder="Email ID"
                                name="email"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                                disabled={customer.isCustomerLoggedIn}
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                            <div className="form-group has-search">
                            <span className="form-control-feedback address-span">
                                <img src={materiallocation} alt="" />
                              </span>
                              <input
                                type="text"
                                value={delivery.streetAddress}
                                className={`form-control ${
                                  deliveryerror.streetAddress ? "invalid-field" : ""
                                }`}
                                placeholder="Street Number"
                                name="streetAddress"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                                disabled={customer.isCustomerLoggedIn}
                              />
                            </div>
                          </div> */}

                          <div className="col-md-12">
                            <div className="form-group has-search">
                              <span className="form-control-feedback address-span">
                                <img src={materiallocation} alt="" />
                              </span>
                              <input
                                type="text"
                                value={delivery.street}
                                className={`form-control ${
                                  deliveryerror.street ? "invalid-field" : ""
                                }`}
                                name="street"
                                placeholder="Street and Zip code"
                                onChange={(e) => {
                                  handleDeliveryChange(e);
                                }}
                              />
                              {placeSuggestionTool.show ? (
                                <div className="autocomplete-items">
                                  {placeSuggestionTool.loading ? (
                                    <div>loading...</div>
                                  ) : (
                                    <div className="list-group">
                                      {placeSuggestion.map((item, index) => {
                                        return (
                                          <li
                                            className="list-group-item list-group-item-action flex-column align-items-start"
                                            key={index}
                                            onClick={() => {
                                              handlePlaceSuggestionClick(
                                                item.structured_formatting
                                                  .main_text,
                                                item.description
                                              );
                                            }}
                                          >
                                            <strong>
                                              {
                                                item.structured_formatting
                                                  .main_text
                                              }
                                            </strong>
                                            <p>{item.description}</p>
                                          </li>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <br />
                              {!!finalAddress && delivery.street ? (
                                <div className="formatedview">
                                  <h5>Formatted Address</h5>
                                  <p>&nbsp;&nbsp;{finalAddress}</p>
                                </div>
                              ) : (
                                ""
                              )}
                              {deliverChargesError ? (
                                <div className="invalid-feedback">
                                  We can`t deliver on this address
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {option.pick ? (
                      <div className="pickupOption container">
                        <div className="form-row mt-3">
                          <div className="col-md-12">
                            <div className="form-group has-search">
                              <label>Nearest Store Location</label>
                              <span className="form-control-feedback address-span">
                                <img src={materiallocation} alt="" />
                              </span>
                              <select
                                value={pickup.storeLocation}
                                className={`form-control ${
                                  pickuperror.storeLocation
                                    ? "invalid-field"
                                    : ""
                                }`}
                                name="storeLocation"
                                onChange={(e) => {
                                  handlePickChange(e);
                                }}
                              >
                                <option value={address.addressId} selected>
                                  {" "}
                                  {address.address}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            {isAskForConfirmation ? (
              <></>
            ) : (
              <div className="modal-footer custom-model-footer">
                <div className="customfooter position-relative">
                  <div className="cstfooter-inner justify-content-end">
                    <div className="BtnWrapper">
                      <button
                        className="btn cancel mr-2"
                        data-dismiss="modal"
                        onClick={handleCloseModel}
                      >
                        CANCEL
                      </button>
                      <button className="btn ok" onClick={handleNextStage}>
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryAndPickup;
