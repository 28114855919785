import { NavLink } from "react-router-dom";
export default function CopyRight() {
  return (
    <div className="bottom-footer z-top">
      <div className="container">
        <div className="row ">
          <div className="col-md-8">
            <p>© Copyright Oliverspizza 2022. All Right Reserved</p>
          </div>
          <div className="col-md-4 text-right">
            <NavLink to="">Terms of Use</NavLink>
            <NavLink to="">Privacy Policy</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
