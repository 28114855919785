import React, { useEffect, useState, useCallback } from "react";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import ManageDriverIndex from '../manageDriverIndex';
import { useParams } from 'react-router-dom';
import { driverHistory } from "../../../Services/manageCrewOrDriver";
import formettedDate from "../../../helpers/moomentDate";

const DriverHistory = function DriverOrders(props) {
    const { userId } = useParams();
    const roleName = "driver";
    const [litsData, setLitsData] = useState([]);
    /** this function will dispatch the driver history list  */
    const repectFunction = () => {
        driverHistory(userId).then(res => {
            setLitsData(res.result);
        }).catch(err => {

        });
    }
    /** this effect will handle the updatation of the list   */
    useEffect(() => {
        repectFunction()
        setInterval(e => {
            try {
                if (document.getElementById('search-bar-0').value.length < 1) {
                    repectFunction()
                }
            }
            catch (err) {
                //
            }
        }, 10000);
    }, []);

    const columns = [
        {
            dataField: "checkType",
            text: "Check Type",
            sort: true,
        },
        {
            dataField: "createdAt",
            text: "Date Of Orders",
            formatter: (cell, row) => {
                return formettedDate(cell);
            },
            filterValue: (cell, row) => {
                return formettedDate(cell);
            },
            sortValue: (cell, row) => {
                return formettedDate(cell);
            },
            sort: true,
        }
    ];
    return (
        <>
            <ManageDriverIndex >
                <div className="order-wrapper">
                    <BootstrapeTableData
                        data={litsData}
                        columns={columns}
                        keyfield={"_id"}
                        searchBar={true}
                    //isLoading={isLoding.tableLoading}
                    />
                </div>
            </ManageDriverIndex>
        </>
    )
}

export default DriverHistory;