import React, { useState, useEffect, useCallback } from "react";
import {
  getProductCategoryPublicAction,
  getProductCategoryItemListById,
  getCustomizeItemModifierById,
} from "../../../Action/manageProductCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import MainParent from "../mainParent";
import Banner from "./Banner";
import HomeAbout from "./HomeAbout";
import CategoryCarouselWithtabs from "../helpers/CategoryCarouselWithtabs";
import CostumCart from "../cart/CostumCart";
import { createItemToCart } from "../../../Action/cartAction";
import DeliveryAndPickup from "../cart/DeliveryAndPopup";
import RestorentClosed from "../cart/restorentClosed";
import OutOfStock from "../cart/outOfStock";
const HomePage = () => {
  const dispatch = useDispatch();
  const responseData = useSelector((state) => state.productCat);
  const [popularCatList, setPopularCatList] = useState([]);
  const [productItemid, setProductItemid] = useState("");
  const [categoryItemList, setCategoryItemList] = useState([]);
  const [cartModalShow, setCartModalShow] = useState(false);
  const [itemIdOfcategory, setItIemIdOfcategory] = useState("");
  const [itemName, setitemName] = useState("");
  const [isModelForEdit, setIsModelForEdit] = useState(false);
  const [uniqueId, setuniqueId] = useState(false);
  const [halfTopping, sethalfTopping] = useState(false);
  const [itemImage, setitemImage] = useState("");
  const [deliveryandpickup, setdeliveryandpickup] = useState(false);
  const [restorentStatus, setRestorentStatus] = useState(false);
  const [outOfStock, setoutOfStock] = useState(false);
  /*** normalize the props values  */
  const {
    productCategoryList,
    productCateItemList,
    loading: isLoding,
  } = responseData;
  
 // remove old item data form localstorage
  useEffect(() => {
   if(!cartModalShow){
    localStorage.removeItem("oldItem")
   }
  }, [cartModalShow])

  /** this effect dispatch action for get the product category  */
  useEffect(() => {
    dispatch(getProductCategoryPublicAction());
  }, [dispatch]);

  /** this effect handle  the product category  */
  useEffect(() => {
    if (productCategoryList.length > 0) {
      setPopularCatList(productCategoryList);
    }
    if (productCategoryList.length > 0 && productItemid === "") {
      setProductItemid(productCategoryList[0].UniqueID);
    }
    if (productItemid) {
      dispatch(getProductCategoryItemListById({ itemId: productItemid }));
    }
  }, [productCategoryList, productItemid, dispatch]);
  /*** this effect will update the  product category  */
  useEffect(() => {
    if (productCateItemList) {
      setCategoryItemList(productCateItemList);
    }
  }, [productCateItemList]);
  /*** handle click of the tabs panel */
  const tabsPanelClickFunc = (itemId) => {
    setProductItemid((prevState) =>
      prevState !== itemId ? itemId : prevState
    );
  };
  /** callback function for the tabs panel */
  const tabsPanelClickFuncMemo = useCallback(
    (itemId) => tabsPanelClickFunc(itemId),
    []
  );
  /**  callback function for the category listing on the homepage */
  const customizeModalMemorized = useCallback(
    (
      item,
      itemName,
      isEdited = false,
      uniqueId = null,
      halfTopping = false,
      itemImage = ""
    ) => {
      if (!cartModalShow) {
        setItIemIdOfcategory(item);
        setitemName(itemName);
        setIsModelForEdit(isEdited);
        setuniqueId(uniqueId);
        sethalfTopping(halfTopping);
        setitemImage(itemImage);
        dispatch(getCustomizeItemModifierById(item));
        setCartModalShow((prevState) => !prevState);
      }
    },
    []
  );
  /** handle the open and close of the delivery and pickup model  */
  const handleDeliveryAndPickupModelMemo = useCallback(() => {
    setdeliveryandpickup(!deliveryandpickup);
  });
  
  /** handle the restourent open and close for the restuarant close model  */
  const handleRestorentModelMemo = useCallback(() => {
    setRestorentStatus(!restorentStatus);
  });
  /** handle the out of stock model */
  const handleOutOfStockMemo = useCallback(() => {
    setoutOfStock(!outOfStock);
  });
  return (
    <>
      <Banner />
      <div className="container-fluid">
        {popularCatList && (
          <CategoryCarouselWithtabs
            responseData={responseData}
            categoryslider={popularCatList}
            tabsPanelClickFunc={tabsPanelClickFuncMemo}
            handleDeliveryAndPickupModel={handleDeliveryAndPickupModelMemo}
            categoryItemList={categoryItemList}
            isLoding={isLoding}
            customizeModal={customizeModalMemorized}
            handleOutOfStockMemo={handleOutOfStockMemo}
            handleRestorentClosedModel={handleRestorentModelMemo}
          />
        )}
        <HomeAbout />
      </div>
      {cartModalShow && (
        <CostumCart
          show={cartModalShow}
          itemIdOfcategory={itemIdOfcategory}
          customizeModal={customizeModalMemorized}
          itemName={itemName}
          isEdited={isModelForEdit}
          uniqueId={uniqueId}
          halfTopping={halfTopping}
          itemImage={itemImage}
          handleRestorentClosedModel={handleRestorentModelMemo}
        />
      )}
      {deliveryandpickup && (
        <DeliveryAndPickup
          show={deliveryandpickup}
          handleDeliveryAndPickupModel={handleDeliveryAndPickupModelMemo}
        />
      )}
      {restorentStatus && (
        <RestorentClosed
          show={restorentStatus}
          handleRestorentClosedModel={handleRestorentModelMemo}
        />
      )}
      {outOfStock && (
        <OutOfStock
          show={outOfStock}
          handleOutOfStockMemo={handleOutOfStockMemo}
        />
      )}
    </>
  );
};

export default MainParent(HomePage);
