import * as types from "./types";

/***
 * this action will  save item
 */
export const saveDiscount = (payload) => ({
  type: types.ADD_DISCOUNT,
  payload,
});

/***
 * this action will get item
 */
export const getDiscount = (payload) => ({
  type: types.GET_DISCOUNT,
});

/***
 * this action will update item
 */
export const updateDiscount = (payload) => ({
  type: types.UPDATE_DISCOUNT,
  payload,
});

/***
 * this action will delete item
 */
export const deleteDiscount = (payload) => ({
  type: types.DELETE_DISCOUNT,
  payload,
});
