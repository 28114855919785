import * as types from "./types";

/***
 *  this action will create an item to the cart 
 */
export const createItemToCart = (payload) => {
  return {
    type: types.CREATE_ITEM_TO_CART,
    payload
  };
};
/***
 *  this action will uodate an item to the cart 
 */
export const updateItemToCart = (payload) => {
  return {
    type: types.UPDATE_ITEM_TO_CART,
    payload
  };
};
/***
 *  this action will delete an item to the cart 
 */
export const deleteItemToCart = (payload) => {
  return {
    type: types.DELETE_ITEM_TO_CART,
    payload
  };
};
/***
 *  this action will increment an item quantity to the cart 
 */
export const incrementItemToCart = (payload) => {
    return {
      type: types.INCREMENT_ITEM_TO_CART,
      payload
    };
};

/***
 *  this action will delete an mofifier data  an item to the cart 
 */
export const deleteModifierToCart = (payload) => {
  return {
    type: types.DELETE_MODIFIER_TO_CART,
    payload
  };
};
/***
 *  this action will delete an mofifier data  an item to the cart 
 */
export const decrementItemToCart = (payload) => {
  return {
    type: types.DECREMENT_ITEM_TO_CART,
    payload
  };
};

/***
 *  this action will delete all item  to the cart 
 */
export const flushCart = () => {
  return {
    type: types.FLUSH_CART
  };
};