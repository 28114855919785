import * as api from './api';

//get delivery charges list 
export const getDeliveryChargesList = async () => {
    try {
        return await api.get('deliveryCharges/getDeliveryAndCharges');
    }
    catch (error) {
        return error;
    }
}
//add delivery charges 
export const addDeliveryCharges = async (params) => {
    try {
        return await api.post('deliveryCharges/addDeliveryAndCharges', params);
    }
    catch (error) {
        return error;
    }
}
//update delivery charges 
export const updateDeliveryCharges = async (params) => {
    let id = params._id;
    try {
        return await api.put(`deliveryCharges/updateDeliveryCharges/${id}`, params.data);
    }
    catch (error) {
        return error;
    }
}
//delete delivery charges
export const deleteDeliveryCharges = async (params) => {
    let id = params._id;
    delete params._id;
    try {
        return await api.deleteRec(`deliveryCharges/deleteDeliveryCharges/${id}`, params);
    }
    catch (error) {
        return error;
    }
}
//calcualte delivery charges 
export const calculateDeliveryCharges = async (params) => {
    try {
        return await api.post('deliveryCharges/calculateDeliveryCharges', params);
    }
    catch (error) {
        return error;
    }
}
//get places suggestions 
export const getPlacesSuggestions = async (params) => {
    try {
        return await api.post('deliveryCharges/placesSuggestions', params);
    }
    catch (error) {
        return error;
    }
}

//get formated address ; 
export const getFormatedAddress = async (params) => {
    try {
        return await api.post('deliveryCharges/formattedAddress', params);
    }
    catch (error) {
        return error;
    }
}