import moment from "moment";
import * as api from "./api";

// Service for User Auth
export const loginService = async (payload) => {
  try {
    let response = await api.post("user/login", payload);
    return response;
  } catch (error) {
    return error;
  }
};

//customer login service
export const customerLoginService = async (payload) => {
  try {
    let response = await api.post("user/customerLogin", payload);
    return response;
  } catch (error) {
    return error;
  }
};

//customer registration service
export const customerRegistrationService = async (payload) => {
  try {
    return new Promise((resolve, reject) => {
      api
        .newPost("user/customerRegistration", payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          resolve(err.response);
        });
    });
  } catch (error) {
    return error;
  }
};

// Service for Get manage Profile data
export const getManageProfileservice = async (payload) => {
  const { userId } = payload;
  try {
    let response = await api.get(`user/getUser/${userId}`);
    return response;
  } catch (error) {
    return error;
  }
};
//sarvice for the update the  profiles
export const updateManageProfileservice = async (payload) => {
  const { userId } = payload;
  try {
    let response = await api.put(`user/updateUser/${userId}`, {
      restorentStatus: payload.restorentStatus,
    });
    return response;
  } catch (error) {
    return error;
  }
};
//sarvice for the update the  profiles
export const updateDiscountStatus = async (payload) => {
  const { userId } = payload;
  try {
    let response = await api.put(`user/updateUser/${userId}`, {
      discountStatus: payload.discountStatus,
    });
    return response;
  } catch (error) {
    return error;
  }
};
//sarvice for the update the  profiles
export const updateCouponStatus = async (payload) => {
  const { userId } = payload;
  try {
    let response = await api.put(`user/updateUser/${userId}`, {
      couponStatus: payload.couponStatus,
    });
    return response;
  } catch (error) {
    return error;
  }
};
//service for update the profile 2
export const updateManageProfileservice2 = async (payload) => {
  const { userId, data } = payload;
  try {
    let response = await api.put(`user/updateUser2/${userId}`, data);
    return response;
  } catch (error) {
    return error;
  }
};
//service for update the profile tax
export const updateManageProfileserviceTax = async (payload) => {
  const { userId, data } = payload;
  try {
    let response = await api.put(`user/updateTax/${userId}`, data);
    return response;
  } catch (error) {
    return error;
  }
};
//service for update the stripe key
export const updateManageProfileStripeKey = async (payload) => {
  const { userId, data } = payload;
  try {
    let response = await api.put(`user/updateStripeKey/${userId}`, data);
    return response;
  } catch (error) {
    return error;
  }
};
//service for update the customer
export const updateCustomer = async (payload) => {
  try {
    let response = await api.put(`user/updateCustomer`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
//service to check restorent status
export const checkRestorentStatus = async () => {
  try {
    var currenDate = new Date();
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var currentTime = moment().format("HH:mm");
    var currentDay = days[currenDate.getDay()];
    let response = await api.post(`user/checkRestorentStatus`, {
      currentTime,
      currentDay,
    });
    return response;
  } catch (error) {
    return error;
  }
};
//savice to get country
export const getCountryDataService = async () => {
  try {
    let response = await api.get(`user/getCountry`);
    return response;
  } catch (error) {
    return error;
  }
};

// service state list
export const getStateDataService = async (payload) => {
  const { countryId } = payload.payload;
  try {
    let response = await api.get(`user/getState`);
    return response;
  } catch (error) {
    return error;
  }
};

// servive for city list
export const getCityDataService = async (payload) => {
  const { stateId } = payload;
  try {
    let response = await api.get(`user/getCity`);
    return response;
  } catch (error) {
    return error;
  }
};

//service get user object with address
export const getUserAddressAndPhoneNumber = async (payload) => {
  try {
    let response = await api.get(`user/getUserAddressAndPhoneNumber`);
    return response;
  } catch (error) {
    return error;
  }
};

//service to get user object with address
export const getCustomerAddressAndPhoneNumber = async (payload) => {
  try {
    let response = await api.get(`user/getCustomerAddressAndPhoneNumber`);
    return response;
  } catch (error) {
    return error;
  }
};
//service to change the password of  the customer
export const changePassword = async (payload) => {
  try {
    return new Promise((resolve, reject) => {
      api
        .newPost(`user/changePassword`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  } catch (error) {
    return error;
  }
};

//service send forgot password email
export const fireForgotMail = async (payload) => {
  return new Promise((resolve, reject) => {
    api
      .newPost("/user/forgotPassword", payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

//service send forgot password email
export const forgotPasswordLink = async (payload) => {
  return new Promise((resolve, reject) => {
    api
      .newPost("/user/resetPassword", payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

// service state list
export const getLocationService = async () => {
  try {
    let response = await api.get(`user/getalllocation`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getLocationServiceAdmin = async () => {
  try {
    let response = await api.get(`user/getalllocationAdmin`);
    return response;
  } catch (error) {
    return error;
  }
};
// service state list
export const createLocationService = async (payload) => {
  try {
    return new Promise((resolve, reject) => {
      api
        .newPost(`user/createLocation`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  } catch (error) {
    return error;
  }
};

// service state list
export const updateLocationService = async (payload) => {
  const { id, data } = payload;
  try {
    return new Promise((resolve, reject) => {
      api
        .newPost(`user/updateLocation/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  } catch (error) {
    return error;
  }
};
