import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../../helpers/RightSideModal";
import { useDispatch, useSelector } from "react-redux";
import "../manageCrewOrDriverOrders.css";
import { getOrderItemData } from "../../../Services/manageOrderServices";
import { changeOrderStatus } from "../../../Action/manageOrdersAction";
import { getCrewOrDriver } from "../../../Action/crewOrDriverAction";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js"


const ManageDriverOrderPopup = (props) => {
  const dispatch = useDispatch();
  const [orderStatus, setorderStatus] = useState({
    OrderStatusCode: "crew",
    userId: "",
    timing: "",
  });
  /*** get crew and driver list from redux */
  var { crewOrDriverList } = useSelector((state) => state.crewOrDriver);
  /** order status is driver than set the  driver status */
  if (orderStatus.OrderStatusCode === "driver") {
    crewOrDriverList = crewOrDriverList.filter((e) => e.checkInOut !== "out");
  }
  var crewUserDefault = crewOrDriverList.find(
    (v) => v.role === "crew" && v.fname === "default" && v.lname === "crew"
  );
  crewUserDefault = crewUserDefault ? crewUserDefault : {};
  /*** normalizing the props  */
  const {
    showModal,
    closeModal,
    editData: { edit, data, userData, paymentData },
    isTodayCheckBox,
  } = props;
  const [isloading, setisloading] = useState(false);
  const [orderStatusError, setorderStatusError] = useState({
    OrderStatusCode: false,
    userId: false,
    timing: false,
  });
  /** this function will handle order status chnage */
  const handleOrderStatus = (e) => {
    setorderStatusError({
      ...orderStatusError,
      [e.target.name]: e.target.value === "" ? true : false,
    });
    setorderStatus({ ...orderStatus, [e.target.name]: e.target.value });
  };
  const [orderitemData, setorderitemData] = useState([]);
  /*** this function will  close model and clear all state */
  const closeModaleWithClearAll = () => {
    setorderStatus({
      OrderStatusCode: "crew",
      userId: "",
      timing: "",
    });
    closeModal();
  };
  /** assigning address to new variable to preventing exception */
  var address = "";
  try {
    address = data.address[0].street;
  } catch (err) {
    //
  }
  //generate google map url address
  const generateGoogleMapurl = (lat, long) => {
    return `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${long}`;
  };
  /*** this function will get orders details */
  useEffect(() => {
    setisloading(true);
    getOrderItemData(data._id)
      .then((res) => {
        setisloading(false);
        setorderitemData(res.result);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, [data]);
  /** this effect will dispatch the crew and driver */
  useEffect(() => {
    dispatch(getCrewOrDriver());
  }, [orderStatus]);
  /** this function will handle fields change */
  const handleChangeOrderStatus = (id) => {
    isTodayCheckBox
      ? dispatch(
          changeOrderStatus({
            id,
            status: "delivered",
            collectionName: "order",
          })
        )
      : dispatch(
          changeOrderStatus({
            id,
            status: "delivered",
            collectionName: "AllOrders",
          })
        );

    closeModaleWithClearAll();
    setorderStatus({
      OrderStatusCode: "",
      userId: "",
      timing: "",
    });
    setorderStatusError({
      OrderStatusCode: false,
      userId: false,
      timing: false,
    });
  };
  /**  this will filterize values */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /**  this function will return button name according to the condition  */
  const ButtonName = () => {
    return "Delivered";
  };
  /*** this function will return order value according to the condition */
  const OrderPopupFooter = () => {
    const { OrderStatusCode, OrderServiceOption, qrCode } = data;
    if (OrderStatusCode === "new") {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <select
                value={orderStatus.timing}
                name="timing"
                onChange={handleOrderStatus}
                className={`form-control ${
                  orderStatusError.timing ? "invalid-field" : ""
                }`}
              >
                <option value="" selected>
                  Select Timing
                </option>
                <option key={1} value="15">
                  15 Minutes
                </option>
                <option key={2} value="20">
                  20 Minutes
                </option>
                <option key={3} value="25">
                  25 Minutes
                </option>
                <option key={4} value="30">
                  30 Minutes
                </option>
                <option key={5} value="35">
                  35 Minutes
                </option>
                <option key={6} value="40">
                  40 Minutes
                </option>
                <option key={7} value="45">
                  45 Minutes
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checoutbtn">
                <button
                  type="submit"
                  className="btn mt-0"
                  onClick={() => {
                    handleChangeOrderStatus(data._id);
                  }}
                >
                  <ButtonName />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (OrderStatusCode === "crew") {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checoutbtn">
                <button
                  type="submit"
                  className="btn mt-0"
                  onClick={() => {
                    handleChangeOrderStatus(data._id);
                  }}
                >
                  <ButtonName />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      OrderStatusCode === "ready" &&
      OrderServiceOption === "delivery"
    ) {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <img src={qrCode} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checoutbtn">
                <button
                  className="btn"
                  onClick={() => {
                    history.push(`/orders/${data.randomOrderId}`);
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (OrderStatusCode === "driver") {
      return (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <img src={qrCode} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checoutbtn">
                <button
                  className="btn"
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    value = value.toString();
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"100"}
          title={edit === true ? "Order Details" : "Add Modifier Category"}
          addNewButtonName={"Add New"}
          edit={edit}
          className={"order-detailspopup"}
        >
          <div className="order-details-pop px-3 py-1">
            <div className="row">
              <div className="col-md-6">
                <table className="table1 ordertable ordertable-info">
                  <tbody>
                    <tr>
                      <th>Customer Name</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {userData.fname + " " + userData.lname}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Customer Email</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {userData.email}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Customer Contact Number</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {normalizeInput(data.contactNumber)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {data.OrderServiceOption === "pickup"
                          ? "Pickup Address"
                          : "Customer Address"}
                      </th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          <a
                            href={generateGoogleMapurl(
                              data.address?.[0].latitude,
                              data.address?.[0].longitude
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {address}
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Id</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {data.randomOrderId}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table1 ordertable ordertable-info">
                  <tbody>
                    <tr>
                      <th>Order Total</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          ${data.totalGrossAmount}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Service Type</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {data.OrderServiceOption}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status Type</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {paymentData?.paymentStatus}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Order Date</th>
                      <td>
                        <div className="itemsWrapper d-inline-flex align-items-center">
                          {new Date(data.createdAt).toUTCString()}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-sm-6 text-center">
                <div className="form-group">
                  <div className="checoutbtn">
                    <button
                      type="submit"
                      className="btn mt-0"
                      onClick={() => {
                        handleChangeOrderStatus(data._id);
                      }}
                    >
                      <ButtonName />
                    </button>
                  </div>
                </div>
              </div>


            </div>
            <div className="table-respnsive">
              <table className=" table table-bordered checkListWrapper2">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Items</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderitemData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="imgWrapper">
                            {item.itemImage ? (
                              <img
                                src={changeImageUrl(item.itemImage)}
                                alt="item here"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                }}
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                alt="items here"
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="itemsWrapper d-inline-flex align-items-center">
                            <div className="details">
                              <h6>{item.itemName}</h6>
                              <p>
                                {item.orderModifierDetail.map(
                                  (modifier, index) => {
                                    return modifier.isDeleted ? (
                                      <div className="order-modifier color-red">
                                        No {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption != " "
                                          ? `(${modifier.halfToppingOption.toUpperCase()} )`
                                          : ""}
                                        {index ==
                                        item.orderModifierDetail.length - 1
                                          ? ""
                                          : ","}
                                      </div>
                                    ) : (
                                      <div className="order-modifier">
                                        {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption != " "
                                          ? `( ${modifier.halfToppingOption.toUpperCase()} ${
                                              modifier.layer == "extra"
                                                ? `x 2`
                                                : ""
                                            })`
                                          : modifier.layer == "extra"
                                          ? `(x 2)`
                                          : ""}
                                        {index ==
                                        item.orderModifierDetail.length - 1
                                          ? ""
                                          : ","}
                                      </div>
                                    );
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="sizes">
                            <span className="customController">
                              {item.varaitionName}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="addmore-row justify-content-center p-0 border-top-0">
                            <div className="control">{item.quantity}</div>
                          </div>
                        </td>
                        <td>${priceMature(item.itemPrice * item.quantity)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="bottam">
            <div className="row">
              <div className="col-sm-6">
                <ul className="list">
                  <li>
                    <div className="TotalMain">
                      <div>
                        {" "}
                        <h3>Total Amount</h3>
                      </div>
                      <div>
                        <p>${priceMature(data.totalAmount)}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="TotalMain">
                      <div>
                        {" "}
                        <h3>Total Discount Amount </h3>
                      </div>
                      <div>
                        <p>${priceMature(data.discountAmount)}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="TotalMain">
                      <div>
                        {" "}
                        <h3>Total Tax Amount</h3>
                      </div>
                      <div>
                        <p>${priceMature(data.taxAmount)}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="TotalMain">
                      <div>
                        {" "}
                        <h3>Delivery Charges</h3>
                      </div>
                      <div>
                        <p>${priceMature(data.deliveryCharge)}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="TotalMain">
                      <div>
                        {" "}
                        <h3>Gross Total</h3>
                      </div>
                      <div>
                        <p>${priceMature(data.totalGrossAmount)}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              
            </div>
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageDriverOrderPopup);
