import React, { useState, useEffect } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "./manageMasterModifier.css";
import validation from "../../helpers/validation";
import {
  createMasterModifierListItemAction,
  updateMasterModifierListItemAction,
} from "../../Action/manageMasterModifierAction";

function AddMasterModifierModal(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data: editDataObject },
  } = props;
  /** get the modifier category from redux */
  const respModiCat = useSelector(
    (state) => state.modifierCat.modifierCategoryList
  );
  const [masterModifier, setMasterModifier] = useState([
    {
      MadifierCategoryID: [],
      MadifierName: "",
      Description: "",
    },
  ]);
  const [errors, setErrors] = useState([]);
  /** this effect will set modifier list to form state */
  useEffect(() => {
    if (edit) {
      setMasterModifier((prevState) =>
        prevState.map((item) => ({
          MadifierCategoryID: respModiCat.filter(
            (item) =>
              item.UniqueID === editDataObject.ModifiersCategory.UniqueID
          ),
          MadifierName: editDataObject.MadifierName,
          Description: editDataObject.Description,
        }))
      );
    }
  }, [edit, editDataObject, respModiCat]);
  /*** this function will close the model  */
  const closeModaleWithClearAll = () => {
    closeModal();
    setMasterModifier([
      {
        MadifierCategoryID: [],
        MadifierName: "",
        Description: "",
      },
    ]);
    setErrors([]);
    // setDuplicateErrors([]);
    // setTypeHeadCheck([]);
  };
  /***  this function will handle the modifier category change */
  const onChangeModifierscategory = (data, index) => {
    // setTypeHeadCheck(data);
    setMasterModifier((prevSate) =>
      prevSate.map((item, i) => {
        return (i === index && Object.keys(data[0] ?? "").length > 0) ||
          (i === index && Object.keys(data[0] ?? "").length === 0)
          ? { ...item, MadifierCategoryID: data }
          : item;
      })
    );
  };
  /***  this function will handle the master modifier category change */
  const onChangeMasterCategory = (e, index) => {
    setMasterModifier((prevSate) =>
      prevSate.map((item, i) =>
        i === index ? { ...item, [e.target.name]: e.target.value } : item
      )
    );
  };
  /** this function will add new row to the master modifier  */
  const addNewitemRowMasterModifier = () => {
    const addNewValues = [...masterModifier];
    addNewValues.push({
      MadifierCategoryID: [],
      MadifierName: "",
      Description: "",
    });
    setMasterModifier(addNewValues);
  };
  /** this function will delete  row to the master modifier  */
  const deleteMasterModifierRow = (index) => {
    const deleteValues = [...masterModifier];
    deleteValues.splice(index, 1);
    setMasterModifier(deleteValues);
    // setDuplicateErrors([]);
    // setDuplicateShow(false);
  };
  /** this function will handle the submit of the form */
  const onSubmitMasterFormModifier = (e, action) => {
    e.preventDefault();
    const isValid = validation(masterModifier);
    setErrors(isValid);
    const arrayIsValid = isValid.filter((item) => Object.keys(item).length > 0);

    if (Object.keys(arrayIsValid).length === 0) {
      const dataOfAction = masterModifier.map((item) => ({
        ...item,
        MadifierCategoryID: item.MadifierCategoryID[0].UniqueID,
      }));
      switch (action) {
        case "create":
          dispatch(createMasterModifierListItemAction({ data: dataOfAction }));
          closeModaleWithClearAll();
          break;

        case "update":
          dispatch(
            updateMasterModifierListItemAction({
              data: dataOfAction[0],
              itemId: editDataObject._id,
            })
          );
          closeModaleWithClearAll();
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addNewitemRowMasterModifier}
          title={`${edit ? "Update" : "Add"} Master Modifier`}
          addNewButtonName={"Add New"}
          edit={edit}
        >
          <Form
            className={"grid_add_master_modifier"}
            onSubmit={(e) =>
              onSubmitMasterFormModifier(e, edit ? "update" : "create")
            }
          >
            <div className="">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th>Modifier Category Name</th>
                    <th>Modifier Name</th>
                    <th>Description</th>
                    {edit === true ? (
                      ""
                    ) : (
                      <th>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(masterModifier).length > 0
                    ? masterModifier.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Typeahead
                            id={"MadifierCategoryID"}
                            labelKey="MadifierCategoryName"
                            className="Typeahead_multiple_select"
                            // multiple
                            filterBy={["MadifierCategoryName"]}
                            options={respModiCat}
                            placeholder={`Search & Select`}
                            selected={item.MadifierCategoryID}
                            onChange={(data) =>
                              onChangeModifierscategory(data, index)
                            }
                          />
                          {errors[index]?.MadifierCategoryID && (
                            <p className="error-validation">
                              {errors[index].MadifierCategoryID}
                            </p>
                          )}
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="MadifierName"
                            placeholder="Modifier Name"

                            value={item.MadifierName}
                            onChange={(e) => onChangeMasterCategory(e, index)}
                          />
                          {errors[index]?.MadifierName && (
                            <p className="error-validation">
                              {errors[index].MadifierName}
                            </p>
                          )}
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="Description"
                            placeholder="Description"
                            value={item.Description}
                            onChange={(e) => onChangeMasterCategory(e, index)}

                          />
                          {errors[index]?.Description && (
                            <p className="error-validation">
                              {errors[index].Description}
                            </p>
                          )}
                        </td>
                        {edit === true ? (
                          ""
                        ) : (
                          <td>
                            <div className="deleteButton_modifier_category">
                              <button
                                disabled={
                                  Object.keys(masterModifier).length === 1
                                    ? true
                                    : false
                                }
                                className="deleteButton_1"
                                onClick={() => deleteMasterModifierRow(index)}
                                type="button"
                              >
                                <FaRegTrashAlt />
                              </button>
                            </div>
                          </td>
                        )}



                      </tr>
                    ))
                    : ""}
                </tbody>
              </table>
            </div>
            <Row className="mt-3 mb-3">
              <Col md={12} className="text-right">
                <div className="regBtn btn_right_pad_1">
                  <button className="btn">
                    {edit ? "Update" : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </RightSideModal>
      )}
    </>
  );
}

export default AddMasterModifierModal;
