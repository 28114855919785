import React, { useState, useEffect, memo } from "react";
import { Modal, Row, FormLabel, Form, Image, Col } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa";
import "./ManageMenu.css";
import validation from "../../helpers/validation";
import {
  Typeahead,
  Highlighter,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import {
  postMenuItems,
  updateMenuItemsList,
} from "../../Action/menuItemsAction";

const AddModalRightAnimation = (props) => {
  const dispatch = useDispatch();
  const {
    show,
    animationModal: showModal,
    closeNewMenu,
    modifierTypeList,
    individualCategoryList,
    editData: { data: dataMenuEdit, edit },
  } = props;
  const [menuItemRow, setMenuItemRow] = useState([
    {
      categoryId: [],
      modifiersId: [],
      defaultModifierId: [],
      itemImage: [],
      itemName: "",
      itemDescription: "",
      halfTopping: false,
      outOfStock: false,
      isUpsale: false,
    },
  ]);
  const [errors, setErrors] = useState({});
  const [modifiresNameList, setModifiresNameList] = useState([]);
  const [modifiersWithDefault, setModifiersWithDefault] = useState([]);

  /* this effect will save modifier list to the state */
  useEffect(() => {
    if (modifierTypeList.length > 0 && edit === false) {
      const dataModi = modifierTypeList.map((item) => ({
        ...item,
        defaultModifier: false,
      }));
      setModifiresNameList(dataModi);
      setModifiersWithDefault([dataModi]);
    }
    if (edit === true && modifierTypeList.length > 0) {
      const dataModiEdit = modifierTypeList.map((item) => ({
        ...item,
        defaultModifier: false,
      }));
      const defaultModifireEdit = dataMenuEdit.ModifierDetails.filter(
        (item) => item.isDefault === true
      );

      const modifiersWithDefaultEdit = dataModiEdit.map((item) => {
        const isObjeMatch = defaultModifireEdit.filter(
          (itemEdit) => itemEdit.modifiersId === item.UniqueID
        );
        return Object.keys(isObjeMatch).length > 0
          ? { ...item, defaultModifier: isObjeMatch[0].isDefault }
          : item;
      });
      /** set  the default modifier  array  */
      setModifiersWithDefault([modifiersWithDefaultEdit]);
      const noneDefaultModifireEdit = dataMenuEdit.ModifierDetails.filter(
        (item) => item.isDefault === false
      );
      const modifiersWithNoneDefaultEdit = dataModiEdit.filter((item) => {
        const isNonDefaultObjeMatch = noneDefaultModifireEdit.filter(
          (itemEdit) => itemEdit.modifiersId === item.UniqueID
        );
        if (Object.keys(isNonDefaultObjeMatch).length > 0) {
          return item;
        }
        return false;
      });
      /** finally update the menu item state */
      setMenuItemRow([
        {
          categoryId: individualCategoryList.filter(
            (item) =>
              item.UniqueID === dataMenuEdit.productcategory.categoryId || ""
          ),
          modifiersId: modifiersWithNoneDefaultEdit || "",
          defaultModifierId: defaultModifireEdit || "",
          itemImage: [
            {
              imagePrev: dataMenuEdit.menuitems.itemImage || "",
              dataserver: "",
            },
          ],
          itemName: dataMenuEdit.menuitems.itemName || "",
          itemDescription: dataMenuEdit.menuitems.itemDescription || "",
          halfTopping: dataMenuEdit.menuitems.halfTopping || false,
          outOfStock: dataMenuEdit.menuitems.outOfStock || false,
          isUpsale: dataMenuEdit.menuitems.isUpsale || false,
        },
      ]);
    }
  }, [modifierTypeList, edit, dataMenuEdit, individualCategoryList]);

  showModal === "yes"
    ? document.body.classList.add("modal-open")
    : document.body.classList.remove("modal-open");
  showModal === "yes"
    ? (document.body.style.overflow = "hidden")
    : document.body.style.removeProperty("overflow");

  /*** this will add a new row in form */
  const addMenuItemInfo = () => {
    const values = [...menuItemRow];
    const modi = [...modifiersWithDefault];
    values.push({
      categoryId: [],
      modifiersId: [],
      defaultModifierId: [],
      itemImage: [],
      itemName: "",
      itemDescription: "",
      isUpsale: false,
      outOfStock: false,
      halfTopping: false,
    });
    setMenuItemRow(values);
    modi.push(modifiresNameList);
    setModifiersWithDefault(modi);
  };
  /*** this will delete the row from list  */
  const deleteMenuItemInfo = (index) => {
    const removeValues = [...menuItemRow];
    removeValues.splice(index, 1);
    setMenuItemRow(removeValues);

    // modifiersWithDefault
    const removeModi = [...modifiersWithDefault];
    removeModi.splice(index, 1);
    setModifiersWithDefault(removeModi);
  };
  /*** this will handle change for form  */
  const menuItemInfoChange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? e.target.name === "itemImage"
            ? {
                ...item,
                itemImage: [
                  {
                    imagePrev: URL.createObjectURL(e.target.files[0]),
                    dataserver: e.target.files[0],
                  },
                ],
              }
            : { ...item, [e.target.name]: e.target.value }
          : item
      )
    );
  };
  /*** this will handle change only for topping  */
  const menuItemToppingChange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, [e.target.name]: !item[e.target.name] } : item
      )
    );
  };
  /*** this will handle category change for form  */
  const categorySearchonchange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, ind) =>
        ind === index ? { ...item, categoryId: e } : item
      )
    );
  };
  /*** this will handle modifier category change for form  */
  const modifierSearchonchange = (e, index) => {
    setMenuItemRow((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? {
              ...item,
              modifiersId: e.filter((element) => {
                return (
                  menuItemRow[i].defaultModifierId.findIndex(
                    (e) => e.modifiersId == element.UniqueID
                  ) === -1
                );
              }),
            }
          : item
      )
    );
  };
  /** this will handle change on default modifier  */
  const modifierDefaultOnchange = (listindex, index, result) => {
    setModifiersWithDefault((prevState) =>
      prevState.map((itemArray, ind) =>
        ind === index
          ? itemArray.map((item, i) =>
              item.UniqueID === result.UniqueID
                ? { ...item, defaultModifier: !item.defaultModifier }
                : item
            )
          : itemArray
      )
    );
    setMenuItemRow((prevState) =>
      prevState.map((item, ind) => {
        if (ind === index) {
          if (result.defaultModifier === false) {
            const resultData = { ...result };
            resultData.defaultModifier = true;
            item["defaultModifierId"].push(resultData);
            return item;
          }
          if (result.defaultModifier === true) {
            const valueIndex = item["defaultModifierId"].findIndex(
              (itemId) => itemId._id === result._id
            );
            item["defaultModifierId"].splice(valueIndex, 1);
            return item;
          }
        }
        return item;
      })
    );
  };
  /** this will handle update the menu items  */
  const updateMenuItems = (e, action) => {
    e.preventDefault();
    const isValid = validation(menuItemRow);
    setErrors(isValid);
    const checkIsValid = isValid.map((item) =>
      Object.keys(item).length === 0 ? true : false
    );

    if (!checkIsValid.includes(false) === true) {
      const dataArray = menuItemRow.map((item, index) => {
        const modifi = [];
        modifi.push(item.defaultModifierId);
        modifi.push(item.modifiersId);
        const joinAllAray = modifi.flat(Infinity);
        var removeDuplicate = [
          ...new Map(joinAllAray.map((item) => [item._id, item])).values(),
        ];
        return {
          categoryId: item.categoryId[0].UniqueID,
          itemImage: item.itemImage[0]?.dataserver ?? "",
          itemName: item.itemName,
          halfTopping: item.halfTopping || false,
          isUpsale: item.isUpsale || false,
          outOfStock: item.outOfStock || false,
          itemDescription: item.itemDescription,
          ModifierDetails: removeDuplicate,
        };
      });
      for (let i = 0; i < dataArray.length; i++) {
        const formDataCreate = new FormData();
        formDataCreate.append("categoryId", dataArray[i].categoryId);
        formDataCreate.append("itemDescription", dataArray[i].itemDescription);
        formDataCreate.append("outOfStock", dataArray[i].outOfStock);
        formDataCreate.append("halfTopping", dataArray[i].halfTopping);
        formDataCreate.append("itemImage", dataArray[i].itemImage);
        formDataCreate.append("itemName", dataArray[i].itemName);
        formDataCreate.append("isUpsale", dataArray[i].isUpsale);
        formDataCreate.append(
          "ModifierDetails",
          JSON.stringify(dataArray[i].ModifierDetails)
        );
        switch (action) {
          case "create":
            dispatch(postMenuItems({ data: formDataCreate }));
            break;
          case "update":
            dispatch(
              updateMenuItemsList({
                data: formDataCreate,
                itemId: dataMenuEdit.menuitems.itemId,
              })
            );
            break;
          default:
            break;
        }
        if (i == dataArray.length - 1) {
          closeModal();
        }
      }
    }
  };
  /*** this will handle the close of model  */
  const closeModal = () => {
    closeNewMenu();
    setMenuItemRow([
      {
        categoryId: [],
        defaultModifierId: [],
        modifiersId: [],
        itemImage: [],
        itemName: "",
        itemDescription: "",
        halfTopping: false,
        outOfStock: false,
        isUpsale: false,
      },
    ]);
    setModifiersWithDefault([modifiresNameList]);
    setErrors([]);
  };
  return (
    <>
      <Modal
        size="lg"
        animation={true}
        backdrop={false}
        className={`modal_styled_1 ${
          showModal === "yes" ? "transitionStyle_1" : ""
        }`}
        show={show === "yes" ? true : false}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {edit === true ? "Update Menu Items Info" : "Add Menu Items Info"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {edit === true ? (
            ""
          ) : (
            <Row>
              <Col md={12} className="text-right">
                <div className="regBtn add_small_button_1">
                  <button className="btn" onClick={addMenuItemInfo}>
                    Add New
                  </button>
                </div>
              </Col>
            </Row>
          )}

          <div className="modalBodyForm_1 add_menu_item_modal">
            <Form
              onSubmit={(e) =>
                updateMenuItems(e, edit === true ? "update" : "create")
              }
            >
              <div className="table-responsive table-responsive21">
                <table className="table  add_menu_info">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Modifiers</th>
                      <th>Out Of Stock</th>
                      <th>Half Toppings</th>
                      <th>Upselling</th>
                      <th>Image</th>
                      {edit === true ? "" : <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(menuItemRow).length > 0
                      ? menuItemRow.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Typeahead
                                id="CategoryName"
                                filterBy={["CategoryName"]}
                                labelKey="CategoryName"
                                options={individualCategoryList}
                                placeholder="Select Category"
                                onChange={(e) =>
                                  categorySearchonchange(e, index)
                                }
                                selected={item.categoryId}
                              />
                              {errors[index]?.categoryId && (
                                <p className="error-validation">
                                  {errors[index].categoryId}
                                </p>
                              )}
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                value={item.itemName}
                                name="itemName"
                                placeholder="Item Name"
                                onChange={(e) => menuItemInfoChange(e, index)}
                              />
                              {errors[index]?.itemName && (
                                <p className="error-validation">
                                  {errors[index].itemName}
                                </p>
                              )}
                            </td>
                            <td>
                              <Form.Control
                                type="type"
                                value={item.itemDescription}
                                placeholder="Description"
                                name="itemDescription"
                                onChange={(e) => menuItemInfoChange(e, index)}
                              />
                              {errors[index]?.itemDescription && (
                                <p className="error-validation">
                                  {errors[index].itemDescription}
                                </p>
                              )}
                            </td>
                            <td>
                              <div className="uploadImageLarge_1">
                                <Typeahead
                                  id="MadifierName"
                                  className="modifier-select"
                                  filterBy={["MadifierName"]}
                                  labelKey="MadifierName"
                                  multiple
                                  options={modifiersWithDefault[index] ?? []}
                                  placeholder="Search modifiers"
                                  selected={item.modifiersId}
                                  onChange={(e) =>
                                    modifierSearchonchange(e, index)
                                  }
                                  renderMenu={(results, menuProps) => (
                                    <Menu {...menuProps}>
                                      <ul className="typeHeadCheckBoxList">
                                        {results.map((result, ind) => (
                                          <li key={ind}>
                                            <MenuItem
                                              option={result}
                                              position={ind}
                                            >
                                              <Highlighter
                                                search={result.MadifierName}
                                              >
                                                {result.MadifierName}
                                              </Highlighter>
                                            </MenuItem>
                                            <span
                                              className={ind}
                                              position={ind}
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                checked={result.defaultModifier}
                                                name={`checkModi${ind}`}
                                                onChange={() =>
                                                  modifierDefaultOnchange(
                                                    ind,
                                                    index,
                                                    result
                                                  )
                                                }
                                              />
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    </Menu>
                                  )}
                                />
                                {errors[index]?.modifiersId && (
                                  <p className="error-validation">
                                    {errors[index].modifiersId}
                                  </p>
                                )}
                              </div>
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={item.outOfStock}
                                name="outOfStock"
                                onChange={(e) =>
                                  menuItemToppingChange(e, index)
                                }
                              />
                              {errors[index]?.outOfStock && (
                                <p className="error-validation">
                                  {errors[index].outOfStock}
                                </p>
                              )}
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={item.halfTopping}
                                name="halfTopping"
                                onChange={(e) =>
                                  menuItemToppingChange(e, index)
                                }
                              />
                              {errors[index]?.itemDescription && (
                                <p className="error-validation">
                                  {errors[index].halfTopping}
                                </p>
                              )}
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={item.isUpsale}
                                name="isUpsale"
                                onChange={(e) =>
                                  menuItemToppingChange(e, index)
                                }
                              />
                              {errors[index]?.isUpsale && (
                                <p className="error-validation">
                                  {errors[index].isUpsale}
                                </p>
                              )}
                            </td>
                            <td>
                              <div className="uploadImageLarge_1">
                                <Form.Group
                                  controlId="formFile"
                                  className="uploadImageRow_1"
                                >
                                  <div>
                                    <Form.Control
                                      // value={item.imageitem}
                                      name="itemImage"
                                      type="file"
                                      className="form-control"
                                      onChange={(e) =>
                                        menuItemInfoChange(e, index)
                                      }
                                    />
                                    <span>
                                      {" "}
                                      {item.itemImage.length === 0 ? (
                                        <FaRegImage />
                                      ) : (
                                        <Image
                                          alt="image"
                                          src={
                                            item.itemImage[0]?.imagePrev || ""
                                          }
                                        />
                                      )}
                                    </span>
                                  </div>

                                  {errors[index]?.itemImage && (
                                    <p className="error-validation">
                                      {errors[index].itemImage}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                            </td>
                            {edit === true ? (
                              ""
                            ) : (
                              <td>
                                <button
                                  className="deleteButton_1"
                                  disabled={
                                    Object.keys(menuItemRow).length === 1
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  onClick={() => deleteMenuItemInfo(index)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>

              {Object.keys(menuItemRow).length > 0 ? (
                <Row className="mt-3 mb-3">
                  <Col md={12} className="text-right">
                    <div className="regBtn btn_right_pad_1">
                      <button className="btn">
                        {edit === true ? "Update" : "Save"}
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {showModal === "yes" ? (
        <div className="right-modal-backdrop_1"></div>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(AddModalRightAnimation);
