import React, {useEffect, memo} from "react";
import { Modal, Row, Col } from "react-bootstrap";

const RightSideModal = (props) => {
  const {
    showModal,
    closeModal,
    children,
    sizeModal,
    addNewitemRow,
    title,
    addNewButtonName,
    edit,
    disabled,
    className
  } = props;
 
  useEffect(() => {
    if(showModal === 'yes') {    
      document.body.classList.add('modal-open');
      document.body.style.overflow = 'hidden'
    } else {     
      document.body.classList.remove('modal-open');  
      document.body.style.overflow = null;
    }
  }, [showModal])
  

  return (
    <>
      <Modal
        size={sizeModal}
        animation={true}
        backdrop={false}
        className={`modal_styled_2 ${className || ""} ${
          showModal === "yes" ? "transitionStyle_2" : ""
        } `}
        // show={showModal === "yes" ? true : false}
        show={true}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {edit === true ? (
            ""
          ) : (
            <Row>
              <Col md={12} className="text-right">
                {addNewButtonName  ? <div className="regBtn add_small_button_2">
                  <button disabled={disabled} className="btn" onClick={addNewitemRow}>
                    {addNewButtonName}
                  </button>
                </div>:""}
              </Col>
            </Row>
          )}

          <div className="modalBodyForm_2">{children}</div>
        </Modal.Body>
      </Modal>
      {showModal === "yes" ? (
        <div className="right-modal-backdrop_2"></div>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(RightSideModal);
