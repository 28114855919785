import * as api from './api';


// get
export const getModifierCategoryListService = () => {
    try{
        const res = api.get('modifireCategoryType/getModifierCategory');
        return res
    } catch(error) {
        return error
    }
}

// post
export const postModifierCategoryListService = async (payload) => {
    const { data } = payload;
    try {
        let res = await api.post('modifireCategoryType/addModiFiersCategoryType', data);
        return res;
    } catch(err) {
        return err;
    }
}



// delete 
export const deleteModifierCategoryListService = async (payload) => {
    const { itemId } = payload;
    try {
        let res = await api.deleteRec(`modifireCategoryType/deleteModifierCategoryItem/${itemId}`);
        return res;
    } catch(err) {
        return err;
    }
}

// update 
export const updateModifierCategoryListService = async (payload) => {
    const { itemId, data } = payload;
    try {
        let res = await api.put(`modifireCategoryType/updateModifierCategoryItem/${itemId}`, data);
        return res;
    } catch(err) {
        return err;
    }
}
