import React, { useState, useEffect, useCallback } from "react";
import { FaRegEdit, FaCopy, FaQrcode, FaRedo } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../helpers/ConfirmModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";
import { ButtonGroup, Button } from "react-bootstrap";
import {
  driverCheckInOut,
  getCrewOrDriver,
  updateCrewOrDriver,
  updateCrewOrDriverOtp,
} from "../../Action/crewOrDriverAction";
import ManageOrderPopup from "./manageCrewPopupDriver";
import { Store } from "react-notifications-component";
import CrewOrDriverQrCodePopup from "./crewOrDriverQrCodePopup";

const ManageOrderCrew = () => {
  const responseData = useSelector((state) => state.crewOrDriver);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [showModal, setShowModal] = useState("no");
  const [showQrModal, setshowQrModal] = useState("no");
  /*** this will copy the string into the clipboard  */
  const handCopy = (text) => {
    navigator.clipboard.writeText(text);
    Store.addNotification({
      message: "Copied",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  };
  const [deletConfirmModal, setDeletConfirmModal] = useState({
    show: false,
    data: [],
  });
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
  });
  const activateDeactivate = (user) => {
    dispatch(
      updateCrewOrDriver({ _id: user._id, active: user.active ? false : true })
    );
  };
  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
        });
        addNewModal();
        break;
      case "qrCode":
        setEditData({
          edit: false,
          data: data,
        });
        handleQrModal();
        break;
      default:
        break;
    }
  };
  const updateCrewOrDriverOtpHandler = (_id) => {
    dispatch(updateCrewOrDriverOtp({ _id }));
  };
  const columns = [
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "fname",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "lname",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "driverOtp",
      text: "OTP",
      sort: true,
      formatter: (cell, row) => {
        return row.driverOtp;
      },
    },
    {
      dataField: "accessUrl",
      text: "Access Url",
      formatter: (cell, row) => {
        return (
          <div className="access_url">
            <span>
              <FaQrcode
                onClick={() => {
                  actionData(row, "qrCode");
                }}
              />
            </span>
          </div>
        );
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button
                onClick={() => {
                  actionData(row, "edit");
                }}
              >
                <FaRegEdit />
              </Button>{" "}
              <label
                className="switch"
                onChange={(e) => activateDeactivate(row)}
              >
                <input type="checkbox" defaultChecked={row.active} />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        );
      },
    },
  ];

  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
      });
    }
  };
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const handleQrModal = useCallback(() =>
    setshowQrModal((prevState) => (prevState === "yes" ? "no" : "yes"))
  );
  // mount
  useEffect(() => {
    dispatch(getCrewOrDriver());
  }, [dispatch]);

  // update
  useEffect(() => {
    const { crewOrDriverList, loading, tableLading } = responseData;
    if (crewOrDriverList) {
      setLitsData(crewOrDriverList);
      setIsLoading({
        pageLoading: loading,
        tableLoading: tableLading,
      });
    }
  }, [responseData]);
  const handleCloseAlert = () => {
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  const handleSuccessAlert = () => {
    dispatch(
      deleteModifierCategoryAction({ itemId: deletConfirmModal.data._id })
    );
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };

  if (isLoding.pageLoading === true) {
    return <PageLoader size={50} />;
  }
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Crew/Drivers Users </h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn custom-mobileheading pb-2">
                  <h2>Manage Crew/Drivers Users</h2>
                  <button className="btn custombtn" onClick={addNewModal}>
                    Add Users
                  </button>
                </div>
                <div className="card-body">
                  {isLoding.pageLoading === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ManageOrderPopup
          showModal={showModal}
          closeModal={addNewModalMemorized}
          editData={editData}
        />
      )}
      {showQrModal === "yes" && (
        <CrewOrDriverQrCodePopup
          sizeModal={1000}
          showModal={showQrModal}
          closeModal={handleQrModal}
          editData={editData}
        />
      )}
      {deletConfirmModal && (
        <ConfirmModal
          show={deletConfirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
    </>
  );
};
export default ManageOrderCrew;
