import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/paymentServices";
import { Store } from 'react-notifications-component';
import { getOrdersList } from "./manageOrdersSaga";
// get
export function* getPayment() {
   const response = yield call(auth.getpayment);
   try {
      yield put({
         type: types.GET_PAYMENT_SUCCESS,
         payload: response.result,
         msg: response.message
      })
   } catch (err) {
      yield put({
         type: types.GET_PAYMENT_ERROR,
         msg: response.message
      })
   }
}
/** refund category  */
export function* refundPaymemt({ payload }) {
   try {
      const response = yield call(auth.refundPaymemt, payload);
      if (response.status === 200) {
         yield call(getOrdersList);
         Store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
               duration: 3000,
               onScreen: true,
            },
         });
      } else {
         throw new Error();
      }
   } catch (err) {
      Store.addNotification({
         message: "refund Failed",
         type: "danger",
         insert: "top",
         container: "top-center",
         animationIn: ["animate__animated", "animate__fadeIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
            duration: 3000,
            onScreen: true,
         },
      });
   }
}