import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginUserAction } from "../../Action/authAction";
import validation from "../../helpers/validation";
import envelopeIcon from "../../Assets/images/envelope.svg";
import o_original_logo from "../../Assets/images/o_original_logo.jpg";
import padlockIcon from "../../Assets/images/padlock.svg";
import loginBg from "../../Assets/images/Login-bg.svg";
import { successNotification } from "../../helpers/notificationActivity";

class LoginComponent extends Component {
  state = {
    loading: false,
    email: "",
    password: "",
    errors: {},
    msg: "",
  };

  /**
   * handle change of the form
   */
  valueChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    const { msg } = this.props.logOut;
    if (prevState.msg !== msg) {
      const { msg } = this.props.logOut;
      this.setState({ msg: msg });
      this.notiFicationUserActivity(msg);
    }
  }

  notiFicationUserActivity = (msg) => {
    if (msg !== "" && msg !== this.state.msg && msg !== undefined) {
      successNotification(msg);
    }
  };
  componentWillUnmount() {
    this.setState({ msg: "" });
    this.notiFicationUserActivity();
  }
  /***
   * submit the form
   */
  onFinish = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const isvalid = validation({ email, password });
    this.setState({
      errors: isvalid,
    });
    if (Object.keys(isvalid).length === 0) {
      const payload = {
        email: email,
        password: password.trim(),
      };
      this.setState({ loading: true });
      setTimeout(() => {
        this.props.loginUserAction(payload);
      }, 1000);
    }
  };

  render() {
    const { email, password, errors } = this.state;

    return (
      <section
        className="loginSec"
        style={{ backgroundImage: `url(${loginBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="loginBox">
                <div className="loginform">
                  <div className="loginlogo">
                    <img
                      src={o_original_logo}
                      className="img-fluid"
                      alt="o_original_logo"
                    />
                  </div>
                  <div className="logintitle">
                    <h2>Login</h2>
                    <span>Please Login to Continue</span>
                  </div>
                  <form onSubmit={this.onFinish}>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="form-group has-search">
                          <label>Email </label>
                          <span className="form-control-feedback">
                            <img
                              className="login-email"
                              src={envelopeIcon}
                              alt=""
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your email"
                            name="email"
                            value={email}
                            onChange={(e) => this.valueChange(e)}
                          />
                          {errors.email && (
                            <p className="error-validation">{errors.email}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group has-search">
                          <label>Password </label>
                          <span className="form-control-feedback">
                            <img src={padlockIcon} alt="" />
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            name="password"
                            value={password}
                            onChange={(e) => this.valueChange(e)}
                          />
                          {errors.password && (
                            <p className="error-validation">
                              {errors.password}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <Link to="/forgot-password" className="forglink">
                          Forgot Password?
                        </Link>
                      </div>

                      <div className="col-md-6 text-right">
                        <div className="regBtn">
                          <button className="btn">
                            Login <img src="images/loginicon.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  logOut: state.logOut,
});
const mapDispatchToProps = {
  loginUserAction: loginUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
