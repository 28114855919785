import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  payment:[],
  msg: "",
};
function managePaymentReducer(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_PAYMENT:
      return { ...state, loading: true };
    case types.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        payment: payload || [],
        msg: msg,
      };
    case types.GET_PAYMENT_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    default:
      return state;
  }
}

export default managePaymentReducer;
