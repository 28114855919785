import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapeTableData from "../../../helpers/BootstrapeTableData";
import { FaRegEdit } from "react-icons/fa";
import ManageDriverIndex from "../manageDriverIndex";
import {
  checkInOutHistory,
  driverOrderHistory,
} from "../../../Services/manageCrewOrDriver";
import { useParams } from "react-router-dom";
import formettedDate from "../../../helpers/moomentDate";

const DriverOrderHistory = function DriverOrders(props) {
  const { userId } = useParams();
  const [litsData, setLitsData] = useState([]);
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  /** this will get driver history  = */
  const repectFunction = () => {
    setIsLoading({
      pageLoading: false,
      tableLoading: true,
    });
    driverOrderHistory(userId)
      .then((res) => {
        setLitsData(res.result);
        setIsLoading({
          pageLoading: false,
          tableLoading: false,
        });
      })
      .catch((error) => {
        setIsLoading({
          pageLoading: false,
          tableLoading: false,
        });
      });
  };
  /** this effect will repectfunction in every 5 sec */
  useEffect(() => {
    repectFunction();
    setInterval((e) => {
      try {
        if (document.getElementById("search-bar-0").value.length < 1) {
          repectFunction();
        }
      } catch (err) {
        //
      }
    }, 10000);
  }, []);

  const columns = [
    {
      dataField: "orderId",
      text: "Order Id",
      formatter: (cell, row) => {
        return cell.randomOrderId;
      },
      filterValue: (cell, row) => {
        return cell.randomOrderId;
      },
      sortValue: (cell, row) => {
        return cell.randomOrderId;
      },
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date Of Orders",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
  ];
  return (
    <>
      <ManageDriverIndex>
        <div className="order-wrapper">
          <BootstrapeTableData
            data={litsData}
            columns={columns}
            keyfield={"_id"}
            searchBar={true}
            isLoading={isLoding.tableLoading}
          />
        </div>
      </ManageDriverIndex>
    </>
  );
};

export default DriverOrderHistory;
