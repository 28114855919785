import * as types from "./types";

// GET
export const getProductCategoryAction = () => ({
  type: types.GET_PRODUCT_CATEGORY,
});

// GET NOT REQUIRED TOKEN
export const getProductCategoryPublicAction = () => ({
  type: types.GET_PRODUCT_CATEGORY_NO_TOKEN,
});

// Post
export const postProductCategoryAction = (data) => ({
  type: types.POST_PRODUCT_CATEGORY,
  payload: data,
});

// Delete
export const deleteProductCategoryAction = (payload) => ({
  type: types.DELETE_PRODUCT_CATEGORY,
  payload,
});

// Update
export const updateProductCategoryAction = (data) => ({
  type: types.UPDATE_PRODUCT_CATEGORY,
  payload: data,
});

// get individual category list
export const getIndividualProductCategoryList = () => ({
  type: types.GET_INDIVIDUAL_CATEGORY_LIST,
});

// get product category item by item public,
export const getProductCategoryItemListById = (itemId) => ({
  type: types.GET_PRODUCT_ITEM_LIST_BYID,
  payload: itemId,
});

// get customize item variation by item public,
export const getCustomizeItemVariationListById = (itemId) => ({
  type: types.GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID,
  payload: itemId,
});



// get customiz modifier by Id
export const getCustomizeItemModifierById = (itemId) => ({
  type: types.GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID,
  payload: itemId,

})

























