import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer";
import { useLocation } from "react-router-dom";
const CustomerContainer = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <section className="mainbodysection">
        <div className="container">
          <div className="user-account-page">{children}</div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default CustomerContainer;
